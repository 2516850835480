import React from "react";
import { PurchaseOrderBadgeStyled } from "./styles";
import { cx } from "@emotion/css";
import TooltipTD from "../Tooltip/TooltipTD";

function POBadge({
  number,
  template = {
    emoji: "",
    name: "",
  },
  id = "",
  className = "purchase-order-badge",
  isDisable,
  style = {},
}) {
  return (
    <PurchaseOrderBadgeStyled
      className={cx(className, { isDisable })}
      id={id}
      style={style}
    >
      {template.emoji && (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TooltipTD
              style={{ fontSize: 14, lineHeight: "13px" }}
              label={template.name}
            >
              {template.emoji}
            </TooltipTD>
          </div>
          <span className="numberText" style={{ fontSize: 16 }}>
            |
          </span>
        </>
      )}
      <span className="numberText">{number}</span>
    </PurchaseOrderBadgeStyled>
  );
}

export default POBadge;
