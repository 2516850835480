import React from "react";
import { PANEL_TEXT } from "../../helpers/constants";
import { ExpandPanelIcon } from "../../helpers/iconSvgPath";
import { cx } from "@emotion/css";
import { ExpandTooltipStyled } from "./styles";

function ExpandPanelButton({ handleActivePanel, activePanel }) {
  return (
    <ExpandTooltipStyled
      tooltipClick={handleActivePanel}
      label={activePanel ? PANEL_TEXT.SMALL : PANEL_TEXT.BIG}
      className={cx("TooltipBase", { activeButton: activePanel })}
    >
      <ExpandPanelIcon />
    </ExpandTooltipStyled>
  );
}

export default ExpandPanelButton;
