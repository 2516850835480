import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "@mui/material";
import { cx } from "@emotion/css";
import {
  CustomizedEntityDoc,
  FIELD_SCOPE,
  POObjFields,
  purchaseOrderFieldsCustomized,
  salesOrderFieldsCustomized,
  shipmentFieldsCustomized,
  shipmentObjFields,
  SOObjFields,
} from "../../../helpers/constants";
import { useCompanyId, useCompanyUsers } from "../../../hooks/user";
import { doc, getDoc, updateDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../../api/types/dbTables";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";
import AttentionModal from "../../Modal/AttentionModal";
import { PlusIcon } from "../../../helpers/iconSvgPath";
import FieldCustomizedTable from "./FieldCustomizedTable";
import { FieldCustomizationPlaceholderStyled } from "../styles";
import FieldCustomizationModal from "./FieldCustomizationModal";
import FieldCustomizedSort from "./FieldCustomizedSorting";
import { sortObjectsBy } from "../../../helpers/sortingHelper";
import {
  changeFieldActive,
  hasMayorChanges,
  restoreDescription,
  restoreFieldsValues,
  filterAndUpdateHeaders,
} from "../companyTabHelper";
import { isEqual } from "lodash";

function FieldCustomization() {
  const users = useCompanyUsers({});

  const companyId = useCompanyId();
  const [tabIndex, setTabIndex] = useState(0);
  const [entityDoc, setEntityDoc] = useState({});
  const [bassedEntityDoc, setBassedEntityDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [formModal, setFormModal] = useState({
    open: false,
    edit: false,
    data: {},
  });
  const [attentionModalData, setAttentionModalData] = useState({
    open: false,
    description: "",
    action: () => {},
    cancelText: "Cancel",
    cancellable: true,
    confirmationText: "Ok",
  });

  useEffect(() => {
    if (!companyId || !currentValues?.docId) {
      return;
    }
    getDocEntity();
  }, [companyId, tabIndex]);

  const getDocEntity = async () => {
    setLoading(true);
    const docRef = doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${dbTables.MISCELLANEOUS}/${currentValues.docId}`
    );
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const list = docSnap.data().list || [];
        setEntityDoc({
          ...docSnap.data(),
          ref: docSnap.ref,
          list: list.sort(sortObjectsBy("listIndex")),
        });
        setBassedEntityDoc({
          ...docSnap.data(),
          ref: docSnap.ref,
          list: list.sort(sortObjectsBy("listIndex")),
        });
      } else {
        setEntityDoc({});
        setBassedEntityDoc({});
      }
      setLoading(false);
    });
  };

  const changeActive = (fieldId, active) => {
    const updatedFields = changeFieldActive({
      fieldId,
      active,
      entityDoc,
    });
    setEntityDoc((oldValue) => ({ ...oldValue, list: updatedFields }));
  };

  const handleSortItems = (newList) => {
    setEntityDoc((oldValue) => ({ ...oldValue, list: newList }));
  };

  const restoreValues = async () => {
    setLoading(true);
    setAttentionModalData({
      open: false,
    });
    const updatedFields = restoreFieldsValues({
      entityDoc,
      defaultValue: values[tabIndex].defaultValue,
    });
    setEntityDoc((oldValue) => ({ ...oldValue, list: updatedFields }));
    setBassedEntityDoc(() => ({ ...entityDoc, list: updatedFields }));
    const batch = writeBatch(firestore);
    batch.set(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.MISCELLANEOUS}/${currentValues.docId}`
      ),
      { list: updatedFields, id: currentValues.docId }
    );
    await syncUserHeaders(batch, updatedFields);
    updateUsers(batch);
  };

  const syncUserHeaders = async (batch, currentList) => {
    const promises = users.map(async (user) => {
      const ref = doc(
        firestore,
        `/${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
      );
      const snapshot = await getDoc(ref);
      return {
        ...snapshot.data(),
        ref: snapshot.ref,
      };
    });
    const userHeaders = await Promise.all(promises);

    filterAndUpdateHeaders({
      userHeaders,
      batch,
      tabIndex,
      updatedHeaderList: currentList,
    });
  };

  const updateUsers = (batch) => {
    batch.commit().then(() => {
      users.forEach((user) => {
        updateDoc(user.ref, {
          shouldUpdateHeader: !user.shouldUpdateHeader,
        });
      });
      setLoading(false);
    });
  };

  const saveChanges = async () => {
    setLoading(true);
    const hadToUpdateUsers = hasMayorChanges({
      newList: entityDoc.list,
      oldList: bassedEntityDoc.list,
    });
    const batch = writeBatch(firestore);
    batch.update(entityDoc.ref, { list: entityDoc.list });
    if (hadToUpdateUsers) {
      await syncUserHeaders(batch, entityDoc.list);
    }
    updateUsers(batch);
    setBassedEntityDoc({ ...entityDoc });
  };

  const addValue = (field) => {
    const currentList = entityDoc.list || [];
    const newList = currentList.filter(
      (currentField) => currentField.id !== field.id
    );
    newList.push(field);
    newList.sort(sortObjectsBy("listIndex"));
    setEntityDoc((oldValue) => ({
      ...oldValue,
      list: newList.map((field, index) => ({ ...field, listIndex: index + 1 })),
    }));
  };

  const handleChangeTab = (event, newValue) => {
    if (loading) return;
    setTabIndex(newValue);
  };

  const handleErase = (fieldId) => {
    const currentList = entityDoc.list || [];
    const updatedFields = currentList.filter((field) => field.id !== fieldId);
    setEntityDoc((oldValue) => ({ ...oldValue, list: updatedFields }));
  };

  const values = {
    0: {
      defaultValue: salesOrderFieldsCustomized,
      title: "Sales Order Fields",
      docId: CustomizedEntityDoc.SALES_ORDER_METADATA,
      entityFields: SOObjFields,
    },
    1: {
      defaultValue: purchaseOrderFieldsCustomized,
      title: "Purchase Order Fields",
      docId: CustomizedEntityDoc.PURCHASE_ORDER_METADATA,
      entityFields: POObjFields,
    },
    2: {
      defaultValue: shipmentFieldsCustomized,
      title: "Shipment Fields",
      docId: CustomizedEntityDoc.SHIPMENT_METADATA,
      entityFields: shipmentObjFields,
    },
  };

  const currentValues = values[tabIndex];
  const hasChanges = !isEqual(entityDoc.list, bassedEntityDoc.list);

  return (
    <>
      {formModal.open && (
        <FieldCustomizationModal
          isEditing={formModal.edit}
          data={formModal.data}
          onClose={() =>
            setFormModal({ open: false, type: "", edit: false, data: {} })
          }
          currentList={entityDoc.list}
          onCreate={(field) => {
            addValue(field);
          }}
          entityFields={currentValues.entityFields}
        />
      )}
      {attentionModalData.open && (
        <AttentionModal
          title={"Attention"}
          isOpen={attentionModalData.open}
          cancellable={attentionModalData.cancellable}
          onClick={() => attentionModalData.action()}
          cancelText={attentionModalData.cancelText}
          acceptBlue={!!attentionModalData.action}
          confirmationText={attentionModalData.confirmationText}
          description={attentionModalData.description}
          onClose={() => {
            setAttentionModalData({
              open: false,
            });
          }}
        />
      )}
      <FieldCustomizationPlaceholderStyled
        className={cx("container", {
          poContainer: tabIndex === 1,
          shipmentContainer: tabIndex === 2,
        })}
      >
        {loading && <LoadingBackdrop withLogo />}
        <div
          className="save-cancel-buttons-container"
          style={{ opacity: hasChanges ? 1 : 0, width: hasChanges ? 220 : 0 }}
        >
          <Button
            id="discard-changes"
            className="discard-changes-button"
            onClick={getDocEntity}
            variant="contained"
            disabled={!hasChanges}
          >
            Discard Changes
          </Button>
          <Button
            id="save-new-version"
            className="save-changes-button"
            onClick={saveChanges}
            variant="contained"
            disabled={!hasChanges}
          >
            Save
          </Button>
        </div>
        <Tabs
          className="tabEntities"
          value={tabIndex}
          onChange={handleChangeTab}
          aria-label="field tabs"
        >
          <Tab className="soTab" label="Sales Order Fields" />
          <Tab className="poTab" label="Purchase Order Fields" />
          <Tab className="shipmentTab" label="Shipment Fields" />
        </Tabs>

        <div
          className={cx("soContainer", {
            poContainer: tabIndex === 1,
            shipmentContainer: tabIndex === 2,
          })}
        >
          <FieldCustomizedTable
            title={"Built-in Fields"}
            onChangeActive={changeActive}
            fieldList={entityDoc.list?.filter(
              (filter) => filter.scope === FIELD_SCOPE.BUILD_IN_FIELDS
            )}
            buttonAction={() => {
              setAttentionModalData({
                action: restoreValues,
                open: true,
                description: restoreDescription,
                cancelText: "Cancel",
                confirmationText: "Proceed",
                cancellable: true,
              });
            }}
            buttonText="Restore default Field Labels and Pop-up Text"
            onErase={(fieldId) => {
              handleErase(fieldId);
            }}
            onEdit={(field) =>
              setFormModal({
                edit: true,
                open: true,
                data: field,
              })
            }
            tab={tabIndex}
          />
          <FieldCustomizedTable
            title={"Company Custom Fields"}
            onChangeActive={changeActive}
            fieldList={entityDoc.list?.filter(
              (filter) => filter.scope === FIELD_SCOPE.COMPANY_CUSTOM_FIELDS
            )}
            onErase={(fieldId) => {
              handleErase(fieldId);
            }}
            buttonAction={() =>
              setFormModal({
                edit: false,
                open: true,
                field: {},
              })
            }
            buttonText={
              <span className="addButton">
                <PlusIcon width={12} height={12} />
                <span> Add Field</span>
              </span>
            }
            onEdit={(field) =>
              setFormModal({
                edit: true,
                open: true,
                data: field,
              })
            }
          />
          <FieldCustomizedSort
            fieldList={entityDoc.list
              ?.sort(sortObjectsBy("listIndex"))
              .filter((filter) => filter.active)}
            handleDragEnd={(items) => handleSortItems(items)}
            inactiveList={entityDoc.list
              ?.sort(sortObjectsBy("listIndex"))
              .filter((filter) => !filter.active)}
          />
        </div>
      </FieldCustomizationPlaceholderStyled>
    </>
  );
}

export default FieldCustomization;
