import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import IntlMessages from "../../util/IntlMessages";
import CreateCategoryForm from "./CreateCategoryForm";

function CreateCategory({
  openModal,
  handleClose,
  categoryItem,
  onSubmit,
  isUpdating,
  user = {},
}) {
  const [currentCategoryItem, setCurrentCategoryItem] = useState(null);

  useEffect(() => {
    setCurrentCategoryItem(categoryItem);
  }, [categoryItem]);

  function onChange({ value, field }) {
    setCurrentCategoryItem({ ...currentCategoryItem, [field]: value });
  }

  if (!currentCategoryItem) {
    return null;
  }
  return (
    <CustomModal
      header={
        isUpdating ? (
          <IntlMessages id={"title.text.category.update"} />
        ) : (
          <IntlMessages id={"title.text.category.create"} />
        )
      }
      isOpen={openModal}
      onClose={handleClose}
      modalStyle={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CreateCategoryForm
        categoryItem={currentCategoryItem}
        onChange={onChange}
        onSubmit={({ currentCategoryItem }) => {
          onSubmit({ currentCategoryItem });
        }}
        handleClose={handleClose}
        isUpdating={isUpdating}
        user={user}
      />
    </CustomModal>
  );
}

export default CreateCategory;
