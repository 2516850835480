import React, { useEffect, useState } from "react";
import { ExpensesContainerStyled } from "./styles";
import Expense from "../../../api/model/Expense";
import ExpenseItem from "../../../api/model/ExpenseItem";

import ExpenseItemComponent from "./ExpenseItem";
import { orderTypeSort } from "../../../helpers/salesOrderHelpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useGLAccountItems, useAutoCompleteLists } from "../../../hooks";
import {
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
  TYPE_OF_AUTOCOMPLETE_LISTS,
} from "../../../helpers/constants";
import { EXPENSE_FIELDS, orderType } from "../../../helpers/salesOrder";
import { firestore, storage } from "../../../firebase";
import { dbTables } from "../../../api/types/dbTables";
import {
  EXCLUDE_PERFORM_MARK,
  expensesHeader,
  getGLAccount,
  getTotal,
  performMark,
} from "../../../helpers/expenses";
import AttentionModal from "../../Modal/AttentionModal";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { useUser } from "../../../hooks/user";
import Mark from "mark.js";
import { CancelIcon } from "../../../helpers/iconSvgPath";
import axios from "axios";
import moment from "moment";
import Loader from "../../General/Loader";
import DocumentToTrain from "../../../api/model/DocumentToTrain.model";
import {
  onSnapshot,
  collection as collectionFirestore,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../../constants/globalVariables";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { colors } from "../../../assets/jss/variables";
import { Modal } from "@mui/material";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";
import ExpensesFooter from "./ExpensesFooter";
import ExpensesHeader from "./ExpensesHeader";
import { sortObjectsBy } from "../../../helpers/sortingHelper";
import { getBackgroundColorByType } from "../dashboardHelpers";

let DB_TABLES_TO_SCOPE = {
  [dbTables.SALES_ORDERS]: orderType.SALES_ORDER,
  [dbTables.PURCHASE_ORDERS]: orderType.PURCHASE_ORDER,
  [dbTables.SHIPMENTS]: orderType.SHIPMENT,
};

function ExpensesModal({
  open,
  onClose,
  className,
  collection = "",
  documentId = "",
  companyId,
  expenseRef,
  order = {},
}) {
  const isVoided = order.status === SALES_ORDER_STATUS.VOIDED;
  const [expenses, setExpenses] = useState([]);
  const [editing, setEditing] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    query: "",
    sortedColumn: EXPENSE_FIELDS.DATE,
    orderBy: orderTypeSort.DESC,
  });
  const [expandConfig, setExpandConfig] = useState({});
  const [expenseToDelete, setExpenseToDelete] = useState({});
  const [modalConfirmation, setModalConfirmation] = useState({
    open: false,
    description: <React.Fragment></React.Fragment>,
  });
  const [modalAttention, setModalAttention] = useState({
    open: false,
    description: <React.Fragment></React.Fragment>,
  });
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [documentAI, setdocumentAI] = useState({});
  const [currentTotalActual, setCurrentTotalActual] = useState(0);

  const isAllowed = useIsAllowedFunction();
  const user = useUser();
  const GLAccountItems = useGLAccountItems();
  const paidToMiscellaneo = useAutoCompleteLists({
    id: TYPE_OF_AUTOCOMPLETE_LISTS.PAID_TO,
  });
  const uniquePaidToMiscellaneo = useAutoCompleteLists({
    id: TYPE_OF_AUTOCOMPLETE_LISTS.UNIQUE_PAID_TO,
  });

  useEffect(() => {
    let expensesListener = () => {};
    if (documentId && collection && open) {
      if (!expenseRef) {
        expensesListener = onSnapshot(
          collectionFirestore(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${collection}/${documentId}/${dbTables.EXPENSES}`
          ),
          (snapshot) => {
            const expenses = snapshot.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            }));
            setExpenses(expenses);
            if (isLoading) {
              setIsLoading(false);
            }
          }
        );
      } else {
        expensesListener = onSnapshot(expenseRef, (snapshot) => {
          if (snapshot.exists) {
            const total = +getTotal({
              list: snapshot.data().items,
              field: EXPENSE_FIELDS.ACTUAL,
              format: false,
            });
            setCurrentTotalActual(total);
            setExpenses([{ ...snapshot.data(), ref: snapshot.ref }]);
            setExpandConfig({ ...expandConfig, [snapshot.id]: true });
            setEditing({ ...snapshot.data(), ref: snapshot.ref });
          } else {
            setExpenses([]);
          }
          if (isLoading) {
            setIsLoading(false);
          }
        });
      }
    }
    return function cleanup() {
      return expensesListener();
    };
  }, [documentId]);
  const markInstance = new Mark(document.getElementById("expenses-table-body"));

  useEffect(() => {
    performMark({
      keyword: filters.query,
      markInstance,
      exclude: EXCLUDE_PERFORM_MARK,
    });
  }, [filters]);

  function renderArrow(headerLabel) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return <ArrowDropUpIcon className="header-arrow-button-active" />;
      }
      return <ArrowDropDownIcon className="header-arrow-button-active" />;
    }
    return <ArrowDropDownIcon className="header-arrow-button" />;
  }

  function filterList({ list = [], filters = {}, fieldsToSearch = [] }) {
    let filteredList = [];
    if (isAllowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_EXPENSES)) {
      filteredList = [...list];
    } else if (
      isAllowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF)
    ) {
      filteredList = [...list.filter((el) => el.createdBy === user.id)];
    }
    if (filters.query) {
      filteredList = filteredList.filter((el) => {
        const isMatchItem = el.items.some((item) => {
          return fieldsToSearch.some((field) => {
            if (
              field &&
              item[field] &&
              item[field]
                .toString()
                .toLowerCase()
                .includes(filters.query.toLowerCase())
            ) {
              return true;
            }
            return false;
          });
        });

        const isMatchEl = fieldsToSearch.some((field) => {
          if (
            field &&
            el[field] &&
            el[field]
              .toString()
              .toLowerCase()
              .includes(filters.query.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
        return isMatchEl || isMatchItem;
      });
    }
    if (editing && editing.isCreating) {
      filteredList = filteredList.map((item) => {
        if (item.id === editing.id) {
          return { ...item, isCreating: true };
        }
        return item;
      });
    }
    filteredList
      .sort(
        sortObjectsBy(
          filters.sortedColumn,
          filters.orderBy === orderTypeSort.ASC ? true : false
        )
      )
      .sort(sortObjectsBy("isCreating", true));
    return filteredList;
  }

  function formatExpenses({ expenses = [] }) {
    let expensesCpy = [...expenses];
    expensesCpy = expensesCpy.map((expenseCpy) => {
      let itemsCpy = [...expenseCpy.items];
      itemsCpy = itemsCpy.map((itemCpy) => {
        const { name, description } = getGLAccount({
          items: GLAccountItems,
          itemId: itemCpy.GLAccountId,
        });
        return {
          ...itemCpy,
          GLAccountName: name,
          GLAccountDescription: description,
        };
      });
      if (expenseCpy.items.length === 1) {
        const firstItem = expenseCpy.items[0];
        const { name, description } = getGLAccount({
          items: GLAccountItems,
          itemId: firstItem.GLAccountId,
        });
        return {
          ...expenseCpy,
          GLAccountName: name,
          GLAccountDescription: description,
          [EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES]:
            firstItem[EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES],
          [EXPENSE_FIELDS.NOTES]: firstItem[EXPENSE_FIELDS.NOTES],
          [EXPENSE_FIELDS.FORECAST]: +firstItem[EXPENSE_FIELDS.FORECAST],
          [EXPENSE_FIELDS.ACTUAL]: +firstItem[EXPENSE_FIELDS.ACTUAL],
          items: itemsCpy,
        };
      }

      return {
        ...expenseCpy,
        GLAccountName: "",
        GLAccountDescription: "",
        [EXPENSE_FIELDS.GL_ACCOUNT_ID]: "",
        [EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES]: "",
        [EXPENSE_FIELDS.NOTES]: "",
        [EXPENSE_FIELDS.FORECAST]: getTotal({
          list: expenseCpy.items,
          field: EXPENSE_FIELDS.FORECAST,
          format: false,
        }),
        [EXPENSE_FIELDS.ACTUAL]: getTotal({
          list: expenseCpy.items,
          field: EXPENSE_FIELDS.ACTUAL,
          format: false,
        }),
        items: itemsCpy,
      };
    });
    return expensesCpy;
  }

  function onCloseModalConfirmation() {
    setExpenseToDelete({});
    setModalConfirmation({
      open: false,
      description: <React.Fragment></React.Fragment>,
    });
  }

  const handleCloseExpensesModal = (newExpense) => {
    setEditing(null);
    setExpenses([]);
    setFilters({
      query: "",
      sortedColumn: EXPENSE_FIELDS.DATE,
      orderBy: orderTypeSort.DESC,
    });
    setExpandConfig({});
    setExpenseToDelete({});
    onClose(newExpense ? [newExpense] : expenses);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleDrop(file) {
    setLoadingPDF(true);
    let name = file.name;
    if (name.split(".").pop() !== "pdf") {
      setLoadingPDF(false);
      setModalAttention({
        open: true,
        description: (
          <React.Fragment>Only PDF files are allowed</React.Fragment>
        ),
      });
      return;
    }
    let base64 = await toBase64(file);
    base64 = base64.substring(28);
    const url = getFunctionByName({
      name: "documentAI",
      env: globalEnvironment,
    });
    try {
      // const url = "http://localhost:5001/tradedash-dev/us-central1/documentai";
      const response = await axios.post(url, {
        file: base64,
      });
      const { expense, totalActual, status, confidenceAverage } = response.data;
      if (status === 200) {
        const expensesCpy = [...expenses];
        let dateformat = "";
        if (expense.date !== "") {
          dateformat = moment(expense.date).format("YYYY-MM-DD");
        }
        if (
          !paidToMiscellaneo.list.includes(expense.paidTo) &&
          expense.paidTo !== ""
        ) {
          const listCpy = [...paidToMiscellaneo.list];
          listCpy.push(expense.paidTo);
          updateDoc(paidToMiscellaneo.ref, { list: listCpy });
        }
        const uniqueList = [...(uniquePaidToMiscellaneo.list || [])];
        if (!uniqueList.includes(expense.paidTo)) {
          uniqueList.push(expense.paidTo);
          updateDoc(uniquePaidToMiscellaneo.ref, {
            list: uniqueList,
          });
        }
        const newExpense = {
          ...new Expense({
            ...expense,
            date: dateformat,
            companyId,
            parentDocumentId: documentId,
            type: DB_TABLES_TO_SCOPE[collection],
            createdBy: user.id,
          }),
        };

        const storageRef = ref(
          storage,
          `companies/${companyId}/${DB_TABLES_TO_SCOPE[collection]}/${documentId}/${dbTables.EXPENSES}/${newExpense.id}/${name}`
        );
        const task = uploadBytesResumable(storageRef, file);
        task.on(
          "state_changed",
          () => {},
          () => {},
          () => {
            //
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              newExpense.pdfURL = downloadURL;
              setdocumentAI({
                totalActual,
              });
              setEditing({ ...newExpense, isCreating: true });
              expensesCpy.push(newExpense);
              setExpenses(expensesCpy);
              setTimeout(() => {
                const dateElement =
                  document.getElementById("expense_date_input");
                dateElement.focus();
              }, 50);
              setExpandConfig({ ...expandConfig, [expense.id]: true });
              if (confidenceAverage < 100) {
                const documentToTrain = new DocumentToTrain({
                  companyId: companyId,
                  parentId: documentId,
                  scope: DB_TABLES_TO_SCOPE[collection],
                  pdfURL: url,
                  confidenceAverage,
                });
                setDoc(
                  doc(
                    firestore,
                    `${dbTables.COMPANIES}/${companyId}/${dbTables.DOCUMENTS_TO_TRAIN}/${documentToTrain.id}`
                  ),
                  { ...documentToTrain }
                );
              }
            });
          }
        );
        setLoadingPDF(false);
      } else {
        console.log("error", response.data);
        setModalAttention({
          open: true,
          description: <React.Fragment>{response.data.message}</React.Fragment>,
        });
        setLoadingPDF(false);
      }
    } catch (error) {
      console.error("error", error);
      setLoadingPDF(false);
    }
  }

  return (
    <Modal open={open} onClose={handleCloseExpensesModal} className={className}>
      <ExpensesContainerStyled
        className="expensesBoxContainer"
        style={{
          background: "#fff",
          margin: "64px 24px 24px 24px",
          borderRadius: 6,
          position: "relative",
        }}
      >
        <CancelIcon
          style={{
            position: "absolute",
            top: 14,
            right: 22,
            cursor: "pointer",
            zIndex: 5,
          }}
          onClick={handleCloseExpensesModal}
        />
        {loadingPDF && (
          <Loader
            style={{
              justifyContent: "center",
              display: "flex",
              zIndex: 1000,
              alignItems: "center",
              top: 0,
              left: 0,
            }}
          />
        )}
        {modalConfirmation.open && (
          <AttentionModal
            isOpen={modalConfirmation.open}
            description={modalConfirmation.description}
            onClick={() => {
              deleteDoc(expenseToDelete.ref);
              onCloseModalConfirmation();
            }}
            onClose={onCloseModalConfirmation}
            title="Attention"
            acceptBlue={true}
            cancelText={"No"}
            confirmationText={"Yes"}
            cancellable={true}
          />
        )}
        {modalAttention.open && (
          <AttentionModal
            isOpen={modalAttention.open}
            description={modalAttention.description}
            onClick={() => {
              setModalAttention({
                open: false,
                description: <React.Fragment></React.Fragment>,
              });
            }}
            title="Attention"
          />
        )}

        {!expenseRef && (
          <ExpensesHeader
            companyId={companyId}
            query={filters.query}
            collection={collection}
            order={order}
            documentId={documentId}
            setFileTransferedData={handleDrop}
            onSetFilter={setFilters}
            setEditing={setEditing}
            setExpenses={setExpenses}
            editing={editing}
            expenses={expenses}
          />
        )}

        <div
          className={"expensesBodyContainer"}
          style={{
            background: getBackgroundColorByType({
              type: DB_TABLES_TO_SCOPE[collection],
            }),
          }}
        >
          <div
            className="expense-itemRow"
            style={{
              background: colors.tableHeaderColor,
            }}
          >
            {expensesHeader.map((header) => {
              return (
                <div
                  key={header.id}
                  id={header.id}
                  className={"header-content-grid"}
                  style={header.headerStyles}
                  onClick={() => {
                    if (!editing) {
                      setFilters({
                        ...filters,
                        sortedColumn: header.sortBy,
                        orderBy:
                          filters.orderBy === orderTypeSort.ASC
                            ? orderTypeSort.DESC
                            : orderTypeSort.ASC,
                      });
                    }
                  }}
                >
                  {header.label}

                  {renderArrow(header.sortBy)}
                </div>
              );
            })}
          </div>
          <div className="expenses-table-body" id="expenses-table-body">
            {isLoading && <LoadingBackdrop withLogo />}
            {filterList({
              list: formatExpenses({ expenses }),
              fieldsToSearch: [
                EXPENSE_FIELDS.PAID_TO,
                "GLAccountName",
                "GLAccountDescription",
                EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
                EXPENSE_FIELDS.NOTES,
              ],
              filters,
            }).map((expense) => {
              return (
                <ExpenseItemComponent
                  expandConfig={expandConfig}
                  onExpand={(value) => {
                    setExpandConfig({ ...expandConfig, [expense.id]: value });
                  }}
                  expense={expense}
                  currentTotalActual={currentTotalActual}
                  expensesHeader={expensesHeader}
                  editing={editing}
                  onlyRead={isVoided}
                  onEdit={({ expense }) => {
                    setEditing(expense);
                  }}
                  onSave={async ({ expense }) => {
                    let itemsCpy = [...expense.items];
                    itemsCpy = itemsCpy.map((item) => {
                      let { actual = 0, forecast = 0 } = item;
                      actual = isFinite(actual) ? actual : 0;
                      forecast = isFinite(forecast) ? forecast : 0;

                      const curentActual = actual
                        ? parseFloat(actual).toFixed(2)
                        : "";
                      const currentForecast = forecast
                        ? parseFloat(forecast).toFixed(2)
                        : "";

                      return {
                        ...new ExpenseItem({
                          ...item,
                          actual: curentActual,
                          forecast: currentForecast,
                        }),
                      };
                    });
                    const glAccountIds = itemsCpy.map(
                      (item) => item.GLAccountId
                    );
                    const newExpense = {
                      ...new Expense({
                        ...expense,
                        items: itemsCpy,
                        createdBy: expense.createdBy
                          ? expense.createdBy
                          : user.id,
                        glAccountIds,
                      }),
                    };
                    setDoc(
                      doc(
                        firestore,
                        `${dbTables.COMPANIES}/${companyId}/${collection}/${documentId}/${dbTables.EXPENSES}/${expense.id}`
                      ),
                      {
                        ...newExpense,
                      }
                    );
                    setEditing(null);
                    if (expenseRef) {
                      handleCloseExpensesModal({
                        ...newExpense,
                      });
                    }
                  }}
                  onDelete={({ expense }) => {
                    setExpenseToDelete(expense);
                    setModalConfirmation({
                      open: true,
                      description: (
                        <React.Fragment>
                          Do you want to delete this expense? <br /> This cannot
                          be undone
                        </React.Fragment>
                      ),
                    });
                  }}
                  GLAccountItems={GLAccountItems}
                  paidToMiscellaneo={paidToMiscellaneo}
                  uniquePaidToMiscellaneo={uniquePaidToMiscellaneo}
                  // classes={classes}
                  isAllowed={isAllowed}
                  user={user}
                  documentAI={documentAI}
                  withRef={expenseRef ? "withRef" : ""}
                />
              );
            })}
          </div>
          <div className="expenses-table-body" style={{ minHeight: 69 }}>
            {!editing?.isCreating && (
              <ExpensesFooter
                filterList={filterList({
                  list: formatExpenses({ expenses }),
                  fieldsToSearch: [
                    EXPENSE_FIELDS.PAID_TO,
                    "GLAccountName",
                    EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
                    EXPENSE_FIELDS.NOTES,
                  ],
                  filters,
                })}
              />
            )}
          </div>
        </div>
      </ExpensesContainerStyled>
    </Modal>
  );
}

export default ExpensesModal;
