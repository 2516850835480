import React from "react";
import taskStages from "../api/types/taskStages";
import {
  ExpensesIcon,
  SalesOrderIcon,
  PurchaseOrderIcon,
  ShipmentOrderIcon,
  ReportsIcon,
  TaskIcon,
  OrdersByPhaseIcon,
  VendorsIcon,
  CustomersIcon,
  SettingsIcon,
  NewOrderInbox,
} from "./iconSvgPath";
import { ORDER_TYPE } from "../api/types/dbTables";
import { colors } from "../assets/jss/variables";

export const ORDER_DASHBOARD_ACTUAL_VIEWS = {
  SALES_ORDER: "sales-order-info-to-scroll",
  PURCHASE_ORDER: "purchase-order-info-to-scroll",
  SHIPMENT: "shipment-info-to-scroll",
  THREE_PANEL_SECTION: "three-panel-section-to-scroll",
};

export const defaultBackdropState = { type: "", isOpen: false, id: "" };
export const emptyList = [];
export const emptyObject = {};

export const shippingTerms = {
  EXW: "Ex Works",
  FCA: "Free Carrier",
  CPT: "Carriage Paid To",
  CIP: "Carriage and Insurance Paid To",
  DAT: "Delivered at Terminal",
  DAP: "Delivered at Place",
  DDP: "Delivered Duty Paid",
  FAS: "Free Alongside Ship",
  FOB: "Free on Board",
  CFR: "Cost and Freight",
  CIF: "Cost,Insurance and Freight",
};

export const GENERAL_PERMISSION_VALUE = {
  PURCHASE_ORDER_AMOUNT: "purchase_order_amount",
  SALES_ORDER_AMOUNT: "sales_order_amount",
  MODIFY_DUE_DATES: "modify_due_dates",
  SUPPLIER: "Suppliers",
  CREATE_SHIPMENTS: "create_shipments",
  SHIPMENT_QUANTITY: "shipment_quantity",
  SHIPMENT_DELIVERY_DATE: "shipment_delivery_date",
  SHIPMENT_SHIP_DATE: "shipment_ship_date",
  CREATE_USERS: "create_users",
  MODIFY_USERS: "modify_users",
  CHANGE_USER_STATUS: "change_user_status",
  SHIPMENT_AMOUNT: "shipment_amount",
  ALL_CUSTOMERS: "all_customers",
  ALL_VENDORS: "all_vendors",
  ORDER_READY_DATE: "order_ready_date",
  RECEIVE_NOTIFICATION_FROM_API: "receive_notification_from_API",
  NOTIFICATION_UPDATE_CREATE_VENDOR_TEMPLATE:
    "notification_update_create_vendor_template",
  REASSIGN_TASK: "reassign_task",
  UN_ASSIGN_TAGS: "un-assign_tags",
  ASSIGN_TAGS: "assign_tags",
  RENAME_TAGS: "rename_tags",
  CREATE_TAGS: "create_tags",
  SEE_TAGS: "see_tags",
  DELETE_TAGS: "delete_tags",
  VISIBLE_IN_VENDOR_PAGE: "visible_in_vendor_page",
  VISIBLE_IN_CUSTOMER_PAGE: "visible_in_customer_page",
  CREATE_CATEGORIES: "create_categories",
  RENAME_CATEGORIES: "rename_categories",
  ASIGN_CATEGORIES: "asign_categories",
  UN_ASSIGN_CATEGORIES: "un-assign_categories",
  CAN_ACTIVE_INACTIVE_CATEGORIES: "can_active_inactive_categories",
  EDIT_ROLES_NAMES: "edit_roles_names",
  CAN_SEE_ALL_NOTES: "can_see_all_notes",
  CAN_DELETE_VENDOR_FILES_AND_FOLDERS: "can_delete_vendor_files_and_folders",
  CAN_DELETE_CUSTOMER_FILES_AND_FOLDERS:
    "can_delete_customer_files_and_folders",
  CAN_VOID_PURCHASE_ORDERS: "can_void_purchase_orders",
  CAN_VOID_PURCHASE_QUOTES: "can_void_purchase_quotes",
  CAN_DELETE_ORDER_DASHBOARD_FILES_AND_FOLDERS:
    "can_delete_order_dashboard_files_and_folders",
  CAN_ENTER_EXPENSES_MANUALLY: "can_enter_expenses_manually",
  CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP: "can_enter_expenses_via_drag_and_drop",
  CAN_SEE_ALL_EXPENSES: "can_see_all_expenses",
  CAN_SEE_ALL_VENDOR_DOCUMENT: "can_see_all_vendor_documents",
  CAN_SEE_ALL_CUSTOMER_DOCUMENT: "can_see_all_customer_documents",
  CAN_SEE_ALL_DASHBOARD_DOCUMENT: "can_see_all_dashboard_documents",
  CAN_CREATE_CUSTOMER: "can_create_customers",
  CAN_CREATE_VENDORS: "can_create_vendors",
  CAN_CHANGE_VENDOR_PAYMENT_TERMS: "can_change_vendor_payment_terms",
  CAN_CHANGE_CUSTOMER_PAYMENT_TERMS: "can_change_customer_payment_terms",

  CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF:
    "can_see_only_expenses_entered_by_self",
  CAN_DELETE_ANY_EXPENSE: "can_delete_any_expense",
  CAN_ONLY_DELETE_EXPENSE_ENTERED_BY_SELF:
    "can_only_delete_expense_entered_by_self",
  CAN_EDIT_ANY_EXPENSE: "can_edit_any_expense",
  CAN_ONLY_EDIT_EXPENSE_ENTERED_BY_SELF:
    "can_only_edit_expense_entered_by_self",
  CAN_EDIT_PO_DISCOUNT_PERCENTAGE: "can_edit_po_discount_percentage",
  CAN_CREATE_SUBTASKS: "can_create_subtasks",
  CAN_CREATE_PENDING_TASK: "can_create_pending_task",

  //
  REPORTS_VIEW: "reports_view",
  INBOX_VIEW: "inbox_view",
  SALES_ORDERS_VIEW: "sales_orders_view",
  PURCHASE_ORDERS_VIEW: "purchase_orders_view",
  TASKS_VIEW: "tasks_view",
  SHIPMENTS_VIEW: "shipments_view",
  ORDERS_BY_PHASE_VIEW: "orders_by_phase_view",
  FACTORIES_VIEW: "factories_view",
  CUSTOMERS_VIEW: "customers_view",
  EXPENSES_VIEW: "expenses_view",
  TAGS_VIEW: "tags_view",
  SETTINGS: "settings",
  // settings tabs
  SETTINGS_LABELS: "settings_labels",
  SETTINGS_CATEGORIES: "settings_categories",
  SETTINGS_REPORTS: "settings_reports",
  SETTINGS_USERS: "settings_users",
  SETTINGS_ROLES: "settings_roles",
  SETTINGS_PLACEHOLDER: "settings_placeholder",
  SETTINGS_ACCESS_GROUPS: "settings_access_groups",
  SETTINGS_TEMPLATE: "settings_template",
  SETTINGS_PO_TEMPLATE: "settings_po_template",
  SETTINGS_GL_ACCOUNTS: "settings_gl_accounts",
  SETTINGS_PROJECTS: "settings_projects",
  SETTINGS_IMPORTED_DATA: "settings_imported_data",
  SETTINGS_API_DATA: "settings_api_data",
  SETTINGS_API_ERROR_LOG: "settings_api_error_log",
  SETTINGS_API_CONFIGURATION: "settings_api_configuration",
  SETTINGS_BACKEND_ERRORLOG: "settings_backend_errorlog",
  SETTINGS_FRONTEND_ERRORLOG: "settings_frontend_errorlog",
  SETTINGS_FIELDS_CUSTOM: "settings_fields_custom",
};

export const CATEGORY_PERMISSION = {
  SCREEN_ACCESS: "Screen Access",
  GENERAL_PERMISSION: "General Permissions",
  DOLAR_AMOUNT: "Can see dollar amounts $$$",
  TASKS: "Tasks",
  SHIPMENTS: "Shipments",
  PURCHASE_ORDER: "Purchase Order",
  SALES_ORDER: "Sales Order",
  EXPENSES: "Expenses",
  LABELS: "Sales Order/Quote/PO/Shipment Labels",
  CATEGORIES: "Customer/Vendor Categories",
  NOTIFICATIONS: "Notifications",
  FILES_FOLDER: "Files and Folders",
  OTHER: "Other",
  SETTINGS_PERMISSIONS: "Settings Permission",
};

export const ROLES_PERMISSION_CATEGORY = [
  {
    id: CATEGORY_PERMISSION.SCREEN_ACCESS,
  },
  {
    id: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    categories: [
      CATEGORY_PERMISSION.DOLAR_AMOUNT,
      CATEGORY_PERMISSION.TASKS,
      CATEGORY_PERMISSION.PURCHASE_ORDER,
      CATEGORY_PERMISSION.SHIPMENTS,
      CATEGORY_PERMISSION.EXPENSES,
      CATEGORY_PERMISSION.LABELS,
      CATEGORY_PERMISSION.CATEGORIES,
      CATEGORY_PERMISSION.NOTIFICATIONS,
      CATEGORY_PERMISSION.FILES_FOLDER,
      CATEGORY_PERMISSION.OTHER,
    ],
  },
];

export const featureFlagNames = {
  EXPENSES_V1: "Expenses v1",
  THREAD_NOTES: "thread_in_notes",
};

export const COMPANY_PERMISSION_VALUE = {
  SALES_ORDER_QUANTITY: "sales_order_quantity",
  SALES_ORDER_PRICE: "sales_order_price",
  PURCHASE_ORDER_QUANTITY: "purchase_order_quantity",
  PURCHASE_ORDER_PRICE: "purchase_order_price",
  SHIPMENT_QUANTITY: "shipment_quantity",
  CUSTOMER_PO: "customer_po",
  SALES_REP: "sales_rep",
  SHIP_BY_DATE: "ship_by_date",
  ORDER_READY_DATE: "order_ready_date",
};

export const FONTSIZE_NOTES = {
  NORMAL: 14,
  MEDIUM: 20,
  LARGE: 27,
  HUGE: 36,
  MENTION: 13,
};

export const FONTSIZE_ACTIVITY_ENTRY = {
  NORMAL: 14,
  MEDIUM: 20,
  LARGE: 27,
  HUGE: 36,
};

export const FONT_FAMILY_NOTES = "monospace";

export const screenPermissionLabels = [
  {
    label: "Reports",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.REPORTS_VIEW,
    icon: <ReportsIcon height={24} width={24} />,
  },
  {
    label: "Sales Order Inbox",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.INBOX_VIEW,
    icon: <NewOrderInbox height={24} width={24} />,
  },
  {
    label: "Sales Orders",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
    icon: <SalesOrderIcon height={24} width={24} />,
  },
  {
    label: "Purchase Orders",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.PURCHASE_ORDERS_VIEW,
    icon: <PurchaseOrderIcon height={24} width={24} />,
  },
  {
    label: "Tasks",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.TASKS_VIEW,
    icon: <TaskIcon height={24} width={24} />,
  },

  {
    label: "Shipments",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
    icon: <ShipmentOrderIcon height={24} width={24} />,
  },
  {
    label: "Order By Phase",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.ORDERS_BY_PHASE_VIEW,
    icon: <OrdersByPhaseIcon height={24} width={24} />,
  },

  {
    label: "Vendors",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.FACTORIES_VIEW,
    icon: <VendorsIcon height={24} width={24} />,
  },
  {
    label: "Customers",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.CUSTOMERS_VIEW,
    icon: <CustomersIcon height={24} width={24} />,
  },

  {
    label: "Expenses",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.EXPENSES_VIEW,
    permissionFlag: featureFlagNames.EXPENSES_V1,
    icon: <ExpensesIcon height={24} width={24} />,
  },
  {
    label: "Settings",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.SETTINGS,
    icon: <SettingsIcon height={24} width={24} />,
    tabs: [
      {
        label: "Labels",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_LABELS,
      },
      {
        label: "Categories",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_CATEGORIES,
      },
      {
        label: "Reports",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_REPORTS,
      },
      {
        label: "Users",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_USERS,
      },
      {
        label: "Roles",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_ROLES,
      },
      {
        label: "Document Placeholders",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_PLACEHOLDER,
      },
      {
        label: "Access Groups",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_ACCESS_GROUPS,
      },
      {
        label: "Sales Order Template",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_TEMPLATE,
      },
      {
        label: "Purchase Order Template",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_PO_TEMPLATE,
      },
      {
        label: "G/L Accounts",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_GL_ACCOUNTS,
      },
      {
        label: "Suppliers",
        value: GENERAL_PERMISSION_VALUE.SUPPLIER,
      },
      {
        label: "Field Customization",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_FIELDS_CUSTOM,
      },

      { label: "Projects", value: GENERAL_PERMISSION_VALUE.SETTINGS_PROJECTS },
      {
        label: "Imported Data",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_IMPORTED_DATA,
      },
      {
        label: "API Imported Data",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_API_DATA,
      },
      {
        label: "API Error Log",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_API_ERROR_LOG,
      },
      {
        label: "API Settings",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_API_CONFIGURATION,
      },
      {
        label: "Back-End Error Log",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_BACKEND_ERRORLOG,
      },
      {
        label: "Front-End Error Log",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_FRONTEND_ERRORLOG,
      },
    ],
  },
];

export const permissionsLabels = [
  {
    label: "Sales Ordes/Quotes",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.DOLAR_AMOUNT,
    value: GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT,
  },
  {
    label: "PO",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.DOLAR_AMOUNT,
    value: GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT,
  },
  {
    label: "Shipment",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.DOLAR_AMOUNT,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_AMOUNT,
  },
  // ---
  {
    label: "Can create sub-tasks",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.TASKS,
    value: GENERAL_PERMISSION_VALUE.CAN_CREATE_SUBTASKS,
  },
  {
    label: "Modify due date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.TASKS,
    value: GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES,
  },
  {
    label: "Reassign to another person",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.TASKS,
    value: GENERAL_PERMISSION_VALUE.REASSIGN_TASK,
  },
  {
    label: "Create Pending Tasks",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.TASKS,
    value: GENERAL_PERMISSION_VALUE.CAN_CREATE_PENDING_TASK,
  },
  // ---
  {
    label: "Can edit PO discount",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.PURCHASE_ORDER,
    value: GENERAL_PERMISSION_VALUE.CAN_EDIT_PO_DISCOUNT_PERCENTAGE,
  },
  {
    label: "Can edit Order Ready Date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.PURCHASE_ORDER,
    value: GENERAL_PERMISSION_VALUE.ORDER_READY_DATE,
  },
  {
    label: "Can void Purchase Orders",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.PURCHASE_ORDER,
    value: GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_ORDERS,
  },
  {
    label: "Can void Purchase Quotes",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.PURCHASE_ORDER,
    value: GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_QUOTES,
  },
  // ----
  {
    label: "Create shipments",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.CREATE_SHIPMENTS,
  },
  {
    label: "Modify item quantities",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_QUANTITY,
  },
  {
    label: "Modify shipment delivery date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_DELIVERY_DATE,
  },
  {
    label: "Modify shipment ship date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_SHIP_DATE,
  },
  // --
  {
    label: "Enter expenses manually",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_MANUALLY,
  },
  {
    label: "Enter expenses via drag-and-drop",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP,
  },
  {
    label: "See all expenses",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_EXPENSES,
  },
  {
    label: "See only expenses entered by self",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF,
  },
  {
    label: "Edit any expense",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_EDIT_ANY_EXPENSE,
  },
  {
    label: "Only edit expenses entered by self",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ONLY_EDIT_EXPENSE_ENTERED_BY_SELF,
  },
  {
    label: "Delete any expense",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_DELETE_ANY_EXPENSE,
  },
  {
    label: "Only delete expenses entered by self",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ONLY_DELETE_EXPENSE_ENTERED_BY_SELF,
  },
  // --
  {
    label: "Create",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.CREATE_TAGS,
  },
  {
    label: "Rename",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.RENAME_TAGS,
  },
  {
    label: "Delete",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.DELETE_TAGS,
  },
  {
    label: "Assign",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.ASSIGN_TAGS,
  },
  {
    label: "Un-assign",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.UN_ASSIGN_TAGS,
  },
  {
    label: "See labels",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.SEE_TAGS,
  },
  // --
  {
    label: "Visible in Vendor page",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.CATEGORIES,
    value: GENERAL_PERMISSION_VALUE.VISIBLE_IN_VENDOR_PAGE,
  },
  {
    label: "Visible in Customer page",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.CATEGORIES,
    value: GENERAL_PERMISSION_VALUE.VISIBLE_IN_CUSTOMER_PAGE,
  },
  {
    label: "Create",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.CATEGORIES,
    value: GENERAL_PERMISSION_VALUE.CREATE_CATEGORIES,
  },
  {
    label: "Rename",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.CATEGORIES,
    value: GENERAL_PERMISSION_VALUE.RENAME_CATEGORIES,
  },
  {
    label: "Assign",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.CATEGORIES,
    value: GENERAL_PERMISSION_VALUE.ASIGN_CATEGORIES,
  },
  {
    label: "Un-assign",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.CATEGORIES,
    value: GENERAL_PERMISSION_VALUE.UN_ASSIGN_CATEGORIES,
  },
  {
    label: "Activate/Deactivate",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.CATEGORIES,
    value: GENERAL_PERMISSION_VALUE.CAN_ACTIVE_INACTIVE_CATEGORIES,
  },
  // --
  {
    label: "Update vendor template",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.NOTIFICATIONS,
    value: GENERAL_PERMISSION_VALUE.NOTIFICATION_UPDATE_CREATE_VENDOR_TEMPLATE,
  },
  {
    label: "API errors",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.NOTIFICATIONS,
    value: GENERAL_PERMISSION_VALUE.RECEIVE_NOTIFICATION_FROM_API,
  },

  // --
  {
    label: "Delete - vendor",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_DELETE_VENDOR_FILES_AND_FOLDERS,
  },
  {
    label: "Delete - customer",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_DELETE_CUSTOMER_FILES_AND_FOLDERS,
  },
  {
    label: "Delete - Order Dashboard",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value:
      GENERAL_PERMISSION_VALUE.CAN_DELETE_ORDER_DASHBOARD_FILES_AND_FOLDERS,
  },

  {
    label: "See all - vendor documents",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_VENDOR_DOCUMENT,
  },
  {
    label: "See all - customer documents",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_CUSTOMER_DOCUMENT,
  },
  {
    label: "See all - Order Dashboard documents",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT,
  },
  // --

  {
    label: "See all activity stream notes",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_NOTES,
  },

  {
    label: "Can edit roles names",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.EDIT_ROLES_NAMES,
  },
  {
    label: "Can create new users",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CREATE_USERS,
  },
  {
    label: "Can edit users",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.MODIFY_USERS,
  },

  {
    label: "Can activate/deactivate users",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CHANGE_USER_STATUS,
  },

  {
    label: "Can create customers",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_CREATE_CUSTOMER,
  },
  {
    label: "Can create vendors",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_CREATE_VENDORS,
  },
  {
    label: "Can change vendor payment terms",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_CHANGE_VENDOR_PAYMENT_TERMS,
  },
  {
    label: "Can change customer payment terms",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_CHANGE_CUSTOMER_PAYMENT_TERMS,
  },
];

export const CompanyPermissionsData = [
  //item table
  {
    prefix: "Can modify:",
    label: "Sales Order quantity",
    value: COMPANY_PERMISSION_VALUE.SALES_ORDER_QUANTITY,
  },
  {
    prefix: "Can modify:",
    label: "Sales Order price",
    value: COMPANY_PERMISSION_VALUE.SALES_ORDER_PRICE,
  },
  {
    prefix: "Can modify:",
    label: "Purchase Order quantity",
    value: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_QUANTITY,
  },
  {
    prefix: "Can modify:",
    label: "Purchase Order price",
    value: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_PRICE,
  },
  {
    prefix: "Can modify:",
    label: "Shipment quantity",
    value: COMPANY_PERMISSION_VALUE.SHIPMENT_QUANTITY,
  },
  {
    prefix: "Can modify:",
    label: "Sales Order Customer PO",
    value: COMPANY_PERMISSION_VALUE.CUSTOMER_PO,
  },
  {
    prefix: "Can  modify:",
    label: "PO Order Ready Date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    value: COMPANY_PERMISSION_VALUE.ORDER_READY_DATE,
  },
  {
    prefix: "Can modify:",
    label: "Sales Order Sales Rep",
    value: COMPANY_PERMISSION_VALUE.SALES_REP,
  },
  {
    prefix: "Can modify:",
    label: "Sales Order Ship By Date",
    value: COMPANY_PERMISSION_VALUE.SHIP_BY_DATE,
  },
];

export const screenHeight = "calc(100vh - 98px)";

export const TASK_TYPE = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
};

export const notAllowedToSee = "********";

export const propertyType = {
  CURRENCY: "CURRENCY",
  DATE: "DATE",
  DATE_TIME: "DATE_TIME",
  DATE_RANGE: "DATE_RANGE",
  INTEGER: "INTEGER",
  FLOAT: "FLOAT",
  PERCENTAGE: "PERCENTAGE",
  SPACER: "SPACER",
  TEXT: "TEXT",
  LINK: "LINK",
  CALCULATED: "CALCULATED",
  SELECTOR: "SELECTOR",
  AUTOCOMPLETE: "AUTOCOMPLETE",
};

export const propertyLabel = {
  [propertyType.CURRENCY]: "Currency",
  [propertyType.DATE]: "Date",
  [propertyType.DATE_TIME]: "Date and Time",
  [propertyType.INTEGER]: "Number without decimals",
  [propertyType.FLOAT]: "Number with decimals",
  [propertyType.PERCENTAGE]: "Percentage",
  [propertyType.SPACER]: "Spacer",
  [propertyType.TEXT]: "Text",
  [propertyType.LINK]: "Web Link",
  [propertyType.CALCULATED]: "Calculated",
  [propertyType.SELECTOR]: "Selector",
  [propertyType.AUTOCOMPLETE]: "Autocomplete",
  [propertyType.DATE_RANGE]: "Date Range",
};

export const headerColumns = {
  FAVORITE: "favorite",
  DIAMOND: "diamond",
  PROGRESS: "progress",
  MENTIONS: "mentions",
  PO: "PO",
  SO: "SO",
  SOs: "Sales Orders",
  CUSTOMER: "Customer",
  PO_DATE: "PO Date",
  PO_SHIP_DATE: "PO Ship Date",
  PO_DELIVERY_DATE: "PO Delivery Date",
  VENDOR: "Vendor",
  PO_VALUE: "PO Value",
  DEPOSIT: "Deposit",
  BALANCE: "Balance",
  POTENTIAL_LATE_PENALTY: "Potential Late Penalty",
  POTENTIAL_LATE_FEE: "Potential Late Fee",
  CBM: "CBM",
  SHIPPING_TERMS: "Shipping Terms",
  LOADING_PORT: "Loading Port",
  UNLOADING_PORT: "Unloading Port",
  FINAL_DESTINATION: "Final Destination",
  EXTRA: "Extra",
  POS: "POs",
  SO_DATE: "Document Date",
  SHIP_DATE: "Ship Date",
  DELIVERY_DATE: "Delivery Date",
  VALUE: "Value",
  BOOKING: "Booking",
  BILL_OF_LADING: "Bill Of Lading",
  CONTAINER: "Container",
  FREIGHT_FORWARDER: "Freight Forwarder",
  INVOICE: "Invoice",
  CUSTOMER_PO: "Customer PO",
  SALES_REP: "Sales Rep.",
  TASK: "Task",
  ASSIGNED_TO: "Assigned To",
  START_DATE: "Start Date",
  DUE_DATE: "Due Date",
  ACTION: "Action",
  PROPOSAL_QUOTE: "Proposal/Quote",
  PRE_PRODUCTION: "Pre Production",
  PRODUCTION: "Production",
  BOOKING_TRANSIT: "Booking & Transit",
  PAYMENT_BILLING: "Payment & Billing",
  SALES_ORDER: "Sales Order",
  TOTAL: "Total",
  SO_SHIP_DATE: "SO Ship Date",
  EXPAND: "Expand",
  SETTINGS: "Settings",
  SHIPMENT: "Shipment",
  BOL: "BOL",
  // TOTAL_VALUE: "Total Value",
  SHIPPING_DOCUMENTS: "Shipping Documents",
  SHIPMENTS: "Shipments",
  SHIP_BY_DATE: "Ship by Date",
  TOTAL_SHIPMENTS: "Total Shipments",
  UNIQUE_ITEMS: "Unique Items",
  VENDOR_INVOICE: "Vendor Invoice",
  CREATION_DATE: "Created Date",
  ORDER_READY_DATE: "Order Ready Date",
  DOCUMENT_DATE: "Document Date",
  REQUIRED_DELIVERY: "Required Delivery",
  PAY_TERMS_START: "Pay Terms Start",
  DISCOUNT: "Discount",
  DISCOUNT_PERCENTAGE: "Discount Percentage",
  SUB_TOTAL: "Subtotal",
  SALES_INVOICE: "Sales Invoice",
  CUSTOMER_INVOICE: "Invoice to Customer",
  TOTAL_WEIGHT: "Total Weight",
  SHIPMENT_VALUE: "Shipment Value",
  REFERENCE_NUMBER: "Reference Number",
  PAID_TO: "Paid To",
  ITEM_TYPE: "Item Type",
  ORDER_NUMBER: "Number",
  GL_ACCOUNT: "G/L Account",
  DESCRIPTION: "Description",
  NOTES: "Notes",
  FORECAST: "Forecast",
  ACTUAL: "Actual",
  DELTA: "%Δ",
  ED_TOOL: "ED Tool",
  EXPORTED_DATE: "Exported Date",
  SUPPLIER: "Supplier",
  PO_ORDER_READY_DATE_VENDOR: "PO - Order Ready Date - Vendor",
  LAST_BLANK_COLUMN: "LAST_BLANK_COLUMN",
  TYPE: "Type",
  SALES_ORDER_TEMPLATE: "Sales Order Template",
  BLANK: "BLANK",
};

export const generalPermissions = {
  SALES_ORDER_AMOUNT: GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT,
  PURCHASE_ORDER_AMOUNT: "purchase_order_amount",
  SHIPMENT_AMOUNT: "shipment_amount",
};

export const featureFlagNamesArr = [
  featureFlagNames.EXPENSES_V1,
  featureFlagNames.THREAD_NOTES,
];

export const dayHeight = 32;

export const taskTableHeader = {
  STATUS: "Status",
  REF: "Ref.",
  BIG_REF: "Reference",
  PO: "Po",
  DESCRIPTION: "Task Name",
  PREDECESOR: "Pred.",
  DURATION: "Dur.",
  BIG_DURATION: "Duration",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  ASSIGNED_TO: "Assigned To",
  DEPENDENCY_TYPE: "Dependency Type",
  SMALL_DEPENDENCY_TYPE: "Type",
  BIG_PREDECESSOR: "Predecessor",
  OFFSET: "Off.",
  BIG_OFFSET: "Offset",
  NOTIFICATION_COMPLETED_EARLY: "Notification Completed Early",
  NOTIFICATION_COMPLETED_LATE: "Notification Completed Late",
  STAGE: "Stage",
  TYPE_OF_TASK: "Type Of Task",
  SUB_TASK: "Sub-Task",
};

export const typeTaskTemplateError = {
  CIRCULAR_DEPENDENCY_PO: "Circular dependency in PO",
  CIRCULAR_DEPENDENCY_SO: "Circular dependency in SO",
  TASK_DEPENDS_ON_ITSELF: "Task depends on itself",
  THERE_IS_NO_DEPENDENCY_TASK: "There is no dependency task",
  THERE_IS_NO_LEAF_TASK: "There is no leaf task",
  SUCCESS: "success",
};

export const taskStatus = {
  LATE: "late",
  NEAR_DUE: "nearDue",
  IN_PROGRESS: "inProgress",
  NOT_STARTED: "notStarted",
  COMPLETE: "complete",
};

export const userFields = {
  PERMISION_GROUPS: "permissionGroups",
  DISPLAY_NAME: "displayName",
  CELL_PHONE: "cellPhone",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  ROLE: "role",
  TYPE_OF_USER: "typeOfUser",
  AVATAR: "avatar",
  TIMEZONE: "timezone",
};

export const reportFields = {
  NAME: "name",
  DESCRIPTION: "description",
  REPORT_EMBED: "reportEmbed",
  LOOM_EMBED: "loomEmbed",
  permissions: "permissions",
  URL_TO_UPDATE_REPORT: "URLToUpdateReport",
  URL_TO_DOWNLOAD_REPORT: "URLToDownloadReport",
  NOTES: "notes",
  EMBED_TYPE: "embedType",
};

export const customerVendorFields = {
  NAME: "name",
  NUMBER: "number",
};

export const GLAccountFields = {
  NAME: "name",
  DESCRIPTION: "description",
  ACTIVE: "active",
};

export const categoryFields = {
  NAME: "name",
  DESCRIPTION: "description",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  ACTIVE: "active",
};

export const EXPENSES_HEADER_LABELS = {
  DATE: "Date",
  PAID_TO: "Supplier",
  GL_ACCOUNT: "G/L Account",
  DESCRIPTION_OF_CHARGES: "Description of Charges",
  NOTES: "Notes",
  FORECAST: "Forecast",
  ACTUAL: "Actual",
  FORECAST_OR_ACTUAL: "Forecast or Actual",
};

export const statusBlockAuth = {
  BLOCK_COMPANY: "blockCompany",
  BLOCK_USER: "blockUser",
};

export const typeOfUser = [
  { value: "ART_DEPT", label: "Art Dept" },
  { value: "FINANCE_ACCOUNTING", label: "Finance/Accounting" },
  { value: "LOGISTICS", label: "Logistics" },
  { value: "MANAGEMENT", label: "Management" },
  { value: "SALES", label: "Sales" },
  { value: "SUPPLY_CHAIN_OPERATIONS", label: "Supply Chain Operations" },
  { value: "WAREHOUSE", label: "Warehouse" },
  { value: "SEPARATOR", label: "" },
  { value: "OTHERS", label: "Other " },
];

export const errorDocumentName = {
  folder: `The character “/” is not allowed to be part of a folder name`,
  file: `The character “/” is not allowed to be part of a file name`,
};

export const regexEmailValidator =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const regexDecimal = /^([0-9]+([.][0-9]{0,2})?|[.][0-9]{1,2})$/;
export const regexInteger = /^[0-9]+$/;

export const direction = {
  LEFT: "left",
  RIGHT: "right",
};

export const BACKDROP_TYPE = {
  SHIPMENT_INFO: "shipmentInfo",
  TASK: "task",
  NOTE: "note",
  SALES_ORDER_INFO: "salesOrderInfo",
  PURCHASE_ORDER_INFO: "purchaseOrderInfo",
  SHIPMENT_FOLDER: "folderShipment",
  FOLDER: "folder",
  ITEM_TABLE_PO: "itemTablePO",
  MENTION: "message",
  BADGE_NUMBER: "badge-notification-number",
  METADATA: "entityMetadata",
  NAV_NUMBER: "navNumber",
};

export const PREFIX_ITEM_TABLE = {
  SHIPMENT: "shipment",
  PURCHASE_ORDER: "purchaseOrder",
  SALES_ORDER: "salesOrder",
};

export const TYPE_OF_TAGS = {
  REGULAR_TAG: "REGULAR_TAG",
  MISMATCH_TAG: "QUANTITY_MISMATCH_TAG",
  FILE_TAG: "FILE_PLACEHOLDER_TAG",
  DEADLINE_TAG: "DEADLINE_ADJUSTMENT_TAG",
  EXPENSES_TAG: "EXPENSES_TAG",
};
export const LABEL_INFO = "System Labels";

export const AUTOMATIC_SYSTEM_LABEL = {
  SALES_ORDER_MISMATCH: "Sales Order ↔ PO Quantity Mismatch",
  SHIPMENT_MISMATCH: "Shipment Quantity Mismatch",
  TASK_DEADLINE: "Task Deadline Adjustment",
  SALES_ORDER_FILE: "Sales Order Pending Files",
  PURCHASE_ORDER_FILE: "PO Pending Files",
  SHIPMENT_FILE: "Shipment Pending Files",
  NO_FORECASTED_EXPENSES_ASSIGNED: "No Forecasted Expenses Assigned",
  NO_ACTUAL_EXPENSES_ASSIGNED: "No Actual Expenses Assigned",
  FORECASTED_EXPENSES_ASIGNED: "Forecasted Expenses Assigned",
  ACTUAL_EXPENSES_ASIGNED: "Actual Expenses Assigned",
};

export const MISMATCH_STATUS = {
  NEW: "new",
  INCOMPLETE: "incomplete",
};

export const itemTableReference = {
  SHIPMENTS: "SHIPMENTS",
  SALES_ORDERS: "SALES_ORDERS",
  ITEM: "ITEM",
  MAIN_SHIPMENT: "MAIN_SHIPMENT",
  MAIN_SALES_ORDER: "MAIN_SALES_ORDER",
};

export const ITEM_TABLE_ACTION = {
  ASSIGNED_SHIPMENT: "ASSIGNED_SHIPMENT",
  CREATED_SHIPMENT: "CREATED_SHIPMENT",
};

export const ACTIVITIES_STREAM_STATUS = {
  NEW: "new",
  UPDATED: "update",
  ERASE: "erase",
};

export const PO_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VOIDED: "VOIDED",
};

export const PO_DIAMOND_STATUS = {
  LATE: "LATE",
  NEAR_DUE: "NEAR_DUE",
};

export const SALES_ORDER_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VOIDED: "VOIDED",
  INBOX: "INBOX",
};

export const SALES_ORDER_DIAMOND_STATUS = {
  LATE: "LATE",
  NEAR_DUE: "NEAR_DUE",
};

export const SHIPMENT_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VOIDED: "VOIDED",
};

export const SHIPMENT_DIAMOND_STATUS = {
  LATE: "LATE",
  NEAR_DUE: "NEAR_DUE",
};

export const TOOLTIP_TYPE = {
  DIAMOND: "diamond",
  FILTER: "filter",
  CALENDAR_HEADER: "calendarHeader",
  BADGE: "badge",
  TAGS: "tags",
  DEFAULT: "default",
  BUTTON_FILTER: "buttonFilter",
  ICON: "icon",
  TASK: "task",
  EXPANDBADGE: "expandBadge",
};

export const TASK_MOVED_STATUS = {
  WAITING: "waiting",
};

export const NOTES_SCOPE = {
  [ORDER_TYPE.QUOTE]: "SALES_ORDER_NOTE",
  [ORDER_TYPE.SALES_ORDER]: "SALES_ORDER_NOTE",
  [ORDER_TYPE.PURCHASE_ORDER]: "PURCHASE_ORDER_NOTE",
  [ORDER_TYPE.PURCHASE_QUOTE]: "PURCHASE_ORDER_NOTE",
  [ORDER_TYPE.SHIPMENT]: "SHIPMENT_NOTE",
};

export const TASK_FILTER = {
  ALL: "ALL",
  MINE: "MINE",
};

export const STAGE_LIST = [
  taskStages.PROPOSAL,
  taskStages.PRE_PRODUCTION,
  taskStages.PRODUCTION,
  taskStages.BOOKING_TRANSIT,
  taskStages.PAYMENT_BILLING,
];

export const labelTaskPhases = {
  PROPOSAL: "Proposal/Quote",
  PRE_PRODUCTION: "Pre-Production",
  PRODUCTION: "Production",
  BOOKING_TRANSIT: "Booking & Transit",
  PAYMENT_BILLING: "Payment & Billing",
};

export const screenPath = {
  SO: "sales_orders",
  PO: "purchase_orders",
  TASK: "tasks",
  SHIPMENT: "shipments",
  ORDER_PHASE: "orders_by_phase",
  WEEK: "week",
  VENDOR: "factories",
  CUSTOMER: "customers",
  EXPENSE: "expenses",
  REPORT: "report",
  INBOX: "sales_order_inbox",
};

export const STAGE_TYPE = {
  [labelTaskPhases.PROPOSAL]: taskStages.PROPOSAL,
  [labelTaskPhases.PRE_PRODUCTION]: taskStages.PRE_PRODUCTION,
  [labelTaskPhases.PRODUCTION]: taskStages.PRODUCTION,
  [labelTaskPhases.BOOKING_TRANSIT]: taskStages.BOOKING_TRANSIT,
  [labelTaskPhases.PAYMENT_BILLING]: taskStages.PAYMENT_BILLING,
};

export const RICH_STYLE = {
  BOLD: "BOLD",
  ITALIC: "ITALIC",
  UNDERLINE: "UNDERLINE",
  ORDER_LIST: "ordered-list-item",
  UNORDER_LIST: "unordered-list-item",
};

export const NOTIFICATION_SCOPE = {
  SO_MENTION: "SO_MENTION",
  PO_MENTION: "PO_MENTION",
  SHIPMENT_MENTION: "SHIPMENT_MENTION",
  SO_TASK_CREATED: "SO_TASK_CREATED",
  PO_TASK_CREATED: "PO_TASK_CREATED",
  SHIPMENT_TASK_CREATED: "SHIPMENT_TASK_CREATED",
  SO_TASK_LATE: "SO_TASK_LATE",
  PO_TASK_LATE: "PO_TASK_LATE",
  SHIPMENT_TASK_LATE: "SHIPMENT_TASK_LATE",
  SO_TASK_EARLY: "SO_TASK_EARLY",
  PO_TASK_EARLY: "PO_TASK_EARLY",
  SHIPMENT_TASK_EARLY: "SHIPMENT_TASK_EARLY",
  API_ERROR_LOG: "API_ERROR_LOG",
  TRADEDASH_WARNING_NOTIFICATION: "TRADEDASH_WARNING_NOTIFICATION",
};

export const TYPE_OF_FILE = {
  FOLDER: "folder",
  FILE: "file",
};

export const TYPE_OF_AUTOCOMPLETE_LISTS = {
  UNIQUE_PAID_TO: "UNIQUE_PAID_TO",
  PAID_TO: "PAID_TO",
  LOADING_PORT: "LOADING_PORT",
  UNLOADING_PORT: "UNLOADING_PORT",
};

export const TYPE_NOTIFICATION = {
  DIRECT_MESSAGE: "directMessage",
  ADHOC_TASK: "adHocTask",
  COMPLETE_EARLY: "completeEarly",
  COMPLETE_LATE: "completeLate",
};

export const FILTER_NOTIFICATION = {
  ALL: "ALL",
  DMs: "DMs",
  AD_HOC_TASK: "AD_HOC_TASK",
  COMPLETE_EARLY: "COMPLETE_EARLY",
  COMPLETE_LATE: "COMPLETE_LATE",
};

export const BY_FILTER_NOTIFICATION = {
  [FILTER_NOTIFICATION.DMs]: TYPE_NOTIFICATION.DIRECT_MESSAGE,
  [FILTER_NOTIFICATION.AD_HOC_TASK]: TYPE_NOTIFICATION.ADHOC_TASK,
  [FILTER_NOTIFICATION.COMPLETE_EARLY]: TYPE_NOTIFICATION.COMPLETE_EARLY,
  [FILTER_NOTIFICATION.COMPLETE_LATE]: TYPE_NOTIFICATION.COMPLETE_LATE,
};

export const READ_FROM_TYPE = {
  ACTIVITY_PANEL: "ACTIVITY_PANEL",
  ACTIVITY_FILTER_TAB: "ACTIVITY_FILTER_TAB",
  NOTIFICATION: "NOTIFICATION",
  MARK_ALL_AS_READ_NOTIFICATIONS: "MARK_ALL_AS_READ_NOTIFICATIONS",
  MARK_ALL_AS_READ_USER_SETTINGS: "MARK_ALL_AS_READ_USER_SETTINGS",
};

export const notesFilters = {
  ALL: "ALL",
  ACTIVITY: "ACTIVITY",
  NOTES: "NOTES",
  MENTIONS: "MENTIONS",
  THREADS: "Threads",
};

export const excludeElementsScreen = [
  "#soDate",
  "#shipDate",
  "#startDate",
  "#dueDate",
  "#createDate",
  "#deliveryDate",
  "#exportedDate",
  "#total",
  "#weight",
  "#subtotal",
  "#deposit",
  "#balance",
  "#CBM",
  "#totalShipment",
  "#totalPO",
  "#uniqueItems",
  "#discount",
  "#percentage",
  "#lateFee",
  "#orderDate",
  "#poDate",
  "#actionButton",
  "#headerCell",
  "#headerTable",
  "#taskSize",
  "#viewLess",
  "#viewAll",
  "#lateDays",
  "#actualExpanded",
  "#deltaExpanded",
  "#forecastExpanded",
  "#tabCards > *",
  "#title > *",
  "#taskSize > *",
  "#dateSeparator > *",
  "#headerCell > *",
  "#headerTable > *",
  "#emptyMessage > *",
  "#footerCell > *",
  "#forecast > *",
  "#actual > *",
  "#delta > *",
  "#paymentTermStart",
  ".headerLabel",
  ".headerLabel > *",
];

export const LOCAL_STORAGE_KEY = {
  projectTasks: "projectTasks",
};

export const REPORTS_TYPE = {
  FOLDER: "folder",
  REPORT: "report",
};

export const REPORT_EMBED_TYPES = {
  GOOGLE_SHEET: "GOOGLE_SHEET",
  DOMO: "DOMO",
};

export const ROUTES_PERMISSION = {
  [`/app/${screenPath.REPORT}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.REPORTS_VIEW,
  },
  [`/app/${screenPath.SO}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
  },
  [`/app/${screenPath.PO}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.PURCHASE_ORDERS_VIEW,
  },
  [`/app/${screenPath.TASK}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.TASKS_VIEW,
  },
  [`/app/${screenPath.SHIPMENT}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
  },
  [`/app/${screenPath.ORDER_PHASE}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.ORDERS_BY_PHASE_VIEW,
  },

  [`/app/${screenPath.VENDOR}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.FACTORIES_VIEW,
  },
  [`/app/${screenPath.CUSTOMER}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.CUSTOMERS_VIEW,
  },
  [`/app/${screenPath.EXPENSE}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.EXPENSES_VIEW,
    permissionFlag: featureFlagNames.EXPENSES_V1,
  },
  [`/app/${screenPath.INBOX}`]: {
    permissionId: GENERAL_PERMISSION_VALUE.INBOX_VIEW,
  },
  "/app/admin": {
    alwayShow: true,
  },
  "/app/dashboard": {
    alwayShow: true,
  },
};

export const PANEL_TEXT = {
  BIG: "Make this panel larger",
  SMALL: "Make this panel smaller",
};

export const basesMentionStyle = {
  background: colors.gray,
  borderRadius: 4,
  padding: "1px 7px",
  marginInline: 3,
  border: "1px solid #948686",
  "& *": {
    fontSize: "13px !important",
    textDecoration: "unset",
    fontStyle: "unset",
    color: `${colors.primaryDark} !important`,
  },
};

export const shipmentMentionBase = {
  background: colors.shipmentBadge,
  borderRadius: 4,
  padding: "1px 7px",
  marginInline: 3,
  border: `2px solid ${colors.shipmentBorderColor}`,
  "& *": {
    fontSize: "13px !important",
    textDecoration: "unset",
    fontStyle: "unset",
    color: `${colors.primaryDark} !important`,
  },
};

export const DEPENDENCY_TYPE = {
  FS: "FINISH_START",
  SS: "START_START",
  FF: "FINISH_FINISH",
};

export const MOVE_TASK_TYPES = {
  START_DUE_DATE_CHANGE: "START_DUE_DATE_CHANGE",
  BADGE_REMAINIG_DAYS: "BADGE_REMAINIG_DAYS",
};

export const TASK_DEPENDENCY_TYPE = {
  FS: "FS",
  SS: "SS",
  FF: "FF",
};

export const dependencyTypes = {
  START_START: "SS",
  FINISH_START: "FS",
  FINISH_FINISH: "FF",
};

export const PERMISSION_TEXT = {
  CUSTOMER: "You do not have permission to see this customer",
  VENDOR: "You do not have permission to see this vendor",
  SHIPMENT_IN_VENDOR_THAT_ARE_NOT_ALLOWED:
    "This shipment contains SKUs from vendor(s) you are not allowed to see",
};

export const ACTIVITY_DELETE_STATUS = {
  DELETED: "DELETED",
  HIDDEN: "HIDDEN",
};

export const BUTTON_FILTER_BOX_SHADOW = {
  FAVORITE: "FAVORITE",
  RED_DIAMOND: "RED_DIAMOND",
  ORANGE_DIAMOND: "ORANGE_DIAMOND",
  GREEN_DIAMOND: "GREEN_DIAMOND",
  WHITE_DIAMOND: "WHITE_DIAMOND",
  GRAY_DIAMOND: "GRAY_DIAMOND",
};

export const LIST_VIEW_BUTTONS_GRID = {
  SO: "55px 110px  minmax(0, 80px) min-content",
  EXPENSES: "min-content min-content",
  ORDER_BY_PHASE: "55px 110px min-content",
  TASK_LIST: "min-content min-content",
  TASK_BY_SO: "1fr min-content min-content",
};

export const filters = { ALL: "ALL", MINE: "MINE", COMPLETED: "COMPLETED" };

export const documentScope = {
  QUOTE: "QUOTE",
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  PURCHASE_QUOTE: "PURCHASE_QUOTE",
  SHIPMENT: "SHIPMENT",
  CUSTOMER: "CUSTOMER",
  FACTORY: "FACTORY",
};

export const triggerTaskTypes = {
  COMPLETE: "COMPLETE",
  FINISH_DATE: "FINISH_DATE",
  ASSIGNED_TO: "ASSIGNED_TO",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  MOVED_REMAINING_TASK: "MOVED_REMAINING_TASK",
};

export const actionType = {
  MOVED_TASK: "movedTask",
  MOVED_REMAINING_TASK: "movedRemainingTask",
  CHANGED_DURATION_TASK: "changedDurationTask",
  CONFIRMED_REMAINING_DAY_OFFSET_MOVED: "confirmedRemainingDayOffsetMoved",
  DENIED_REMAINING_DAY_OFFSET_MOVED: "deniedRemainingDayOffsetMoved",
  COMPLETED_TASK: "completedTask",
  CHANGED_ASSIGNED_TO: "changedAssignedTo",
};

export const ACTIVITY_STREAM_ACTION = {
  COMMON: "COMMON",
  BRING_ACTIVITIES_FROM_THE_BEGINING: "BRING_ACTIVITIES_FROM_THE_BEGINING",
};

export const GANTT_CHART_ACTION = {
  COMMON: "COMMON",
  UPDATE_TASK: "UPDATE_TASK",
};

export const GANTTCHART_TABLE_SIZE = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
};

export const soTaskTableSize = {
  [GANTTCHART_TABLE_SIZE.SMALL]: "15%",
  [GANTTCHART_TABLE_SIZE.MEDIUM]: "40%",
  [GANTTCHART_TABLE_SIZE.LARGE]: "100%",
};

export const activityType = {
  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_NAME_CHANGE: "FILE_NAME_CHANGE",
  FOLDER_NAME_CHANGE: "FOLDER_NAME_CHANGE",
  FOLDER_UPLOAD: "FOLDER_UPLOAD",
  FOLDER_CREATE: "FOLDER_CREATE",
  NOTE: "NOTE",
  FILE_UPLOAD_WITHOUT_PERMISSION: "FILE_UPLOAD_WITHOUT_PERMISSION",
  FILE_UPLOAD_WITH_ONE_USER: "FILE_UPLOAD_WITH_ONE_USER",
  PLACEHOLDER_FILE_UPLOAD_WITHOUT_PERMISSION:
    "PLACEHOLDER_FILE_UPLOAD_WITHOUT_PERMISSION",
  PLACEHOLDER_FILE_UPLOAD_WITH_ONE_USER:
    "PLACEHOLDER_FILE_UPLOAD_WITH_ONE_USER",
  PLACEHOLDER_FILE_UPLOAD: "PLACEHOLDER_FILE_UPLOAD",
  VERSION_UPLOAD: "VERSION_UPLOAD",
  PROPERTY_CHANGE: "PROPERTY_CHANGE",
  TASK_COMPLETED: "TASK_COMPLETED",
  TASKS_COMPLETED: "TASKS_COMPLETED",
  REASSIGNED_TASK: "REASSIGNED_TASK",
  DELETED_FOLDER: "DELETED_FOLDER",
  DELETED_FILE: "DELETED_FILE",
  TASK_MOVED_LATER: "TASK_MOVED_LATER",
  TASK_MOVED_EARLIER: "TASK_MOVED_EARLIER",
  MOVED_TASKS_DUE_DATES: "MOVED_TASKS_DUE_DATES",
  MOVED_TASK_DUE_DATE: "MOVED_TASK_DUE_DATE",
  TASK_MOVED: "TASK_MOVED",
  UPDATED_TASK: "UPDATED_TASK",
  NEW_ITEM_SHIPMENT: "ADD_SHIPMENT",
  UPDATED_ITEM_SHIPMENT: "CHANGE_ITEM_SHIPMENT",
  FILE_MOVED: "FILE_MOVED",
  FOLDER_MOVED: "FOLDER_MOVED",
  NEW_PO_ENTRY: "NEW_PO_ENTRY",
  COPIED_FOLDER_LINK: "COPIED_FOLDER_LINK",
  DOWNLOADED_FOLDER_FROM_LINK: "DOWNLOADED_FOLDER_FROM_LINK",
  DOWNLOADED_FILE: "DOWNLOADED_FILE",
  DOWNLOADED_FILE_FROM_LINK: "DOWNLOADED_FILE_FROM_LINK",
  COPIED_FILE_LINK: "COPIED_FILE_LINK",
  DOWNLOADED_FOLDER: "DOWNLOADED_FOLDER",
  CREATED_ADHOC_TASK: "CREATED_ADHOC_TASK",
  FOLDER_CHANGE_PERMISSION: "FOLDER_CHANGE_PERMISSION",
  FILE_CHANGE_PERMISSION: "FILE_CHANGE_PERMISSION",
  TAGS_UPDATE: "TAGS_UPDATE",
  ITEM_UPDATED: "ITEM_UPDATED",
  ITEM_CREATED: "ITEM_CREATED",
  ITEM_DELETED: "ITEM_DELETED",
  MINIMUM_ORDER_QUANTITY: "MINIMUM_ORDER_QUANTITY",
  MOVED_PROJECT_TASKS: "MOVED_PROJECT_TASKS",
  WITHOUT_TITLE: "WITHOUT_TITLE",
  UPLOADED_MULTI_FILES: "UPLOADED_MULTI_FILES",
  DELETE_MULTI_FILES: "DELETE_MULTI_FILES",
  DELETE_MULTI_FOLDERS: "DELETE_MULTI_FOLDERS",
  DELETE_MULTI_FILES_AND_FOLDERS: "DELETE_MULTI_FILES_AND_FOLDERS",
  MOVE_FILES: "MOVE_FILES",
  MOVE_FOLDERS: "MOVE_FOLDERS",
  MOVE_FILES_AND_FOLDERS: "MOVE_FILES_AND_FOLDERS",
  DOWNLOADED_MULTI_FILES_AND_FOLDERS: "DOWNLOADED_MULTI_FILES_AND_FOLDERS",
  DOWNLOADED_MULTI_FILES: "DOWNLOADED_MULTI_FILES",
  DOWNLOADED_MULTI_FOLDERS: "DOWNLOADED_MULTI_FOLDERS",
  MOVE_COMPLETED_TASKS: "MOVE_COMPLETED_TASKS",
  CREATE_SUB_TASKS: "CREATE_SUB_TASKS",
};

export const SO_SCOPE = [ORDER_TYPE.SALES_ORDER, ORDER_TYPE.QUOTE];
export const PO_SCOPE = [ORDER_TYPE.PURCHASE_ORDER, ORDER_TYPE.PURCHASE_QUOTE];
export const SHIPMENT_SCOPE = [ORDER_TYPE.SHIPMENT];

export const dependencyTypesOnMove = {
  START_DATE_CHANGED: "START_DATE_CHANGED",
  FINISH_DATE_CHANGED: "FINISH_DATE_CHANGED",
  START_DATE_AND_FINISH_DATE_CHANGED: "START_DATE_AND_FINISH_DATE_CHANGED",
};

export const noImageAvailableUrl =
  "https://firebasestorage.googleapis.com/v0/b/document-ai-processing.appspot.com/o/assets%2Fno_image_available.jpg?alt=media&token=b6d6a3c1-3166-4dae-9b24-78bcb36d294c";

export const MISCELLANEOUS_IDS = {
  SALES_ORDER_METADATA: "SALES_ORDER_METADATA",
  PURCHASE_ORDER_METADATA: "PURCHASE_ORDER_METADATA",
  SHIPMENT_METADATA: "SHIPMENT_METADATA",
  USED_EMOJIS_IN_TEMPLATES: "used_emojis_in_templates",
  USERS_SUMMARY: "users_summary",
};

export const SOObjFields = {
  ID: "id",
  DATE: "date",
  SHIP_DATE: "shipDate",
  SHIP_DATES: "shipDates",
  CBM: "CBM",
  UNIQUE_ITEMS: "uniqueItems",
  INVOICE: "invoice",
  CUSTOMER_PO: "customerPO",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  SALES_REPRESENTATIVE: "salesRepresentative",
  COMPANY_ID: "companyId",
  COMPLETED_TASKS: "completedTasks",
  COMPLETED_DATE: "completedDate",
  CREATION_DATE: "creationDate",
  CUSTOMER_ID: "customerId",
  NUMBER: "number",
  TOTAL_TASKS: "totalTasks",
  UPDATED_BY: "updated_by",
  TAGS: "tags",
  TYPE: "type",
  PURCHASE_ORDER_IDS: "purchaseOrderIds",
  STATUS: "status",
  DIAMOND_STATUS: "diamondStatus",
  DISCOUNT: "discount",
  REFERENCE_NUMBER: "referenceNumber",
  ITEMS: "items",
  PAY_TERMS_START: "payTermsStart",
  TOTAL_POS: "totalPOs",
  SHIPMENT_WINDOW: "shipmentWindow",
  DELIVERY_WINDOW: "deliveryWindow",
  TOTAL_SHIPMENTS: "totalShipments",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  BALANCE: "balance",
  DELIVERY_DATE: "deliveryDate",
  TOTAL_DISCOUNT: "totalDiscount",
  TOTAL: "total",
  REMAINDER: "remainder",
  TEMPLATE_EMOJI: "templateEmoji",
  TEMPLATE_NAME: "templateName",
};

export const POObjFields = {
  ID: "id",
  AMOUNT: "amount",
  FACTORY_ID: "factoryId",
  NUMBER: "number",
  DATE: "date",
  CBM: "CBM",
  INVOICE: "invoice",
  SHIP_DATE: "shipDate",
  ORDER_READY_DATE: "orderReadyDate",
  SHIP_DATES: "shipDates",
  WEIGHT: "weight",
  DEPOSIT: "deposit",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  UNIQUE_ITEMS: "uniqueItems",
  BALANCE: "balance",
  SHIPPING_TERMS: "shippingTerms",
  CREATION_DATE: "creationDate",
  COMPANY_ID: "companyId",
  SALES_ORDER_IDS: "salesOrderIds",
  COMPLETED_TASKS: "completedTasks",
  TOTAL_TASKS: "totalTasks",
  UPDATED_BY: "updated_by",
  TAGS: "tags",
  TYPE: "type",
  STATUS: "status",
  DIAMOND_STATUS: "diamondStatus",
  SHIPMENT_IDS: "shipmentIds",
  DISCOUNT: "discount",
  ITEM_TABLE_ID: "itemTableId",
  COMPLETED_DATE: "completedDate",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  SHIPMENT_WINDOW: "shipmentWindow",
  TOTAL_SHIPMENTS: "totalShipments",
  TOTAL_DISCOUNT: "totalDiscount",
  DELIVERY_WINDOW: "deliveryWindow",
  TOTAL: "total",
};

export const shipmentObjFields = {
  ID: "id",
  CREATION_DATE: "creationDate",
  DATE: "date",
  SHIP_DATE: "shipDate",
  SHIP_DATES: "shipDates",
  DELIVERY_DATE: "deliveryDate",
  DELIVERY_DATES: "deliveryDates",
  CBM: "CBM",
  WEIGHT: "weight",
  UNIQUE_ITEMS: "uniqueItems",
  BOOKING: "booking",
  BILL_OF_LADING: "billOfLading",
  CONTAINER: "container",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  SHIPPING_TERMS: "shippingTerms",
  INVOICE: "invoice",
  CUSTOMER_PO: "customerPO",
  AMOUNT: "amount",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  COMPANY_ID: "companyId",
  FINAL_DESTINATION: "finalDestination",
  FREIGHT_FORWARDER: "freightForwarder",
  CUSTOMER_ID: "customerId",
  NUMBER: "number",
  PURCHASE_ORDER_IDS: "purchaseOrderIds",
  SALES_ORDER_IDS: "salesOrderIds",
  STATUS: "status",
  DIAMOND_STATUS: "diamondStatus",
  COMPLETED_TASKS: "completedTasks",
  TOTAL_TASKS: "totalTasks",
  TAGS: "tags",
  ITEMS: "items",
  COMPLETED_DATE: "completedDate",
  TOTAL: "total",
};

export const FIELD_SOURCE = {
  TRADE_DASH: "TradeDash",
  USER: "User",
  EXTERNAL: "External",
};

export const FIELD_SCOPE = {
  BUILD_IN_FIELDS: "BUILD_IN_FIELDS",
  COMPANY_CUSTOM_FIELDS: "COMPANY_CUSTOM_FIELDS",
};

export const shipmentFieldsCustomized = [
  {
    id: shipmentObjFields.FINAL_DESTINATION,
    label: "Final Destination",
    field: shipmentObjFields.FINAL_DESTINATION,
    listIndex: 1,
    active: true,
    type: propertyType.AUTOCOMPLETE,
    source: FIELD_SOURCE.USER,
    listName: "finalDestinations",
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.DATE,
    description: "Date that this project was created in TradeDash",
    label: "Created",
    field: shipmentObjFields.DATE,
    listIndex: 2,
    active: true,
    type: propertyType.DATE,
    source: FIELD_SOURCE.TRADE_DASH,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.SHIP_DATE,
    label: "Ship Date",
    field: shipmentObjFields.SHIP_DATE,
    listIndex: 2,
    active: true,
    type: propertyType.DATE,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    permission: GENERAL_PERMISSION_VALUE.SHIPMENT_SHIP_DATE,
  },
  {
    id: shipmentObjFields.DELIVERY_DATE,
    label: "Delivery Date",
    field: shipmentObjFields.DELIVERY_DATE,
    listIndex: 3,
    active: true,
    type: propertyType.DATE,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    permission: GENERAL_PERMISSION_VALUE.SHIPMENT_DELIVERY_DATE,
  },
  {
    id: shipmentObjFields.LOADING_PORT,
    label: "Loading Port",
    field: shipmentObjFields.LOADING_PORT,
    listIndex: 4,
    active: true,
    type: propertyType.AUTOCOMPLETE,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    listName: "loadingPorts",
  },
  {
    id: shipmentObjFields.UNLOADING_PORT,
    label: "Unloading Port",
    field: shipmentObjFields.UNLOADING_PORT,
    listIndex: 5,
    active: true,
    type: propertyType.AUTOCOMPLETE,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    listName: "unloadingPorts",
  },
  {
    id: shipmentObjFields.FREIGHT_FORWARDER,
    label: "Freight Forwarder",
    field: shipmentObjFields.FREIGHT_FORWARDER,
    listIndex: 6,
    active: true,
    type: propertyType.AUTOCOMPLETE,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    listName: "freightForwarder",
  },
  {
    id: shipmentObjFields.TOTAL,
    label: "Total Value",
    field: shipmentObjFields.TOTAL,
    listIndex: 7,
    active: true,
    type: propertyType.CURRENCY,
    source: FIELD_SOURCE.TRADE_DASH,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.POTENTIAL_LATE_FEE,
    description: "Penalty amount that the customer of this order may charge",
    label: "Potential Late Fee",
    field: shipmentObjFields.POTENTIAL_LATE_FEE,
    listIndex: 8,
    active: true,
    type: propertyType.CURRENCY,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.INVOICE,
    description: "Invoice number issued to bill for this order",
    label: "Invoice",
    field: shipmentObjFields.INVOICE,
    listIndex: 9,
    active: true,
    type: propertyType.TEXT,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.CBM,
    description: "Total cubic volume of this order",
    label: "Total CBM",
    field: shipmentObjFields.CBM,
    listIndex: 10,
    active: true,
    type: propertyType.FLOAT,
    source: FIELD_SOURCE.TRADE_DASH,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.WEIGHT,
    label: "Total Weight",
    field: shipmentObjFields.WEIGHT,
    listIndex: 11,
    active: true,
    type: propertyType.FLOAT,
    source: FIELD_SOURCE.TRADE_DASH,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.UNIQUE_ITEMS,
    description: "Count of unique items in this project",
    label: "Unique Items",
    field: shipmentObjFields.UNIQUE_ITEMS,
    listIndex: 12,
    active: true,
    type: propertyType.INTEGER,
    source: FIELD_SOURCE.TRADE_DASH,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.CUSTOMER_PO,
    label: "Customer PO",
    description: "PO number provided by the customer ",
    field: shipmentObjFields.CUSTOMER_PO,
    listIndex: 13,
    active: true,
    type: propertyType.TEXT,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.BOOKING,
    label: "Booking",
    field: shipmentObjFields.BOOKING,
    listIndex: 14,
    active: true,
    type: propertyType.TEXT,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.CONTAINER,
    label: "Container",
    field: shipmentObjFields.CONTAINER,
    listIndex: 15,
    active: true,
    type: propertyType.TEXT,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.BILL_OF_LADING,
    label: "Bill of Lading",
    field: shipmentObjFields.BILL_OF_LADING,
    listIndex: 16,
    active: true,
    type: propertyType.TEXT,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: shipmentObjFields.SHIPPING_TERMS,
    label: "Shipping Terms",
    field: shipmentObjFields.SHIPPING_TERMS,
    listIndex: 17,
    active: true,
    type: propertyType.SELECTOR,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
];

export const salesOrderFieldsCustomized = [
  {
    id: SOObjFields.DATE,
    label: "Document Date",
    description: "Date from the ERP",
    field: SOObjFields.DATE,
    listIndex: 1,
    active: true,
    type: propertyType.DATE,
    source: FIELD_SOURCE.EXTERNAL,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
  },
  {
    id: SOObjFields.SHIP_DATE,
    label: "Ship By Date",
    field: SOObjFields.SHIP_DATE,
    listIndex: 2,
    active: true,
    type: propertyType.DATE,
    source: FIELD_SOURCE.USER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    description: "Date that the customer expects the order ships",
    companyPermission: COMPANY_PERMISSION_VALUE.SHIP_BY_DATE,
  },

  {
    id: SOObjFields.DELIVERY_DATE,
    description: "Date that the customer is expecting delivery of the order ",
    label: "Required Delivery",
    field: SOObjFields.DELIVERY_DATE,
    listIndex: 3,
    active: true,
    type: propertyType.DATE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.PAY_TERMS_START,
    label: "Pay Terms Start",
    description:
      "Date when the countdown to payment based on customer terms begins",
    field: SOObjFields.PAY_TERMS_START,
    listIndex: 4,
    active: true,
    type: propertyType.DATE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
  },
  {
    id: SOObjFields.SHIPMENT_WINDOW,
    label: "Shipment Window",
    description: "Shipment dates from all the shipments in this project",
    field: SOObjFields.SHIPMENT_WINDOW,
    listIndex: 5,
    active: true,
    type: propertyType.CALCULATED,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.DELIVERY_WINDOW,
    description: "Delivery dates from all the shipments in this project",
    label: "Delivery Window",
    field: SOObjFields.DELIVERY_WINDOW,
    listIndex: 6,
    active: true,
    type: propertyType.CALCULATED,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.TOTAL_POS,
    label: "Total POs",
    field: SOObjFields.TOTAL_POS,
    listIndex: 7,
    active: true,
    type: propertyType.INTEGER,
    source: FIELD_SOURCE.TRADE_DASH,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    description: "Count of POs in this project",
  },
  {
    id: SOObjFields.TOTAL_SHIPMENTS,
    description: "Count of shipments in this project",
    label: "Total Shipments",
    field: SOObjFields.TOTAL_SHIPMENTS,
    listIndex: 8,
    active: true,
    type: propertyType.INTEGER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.UNIQUE_ITEMS,
    description: "Count of unique items in this project",
    label: "Unique Items",
    field: SOObjFields.UNIQUE_ITEMS,
    listIndex: 9,
    active: true,
    type: propertyType.INTEGER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.AMOUNT,
    label: "Subtotal",
    description: "Order total before discount (if any)",
    field: SOObjFields.AMOUNT,
    listIndex: 10,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
    permission: GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT,
  },
  {
    id: SOObjFields.DISCOUNT,
    label: "Discount Percentage",
    description: "Discount applied to this order",
    field: SOObjFields.DISCOUNT,
    listIndex: 11,
    active: true,
    type: propertyType.PERCENTAGE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.EXTERNAL,
  },
  {
    id: SOObjFields.DEPOSIT,
    description: "Deposit amount on this order",
    label: "Deposit",
    field: SOObjFields.DEPOSIT,
    listIndex: 12,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
  },
  {
    id: SOObjFields.TOTAL,
    description: "Order value after the discount is applied",
    label: "Total",
    field: SOObjFields.TOTAL,
    listIndex: 13,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.TOTAL_DISCOUNT,
    label: "Discount",
    description: "Amount of discount on this order",
    field: SOObjFields.TOTAL_DISCOUNT,
    listIndex: 14,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.BALANCE,
    description: "Amount owed on this oder after the deposit",
    label: "Balance",
    field: SOObjFields.BALANCE,
    listIndex: 15,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.POTENTIAL_LATE_FEE,
    description: "Penalty amount that the customer of this order may charge",
    label: "Potential Late Fee",
    field: SOObjFields.POTENTIAL_LATE_FEE,
    listIndex: 16,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
  },

  {
    id: SOObjFields.INVOICE,
    label: "Invoice to Customer",
    description: "Invoice number issued to bill for this order",
    field: SOObjFields.INVOICE,
    listIndex: 17,
    active: true,
    type: propertyType.TEXT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
  },
  {
    id: SOObjFields.CUSTOMER_PO,
    label: "Customer PO",
    description: "PO number provided by the customer ",
    field: SOObjFields.CUSTOMER_PO,
    listIndex: 18,
    active: true,
    type: propertyType.TEXT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
    companyPermission: COMPANY_PERMISSION_VALUE.CUSTOMER_PO,
  },
  {
    id: SOObjFields.REFERENCE_NUMBER,
    description: "Internal reference number",
    label: "Reference Number",
    field: SOObjFields.REFERENCE_NUMBER,
    listIndex: 19,
    active: true,
    type: propertyType.TEXT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
  },
  {
    id: SOObjFields.CBM,
    description: "Total cubic volume of this order",
    label: "Total CBM",
    field: SOObjFields.CBM,
    listIndex: 20,
    active: true,
    type: propertyType.FLOAT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: SOObjFields.SALES_REPRESENTATIVE,
    description: "Sales person on this order",
    label: "Sales Rep",
    field: SOObjFields.SALES_REPRESENTATIVE,
    listIndex: 21,
    active: true,
    type: propertyType.TEXT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
    companyPermission: COMPANY_PERMISSION_VALUE.SALES_REP,
  },
  {
    id: SOObjFields.CREATION_DATE,
    description: "Date that this project was created in TradeDash",
    label: "Created Date",
    field: SOObjFields.CREATION_DATE,
    listIndex: 22,
    active: true,
    type: propertyType.DATE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
];

export const purchaseOrderFieldsCustomized = [
  {
    id: POObjFields.DATE,
    label: "Document Date",
    description: "Date from the ERP",
    field: POObjFields.DATE,
    listIndex: 1,
    active: true,
    type: propertyType.DATE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.ORDER_READY_DATE,
    label: "Order Ready Date",
    field: POObjFields.ORDER_READY_DATE,
    listIndex: 2,
    active: true,
    type: propertyType.DATE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
    permission: GENERAL_PERMISSION_VALUE.ORDER_READY_DATE,
    companyPermission: COMPANY_PERMISSION_VALUE.ORDER_READY_DATE,
  },
  {
    id: POObjFields.UNIQUE_ITEMS,
    description: "Count of unique items in this project",
    label: "Unique Items",
    field: POObjFields.UNIQUE_ITEMS,
    listIndex: 3,
    active: true,
    type: propertyType.INTEGER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.SHIPMENT_WINDOW,
    label: "Shipment Window",
    description: "Shipment dates from all the shipments in this project",
    field: POObjFields.SHIPMENT_WINDOW,
    listIndex: 4,
    active: true,
    type: propertyType.CALCULATED,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.DELIVERY_WINDOW,
    description: "Delivery dates from all the shipments in this project",
    label: "Delivery Window",
    field: POObjFields.DELIVERY_WINDOW,
    listIndex: 5,
    active: true,
    type: propertyType.CALCULATED,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.TOTAL_SHIPMENTS,
    description: "Count of shipments in this project",
    label: "Total Shipments",
    field: POObjFields.TOTAL_SHIPMENTS,
    listIndex: 6,
    active: true,
    type: propertyType.INTEGER,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.AMOUNT,
    label: "Subtotal",
    description: "Order total before discount (if any)",
    field: POObjFields.AMOUNT,
    listIndex: 7,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.DISCOUNT_PERCENTAGE,
    label: "Discount Percentage",
    description: "Discount applied to this order",
    field: POObjFields.DISCOUNT,
    listIndex: 8,
    active: true,
    type: propertyType.PERCENTAGE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
    permission: GENERAL_PERMISSION_VALUE.CAN_EDIT_PO_DISCOUNT_PERCENTAGE,
  },
  {
    id: POObjFields.DEPOSIT,
    description: "Deposit amount on this order",
    label: "Deposit",
    field: POObjFields.DEPOSIT,
    listIndex: 9,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
    permission: GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT,
  },
  {
    id: POObjFields.TOTAL,
    description: "Order value after the discount is applied",
    label: "Total",
    field: POObjFields.TOTAL,
    listIndex: 10,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.TOTAL_DISCOUNT,
    label: "Discount",
    description: "Amount of discount on this order",
    field: POObjFields.TOTAL_DISCOUNT,
    listIndex: 11,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.BALANCE,
    description: "Amount owed on this order after the deposit",
    label: "Balance",
    field: POObjFields.BALANCE,
    listIndex: 12,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.POTENTIAL_LATE_FEE,
    description: "Penalty amount that the customer of this order may charge",
    label: "Potential Late Fee",
    field: POObjFields.POTENTIAL_LATE_FEE,
    listIndex: 13,
    active: true,
    type: propertyType.CURRENCY,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
    permission: GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT,
  },
  {
    id: POObjFields.CBM,
    description: "Total cubic volume of this order",
    label: "CBM",
    field: POObjFields.CBM,
    listIndex: 14,
    active: true,
    type: propertyType.FLOAT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.WEIGHT,
    label: "Total Weight",
    field: POObjFields.WEIGHT,
    listIndex: 15,
    active: true,
    type: propertyType.FLOAT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
  {
    id: POObjFields.INVOICE,
    label: "Vendor Invoice",
    field: POObjFields.INVOICE,
    listIndex: 16,
    active: true,
    type: propertyType.TEXT,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
  },
  {
    id: POObjFields.SHIPPING_TERMS,
    label: "Shipping Terms",
    field: POObjFields.SHIPPING_TERMS,
    listIndex: 17,
    active: true,
    type: propertyType.SELECTOR,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.USER,
  },
  {
    id: POObjFields.CREATION_DATE,
    description: "Date that this project was created in TradeDash",
    label: "Creation Date",
    field: POObjFields.CREATION_DATE,
    listIndex: 18,
    active: true,
    type: propertyType.DATE,
    scope: FIELD_SCOPE.BUILD_IN_FIELDS,
    source: FIELD_SOURCE.TRADE_DASH,
  },
];

export const CustomizedEntityDoc = {
  SALES_ORDER_METADATA: "SALES_ORDER_METADATA",
  PURCHASE_ORDER_METADATA: "PURCHASE_ORDER_METADATA",
  SHIPMENT_METADATA: "SHIPMENT_METADATA",
};

export const COPIED_DOCUMENT_TYPE = {
  FOLDER: "COPIED_FOLDER_LINK",
  FILE: "COPIED_FILE_LINK",
};

export const DOWNLOAD_DOCUMENT_TYPE = {
  FOLDER_LANDING: "DOWNLOADED_FOLDER_FROM_LINK",
  FILE_LANDING: "DOWNLOADED_FILE_FROM_LINK",
  FILES_AND_FOLDER: "DOWNLOADED_MULTI_FILES_AND_FOLDERS",
  MULTI_FOLDER: "DOWNLOADED_MULTI_FOLDERS",
  ONLY_FOLDER: "DOWNLOADED_FOLDER",
  ONLY_FILE: "DOWNLOADED_FILE",
  MULTI_FILE: "DOWNLOADED_MULTI_FILES",
};
