import React from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import { Clear as ClearIcon } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { DisplayFiltersInfo } from "../../container/StyledComponent/DisplayFiltersInfo";

function FilterDisplay({
  tooltip,
  value,
  label,
  onClear,
  clearIconId = "",
  hiddenClose = false,
  notificationFilter = false,
}) {
  const matches = useMediaQuery("(max-width:1425px)") && !notificationFilter;
  const displayStyle = notificationFilter
    ? "notificationFilter"
    : "filterContainer";
  return (
    <TooltipTD
      classes={{ tooltip: "tooltip-filter" }}
      label={tooltip}
      showToolTip={!!tooltip}
    >
      <DisplayFiltersInfo className={displayStyle}>
        {!matches && <legend className="legendFilter">{label}</legend>}
        <div
          className="textFilter"
          style={
            matches
              ? {
                  height: "100%",
                  alignContent: "center",
                }
              : {}
          }
        >
          {matches ? label : value}
        </div>
        {!hiddenClose && (
          <ClearIcon
            id={clearIconId}
            onClick={(ev) => {
              onClear();
              ev.stopPropagation();
            }}
            className="closeIcon"
          />
        )}
      </DisplayFiltersInfo>
    </TooltipTD>
  );
}

export default FilterDisplay;
