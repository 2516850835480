import React, { useState } from "react";
import AutoCompleteWithChips from "../AutoCompleteWithChips.js/AutoCompleteWithChips";
import { Button } from "@mui/material";
import CreateCategory from "./CreateCategory";
import CategoryItem from "../../api/model/CategoryItem.model";
import { useUser } from "../../hooks/user";
import { getRandomId } from "../../helpers/helpers";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import Category from "../../api/model/Category.model";
import { now } from "moment/moment";
import { useCompanyId } from "../../hooks/user";
import { maxCategories } from "../CompanyTabs/Categories";
import AttentionModal from "../Modal/AttentionModal";
import { colors } from "../../assets/jss/variables";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function AutocompleteCategory({
  options = [],
  selectedOptions = [],
  onChange = () => {},
  onBlur = () => {},
  categories = [],
  styles = {},
  onCreateCategory = () => {},
  canAssign,
  canUnassign,
  canCreateCategory,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );
  const [categoryItem, setCategoryItem] = useState({ ...new CategoryItem({}) });

  const user = useUser();
  const companyId = useCompanyId();

  function createNewCategory({ categoryItem }) {
    const id = getRandomId();
    const newCategoryItem = {
      ...new CategoryItem({
        id: categoryItem.id,
        name: categoryItem.name,
        description: categoryItem.description,
        summaryId: id,
        active: true,
        createdBy: user.id,
        createdDate: now(),
        updatedBy: "",
        updatedDate: "",
      }),
    };
    const newCategory = new Category({
      id,
      categories: [newCategoryItem],
      size: 1,
    });
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.CATEGORIES}/${newCategory.id}`
      ),
      { ...newCategory }
    );
    setOpenModal(false);
    setCategoryItem({ ...new CategoryItem({}) });
    onCreateCategory({ categoryItem: newCategoryItem });
  }

  async function handleSubmit({ currentCategoryItem }) {
    const foundCurrentCategory = options.find(
      (category) =>
        category.name.toString().toLowerCase().trim() ===
        currentCategoryItem.name.toString().toLowerCase().trim()
    );
    if (foundCurrentCategory) {
      setDescriptionModalAttention(
        <React.Fragment>That category name is already in use</React.Fragment>
      );
      setOpenModalAttention(true);
      return;
    }
    if (categories.length === 0) {
      createNewCategory({ categoryItem: currentCategoryItem });
      return;
    }

    const [lastCategory] = categories.sort(
      sortObjectsBy("creationDate", true)
    );
    if (+lastCategory.size < maxCategories) {
      const categoriesCpy = [...lastCategory.categories];
      categoriesCpy.push({
        ...currentCategoryItem,
        summaryId: lastCategory.id,
        createdBy: user.id,
        createdDate: now(),
      });
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.CATEGORIES}/${lastCategory.id}`
        ),
        {
          ...lastCategory,
          categories: categoriesCpy,
          size: categoriesCpy.length,
        }
      );
      setOpenModal(false);
      setCategoryItem({ ...new CategoryItem({}) });
      onCreateCategory({ categoryItem: currentCategoryItem });
    }
  }

  const handleChange = (event, value) => {
    // ADDING CATEGORY
    console.log("handleChange", value, selectedOptions);
    if (value.length > selectedOptions.length) {
      if (canAssign) {
        return onChange(event, value);
      }
    } else if (value.length < selectedOptions.length) {
      if (canUnassign) {
        return onChange(event, value);
      }
    }
  };

  return (
    <div style={styles}>
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => {
            setOpenModalAttention(false);
            const categoryEl = document.getElementById(
              "category-id-" + categoryItem.id
            );
            setTimeout(() => {
              categoryEl.focus();
              categoryEl.select();
            }, 5);
          }}
          cancellable={false}
          confirmationText="Ok"
          onClose={() => setOpenModalAttention(false)}
        />
      )}
      {openModal && (
        <CreateCategory
          openModal={openModal}
          handleClose={(ev) => {
            setOpenModal(false);
            ev.stopPropagation();
          }}
          categoryItem={categoryItem}
          onSubmit={handleSubmit}
          user={user}
        />
      )}
      {canCreateCategory && (
        <Button
          style={{
            float: "right",
            fontSize: "12px",
            fontWeight: "bold",
            color: colors.primaryDark,
            fontFamily: "Roboto",
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          + NEW CATEGORY
        </Button>
      )}
      <div>
        <AutoCompleteWithChips
          options={options.filter((item) => item.active)}
          selectedOptions={selectedOptions}
          label="name"
          onChange={handleChange}
          disableClearable={true}
          onBlur={onBlur}
          canAssign={canAssign}
          canUnassign={canUnassign}
        />
      </div>
    </div>
  );
}

export default AutocompleteCategory;
