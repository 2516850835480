import React, { useState } from "react";
import { dbTables } from "../../../api/types/dbTables";
import { ProjectDocumentNoteStyled } from "./styles";
import {
  documentScope,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  PO_STATUS,
} from "../../../helpers/constants";
import NoteDocumentsTab from "./NoteDocumentsTab";
import { getFactoryName, isTradeDashEmployee } from "../../../helpers/helpers";
import NavPicker from "../../DatePicker/NavPicker";
import { useFactories } from "../../../hooks/factories";
import { getPOShipments, getShipmentBadge } from "../../../helpers/shipments";
import {
  ArrowDownIcon,
  PurchaseOrderIcon,
  ShipmentOrderIcon,
} from "../../../helpers/iconSvgPath";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../../../container/StyledComponent/BadgeStyled";
import ManagementNoteDocumentsV2 from "./ManagementDocumentsNoteV2";
import { getDocumentLength } from "../../Documents/documentHelpers";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import POBadge from "../../Badge/POBadge";

const currentTab = {
  SALES_ORDER_NOTE: 0,
  PURCHASE_ORDER_NOTE: 1,
  SHIPMENT_NOTE: 2,
};

function DocumentsNote({
  currentSalesOrder = {},
  user,
  permissionGroupsDB,
  handleRichDocument = () => {},
  handlePOId,
  handleShipmentId,
  shipments = [],
  purchaseOrders = [],
  poDocumentId = "",
  shipmentDocumentId = "",
  handleDocumentsDB = () => {},
  currentDocuments,
  closeIcon,
  typeNote,
}) {
  const [selectedDocumentTab, setSelectedDocumentTab] = useState(
    currentTab[typeNote]
  );
  const [scope, setScope] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const factories = useFactories();
  const allowed = useIsAllowedFunction();

  const changeDocumentTab = (tab) => {
    setSelectedDocumentTab(tab);
  };
  const currentPurchaseOrder = purchaseOrders.find(
    (po) => po.id === poDocumentId
  );
  const currentShipment =
    shipments.find((shipment) => shipment.id === shipmentDocumentId) || {};

  const filterPOs = purchaseOrders.filter(
    (purchaseOrder) => purchaseOrder.status !== PO_STATUS.VOIDED
  );
  const filterShipments = getPOShipments({
    shipments,
    purchaseOrder: currentPurchaseOrder,
  });

  const poList = filterPOs.map((po) => ({
    id: po.id,
    name: <POBadge number={po.number} />,
    number: po.number,
    divider: true,
    selected: po.id === currentPurchaseOrder.id,
    subTitle: getFactoryName(factories, po.factoryId, true),
    userMentions: po.userMentions || {},
    scope: NOTES_SCOPE.PURCHASE_ORDER,
  }));

  const shipmentList = filterShipments.map((shipment) => ({
    id: shipment.id,
    name: getShipmentBadge({
      shipment: shipment,
      classes: shipmentBadgeClass.medium,
      tooltipPlacement: "bottom",
    }),
    divider: false,
    subTitle: shipment.finalDestination || "",
    userMentions: shipment.userMentions || {},
    scope: NOTES_SCOPE.SHIPMENT,
  }));
  const currentList = {
    [NOTES_SCOPE.PURCHASE_ORDER]: {
      list: poList,
      scope: "purchase-order-tab-",
      id: currentPurchaseOrder.id,
      sizeNumber: Math.max(...poList.map((po) => po.number.length)),
      sizeSubtitle: Math.max(...poList.map((po) => po.subTitle.length)),
    },
    [NOTES_SCOPE.SHIPMENT]: {
      list: shipmentList,
      scope: "shipment-tab-",
      id: currentShipment.id,
      sizeNumber: 6,
      sizeSubtitle: Math.max(
        ...shipmentList.map((shipment) => shipment.subTitle.length)
      ),
    },
  };
  const isActive = (targetScope) => {
    if (targetScope === scope) {
      return `NOTE-${targetScope}-active`;
    } else {
      return `NOTE-${targetScope}`;
    }
  };

  const currentTabValue = () => {
    let isCorretOrder;
    const canSeeAll = allowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
    );
    switch (selectedDocumentTab) {
      case 0:
        const soDocuments = currentDocuments[currentSalesOrder.id] || [];
        isCorretOrder = currentDocuments[currentSalesOrder.id];
        return {
          isVoid: currentSalesOrder.status === PO_STATUS.VOIDED,
          parentDocumentId: currentSalesOrder.id,
          mainEntity: currentSalesOrder,
          parentCollection: dbTables.SALES_ORDERS,
          dbCollection: dbTables.SALES_ORDER_DOCUMENTS,
          soSize: isCorretOrder
            ? getDocumentLength({
                currentDocuments: soDocuments,
                user,
                permissionGroupsDB,
                canSeeAll,
              })
            : 0,
          documents: soDocuments.map((salesOrderDocument) => ({
            ...salesOrderDocument,
            scope: documentScope.SALES_ORDER,
          })),
        };
      case 1:
        isCorretOrder = currentDocuments[currentPurchaseOrder.id];
        const poDocuments = currentDocuments[currentPurchaseOrder.id] || [];
        return {
          isVoid: currentPurchaseOrder.status === PO_STATUS.VOIDED,
          mainEntity: currentPurchaseOrder,
          parentDocumentId: currentPurchaseOrder.id,
          parentCollection: dbTables.PURCHASE_ORDERS,
          dbCollection: dbTables.PURCHASE_ORDER_DOCUMENTS,
          poSize: getDocumentLength({
            currentDocuments: poDocuments,
            user,
            permissionGroupsDB,
            canSeeAll,
          }),
          documents: poDocuments.map((poDocument) => ({
            ...poDocument,
            scope: documentScope.PURCHASE_ORDER,
          })),
        };
      default:
        isCorretOrder = currentDocuments[currentShipment.id];
        const shipmentDocuments = currentDocuments[currentShipment.id] || [];
        return {
          isVoid: currentShipment === PO_STATUS.VOIDED,
          mainEntity: currentShipment,
          parentDocumentId: currentShipment.id,
          parentCollection: dbTables.SHIPMENTS,
          dbCollection: dbTables.SHIPMENT_DOCUMENTS,
          shipmentSize: getDocumentLength({
            currentDocuments: shipmentDocuments,
            user,
            permissionGroupsDB,
            canSeeAll,
          }),
          documents: shipmentDocuments.map((shipmentDocument) => ({
            ...shipmentDocument,
            scope: documentScope.SHIPMENT,
          })),
        };
    }
  };

  const tabValue = currentTabValue();

  return (
    <ProjectDocumentNoteStyled className="documentNoteContentCard">
      {anchorEl && (
        <NavPicker
          anchorEl={anchorEl}
          open={!!anchorEl}
          list={currentList[scope].list}
          onClose={() => {
            setAnchorEl(null);
            setScope("");
          }}
          selectedId={currentList[scope].id}
          scope={currentList[scope].scope}
          user={user}
          navStyles={{ top: 80 }}
          sizeNumber={currentList[scope].sizeNumber}
          sizeSubtitle={currentList[scope].sizeSubtitle}
          onClickAction={(id, scope) => {
            if (scope === NOTES_SCOPE.PURCHASE_ORDER) {
              handlePOId(id);
              const newPO = purchaseOrders.find((po) => po.id === id) || {};
              const shipmentsIds = newPO.shipmentIds || [];
              if (!shipmentsIds.includes(shipmentDocumentId)) {
                handleShipmentId(shipmentsIds[0] || "");
              }
              setSelectedDocumentTab(1);
            } else {
              handleShipmentId(id);
              setSelectedDocumentTab(2);
            }
            setAnchorEl(null);
            setScope("");
          }}
          hiddenAvatar
        />
      )}
      {closeIcon}
      <div className="cardHeader">
        <span className="documents-title">Documents</span>
        {filterPOs.length > 0 && (
          <div style={{ display: "flex" }}>
            <div
              className={cx("title-list", isActive(NOTES_SCOPE.PURCHASE_ORDER))}
              onClick={(ev) => {
                setAnchorEl(ev.currentTarget);
                setScope(NOTES_SCOPE.PURCHASE_ORDER);
              }}
              id="long-button"
            >
              <PurchaseOrderIcon />
              <ArrowDownIcon
                svgClass="arrowIcon"
                width={12}
                style={{ marginLeft: 4 }}
              />
            </div>
            {filterShipments.length > 0 && (
              <div
                className={cx("title-list", isActive(NOTES_SCOPE.SHIPMENT))}
                onClick={(ev) => {
                  setAnchorEl(ev.currentTarget);
                  setScope(NOTES_SCOPE.SHIPMENT);
                }}
              >
                <ShipmentOrderIcon />
                <ArrowDownIcon
                  svgClass="arrowIcon"
                  width={12}
                  style={{ marginLeft: 4 }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <NoteDocumentsTab
        onChange={changeDocumentTab}
        value={selectedDocumentTab}
        currentPurchaseOrder={currentPurchaseOrder}
        currentShipment={currentShipment}
        currentSalesOrder={currentSalesOrder}
      />

      <div
        className={cx(
          "content-card-content",
          `container${tabValue.parentCollection}`
        )}
        style={{
          flexDirection: "column",
          height: "100%",
          display: "flex",
          padding: "0px 0px 0px 21px",
        }}
      >
        <ManagementNoteDocumentsV2
          permissionGroupsDB={permissionGroupsDB}
          selectedDocumentTab={selectedDocumentTab}
          isVoided={tabValue.isVoid}
          permissionScope={
            GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
          }
          documents={tabValue.documents}
          dbCollection={tabValue.dbCollection}
          parentCollection={tabValue.parentCollection}
          parentDocumentId={tabValue.parentDocumentId}
          isReadOnly={isTradeDashEmployee(user)}
          mainEntity={tabValue.mainEntity}
          handleRichDocument={(document) => {
            if (document.id) {
              handleRichDocument({
                ...document,
                purchaseOrderId: poDocumentId,
                shipmentId: shipmentDocumentId,
              });
            } else {
              handleRichDocument(document);
            }
          }}
          handleDocumentsDB={handleDocumentsDB}
        />
      </div>
    </ProjectDocumentNoteStyled>
  );
}

export default DocumentsNote;
