import React, { useState } from "react";
import { useUser, useCompanyUsers } from "../../hooks/user";
import { isTradeDashEmployee } from "../../helpers/helpers";
import ProjectDocumentsV2 from "./ProjectDocumentsV2";
import {
  QUERY_PARAMS_SCOPE,
  onChangeQueryParamsv2,
} from "../../helpers/orderDashboard";
import { useQueryParams } from "../../hooks";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useNavigate } from "react-router-dom";
import ActivityStreamv2 from "./ActivityStream/ActivityStreamv2";
import ProjectTasksV2 from "./TaskList/ProjectTasksV2";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../helpers/constants";

 const PANEL = {
  TASK: "task",
  NOTES: "notes",
  DOCUMENTS: "document",
};

function ProjectDashboard({
  companyID,
  currentSalesOrder = {},
  currentPurchaseOrder,
  purchaseOrders = [],
  permissionGroups = [],
  isReadOnly = false,
  setIsLoading,
  currentShipment,
}) {
  let companyUsers = useCompanyUsers({});
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [currentPanel, setCurrentPanel] = useState("");
  let allCompanyUsers = useCompanyUsers({ showBotUser: true });
  const user = useUser();

  const handleChangeShipment = (shipmentId) => {
    onChangeQueryParamsv2({
      scope: QUERY_PARAMS_SCOPE.SHIPMENT,
      shipmentId: shipmentId,
      queryParams: {
        ...queryParams,
        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
      },
      navigate,
    });
  };

  function handleReadOnlyModal() {
    setReadOnlyModal(true);
  }

  const panelWidth = (panelFocus) => {
    if (!currentPanel) {
      return "33.3%";
    }

    return currentPanel === panelFocus ? "50%" : "25%";
  };

  return (
    <div
      id="project-dashboard-container"
      className="project-dashboard-container"
      style={{ gap: 6 }}
    >
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
          isRegularUser={!isTradeDashEmployee(user)}
        />
      )}
      <ProjectTasksV2
        companyId={companyID}
        currentSalesOrder={currentSalesOrder}
        currentPurchaseOrder={currentPurchaseOrder}
        companyUsers={companyUsers}
        purchaseOrders={purchaseOrders}
        currentShipment={currentShipment}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        setIsLoading={setIsLoading}
        onChangeShipment={handleChangeShipment}
        panelWidth={panelWidth(PANEL.TASK)}
        handleActivePanel={() =>
          setCurrentPanel(currentPanel === PANEL.TASK ? "" : PANEL.TASK)
        }
      />

      <ActivityStreamv2
        salesOrder={currentSalesOrder}
        purchaseOrder={currentPurchaseOrder}
        currentShipment={currentShipment}
        panelWidth={panelWidth(PANEL.NOTES)}
        companyUsers={allCompanyUsers}
        onActivePanel={() => {
          setCurrentPanel(currentPanel === PANEL.NOTES ? "" : PANEL.NOTES);
        }}
      />

      <ProjectDocumentsV2
        permissionGroups={permissionGroups}
        companyID={companyID}
        currentSalesOrder={currentSalesOrder}
        currentPurchaseOrder={currentPurchaseOrder}
        user={user}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        currentShipment={currentShipment}
        onChangeShipment={handleChangeShipment}
        panelWidth={panelWidth(PANEL.DOCUMENTS)}
        handleActivePanel={() =>
          setCurrentPanel(
            currentPanel === PANEL.DOCUMENTS ? "" : PANEL.DOCUMENTS
          )
        }
      />
    </div>
  );
}

export default ProjectDashboard;
