import React from "react";
import { propertyType } from "../../helpers/constants";
import { TableCell } from "@mui/material";
import { getTheDate } from "../../helpers/helpers";
import {
  percentageValue,
  zeroIsBlank,
  zeroIsBlankNumber,
} from "../../helpers/salesOrder";
import TooltipTD from "../../components/Tooltip/TooltipTD";

function CustomTableRowSelector({ item = {}, header = {}, index }) {
  function displayDate({
    index,
    value,
    field,
    format = "M/D/YY",
    styles = {},
  }) {
    return (
      <TableCell key={index} className="date" id={field} style={styles}>
        {getTheDate(value, format)}
      </TableCell>
    );
  }

  function displayDollarAmount({ index, value, field, styles = {} }) {
    return (
      <TableCell key={index} className="deposit" id={field} style={styles}>
        {zeroIsBlank(value)}
      </TableCell>
    );
  }

  function displayIntegerNumber({ index, value, field, styles = {} }) {
    return (
      <TableCell key={index} className="deposit" id={field} style={styles}>
        {zeroIsBlankNumber({ value, format: "0,0" })}
      </TableCell>
    );
  }

  function displayFloatNumber({ index, value, field, styles = {} }) {
    return (
      <TableCell key={index} className="deposit" id={field} style={styles}>
        {zeroIsBlankNumber({ value, format: "0,0.00" })}
      </TableCell>
    );
  }

  function displayPercentage({ index, value, field, styles = {} }) {
    return (
      <TableCell key={index} className="deposit" id={field} style={styles}>
        {percentageValue(value)}
      </TableCell>
    );
  }

  function displayText({ index, value, field, styles = {} }) {
    return (
      <TableCell
        key={index}
        className="finalDestination"
        id={field}
        style={styles}
      >
        <TooltipTD
          label={value}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className="customized-tooltip"
        >
          <span>{value}</span>
        </TooltipTD>
      </TableCell>
    );
  }
  function customTableRowSelector({ index }) {
    const { field, enabled, type, styles } = header;
    switch (type) {
      case propertyType.TEXT:
      case propertyType.AUTOCOMPLETE:
      case propertyType.LINK:
      case propertyType.SELECTOR: {
        if (enabled) {
          return displayText({
            value: item[field],
            field: field,
            styles,
            index,
          });
        }
        return null;
      }
      case propertyType.DATE: {
        if (enabled) {
          return displayDate({
            value: item[field],
            field: field,
            styles,
            index,
          });
        }
        return null;
      }
      case propertyType.DATE_TIME: {
        if (enabled) {
          return displayDate({
            value: item[field],
            field: field,
            index,
            format: "M/D/YY h:mma",
            styles,
          });
        }
        return null;
      }
      case propertyType.FLOAT: {
        if (enabled) {
          return displayFloatNumber({
            value: item[field],
            field: field,
            index,
            styles,
          });
        }
        return null;
      }
      case propertyType.INTEGER: {
        if (enabled) {
          return displayIntegerNumber({
            value: item[field],
            field: field,
            index,
            styles,
          });
        }
        return null;
      }
      case propertyType.CURRENCY: {
        if (enabled) {
          return displayDollarAmount({
            value: item[field],
            field: field,
            index,
            styles,
          });
        }
        return null;
      }
      case propertyType.PERCENTAGE: {
        if (enabled) {
          return displayPercentage({
            value: item[field],
            field: field,
            index,
            styles,
          });
        }
        return null;
      }

      default:
        return null;
    }
  }
  return customTableRowSelector({ index });
}

export default CustomTableRowSelector;
