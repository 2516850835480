import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import CustomButton from "../Buttons/CustomButton";
import IntlMessages from "../../util/IntlMessages";
import AutocompleteUsers from "../TextFields/AutocompleteUsers";

function UserPermissionsModal({
  open,
  onClose,
  users = [],
  value = [],
  onSave = () => {},
}) {
  const [usersSelected, setUsersSelected] = useState([]);

  useEffect(() => {
    if (open) {
      setUsersSelected(value);
    }
  }, [open]);

  function handleChange(value) {
    setUsersSelected([...value]);
  }

  function save() {
    onSave(usersSelected);
    setUsersSelected([]);
    onClose();
  }

  function handleClose() {
    setUsersSelected([]);
    onClose();
  }
  return (
    <CustomModal
      header={
        <IntlMessages
          id={"components.userManagement.userPermissionsModal.addUserRoles"}
        />
      }
      isOpen={open}
      onClose={handleClose}
      isCentered
      bodyStyles={{
        width: 650,
      }}
    >
      <AutocompleteUsers
        usersSelected={usersSelected}
        users={users}
        onChange={handleChange}
        userSelected={usersSelected}
      />
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <CustomButton onClick={save} type="success">
          {"generic.text.save"}
        </CustomButton>
        <CustomButton
          onClick={() => {
            setUsersSelected([]);
            onClose();
          }}
          type="cancel"
        >
          {"generic.text.cancel"}
        </CustomButton>
      </div>
    </CustomModal>
  );
}

export default UserPermissionsModal;
