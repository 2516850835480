import { useState } from "react";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Button,
} from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/Title";
import NumbersIcon from "@mui/icons-material/Numbers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoneyIcon from "@mui/icons-material/Money";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PercentageIcon from "@mui/icons-material/Percent";
import LinkIcon from "@mui/icons-material/Link";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { propertyLabel, propertyType } from "../../../helpers/constants";
import { FieldSelectorMenu } from "../../SalesOrders/styles";
import { cx } from "@emotion/css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const menuItems = [
  {
    label: propertyLabel[propertyType.TEXT],
    value: propertyType.TEXT,
    icon: <TextFieldsIcon />,
  },
  {
    label: "Number",
    icon: <NumbersIcon />,
    submenu: [
      {
        label: propertyLabel[propertyType.INTEGER],
        value: propertyType.INTEGER,
        icon: <LooksOneIcon />,
      },
      {
        label: propertyLabel[propertyType.FLOAT],
        value: propertyType.FLOAT,
        icon: <LooksTwoIcon />,
      },
      {
        label: propertyLabel[propertyType.PERCENTAGE],
        value: propertyType.PERCENTAGE,
        icon: <PercentageIcon />,
      },
      {
        label: propertyLabel[propertyType.CURRENCY],
        value: propertyType.CURRENCY,
        icon: <MoneyIcon />,
      },
    ],
  },
  {
    label: "Date",
    icon: <CalendarTodayIcon />,
    submenu: [
      {
        label: propertyLabel[propertyType.DATE],
        value: propertyType.DATE,
        icon: <CalendarTodayIcon />,
      },
      {
        label: propertyLabel[propertyType.DATE_TIME],
        value: propertyType.DATE_TIME,
        icon: <AccessTimeIcon />,
      },
    ],
  },
  {
    label: propertyLabel[propertyType.LINK],
    value: propertyType.LINK,
    icon: <LinkIcon />,
  },
  {
    label: propertyLabel[propertyType.SPACER],
    value: propertyType.SPACER,
    icon: <HorizontalRuleIcon />,
  },
];

function FieldTypeSelector({ type, setType, disabled }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [submenuItems, setSubmenuItems] = useState([]);

  const openMainMenu = !!anchorEl;
  const openSubMenu = !!submenuAnchorEl;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubmenuOpen = (event, submenu) => {
    setSubmenuAnchorEl(event.currentTarget);
    setSubmenuItems(submenu || []);
  };

  const handleCloseAll = () => {
    if (openMainMenu) {
      setAnchorEl(null);
      setSubmenuAnchorEl(null);
      setSubmenuItems([]);
    }
  };

  const handleSelect = (value) => {
    setType(value);
    handleCloseAll();
  };

  return (
    <div>
      <Button
        onClick={handleMenuOpen}
        variant="outlined"
        size="small"
        className="fieldTypeSelector"
        fullWidth
        disabled={disabled}
      >
        {propertyLabel[type] || "Select one"}
        <ExpandMoreIcon
          className={cx("arrowDropDownIcon", { arrowActive: !!openMainMenu })}
        />
      </Button>
      {openMainMenu && (
        <ClickAwayListener onClickAway={handleCloseAll}>
          <>
            <FieldSelectorMenu
              anchorEl={anchorEl}
              open={openMainMenu}
              onClose={handleCloseAll}
            >
              {menuItems.map((item) => (
                <MenuItem
                  key={item.value}
                  className={cx("menuItem", {
                    selected: type === item.value,
                  })}
                  onClick={(event) =>
                    item.submenu
                      ? handleSubmenuOpen(event, item.submenu)
                      : handleSelect(item.value)
                  }
                >
                  <ListItemIcon className="itemIcon">{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                  {item.submenu && (
                    <ArrowForwardIosIcon
                      className={cx("subMenuIcon", {
                        arrowSelected: item.submenu.some(
                          (subItem) => subItem.value === type
                        ),
                      })}
                    />
                  )}
                </MenuItem>
              ))}
            </FieldSelectorMenu>

            {openSubMenu && (
              <FieldSelectorMenu
                anchorEl={submenuAnchorEl}
                open={openSubMenu}
                onClose={() => setSubmenuAnchorEl(null)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                {submenuItems.map((subItem) => (
                  <MenuItem
                    key={subItem.value}
                    onClick={() => handleSelect(subItem.value)}
                    className={cx("menuItem", {
                      selected: type === subItem.value,
                    })}
                  >
                    <ListItemIcon className="itemIcon">
                      {subItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={subItem.label} />
                  </MenuItem>
                ))}
              </FieldSelectorMenu>
            )}
          </>
        </ClickAwayListener>
      )}
    </div>
  );
}

export default FieldTypeSelector;
