import React, { useState, useEffect } from "react";
import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import ProgressStage from "./ProgressStage";
import { buildHeaderCells } from "../../components/Managers/TableHeaderProvider";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { addFavorite, removeFavorite } from "../../helpers/favoritesHelper";
import { useUser } from "../../hooks/user";
import { NavLink } from "react-router-dom";
import FooterTable from "../../components/Tables/FooterTable";
import { footerOrdersByPhasePOsWithinSO } from "../../components/Managers/FooterTableManager";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import { TableCell } from "@mui/material";
import TagsModal from "../../components/Modal/TagsModal";
import {
  getTagsLabel,
  verifyVendorIcon,
  isTradeDashEmployee,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import { orderByPhasePOGroupBySOSetup } from "../../helpers/screenHeaderSetup";
import {
  generalPermissions,
  headerColumns,
  ORDER_DASHBOARD_ACTUAL_VIEWS,
  propertyType,
} from "../../helpers/constants";
import { dbTables } from "../../api/types/dbTables";
import { getTagStatus } from "../../helpers/salesOrder";
import { useFactories } from "../../hooks/factories";
import { useFavorites, useFavoritesList } from "../../hooks";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import {
  getDiamondStatus,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import POBadge from "../../components/Badge/POBadge";
import { colors } from "../../assets/jss/variables";
import CustomTableRowSelector from "../SalesOrdersScreen/CustomTableRowSelector";
import { PO_VIEW_FIELDS } from "../../helpers/purchaseOrder";

function FilterTablePO({
  purchaseOrderList,
  headerStages,
  filtersTable,
  onUpdateFilters,
  tags,
  onUpdateItem,
  isAllowed,
  filters = { factory: [] },
  expanded = false,
}) {
  const user = useUser();
  const [headerCells, setHeaderCells] = useState([]);
  const filterTag = filters.tag || [];
  const factories = useFactories();
  const favorites = useFavoritesList({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });

  useEffect(() => {
    let userHeaderCell = orderByPhasePOGroupBySOSetup || [];
    let newHeaderCells = buildHeaderCells({
      userHeaderSetup: userHeaderCell,
    });
    let cpyHeaderCell = newHeaderCells.map((header) => {
      const staticHeader = orderByPhasePOGroupBySOSetup.find(
        (h) => h.field === header.field
      );
      if (
        header.type === propertyType.CURRENCY &&
        !isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
      ) {
        return {
          ...header,
          resizable: false,
          styles: {
            ...header.styles,
            width: staticHeader.width,
            display: "none",
          },
        };
      }
      return {
        ...header,
        resizable: false,
        styles: { ...header.styles, width: staticHeader.width },
      };
    });
    setHeaderCells(cpyHeaderCell);
  }, [isAllowed]);

  function filterTablePOSelector({ itemPO, header, index }) {
    const { field, enabled } = header;
    switch (field) {
      case headerColumns.EXPAND:
        return enabled && <TableCell className="emptyTableCell" />;
      case PO_VIEW_FIELDS.FAVORITE:
        return (
          enabled && (
            <TableCell key={index} center classes={{ root: "favorite" }}>
              {getStarFavorite({
                removeFavorite: () =>
                  removeFavorite({ favoritesDB, id: itemPO.id }),
                addFavorite: () =>
                  addFavorite({
                    favoritesDB,
                    id: itemPO.id,
                    user,
                    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
                  }),
                itemId: itemPO.id,
                favorites: favorites,
              })}
            </TableCell>
          )
        );

      case PO_VIEW_FIELDS.DIAMOND:
        return (
          enabled && (
            <TableCell key={index} className="diamond">
              {getDiamondStatus({
                diamondStatus: itemPO.diamondStatus,
                itemStatus: itemPO.status,
              })}
            </TableCell>
          )
        );

      case PO_VIEW_FIELDS.NUMBER:
        const currentVendor = factories.find(
          (factory) => factory.id === itemPO.factoryId
        );
        const showIconVendor = verifyVendorIcon({
          factoryFilter: filters.factory,
          item: itemPO,
          currentVendor,
        });
        const purchaseOrderTags = itemPO.tags || [];
        return (
          enabled && (
            <TableCell key={index} className={"poNumber"}>
              <div className={"number-table-container"}>
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={itemPO.id}
                    isReadOnly={isTradeDashEmployee(user)}
                    label={getTagsLabel(tags, purchaseOrderTags).tagsString}
                    tags={getTagsLabel(tags, purchaseOrderTags).tagsArray}
                    allTags={tags}
                    item={itemPO}
                    currentUser={user}
                    tagReference={dbTables.PURCHASE_ORDERS}
                    onUpdateItem={onUpdateItem}
                    imageStyle={{
                      height: 15,
                      width: 13,
                      position: "relative",
                      top: 1,
                    }}
                    isThereItemRef={false}
                    status={getTagStatus({
                      tags: purchaseOrderTags,
                      currentTags: filterTag,
                    })}
                  />
                )}
                {showIconVendor && (
                  <TooltipTD label={itemPO.factoryName}>
                    <img
                      src={FactoryIcon}
                      alt={"FactoryIcon"}
                      style={{
                        width: 11,
                      }}
                    />
                  </TooltipTD>
                )}

                <NavLink
                  style={{
                    color: colors.primaryDark,
                    cursor: "pointer",
                    marginLeft: 0,
                    textDecoration: "none",
                  }}
                  to={getDashboardSearchPath({
                    salesOrderId: itemPO.salesOrderId,
                    purchaseOrderId: itemPO.id,
                    shipmentId: itemPO.shipmentIds[0],
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                  })}
                  id="list-view-content-purchaseorder"
                >
                  <POBadge
                    number={itemPO.number}
                    className="purchase-order-badge-white"
                  />
                </NavLink>
                <ThreePanelButton
                  soId={itemPO.salesOrderId}
                  poId={itemPO.id}
                  shipmentId={itemPO.shipmentIds[0]}
                  tab={1}
                />
              </div>
            </TableCell>
          )
        );

      case PO_VIEW_FIELDS.VENDOR:
        return (
          enabled && (
            <TableCell key={index} className="factoryName">
              <TooltipTD
                label={itemPO.factoryName}
                style={{ width: "fit-content", display: "flex" }}
              >
                <NavLink
                  to={`/app/factories/${itemPO.factoryId}`}
                  style={{
                    verticalAlign: "-webkit-baseline-middle",
                    color: "#25486D",
                    textDecoration: "underline",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "-webkit-fill-available",
                  }}
                  id="list-view-content-factory"
                >
                  {itemPO.factoryName}
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.BLANK:
        return enabled && <TableCell key={index} className="emptyTableCell" />;

      case headerColumns.PROPOSAL_QUOTE: {
        return (
          enabled && (
            <TableCell key={index} className="proposalTasks">
              <ProgressStage
                tasks={itemPO.proposalTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );
      }

      case headerColumns.PRE_PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="preProductionTasks">
              <ProgressStage
                tasks={itemPO.preProductionTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="productionTasks">
              <ProgressStage
                tasks={itemPO.productionTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.BOOKING_TRANSIT:
        return (
          enabled && (
            <TableCell key={index} className="bookingTransitTasks">
              <ProgressStage
                tasks={itemPO.bookingTransitTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.PAYMENT_BILLING:
        return (
          enabled && (
            <TableCell key={index} className="paymentBillingTasks">
              <ProgressStage
                tasks={itemPO.paymentBillingTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      default:
        return (
          <CustomTableRowSelector
            index={itemPO.id + header.field}
            header={header}
            item={itemPO}
          />
        );
    }
  }
  return (
    <MainTableRefactor
      className={cx("poGroupBySO", { showTable: expanded })}
      headCells={headerCells.map((cell) => {
        if (cell.label === headerColumns.PROPOSAL_QUOTE) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.proposalQuoteMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRE_PRODUCTION) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.preProductionMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRODUCTION) {
          return {
            ...cell,
            styles: { ...cell.styles, width: headerStages.productionMinWidh },
          };
        } else if (cell.label === headerColumns.BOOKING_TRANSIT) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.bookingTransitMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PAYMENT_BILLING) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.paymentBillingMinWidh,
            },
          };
        } else {
          return cell;
        }
      })}
      stylesTable={{
        boxShadow: "2px 2px 14px 0px rgba(0, 0, 0, 0.25)",
        zIndex: 1,
      }}
      backgroundHeaderColor={colors.purchaseOrderBackgroundColor}
      bodyRows={purchaseOrderList}
      filters={filtersTable}
      onUpdateFilters={onUpdateFilters}
      footer={
        <FooterTable
          footer={footerOrdersByPhasePOsWithinSO({
            headers: headerCells,
            list: purchaseOrderList,
            isAllowed,
          })}
          headerCells={headerCells}
        />
      }
      renderRow={(itemPO) => (
        <MainRowStyled
          className={cx(mainRowClasses.SubTableRow, mainRowClasses.hoverEffect)}
          key={
            itemPO.purchaseOrderId
              ? itemPO.id + itemPO.purchaseOrderId
              : itemPO.id + itemPO.salesOrderId
          }
        >
          {headerCells.map((header) => {
            if (!!header.type) {
              return (
                <CustomTableRowSelector
                  index={itemPO.id + header.field}
                  header={header}
                  item={itemPO}
                />
              );
            } else {
              return filterTablePOSelector({
                header,
                index: itemPO.id + header.label,
                itemPO,
              });
            }
          })}

          <TableCell className="LAST_100_WIDTH_COLUMN" />
          <TableCell className="LAST_100_WIDTH_COLUMN" />
        </MainRowStyled>
      )}
      key="po-table"
      sortArrowsColor="#FFF"
      displayLastBlankColumn={false}
    />
  );
}

export default FilterTablePO;
