import React, { useState } from "react";
import { taskStatus } from "../../helpers/constants";
import { InfoPopoverStyled, TaskStatusStyled } from "./styles";
import {
  getReminderDays,
  getTaskDiamond,
} from "../../helpers/timelineCalendar";
import { getUserAvatar } from "../../helpers/users";
import { getCompanyUserDisplayName } from "../../helpers/helpers";
import moment from "moment";
import { Popover } from "@mui/material";
import { cx } from "@emotion/css";
import { getBackgroundColorByType } from "../../components/PurchaseOrderDashboard/dashboardHelpers";

const textStatus = {
  [taskStatus.NOT_STARTED]: "Not Started",
  [taskStatus.IN_PROGRESS]: "In Progress",
  [taskStatus.NEAR_DUE]: "Imminently due",
  [taskStatus.LATE]: "Late",
  [taskStatus.COMPLETE]: "Completed",
};

function TaskPopoverInfo({ task, companyUsers = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <TaskStatusStyled>
      <div
        key={task.id}
        className={cx("stageRoot", {
          notStarted: task.status === taskStatus.NOT_STARTED,
          inProgress: task.status === taskStatus.IN_PROGRESS,
          nearDue: task.status === taskStatus.NEAR_DUE,
          late: task.status === taskStatus.LATE,
          complete: task.status === taskStatus.COMPLETE,
        })}
        onMouseEnter={(ev) => setAnchorEl(ev.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        {task.complete && <div className="checkTask" />}
      </div>
      {open && (
        <Popover
          marginThreshold={150}
          id="mouse-over-popover"
          hideBackdrop
          open={open}
          classes={{
            paper: "popoverTaskPaper",
          }}
          slotProps={{
            root: {
              style: { pointerEvents: "none" },
              onClick: (ev) => {
                ev.stopPropagation();
              },
            },
            paper: {
              style: {
                background: getBackgroundColorByType({ type: task.type }),
                borderRadius: 5,
                transform: "translate(0px, -20px)",
                pointerEvents: "none",
              },
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <InfoPopoverStyled className="rootContainerInfo">
            <div className="title">
              {getTaskDiamond({
                status: task.status,
                styles: { height: 15, width: 13, margin: "0px 4px" },
              })}
              <span className="textBold">{task.description}</span>
            </div>
            <div className="generalInfo">
              <span className="taskInfo">Status:</span>
              <span className="taskDescriptionNormal">
                {textStatus[task.status]}
              </span>
            </div>

            <div className="generalInfo">
              <span className="taskInfo">
                {moment(task.startDate).isBefore(moment())
                  ? "Started:"
                  : "Starts:"}
              </span>
              <span className="taskDescriptionNormal">
                {moment(task.startDate).format("M/D/YY")}
                {getReminderDays({ date: task.startDate, isEndDate: false })}
              </span>
            </div>

            <div className="generalInfo">
              <span className="taskInfo">
                {moment(task.finishDate).isBefore(moment())
                  ? "Ended:"
                  : "Ends:"}
              </span>
              <span className="taskDescriptionNormal">
                {moment(task.finishDate).format("M/D/YY")}
                {getReminderDays({
                  date: task.finishDate,
                  isEndDate: task.status !== taskStatus.COMPLETE,
                })}
              </span>
            </div>
            <div className="generalInfo">
              <span className="taskInfo">Duration:</span>
              <span className="taskDescriptionNormal">
                {task.duration} {task.duration === 1 ? "day" : "days"}
              </span>
            </div>
            <div className="generalInfo">
              <span className="taskInfo">Owner:</span>
              <div className="taskDescriptionNormal" style={{ height: 28 }}>
                {getUserAvatar({
                  user: companyUsers.find(
                    (user) => user.id === task.assignedTo
                  ),
                  styles: {
                    width: 21,
                    height: 21,
                    marginRight: 3,
                    fontSize: 11,
                    outline: "1px solid #000",
                    marginLeft: 2,
                  },
                })}
                {getCompanyUserDisplayName(companyUsers, task.assignedTo)}
              </div>
            </div>
          </InfoPopoverStyled>
        </Popover>
      )}
    </TaskStatusStyled>
  );
}

export default TaskPopoverInfo;
