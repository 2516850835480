import React from "react";
import { SelectButtonStyled } from "../Selects/styles";
import {
  DuplicateIcon,
  PlusIcon,
  TemplateParentIcon,
} from "../../helpers/iconSvgPath";
import { getCompanyUserDisplayName } from "../../helpers/helpers";
import { getUserAvatar } from "../../helpers/users";
import moment from "moment";
import SelectButton from "../Selects/SelectButton";
import TooltipTD from "../Tooltip/TooltipTD";
import { TemplateMetadataContainerStyled } from "./styles";
import SelectButtonIcon from "../Selects/SelectButtonIcon";
import TemplateChangeLog from "./TemplateChangeLog";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId } from "../../hooks/user";
import { formatToTwoDigits, getGroupedData } from "./soTemplateHelper";
import { TASK_TYPE } from "../../helpers/constants";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function TemplateMetadata({
  templates = [],
  onChangeTemplate = () => {},
  onChangeSalesOrderTemplate = () => {},
  buttonSetup = {},
  currentTemplateVersion = {},
  currentTemplate = {},
  salesOrderTemplate = {},
  users = [],
  onCreateTemplate = () => {},
  onDuplicateTemplate = () => {},
  onEdit,
  hasChanges,
  salesOrderTemplates = [],
  showSOTemplate = false,
  warningIcon,
  scope = dbTables.SALES_ORDER_TEMPLATES,
  tasks = [],
}) {
  const companyId = useCompanyId();
  
  const errorInClone = () => {
    if (!currentTemplate.id) {
      return "Please select a template";
    }
    if (!currentTemplate.salesOrderTemplateId && showSOTemplate) {
      return "Blind a SO template to this PO Template";
    }
    if (hasChanges) {
      return "Save first before duplicating the template";
    }
    if (
      scope === dbTables.PURCHASE_ORDER_TEMPLATES &&
      tasks.filter((task) => task.type !== TASK_TYPE.SALES_ORDER).length === 0
    ) {
      return "Cannot duplicate template without any Purchase Order tasks";
    }
  };

  return (
    <TemplateMetadataContainerStyled className="template-metadata-container">
      <div className="data-side-container">
        <div className="template-selector-content">
          <div className="template-selector">
            <SelectButton
              data={templates.sort(sortObjectsBy("name")).map((el) => ({
                value: el.id,
                label: el.name,
                emoji: el.emoji,
                description: el.description,
                isOutdated: el.isOutdated,
              }))}
              groupedData={
                scope !== dbTables.SALES_ORDER_TEMPLATES &&
                getGroupedData(salesOrderTemplates, templates)
              }
              salesOrderTemplates={salesOrderTemplates}
              buttonSetup={buttonSetup}
              onChange={onChangeTemplate}
              onEdit={onEdit}
              currentTemplate={currentTemplate}
              warningIcon={warningIcon}
              selectStyle={{ minWidth: 190, maxWidth: 260, width: "auto" }}
              textFieldHeader={
                scope === dbTables.SALES_ORDER_TEMPLATES
                  ? "Sales Order Template"
                  : "PO Template"
              }
            />
            {showSOTemplate && (
              <div className="sales-order-template">
                <TemplateParentIcon />
                <SelectButtonIcon
                  data={salesOrderTemplates
                    .sort(sortObjectsBy("name"))
                    .map((el) => ({
                      value: el.id,
                      label: el.name,
                      emoji: el.emoji,
                      description: el.description,
                      isOutdated: el.isOutdated,
                    }))}
                  onChange={onChangeSalesOrderTemplate}
                  currentTemplate={salesOrderTemplate}
                />
              </div>
            )}
          </div>

          <div className="action-buttons-side-container">
            <SelectButtonStyled
              onClick={onCreateTemplate}
              className={"selectButtonContainer"}
              color="inherit"
              variant="contained"
              id="create-template-button"
            >
              <PlusIcon />
              <span>New Template</span>
            </SelectButtonStyled>
            <TooltipTD showToolTip={errorInClone()} label={errorInClone()}>
              <SelectButtonStyled
                id="duplicate-template-button"
                className={"selectButtonContainer"}
                color="inherit"
                variant="contained"
                onClick={onDuplicateTemplate}
                disabled={errorInClone()}
              >
                <DuplicateIcon />
                <span>Duplicate Template</span>
              </SelectButtonStyled>
            </TooltipTD>
            {currentTemplate.id && (
              <TemplateChangeLog
                id="template-change-log-button"
                changeLogsPath={`${dbTables.COMPANIES}/${companyId}/${scope}/${currentTemplate.id}/${dbTables.TEMPLATE_CHANGE_LOG}`}
                scope={scope}
                currentTemplate={currentTemplate}
              />
            )}
          </div>
        </div>
        {currentTemplate.id && (
          <>
            <div className="metadata-content">
              <span className="label">
                <b>Template version: </b>
                {currentTemplateVersion.version}.
                {formatToTwoDigits(currentTemplateVersion.subVersion, 1)}
              </span>
              <span className="label">
                <b>Created on: </b>
                {moment(currentTemplateVersion.creationDate).format(
                  "M/D/YY hh:mma"
                )}
              </span>
              <div className="created-by-container">
                <b className="label">Created by: </b>
                {getUserAvatar({
                  user: users.find(
                    (user) => user.id === currentTemplateVersion.createdBy
                  ),
                  styles: {
                    width: 24,
                    height: 24,
                    fontSize: 12,
                    outline: "1px solid #000",
                  },
                  className: "avatar-displayed",
                })}
                <span>
                  {getCompanyUserDisplayName(
                    users,
                    currentTemplateVersion.createdBy
                  )}
                </span>
              </div>
              <div className="version-notes-container">
                <b className="label">Version Notes: </b>
                <TooltipTD
                  label={currentTemplateVersion.description}
                  className="description-tooltip"
                >
                  <span className="description-span">
                    {currentTemplateVersion.description}
                  </span>
                </TooltipTD>
              </div>
            </div>

            <div className="metadata-content">
              <div className="version-notes-container">
                <b className="label">Template description: </b>
                <TooltipTD
                  label={currentTemplate.description}
                  className="description-tooltip"
                >
                  <span className="description-span">
                    {currentTemplate.description}
                  </span>
                </TooltipTD>
              </div>
            </div>
          </>
        )}
      </div>
    </TemplateMetadataContainerStyled>
  );
}

export default TemplateMetadata;
