import React, { useEffect, useState } from "react";
import { TradeDashNumericTextField } from "../PurchaseOrderDashboard/sections/styles";
import TooltipTD from "../Tooltip/TooltipTD";
import { TextField } from "@mui/material";
import { cx } from "@emotion/css";
import { InfoIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";

function TextFieldNumericMetadata({
  item = {},
  value,
  isDisabled = true,
  onChange,
  scope = "",
  startAdornment,
  endAdornment,
  decimalScale = 2,
}) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);
  return (
    <TradeDashNumericTextField
      id={item.field}
      key={item.field + scope}
      label={
        <div className="custom-textfield-label-container">
          <label>{item.label}</label>
          {item.description && (
            <TooltipTD label={item.description}>
              <InfoIcon
                width={20}
                height={20}
                fillColor={colors.darkGray}
                className="info-icon"
              />
            </TooltipTD>
          )}
        </div>
      }
      variant={"filled"}
      classes={{
        root: cx(
          "tradedash-metadata-custom-textfield-root",
          isDisabled
            ? "custom-textfield-root-disabled-" + scope
            : "custom-textfield-root-enabled-" + scope
        ),
      }}
      InputLabelProps={{
        classes: { filled: "custom-textfield-input-filled" },
        shrink: true,
      }}
      InputProps={{
        startAdornment,
        endAdornment,
        classes: {
          root: "custom-textfield-input-root",
          disabled: "custom-textfield-input-disabled",
          input: "custom-textfield-input",
        },
        id: item.id,
      }}
      size="small"
      fullWidth
      customInput={TextField}
      decimalScale={decimalScale}
      fixedDecimalScale
      allowNegative={false}
      thousandSeparator=","
      value={currentValue}
      disabled={isDisabled}
      onBlur={onChange}
      onChange={(e) => setCurrentValue(e.target.value)}
    />
  );
}

export default TextFieldNumericMetadata;
