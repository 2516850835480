import React from "react";
import TaskListScreen from "../../container/TaskListScreen/TaskListScreen";
import ReportScreen from "../../container/ReportScreen/ReportScreen";
import SalesOrdersScreen from "../../container/SalesOrdersScreen/SalesOrdersScreen";
import PurchaseOrderScreen from "../../container/PurchaseOrdersScreen/PurchaseOrderScreen";
import ShipmentsScreen from "../../container/Shipments/ShipmentsScreen";
import OrdersByPhase from "../../container/OrdersByPhase/OrdersByPhase";
import ExpensesScreen from "../../container/ExpensesScreen/ExpensesScreen";
import { screenPath } from "../../helpers/constants";
import InboxScreen from "../../container/InboxSalesOrder/InboxScreen";

export const ROUTES = [
  {
    menu_title: "Reports",
    path: `${screenPath.REPORT}/*`,
    element: <ReportScreen />,
  },
  {
    menu_title: "New Sales Order Inbox",
    path: screenPath.INBOX,
    element: <InboxScreen />,
  },
  {
    menu_title: "Sales Orders",
    path: screenPath.SO,
    element: <SalesOrdersScreen />,
  },
  {
    menu_title: "Purchase Orders",
    path: screenPath.PO,
    element: <PurchaseOrderScreen />,
  },
  {
    menu_title: "Tasks",
    path: screenPath.TASK,
    element: <TaskListScreen />,
  },
  {
    menu_title: "Shipments",
    path: screenPath.SHIPMENT,
    element: <ShipmentsScreen />,
  },
  {
    menu_title: "Orders by Phase",
    path: screenPath.ORDER_PHASE,
    element: <OrdersByPhase />,
  },

  {
    menu_title: "Expenses",
    path: screenPath.EXPENSE,
    element: <ExpensesScreen />,
  },
];
