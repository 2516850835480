import {  activityType, COPIED_DOCUMENT_TYPE, documentScope, DOWNLOAD_DOCUMENT_TYPE, GENERAL_PERMISSION_VALUE, TYPE_OF_FILE } from "../../helpers/constants";
import moment, { now } from "moment/moment";
import {
  convertToTreeRefactored,
  getFileName,
  getRandomId,
  getselectedUsersObj,
  getTypeFile,
  replaceFileExtension,
} from "../../helpers/helpers";
import { Alert } from "@mui/material";
import userTypes from "../../api/types/userTypes";
import { getCurrentPermission } from "../Notes/ComponentHelper";
import { dbTables } from "../../api/types/dbTables";
import FolderDocument from "../../api/model/FolderDocument";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import { getAddedDeleteElement, onDisolveGroups } from "../ReportsTree/helpers";
import { firestore } from "../../firebase";
import { updateCompanyUsersUnreadActivity } from "../../helpers/activitiesStream";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import Activity from "../../api/model/activity";
import { doc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { Fragment } from "react";

export const generateUniqueFilename = ({
  currentName,
  files,
  type,
  currentPath = "",
}) => {
  const splitName = currentName.split(".");
  let version = 0;
  const currentDocuments = filterFilesByType({
    type,
    currentDocuments: files,
  });
  const pathList = currentPath.replace(/^(\.\/|\/)+/, "").split("/");

  currentDocuments.forEach((currentDocument) => {
    let nameDocument = currentDocument.name || currentDocument.originalName;
    nameDocument = nameDocument.split(".")[0];
    if (
      nameDocument.toLowerCase() === splitName[0].toLowerCase() &&
      pathList.length <= 1
    ) {
      version = version + 1;
    }
    if (version > 0) {
      const temporalName = `${splitName[0]}(${version}).${splitName[1]}`;
      const number = temporalName === splitName[0] ? 1 : 0;
      version = version + number;
    }
  });
  if (version > 0) {
    currentName = `${splitName[0]}(${version}).${splitName[1]}`;
  }
  return currentName;
};

export function getUserAndGroupPermissionsObj(permissions = {}) {
  let permissionUsersObj = {};
  let permissionGroupsObj = {};
  const { permissionUsers = [], permissionGroups = [] } = permissions;
  permissionUsers.forEach((user) => (permissionUsersObj[user.id] = true));
  permissionGroups.forEach((group) => {
    if (!!group.checked) {
      permissionGroupsObj[group.id] = true;
    }
  });
  return {
    permissionUsers: permissionUsersObj,
    permissionGroups: permissionGroupsObj,
  };
}

export const hasExtension = (fileName) => /\.[^/.]+$/.test(fileName);

export const getDocumentId = (currentDocument) => {
  const idVersion =
    currentDocument.type !== TYPE_OF_FILE.FOLDER
      ? `_V${currentDocument.version || 1}`
      : "";

  const idPrefix = currentDocument.id;

  return `${idPrefix}${idVersion}`;
};

export const replaceDocumentState = ({ oldData, fileData, extraData = {} }) =>
  oldData.map((file) => {
    if (file.name === fileData.name) {
      return {
        ...fileData,
        ...extraData,
        completed: true,
      };
    } else {
      return { ...file, ...extraData };
    }
  });

export const getFolderParentId = ({ file, folders, currentPath = "" }) => {
  const path = file.path?.split("/");
  if (folders.length === 0 || path.length <= 1) {
    return "";
  }
  const parentPath = path.slice(1, -1).join("/");
  const folderParent =
    folders.find(
      (folder) => `${currentPath}${parentPath}` === folder.currentPath
    ) || {};
  return folderParent.id || "";
};

export function processFiles({
  filesUpload = [],
  currentFiles = [],
  documentPath = "",
  uploadingInFile = false,
  currentDocument = {},
  foldersData = [],
}) {
  const filesData = [];
  const originalFiles = [];
  for (let i = 0; i < filesUpload.length; i++) {
    const currentFile = filesUpload[i];
    const { size } = currentFile;
    const type = getTypeFile(currentFile.name);
    const currentName = uploadingInFile
      ? replaceFileExtension(currentDocument.name, type)
      : generateUniqueFilename({
          currentName: currentFile.name,
          files: currentFiles,
          type: TYPE_OF_FILE.FILE,
          currentPath: currentFile.path,
        });
    const currentVersion =
      currentDocument.version && !currentDocument.placeholder
        ? currentDocument.version + 1
        : 1;
    let documentId = uploadingInFile ? currentDocument.id : getRandomId();
    const path = `${documentPath}/${documentId}/version/${currentName}_V${currentVersion}`;
    originalFiles.push(currentFile);
    filesData.push({
      name: currentName,
      documentId,
      version: currentVersion,
      type,
      lastModifiedDate: now(),
      size: size / 1000,
      path,
      originalName: currentFile.name,
      parentId: getFolderParentId({
        file: currentFile,
        folders: foldersData,
        currentPath: currentDocument.currentPath
          ? `${currentDocument.currentPath}/`
          : "",
      }),
    });
  }
  return { filesData, originalFiles };
}

export function handleSelectNonePermissionGroups({
  permissions = {},
  currentUser = {},
  handlePermission = () => {},
}) {
  const { permissionGroups = [] } = permissions;
  let permissionGroupsCpy = [...permissionGroups];
  let permissionUsersCpy = [currentUser];
  permissionGroupsCpy = permissionGroupsCpy.map((permissionGroup) => ({
    ...permissionGroup,
    checked: false,
  }));

  handlePermission({
    permissionGroups: permissionGroupsCpy,
    permissionUsers: permissionUsersCpy,
  });
}

export function handleSelectAllPermissionGroups({
  permissions,
  handlePermission,
}) {
  let permissionGroupsCpy = [...permissions.permissionGroups];
  permissionGroupsCpy = permissionGroupsCpy.map((permissionGroup) => ({
    ...permissionGroup,
    checked: permissionGroup.enable,
  }));
  handlePermission({
    ...permissions,
    permissionGroups: permissionGroupsCpy,
  });
}

export function handleChangeGroups({
  permission,
  checked,
  permissions,
  handlePermission = () => {},
  currentUser,
  companyUsers,
}) {
  const { permissionGroups = [], permissionUsers = [] } = permissions;
  let permissionGroupsCpy = [...permissionGroups];
  permissionGroupsCpy = permissionGroupsCpy.map((elem) => {
    if (elem.id === permission.id) {
      if (checked) {
        let usersCpy = { ...elem.users };
        Object.keys(usersCpy).map((key) => (usersCpy[key] = true));
        return { ...elem, checked, users: usersCpy };
      } else {
        return { ...elem, checked };
      }
    } else {
      return elem;
    }
  });
  if (checked) {
    let permissionUsersCpy = [...permissionUsers];
    Object.keys(permission.users).forEach((key) => {
      permissionUsersCpy = permissionUsersCpy.filter((user) => user.id !== key);
    });
    handlePermission({
      permissionGroups: permissionGroupsCpy,
      permissionUsers: permissionUsersCpy,
    });
  } else {
    if (permission.users[currentUser]) {
      const permissionUsersCpy = [...permissions.permissionUsers];
      if (!permissionUsersCpy.find((user) => user.id === currentUser)) {
        permissionUsersCpy.push(
          companyUsers.find((user) => user.id === currentUser)
        );
        handlePermission({
          permissionGroups: permissionGroupsCpy,
          permissionUsers: permissionUsersCpy,
        });
      } else {
        handlePermission({
          ...permissions,
          permissionGroups: permissionGroupsCpy,
        });
      }
    } else {
      handlePermission({
        ...permissions,
        permissionGroups: permissionGroupsCpy,
      });
    }
  }
}

export function handleChangePermission({
  value,
  lastValue,
  permissions,
  handlePermission,
  companyUsers,
}) {
  const { deletedItem, addedItem } = getAddedDeleteElement({
    value,
    lastValue,
  });
  const { permissionGroups, permissionUsers } = permissions;
  let permissionUsersCpy = [...permissionUsers];
  if (value.length > lastValue.length) {
    permissionUsersCpy.push(addedItem);
    handlePermission({ ...permissions, permissionUsers: permissionUsersCpy });
  } else {
    const permissionGroupCpy = [...permissionGroups];
    const checkedPermission =
      permissionGroupCpy.filter((group) => group.checked).length <= 0;
    permissionUsersCpy = permissionUsersCpy.filter(
      (cUser) => cUser.id !== deletedItem.id
    );
    if (checkedPermission) {
      handlePermission({ ...permissions, permissionUsers: permissionUsersCpy });
    } else {
      const { newPermissionGroups, newPermissionUsers } = onDisolveGroups({
        deletedItem,
        permissionGroups: permissionGroupCpy,
        permissionUsers: permissionUsersCpy,
        companyUsers,
      });
      handlePermission({
        permissionGroups: newPermissionGroups,
        permissionUsers: newPermissionUsers,
      });
    }
  }
}

export function getUserSelected({ permissions, companyUsers }) {
  const userSelected = [...permissions.permissionUsers];
  permissions.permissionGroups.forEach((group) => {
    if (group.checked) {
      Object.keys(group.users).forEach((userId) => {
        const user = companyUsers.find((user) => user.id === userId);
        if (user && !userSelected.find((el) => el.id === userId)) {
          userSelected.push(user);
        }
      });
    }
  });
  return userSelected;
}

export function processFolder({
  filesUpload = [],
  currentFiles = [],
  uploadingInFile = false,
  currentDocument = {},
  currentUser = {},
}) {
  if (uploadingInFile) {
    return [];
  }
  const folderMap = new Map();
  const cpyFiles = filesUpload.sort(
    (a, b) => a.path.split("/").length - b.path.split("/").length
  );
  for (let i = 0; i < cpyFiles.length; i++) {
    let { path = "" } = cpyFiles[i];
    path = path.replace(/^(\.\/|\/)+/, "");
    if (path) {
      const splitPath = path.split("/").filter(Boolean);
      for (let j = 0; j < splitPath.length - 1; j++) {
        const folderName = splitPath[j];
        const folderPath = splitPath.slice(0, j + 1).join("/");
        const parentPath = splitPath.slice(0, j).join("/");
        if (!folderMap.has(folderPath)) {
          const isRoot = folderName === folderPath;
          const parentFolder = folderMap.get(parentPath);
          folderMap.set(folderPath, {
            ...new FolderDocument({
              name: isRoot
                ? verifyFolderDuplicateNames(
                    folderName.trim() || "New Folder",
                    currentFiles
                  )
                : folderName,
              parentId: !isRoot ? parentFolder.id : currentDocument.id || "",
              createdBy: currentUser.id,
              created: now(),
              mainFile: true,
              currentPath: `${
                currentDocument.currentPath
                  ? currentDocument.currentPath + "/" + folderPath
                  : folderPath
              }`,
              triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
            }),
            originalName: folderName,
          });
        }
      }
    }
  }

  const foldersArray = Array.from(folderMap.values());
  return foldersArray;
}

export const getTittleUploadModal = ({
  files,
  folders,
  isRoot,
  currentDocument,
}) => {
  if (isRoot) {
    if (folders.length === 0) {
      const fileMessage = files.length > 1 ? "these files" : "this file";
      return `All users will be able to see ${fileMessage}`;
    } else {
      const foldersRoot = folders.filter(
        (folder) => folder.currentPath.split("/").length <= 1
      );
      const filesRoot = files.filter(
        (file) => file.path.split("/").length <= 1
      );
      if (filesRoot.length === 0) {
        return foldersRoot.length === 1
          ? "All users will be able to see this folder"
          : "All users will be able to see these folders";
      }
      return "All users will be able to see these files and folders";
    }
  } else {
    if (folders.length === 0) {
      const fileLabel = files.length > 1 ? "These files " : "This file ";
      return (
        <>
          {fileLabel}
          will have the permissions of <em>{currentDocument.name}</em>
        </>
      );
    } else {
      const filesLabel = files.length > 1 ? "items" : "item";

      return (
        <>
          {`All ${filesLabel} will have the permissions of `}
          <em>{currentDocument.name}</em>
        </>
      );
    }
  }
};

export const getWarningMessage = (snackOption) => {
  if (!snackOption.message) {
    return "";
  }
  return (
    <Alert
      style={{
        width: "100%",
        marginBottom: 18,
        border: "2px solid #d5b54f",
      }}
      severity={snackOption.type}
    >
      {snackOption.message}
    </Alert>
  );
};

export const getParentRoot = ({
  currentDocument = {},
  documents,
  visited = new Set(),
}) => {
  if (!currentDocument.parentId || visited.has(currentDocument.id)) {
    return currentDocument;
  }
  visited.add(currentDocument.id);
  const parentId = currentDocument.parentId;
  let rootDocument = documents.find((doc) => doc.id === parentId);

  if (rootDocument && rootDocument.parentId !== "") {
    rootDocument = getParentRoot({
      currentDocument: rootDocument,
      documents,
      visited,
    });
  }
  return rootDocument;
};

export const checkFolderContents = ({
  currentDocument,
  documents,
  visited = new Set(),
  isRoot = false,
}) => {
  const folder = currentDocument;
  if (
    !folder ||
    !folder.id ||
    folder.type !== TYPE_OF_FILE.FOLDER ||
    (!folder.placeholder && isRoot) ||
    visited.has(folder.id)
  ) {
    return {
      hasPlaceholderFile: false,
      childFoldersPending: [],
      childFoldersWithPlaceholder: [],
    };
  }
  visited.add(folder.id);
  let hasPlaceholderFile = false;
  let childFoldersPending = [];

  const currentChildren = documents.filter((doc) => doc.parentId === folder.id);
  hasPlaceholderFile = currentChildren.some(
    (childDocument) =>
      childDocument.placeholder && childDocument.type !== TYPE_OF_FILE.FOLDER
  );

  if (!hasPlaceholderFile && folder.placeholder) {
    childFoldersPending.push(folder);
  }
  const currentFolders = currentChildren.filter(
    (doc) => doc.type === TYPE_OF_FILE.FOLDER
  );
  if (currentFolders.length > 0) {
    currentFolders.forEach((childDocument) => {
      const childFolderInfo = checkFolderContents({
        currentDocument: childDocument,
        documents,
        visited,
      });
      hasPlaceholderFile =
        hasPlaceholderFile || childFolderInfo.hasPlaceholderFile;
      childFoldersPending = [
        ...childFoldersPending,
        ...childFolderInfo.childFoldersPending,
      ];
    });
  }
  return {
    hasPlaceholderFile,
    childFoldersPending,
  };
};

export function isAuthorizedUser({
  user,
  currentDocument,
  permissionGroups,
  currentDocuments = [],
  canSeeAll,
}) {
  const { role, id } = user;
  const { parentId, type } = currentDocument;
  if (currentDocument.placeholder) {
    return false;
  }
  const parentDoc =
    currentDocuments.find((doc) => doc.id === currentDocument.parentId) || {};
  const { permissionGroupDoc, permissionUserDoc } = getCurrentPermission({
    childDoc: currentDocument,
    parentDoc,
  });
  const selectedUsersObj = getselectedUsersObj(
    permissionUserDoc,
    permissionGroupDoc,
    permissionGroups
  );

  const isAdmin =
    role === userTypes.SUPER_ADMIN || role === userTypes.TRADEDASH_EMPLOYEE;
  const isTopLevelDocument = !parentId && type !== TYPE_OF_FILE.FOLDER;

  return (
    canSeeAll ||
    isAdmin ||
    isTopLevelDocument ||
    (selectedUsersObj && selectedUsersObj[id] === true)
  );
}

export function getDocumentLength({
  currentDocuments = [],
  user,
  permissionGroupsDB = [],
  canSeeAll,
}) {
  return currentDocuments.filter((currentDocument) =>
    isAuthorizedUser({
      user,
      currentDocument,
      permissionGroups: permissionGroupsDB,
      currentDocuments,
      canSeeAll,
    })
  ).length;
}

export const documentScopesObj = {
  [documentScope.SALES_ORDER]: documentScope.SALES_ORDER,
  [documentScope.QUOTE]: documentScope.QUOTE,
  [documentScope.PURCHASE_ORDER]: documentScope.PURCHASE_ORDER,
  [documentScope.PURCHASE_QUOTE]: documentScope.PURCHASE_QUOTE,
  [dbTables.SHIPMENTS]: documentScope.SHIPMENT,
  [dbTables.CUSTOMERS]: documentScope.CUSTOMER,
  [dbTables.FACTORIES]: documentScope.FACTORY,
};

export function verifyFolderDuplicateNames(folderName, documents = []) {
  let name = folderName;
  let auxName = name;
  let countRepeatName = 0;
  let found = true;
  const findFolderName = (folder) =>
    folder.name.toLowerCase() === name.toLowerCase();
  do {
    found = documents.find(findFolderName);
    if (found) {
      countRepeatName++;
      name = auxName;
      name = `${folderName}(${countRepeatName})`;
    }
  } while (found);
  return name;
}


export function createActivityCopyLinkV2({
  companyId,
  parentCollection,
  parentDocumentId,
  currentUser,
  currentDocument = {},
  activityId = getRandomId(),
  setDoc = () => {},
  doc = () => {},
  companyUsers = [],
  mainEntity = {},
}) {
  if (!currentDocument.id || !parentCollection || !parentDocumentId) {
    return;
  }
  let activity = {
    id: activityId,
  };
  activity.companyId = companyId;
  activity.creationDate = now();
  activity.type =
    currentDocument.type === TYPE_OF_FILE.FOLDER
      ? COPIED_DOCUMENT_TYPE.FOLDER
      : COPIED_DOCUMENT_TYPE.FILE;
  activity.detail = `A link to the current version of <strong>${currentDocument.currentPath}</strong> was copied to the clipboard`;
  activity.user = currentUser.id || "";
  activity.scope = currentDocument.scope;
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export function createActivityDownloadv2({
  companyId,
  parentDocumentId,
  parentCollection,
  userId,
  documents = [],
  isLandingPage = false,
  scope = "",
  currentPath = "top level",
  doc = () => {},
  setDoc = () => {},
  activityId = getRandomId(),
  companyUsers = [],
  mainEntity = {},
}) {
  if (documents.length === 0 || !parentCollection || !parentDocumentId) {
    return;
  }
  let activity = {
    id: activityId,
  };
  activity.companyId = companyId;
  activity.creationDate = now();
  let type = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (isLandingPage) {
    if (hasFolder) {
      type = DOWNLOAD_DOCUMENT_TYPE.FOLDER_LANDING;
    } else {
      type = DOWNLOAD_DOCUMENT_TYPE.FILE_LANDING;
    }
  } else if (hasFolder && hasFile) {
    type = DOWNLOAD_DOCUMENT_TYPE.FILES_AND_FOLDER;
  } else if (hasFolder) {
    if (documents.length === 1) {
      type = DOWNLOAD_DOCUMENT_TYPE.ONLY_FOLDER;
    } else {
      type = DOWNLOAD_DOCUMENT_TYPE.MULTI_FOLDER;
    }
  } else if (hasFile) {
    if (documents.length === 1) {
      type = DOWNLOAD_DOCUMENT_TYPE.ONLY_FILE;
    } else {
      type = DOWNLOAD_DOCUMENT_TYPE.MULTI_FILE;
    }
  }
  activity.type = type;
  let detail = `<strong>`;
  documents
    .sort(sortObjectsBy("currentPath", false))
    .forEach((document, index) => {
      detail += `<li> <strong>${index + 1}. ${
        document.type === TYPE_OF_FILE.FOLDER ? "📂 " : "📄 "
      } ${document.name}</strong></li>`;
    });
  detail += `</strong><br/> From <strong> ${currentPath} </strong>`;
  activity.detail = detail;
  activity.user = userId || "";
  activity.scope = scope;

  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export const getGroupAndUserPermissionsText = ({
  file={},
  companyUsersDB,
  permissionGroupsDB,
}) => {
  let textUserGroupInformation = "";
  const {permissionUsers={}, permissionGroups={}} =file 
  if (
    Object.keys(permissionUsers).length === 0 &&
    Object.keys(permissionGroups).length === 0
  ) {
    textUserGroupInformation += `any permission`;
  } else if (
    Object.keys(permissionUsers).length === companyUsersDB.length
  ) {
    return (textUserGroupInformation = `<br/> <strong>All —</strong> @${companyUsersDB
      .sort(sortObjectsBy("displayName", false))
      .map((user) => user.displayName)
      .join(", @")}
    `);
  } else if (Object.keys(permissionGroups).length === 0) {
    textUserGroupInformation += ` no Access Groups, only `;
  } else if (Object.keys(permissionGroups).length > 0) {
    textUserGroupInformation += `${
      Object.keys(permissionGroups).length === 1
        ? "current Access Group: <br/>"
        : "current Access Groups: <br/>"
    } `;
    const permissionGroupsArr = [];
    Object.keys(permissionGroups).forEach((groupId) => {
      const permissionGroupDB = permissionGroupsDB.find(
        (group) => group.id === groupId
      );
      if (permissionGroupDB) {
        permissionGroupsArr.push(permissionGroupDB);
      }
    });
    
    permissionGroupsArr
      .sort(sortObjectsBy("name", false))
      .forEach((permissionGroupDB, index) => {
        const permissionUsers = [];
        Object.keys(permissionGroupDB.users).forEach((userId) => {
          const userDB = companyUsersDB.find((user) => user.id === userId);
          if (userDB) {
            permissionUsers.push(userDB);
          }
        });
        textUserGroupInformation += `<strong>${
          permissionGroupDB.name
        } —</strong> @${permissionUsers
          .sort(sortObjectsBy("displayName", false))
          .map((user) => user.displayName)
          .join(", @")}${
          index < Object.keys(permissionGroups).length - 1 ? "<br/>" : ""
        }`;
      });
  }
  if (Object.keys(permissionUsers).length > 0) {
    if (Object.keys(permissionGroups).length > 0) {
      textUserGroupInformation += `${
        Object.keys(permissionUsers).length === 1
          ? Object.keys(permissionGroups).length > 0
            ? "<br/><strong>and user: </strong>"
            : "<br/><strong>user: </strong>"
          : Object.keys(permissionGroups).length > 0
          ? "<br/><strong>and users: </strong>"
          : "<br/><strong>users: </strong>"
      }`;
    }

    const users = [];
    Object.keys(permissionUsers).forEach((userId) => {
      const userDB = companyUsersDB.find((user) => user.id === userId);
      if (userDB) {
        users.push(userDB);
      }
    });
    if (Object.keys(permissionGroups).length === 0) {
      if (Object.keys(permissionUsers).length > 0) {
        textUserGroupInformation += `<strong>@${users
          .sort(sortObjectsBy("displayName", false))
          .map((user) => user.displayName)
          .join(", @")}</strong>`;
      }
    } else {
      if (Object.keys(permissionUsers).length > 0) {
        textUserGroupInformation += `@${users
          .sort(sortObjectsBy("displayName", false))
          .map((user) => user.displayName)
          .join(", @")}`;
      }
    }
  }
  return textUserGroupInformation;
};

export function createActivityMultiUpload({
  companyId,
  orderEntityId,
  parentCollection,
  userId = "",
  files = [],
  scope = "",
  currentPath = "top level",
  doc = () => {},
  setDoc = () => {},
  activityId = getRandomId(),
  companyUsers = [],
  mainEntity = {},
  folders = [],
  permissionGroupsDB,
  permissions,
  parentDocumentId,
}) {
  if (files.length === 0 || !parentCollection || !orderEntityId) {
    return;
  }
  let activity = {
    ...new Activity({
      id: activityId,
      companyId,
      type: activityType.UPLOADED_MULTI_FILES,
      user: userId,
      scope,
    }),
  };
  if (folders.length === 0) {
    activity.detail = `<strong><ol class="uploaded-multifiles-container" >`;
    files.forEach((document) => {
      activity.detail += `<li> <strong> 📄 ${document.name}</strong> <br/> </li>`;
    });
    activity.detail += `</ol></strong>to <strong>${currentPath}</strong>`;
  } else {
    activity.detail = getDetailDeleteMultiselect({
      documents: convertToTreeRefactored({
        documents: [...folders, ...files],
        parentDocumentId,
      }),
      fileUpload: true,
    });
    activity.detail += getGroupAndUserPermissionsText({
      file: permissions,
      companyUsersDB: companyUsers,
      permissionGroupsDB,
    });
    activity.detail += `<br/>to <strong>${currentPath}</strong>`;
  }

  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${orderEntityId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export const getDetailDeleteMultiselect = ({
  documents = [],
  activityDeleteBatch = { delete: () => {} },
  fileUpload = false,
}) => {
  let detail = `<ol class="uploaded-multifiles-container" >`;
  if (documents.length === 0) {
    return "";
  }
  documents.sort(sortObjectsBy("currentPath", false)).forEach((document) => {
    activityDeleteBatch.delete(document.ref);
    detail += `<li class="root-deleted-documents"> ${
      document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""
    } ${
      fileUpload ? document.currentPath || document.name : document.currentPath
    }`;
    if (document.type === TYPE_OF_FILE.FOLDER && document.child.length > 0) {
      detail += `${getNestedDocumentsMultiDeleted({
        documents: document.child,
        className: "first-child-deleted-documents",
        activityDeleteBatch,
      })}`;
    }
    detail += `</li>`;
  });
  return detail + `</ol>`;
};

const getNestedDocumentsMultiDeleted = ({
  documents = [],
  className = "child-deleted-documents",
  activityDeleteBatch,
}) => {
  let detail = `<div class=${className} >`;
  documents.sort(sortObjectsBy("name", false)).forEach((document) => {
    if (!document.isVersion) {
      activityDeleteBatch.delete(document.ref);
    }
    detail += `<div>${document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""}  ${
      document.name
    }</div> `;
    const child = document.child || [];
    if (child.length > 0) {
      detail += `${getNestedDocumentsMultiDeleted({
        documents: child,
        activityDeleteBatch,
      })}`;
    }
  });
  return (detail += `</div>`);
};

export function deleteDocuments({
  companyId,
  parentDocumentId,
  parentCollection,
  currentUser,
  documents = [],
  scope = "",
  isMultiSelect = false,
  companyUsers = [],
  mainEntity = {},
}) {
  let type = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (!isMultiSelect) {
    if (hasFolder) {
      type = activityType.DELETED_FOLDER;
    } else {
      type = activityType.DELETED_FILE;
    }
  } else if (hasFolder && hasFile) {
    type = activityType.DELETE_MULTI_FILES_AND_FOLDERS;
  } else if (hasFolder) {
    type = activityType.DELETE_MULTI_FOLDERS;
  } else if (hasFile) {
    type = activityType.DELETE_MULTI_FILES;
  }
  const activityDeleteBatch = writeBatch(firestore);

  let activity = {
    ...new Activity({
      id: getRandomId(),
      companyId,
      type,
      user: currentUser.id || "",
      scope,
    }),
  };

  activity.detail = getDetailDeleteMultiselect({
    documents,
    activityDeleteBatch,
  });
  activityDeleteBatch.commit().then(() => {
    console.log("SUCCESSFULLY DELETED");
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
      ),
      activity
    );
  });

  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export const getDeleteMultiselectPlaceholder = ({
  documents = [],
  activityDeleteBatch = { delete: () => {} },
}) => {
  documents.sort(sortObjectsBy("currentPath", false)).forEach((document) => {
    activityDeleteBatch.delete(document.ref);
    if (document.type === TYPE_OF_FILE.FOLDER && document.child.length > 0) {
      getNestedDocumentsDeletedPlaceholder({
        documents: document.child,
        activityDeleteBatch,
      });
    }
  });
};

const getNestedDocumentsDeletedPlaceholder = ({
  documents = [],
  activityDeleteBatch,
}) => {
  documents.sort(sortObjectsBy("name", false)).forEach((document) => {
    activityDeleteBatch.delete(document.ref);
    if (document.child.length > 0) {
      getNestedDocumentsDeletedPlaceholder({
        documents: document.child,
        activityDeleteBatch,
      });
    }
  });
};

export function deletePlaceholderDocuments({ documents = [] }) {
  const activityDeleteBatch = writeBatch(firestore);
  getDeleteMultiselectPlaceholder({ documents, activityDeleteBatch });
  activityDeleteBatch.commit().then(() => {
    console.log("SUCCESSFULLY DELETED");
  });
}

export function filtersDocumentsPermission({
  documents = [],
  permissionGroupsDB = [],
  user = {},
  permissionScope = "",
  allowed = () => {},
  filterFilePlaceholder = false,
  search = "",
}) {
  const userDocuments = documents.filter((document) => {
    const selectedUsersObj = getselectedUsersObj(
      document.permissionUsers,
      document.permissionGroups,
      permissionGroupsDB
    );
    if (
      filterFilePlaceholder &&
      document.placeholder &&
      document.type !== TYPE_OF_FILE.FOLDER
    ) {
      return false;
    }
    if (document.type !== TYPE_OF_FILE.FOLDER) {
      return true;
    }
    return (
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE ||
      allowed(permissionScope) ||
      (selectedUsersObj && selectedUsersObj[user.id] === true)
    );
  });
  if (search) {
    function existsChild({ documents = [], document = {}, search = "" }) {
      const children = documents.filter((doc) => doc.parentId === document.id);
      if (children.length === 0) {
        return false;
      }
      return children.some(
        (child) =>
          child.name.toLowerCase().includes(search.toLowerCase()) ||
          existsChild({
            documents,
            document: child,
            search,
          })
      );
    }
    return userDocuments.filter(
      (document) =>
        document.name.toLowerCase().includes(search.toLowerCase()) ||
        existsChild({
          documents: userDocuments,
          document,
          search,
        })
    );
  }
  return userDocuments;
}

const documentTypesArr = [
  "odt",
  "doc",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "xlsm",
  "ods",
  "pdf",
  "pptx",
  "png",
  "jpeg",
  "psd",
  "ai",
  "cdr",
  "svg",
  "eps",
  "file",
  TYPE_OF_FILE.FOLDER,
];

export function addNewDocumentType({ company, document }) {
  const documentTypesCpy = [...(company.document_types || [])];
  const documentType = document.type.toLowerCase();
  if (
    !documentTypesArr.includes(documentType) &&
    !documentTypesCpy.includes(documentType)
  ) {
    documentTypesCpy.push(documentType);
    updateDoc(company.ref, { document_types: documentTypesCpy });
  }
}

export function hasPermissionToDelete({ scope, user, isAllowed }) {
  const ORDER_DASHBOARD_SCOPES = [
    dbTables.SALES_ORDERS,
    dbTables.PURCHASE_ORDERS,
    dbTables.SHIPMENTS,
  ];
  if (user.role === userTypes.SUPER_ADMIN) {
    return true;
  } else if (ORDER_DASHBOARD_SCOPES.includes(scope)) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_ORDER_DASHBOARD_FILES_AND_FOLDERS
    );
  } else if (scope === dbTables.CUSTOMERS) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_CUSTOMER_FILES_AND_FOLDERS
    );
  } else if (scope === dbTables.FACTORIES) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_VENDOR_FILES_AND_FOLDERS
    );
  }
  return false;
}

export function getModalForDeleting({
  isMultiSelect = false,
  documents = [],
  placeholderText = "",
}) {
  let type = "";
  let description = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (!isMultiSelect) {
    if (hasFolder) {
      type = "folder";
    } else {
      type = "file";
    }
  } else if (hasFolder && hasFile) {
    type = "files and folders";
  } else if (hasFolder) {
    type = "folders";
  } else if (hasFile) {
    type = "files";
  }
  function getNestedDocuments({
    documents = [],
    className = "child-deleted-documents",
    sortBy = "name",
    desc = false,
  }) {
    if (documents.length === 0) {
      return "";
    }
    return (
      <div
        className={className}
        style={{
          textAlign: "left",
          marginLeft: 22,
        }}
      >
        {documents.sort(sortObjectsBy(sortBy, desc)).map((document) => {
          return (
            <div
              style={{
                padding: "4px 0px",
              }}
            >
              {document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""}
              {document.name}
              {getNestedDocuments({
                documents: document.child,
                sortBy:
                  document.type === TYPE_OF_FILE.FOLDER ? "name" : "version",
                desc: document.type === TYPE_OF_FILE.FOLDER ? false : true,
              })}
            </div>
          );
        })}
      </div>
    );
  }

  function getShallowThreeView(documents = []) {
    return (
      <div
        class="uploaded-multifiles-container"
        style={{
          padding: "0px 24px",
          maxHeight: "calc(100vh - 280px)",
          overflow: "scroll",
        }}
      >
        {documents.sort(sortObjectsBy("currentPath", false)).map((document) => {
          return (
            <div
              class="root-deleted-documents"
              style={{
                textAlign: "left",
                padding: "4px 0px",
                height: "auto",
              }}
            >
              {document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""}
              {document.currentPath}
              {getNestedDocuments({
                documents: document.child,
                className: "first-child-deleted-documents",
                sortBy:
                  document.type === TYPE_OF_FILE.FOLDER ? "name" : "version",
                desc: document.type === TYPE_OF_FILE.FOLDER ? false : true,
              })}
            </div>
          );
        })}
      </div>
    );
  }

  const documentThreeView = getShallowThreeView(documents);
  description = (
    <Fragment>
      <div
        style={{
          padding: 16,
        }}
      >
        Do you want to permanently delete the following {placeholderText} {type}
        ?
        <br />
        <strong>This cannot be undone</strong>
      </div>
      {documentThreeView}
    </Fragment>
  );
  return {
    open: true,
    description,
    style: { height: "auto" },
  };
}

export function getDocumentContainerClass({
  openedFolders,
  document,
  search,
  isMultiSelect = false,
}) {
  if (isMultiSelect) {
    return "document-file-container";
  } else if (search && document.type === TYPE_OF_FILE.FOLDER) {
    return "document-folder-container";
  } else if (
    openedFolders[document.id] &&
    document.type === TYPE_OF_FILE.FOLDER
  ) {
    return "document-folder-container";
  } else {
    return "document-file-container";
  }
}

//

export function getDocumentPathv2({ currentDocument = {}, documents = [] }) {
  const pathArray = [currentDocument.name];
  do {
    const parentDocument = documents.find(
      (document) => document.id === currentDocument.parentId
    );
    if (parentDocument) {
      pathArray.push(parentDocument.name);
      currentDocument = parentDocument;
    } else {
      currentDocument.parentId = null;
    }
  } while (currentDocument.parentId);
  const path = pathArray.reverse().join("/");
  return path;
}

export function isMultiSelectEnabled({ selected = {}, currentDocument = {} }) {
  if (currentDocument.isVersion) {
    return false;
  }
  if (
    selected.parentId &&
    (selected.parentId === currentDocument.id ||
      selected.parentId === currentDocument.parentId) &&
    !selected.hiddenMenu
  ) {
    return true;
  }
  return false;
}

export function getDocumentScope({ parentCollection, mainEntity }) {
  switch (parentCollection) {
    case dbTables.SALES_ORDERS:
    case dbTables.PURCHASE_ORDERS:
      return mainEntity.type;
    case dbTables.SHIPMENTS:
      return documentScope.SHIPMENT;
    case dbTables.CUSTOMERS:
      return documentScope.CUSTOMER;
    case dbTables.FACTORIES:
      return documentScope.FACTORY;
    default:
      return "NO_SCOPE";
  }
}

export function createActivityForMovingDocuments({
  documents = [],
  scope = "",
  oldPath = "top level",
  currentPath = "top level",
  companyId = "",
  userId = "",
  parentCollection = "",
  parentDocumentId = "",
  doc = () => {},
  setDoc = () => {},
  activityId = getRandomId(),
  companyUsers = [],
  mainEntity = {},
}) {
  if (documents.length === 0 || !parentCollection || !parentDocumentId) {
    return;
  }
  let type = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (hasFolder && hasFile) {
    type = activityType.MOVE_FILES_AND_FOLDERS;
  } else if (hasFolder) {
    type = activityType.MOVE_FOLDERS;
  } else if (hasFile) {
    type = activityType.MOVE_FILES;
  }

  let activity = {
    ...new Activity({
      id: activityId,
      companyId,
      type,
      user: userId,
      scope,
    }),
  };

  activity.detail = `<strong>`;
  documents.sort(sortObjectsBy("name", false)).forEach((document, index) => {
    activity.detail += `<div> <strong> ${index + 1}. ${
      document.type === TYPE_OF_FILE.FOLDER ? "📂 " : "📄 "
    } ${document.name}</strong></div>`;
  });
  activity.detail += `<br /></strong>${
    documents.length === 1 ? "Was" : "Were"
  } moved from <strong>${oldPath}</strong> to <strong>${currentPath}</strong>`;
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });

  return;
}

export function canSeeThreeDotMenu({
  currentDocument = {},
  selected = {},
  isVoided = false,
}) {
  const isPlaceHolderFile =
    currentDocument.placeholder && currentDocument.type !== TYPE_OF_FILE.FOLDER;
  if (isPlaceHolderFile) {
    return false;
  }
  if (
    currentDocument.placeholder &&
    currentDocument.type === TYPE_OF_FILE.FOLDER &&
    isVoided
  ) {
    return false;
  }
  if (
    isMultiSelectEnabled({
      selected,
      currentDocument,
    })
  ) {
    return (
      selected.documents.includes(currentDocument.id) ||
      selected.parentId === currentDocument.id
    );
  }
  return true;
}

export function getDocumentNameByType({ currentDocument = {} }) {
  if (
    currentDocument.type === TYPE_OF_FILE.FOLDER ||
    currentDocument.placeholder
  ) {
    return currentDocument.name;
  } else {
    return getFileName(currentDocument.name);
  }
}

export const DOCUMENT_NAMES_ERROR_TYPES = {
  DUPLICATED_NAME: "DUPLICATED_NAME",
  INVALID_CHARACTER: "INVALID_CHARACTER",
};

export const filterFilesByType = ({ type, currentDocuments }) => {
  const cpyDocuments = currentDocuments.filter((currentDocument) =>
    type === TYPE_OF_FILE.FOLDER
      ? currentDocument.type === TYPE_OF_FILE.FOLDER
      : currentDocument.type !== TYPE_OF_FILE.FOLDER
  );
  return cpyDocuments;
};

export function verifyDocumentName({
  documentsInTheSameLevel = [],
  newName = "",
  type,
  currentDocumentId,
}) {
  if (newName.includes("/")) {
    return DOCUMENT_NAMES_ERROR_TYPES.INVALID_CHARACTER;
  }
  const documentsByType = filterFilesByType({
    type,
    currentDocuments: documentsInTheSameLevel,
  });
  const isDuplicatedName = documentsByType.find((document) => {
    return (
      getDocumentNameByType({ currentDocument: document }).toLowerCase() ===
        newName.toLocaleLowerCase() && document.id !== currentDocumentId
    );
  });
  if (isDuplicatedName) {
    return DOCUMENT_NAMES_ERROR_TYPES.DUPLICATED_NAME;
  }
  return null;
}

export function getDuplicatedNamesInDestination({
  documentsInTheSameLevel = [],
  documentsToMove = [],
}) {
  const duplicatedDocument = [];
  documentsToMove.forEach((document) => {
    const filesByType = filterFilesByType({
      currentDocuments: documentsInTheSameLevel,
      type: document.type,
    });
    const isDuplicatedName = filesByType.some((doc) => {
      return (
        getDocumentNameByType({
          currentDocument: document,
        }).toLocaleLowerCase() ===
        getDocumentNameByType({ currentDocument: doc }).toLocaleLowerCase()
      );
    });
    if (isDuplicatedName) {
      duplicatedDocument.push(document);
    }
  });
  if (duplicatedDocument.length === 0) {
    return {
      isDuplicatedName: false,
      attentionModalDescription: null,
    };
  }
  let type = "";
  const hasFolder = duplicatedDocument.some(
    (doc) => doc.type === TYPE_OF_FILE.FOLDER
  );
  const hasFile = duplicatedDocument.some(
    (doc) => doc.type !== TYPE_OF_FILE.FOLDER
  );
  if (hasFolder && hasFile) {
    type = "files and folders";
  } else if (hasFolder) {
    type = "folders";
  } else if (hasFile) {
    type = "files";
  }

  let attentionModalDescription = (
    <Fragment>
      Some of the items you want to move have the same name <br />
      as {type} in the destination. <br />
      Please change those names and try again.
      <div style={{ padding: "24px 0px" }}>
        {duplicatedDocument.sort(sortObjectsBy("name", false)).map((doc) => (
          <div
            key={doc.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {doc.type === TYPE_OF_FILE.FOLDER ? "📂 " : "📄 "} {doc.name}
          </div>
        ))}
      </div>
    </Fragment>
  );
  return { isDuplicatedName: true, attentionModalDescription };
}

export function isOpenedFolder({
  openedFolders = {},
  selected = { parentId: null, documents: [] },
  document = { id: null },
  search = "",
}) {
  if (search) {
    if (document.type !== TYPE_OF_FILE.FOLDER && !openedFolders[document.id]) {
      return false;
    }
    return true;
  }
  if (
    isMultiSelectEnabled({ selected, currentDocument: document }) &&
    selected.parentId === document.parentId
  ) {
    return false;
  }
  return !!openedFolders[document.id];
}

export const typeImage = ["png", "jpg", "jpeg", "svg"];

export function canSeePreview({ currentDocument = {}, selected = {} }) {
  const previewAvailableTypes = [TYPE_OF_FILE.FOLDER, "pdf", ...typeImage];
  const isPlaceholder = currentDocument.placeholder;
  const isNotMultiSelect = !isMultiSelectEnabled({
    selected,
    currentDocument,
  });
  const documentType = (currentDocument.type || "").toLowerCase();
  const isVersion = currentDocument.isVersion;
  const child = currentDocument.child || [];
  const hadImage =
    currentDocument.type === TYPE_OF_FILE.FOLDER
      ? child.some((doc) => typeImage.includes(doc.type))
      : true;
  const isSupportedType = previewAvailableTypes.includes(documentType);
  const canShowPreview =
    (isSupportedType && !isPlaceholder && isNotMultiSelect) ||
    (isVersion && isSupportedType);

  return (canShowPreview && hadImage) || false;
}

export const getPreviewImages = ({ currentDocument = {}, isFolder }) => {
  const child = currentDocument.child || [];
  const errorData = {
    src: "",
    number: "",
    description: "error",
  };
  const photoData = [];
  if (isFolder) {
    if (child.length === 0) {
      return [errorData];
    }
    child.forEach((file) => {
      if (typeImage.includes(file.type)) {
        photoData.push({
          src: file.url,
          number: "",
          description: file.name,
        });
      }
    });
  } else {
    if (!currentDocument.id) {
      return [errorData];
    }
    photoData.push({
      src: currentDocument.url,
      number: "",
      description: currentDocument.name,
    });
  }
  return photoData;
};

export function canEnterInMultiSelect({
  currentDocument = {},
  isVoided = false,
  selected,
}) {
  if (!isVoided && !currentDocument.placeholder) {
    if (
      selected.parentId &&
      (selected.parentId === currentDocument.id ||
        selected.parentId === currentDocument.parentId)
    ) {
      return true;
    } else if (
      currentDocument.type === TYPE_OF_FILE.FOLDER &&
      currentDocument.child.length > 0
    ) {
      return true;
    }
  }

  return false;
}

export function canUploadFile({
  currentDocument = "",
  isVoided = false,
  selected = {},
}) {
  const documentType = (currentDocument.type || "").toLowerCase();
  return (
    documentType === TYPE_OF_FILE.FOLDER &&
    !isVoided &&
    !isMultiSelectEnabled({ selected, currentDocument })
  );
}
export function canCreateFolder({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  const documentType = (currentDocument.type || "").toLowerCase();
  return (
    documentType === TYPE_OF_FILE.FOLDER &&
    !isVoided &&
    !isMultiSelectEnabled({ selected, currentDocument })
  );
}

export function canUploadNewFileVersion({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (currentDocument.isVersion || !currentDocument.id) {
    return false;
  }
  const documentType = (currentDocument.type || "").toLowerCase();
  if (
    documentType !== TYPE_OF_FILE.FOLDER &&
    !currentDocument.placeholder &&
    !isVoided &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canCopyLink({ currentDocument = {}, selected = {} }) {
  if (
    currentDocument.id &&
    !currentDocument.placeholder &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canRenameDocument({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (currentDocument.isVersion || !currentDocument.id) {
    return false;
  }
  if (
    !isVoided &&
    !currentDocument.placeholder &&
    !currentDocument.mainFileId &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canChangePermissions({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (
    currentDocument.type === TYPE_OF_FILE.FOLDER &&
    !isVoided &&
    !currentDocument.placeholder &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canDownload({ currentDocument = {}, selected = {} }) {
  if (
    isMultiSelectEnabled({
      selected,
      currentDocument,
    })
  ) {
    return selected.documents.includes(currentDocument.id);
  }

  if (!currentDocument.placeholder) {
    return true;
  }
  return false;
}

export function canMoveToTopLevel({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (isMultiSelectEnabled({ selected, currentDocument })) {
    return selected.documents.includes(currentDocument.id);
  }
  if (
    !currentDocument.placeholder &&
    !isVoided &&
    currentDocument.parentId &&
    !currentDocument.isVersion
  ) {
    return true;
  }
  return false;
}

export function canDeleteFileFolder({
  currentDocument = {},
  user = {},
  dbCollection = "",
  isAllowed = () => {},
  isVoided = false,
  selected = {},
}) {
  if (
    currentDocument.mainFileId ||
    currentDocument.placeholder ||
    isVoided ||
    currentDocument.isVersion
  ) {
    return false;
  }
  const { createdBy, created } = currentDocument;
  const diffSeconds = moment(new Date()).diff(created, "seconds");
  const limitInSecondsToDelete = 15;
  if (createdBy === user.id && diffSeconds < limitInSecondsToDelete) {
    return true;
  }
  if (
    !hasPermissionToDelete({
      scope: dbCollection,
      user,
      isAllowed,
    })
  ) {
    return false;
  }
  if (
    isMultiSelectEnabled({
      selected,
      currentDocument,
    })
  ) {
    return selected.documents.includes(currentDocument.id);
  }

  return true;
}

export function getChildDocumentsToCopyLink({ documents = [] }) {
  let documentsCpy = [...documents];
  documentsCpy = documentsCpy.map((document) => {
    if (document.type === TYPE_OF_FILE.FOLDER) {
      return {
        id: document.id,
        name: document.name,
        type: document.type,
        child: getChildDocumentsToCopyLink({
          documents: document.child,
        }),
      };
    }
    return {
      id: document.id,
      name: document.name,
      type: document.type,
      url: document.url,
    };
  });
  return documentsCpy;
}

export const filterAutocompleteUserList = ({ input = "", candidate = {} }) => {
  if (input === "") {
    return true;
  }
  const parseInput = input.toLowerCase();
  const candidateLabel = (candidate.label || "").toLowerCase();
  const filteredInput = input.startsWith("@")
    ? parseInput.substring(1)
    : parseInput;
  return candidateLabel.includes(filteredInput);
};

