import { Accordion, Menu, Popover, styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";
import { convertHex } from "../../helpers/timelineCalendar";
import CustomModal from "../Modal/Modal";

export const CreateTemplateModal = styled(CustomModal)(() => ({
  "& .headerText": {
    color: colors.primaryDark,
    fontSize: 12,
    fontWeight: 700,
  },
  "& .MuiSelect-select": {
    color: colors.primaryDark,
    fontSize: 14,
    fontWeight: 500,
  },
  "& .selected": {
    display: "none",
  },
  "& .textDataTemplate": {
    minWidth: 50,
    display: "flex",
    justifyContent: "center",
    "&::placeholder": {
      color: colors.primaryDark,
      fontSize: 14,
      fontWeight: 400,
      opacity: 0.5,
      fontStyle: "normal",
      fontFamily: "Roboto",
    },
    "& input": {
      padding: "0px 10px",
      minHeight: 28,
    },
  },
  "& .textDataTemplateAutosize": {
    width: "100%",
    padding: "10px",
    minHeight: 28,
    fontSize: 14,
    maxHeight: 300,
    overflow: "auto !important",
    borderColor: "rgba(0, 0, 0, 0.23)",
    lineHeight: 1.5,
    fontFamily: "Roboto",
    "&::placeholder": {
      color: "#828282",
      fontSize: 14,
      fontWeight: 400,
      opacity: 0.5,
      fontStyle: "normal",
      fontFamily: "Roboto",
    },
    "&:focus-within": {
      borderColor: "#5D92F4",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
      marginBottom: 15,
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
  "& .gridBody": {
    width: 550,
    display: "grid",
    marginBlock: "34px 40px",
    gridTemplateColumns: "120px 1fr",
    gap: "25px 15px",
    alignItems: "center",

    "& .readOnlyTitle": {
      fontSize: 12,
      fontWeight: 600,
      gap: 5,
      display: "flex",
      alignItems: "center",
    },
    "& .labelData": {
      color: colors.primaryDark,
      fontSize: 12,
      fontWeight: 400,
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      left: 10,
      bottom: -16,
      position: "absolute",
      fontSize: 8,
      letterSpacing: 1,
      width: 300,
    },
    "& .fieldTypeSelector": {
      justifyContent: "flex-start",
      textTransform: "none",
      color: colors.primaryDark,
      borderRadius: 1,
      fontWeight: 400,
      backgroundColor: "transparent",
      width: "auto",
      gap: 5,
      borderColor: "#CCC",
      "& .arrowDropDownIcon": {
        transition: "transform 0.3s ease",
        color: colors.primaryDark,
        padding: 0,
        width: 20,
      },
      "& .textFilter": {
        margin: "0px 0px 0px 6px",
        position: "relative",
        color: colors.primaryDark,
      },
      "& .arrowActive": {
        transform: "rotate(180deg)",
      },
    },
  },
  "& .red": {
    color: colors.diamondRed,
  },
  "& .footerTemplate": {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    "& .mandatoryText": {
      color: colors.primaryDark,
      fontSize: 10,
      fontWeight: 400,
    },
    "& .buttonContainer": {
      gap: 18,
      display: "flex",
      "& .buttonAction": {
        padding: "11px 35px",
        borderRadius: 5,
        background: "#777",
        fontSize: 14,
        fontWeight: 700,
        color: "white",
      },
      "& .saveButton": {
        background: "#218CFF",
      },
    },
  },
}));

export const FieldSelectorMenu = styled(Menu)(() => ({
  "& .menuItem": {
    gap: 10,
    color: colors.primaryDark,
    borderLeft: "4px solid white",
  },
  "& .selected": {
    borderColor: colors.radioCheck,
  },
  "& .itemIcon": {
    minWidth: "unset",
    width: 18,
    "& svg": {
      width: 18,
    },
  },
  "& .subMenuIcon": {
    width: 14,
  },
  "& .arrowSelected": {
    color: colors.radioCheck,
  },
}));

export const PopoverEmoji = styled(Popover)(() => ({
  "& .popoverEmojiPicker": {
    '& .emoji-mart-category[data-id="frequent"]': {
      display: "none",
    },
  },
}));

export const SalesOrderTemplateContainerStyled = styled("div")(() => ({
  "&.sales-order-container": {
    width: "100%",
    display: "flex",
    flexDirection: "column",

    "& .save-cancel-buttons-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: 16,
      padding: "8px 16px",
      transition: "all 3s",
      animation: "fadeIn 3s",
      "& .discard-changes-button": {
        backgroundColor: colors.diamondRed,
        fontWeight: 500,
        fontSize: 14,
        textWrap: "nowrap",
      },
      "& .save-changes-button": {
        backgroundColor: colors.blueButton,
        fontWeight: 500,
        fontSize: 14,
        textWrap: "nowrap",
      },
    },
  },
}));

export const TemplateMetadataContainerStyled = styled("div")(() => ({
  "&.template-metadata-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    padding: "8px 16px",
    width: "100%",
    height: "auto",
    background: "#E4ECF7",
    position: "relative",
    "& .data-side-container": {
      width: "100%",
      height: 120,
      "& .template-selector-content": {
        height: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .template-selector": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 66,
        },
        "& .sales-order-template": {
          display: "flex",
          alignItems: "center",
          gap: 8,
          "& .template-label": {
            color: colors.primaryDark,
            fontSize: 15,
            fontWeight: 500,
          },
          "& .emoji": {
            fontSize: 18,
          },
          "& .template-name": {
            color: colors.primaryDark,
            fontSize: 18,
            fontWeight: 700,
          },
        },
      },
      "& .metadata-content": {
        height: "25%",
        gap: 20,
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        color: colors.primaryDark,
        "& .label": {
          textWrap: "nowrap",
        },
        "& .created-by-container": {
          display: "flex",
          alignItems: "center",
          gap: 4,
        },
        "& .description-tooltip": {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        "& .version-notes-container": {
          display: "flex",
          alignItems: "center",
          gap: 4,
          overflow: "hidden",
        },
      },
    },
    "& .action-buttons-side-container": {
      display: "flex",
      gap: 16,
      flexDirection: "row-reverse",
    },
  },
  "& .save-cancel-buttons-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 16,
    padding: "8px 16px",
    transition: "all 3s",
    animation: "fadeIn 3s",
  },
}));

export const ChangeLogHeaderStyled = styled("div")(() => ({
  "&.table-header-stage": {
    gridTemplateColumns:
      "36px 75px 1fr 38px 204px 68px 47px 140px 124px 123px 135px",
    gap: "21px",
    display: "grid",
    backgroundColor: colors.tableHeaderColor,
    padding: "8px 16px",
    margin: 0,
    alignItems: "center",
    "& span": {
      fontSize: 13,
      fontWeight: 600,
    },
  },
}));
export const AccordionChangeLogsStyled = styled(Accordion)(() => ({
  "& .container": {
    margin: "0px 4px 4px 4px",
    boxShadow: "0 2px 4px 2px #ccc",
    height: "calc(100vh - 165px)",
    display: "block",
    padding: 16,
  },
  "&.accordionSummaryExpanded": {
    background: "#f5f5f5",
  },
  "& .expandIcon": {
    position: "absolute",
    left: "0px",
  },
  "& .tasks-in-stage-container": {
    margin: "0px  24px 0px 32px",
  },
  "& .stage-title": {
    fontSize: 16,
    fontWeight: 700,
    color: colors.primaryDark,
    padding: "16px 0px",
    marginLeft: 16,
  },
  "& .task-displayed": {
    gridTemplateColumns:
      "8px 82px 1fr 33px 224px 50px 60px 157px 119px 135px 108px",
    gap: 15,
    display: "grid",
    backgroundColor: colors.backgroundBlue,
    padding: 16,
    borderBottom: `1px solid ${colors.dividerColor}`,
    "& span": {
      color: colors.primaryDark,
      fontSize: 14,
      "& s": {
        color: colors.dangerRed,
      },
    },
    "& .left-justified": {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& .right-justified": {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
      padding: "0px 10px 0px 0px",
    },
    "& .center-justified": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  "& .added-tasks": {
    backgroundColor: convertHex(colors.diamondGreen, 0.15),
    alignItems: "center",
  },
  "& .deleted-tasks": {
    backgroundColor: convertHex(colors.dangerRed, 0.15),
    alignItems: "center",
  },
  "& .updated-tasks": {
    backgroundColor: convertHex("#FFDC00", 0.2),
  },
  "& .accordion-description": {
    display: "grid",
    gridTemplateColumns: "200px 100px 200px 1fr",
    gap: "24px",
    "& .span-content": {
      padding: 4,
    },
  },
}));

export const ChangeLogsHeaderStyled = styled("div")(() => ({
  "&.change-logs-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: colors.primaryDark,
    },
    "& .change-log-legend-container": {
      display: "flex",
      alignItems: "center",
      gap: 10,
      "& .legend-content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .label": {
          fontSize: 13,
          color: colors.primaryDark,
          fontWeight: 600,
        },
        "& .rectangle-green": {
          width: 120,
          height: 30,
          backgroundColor: convertHex(colors.diamondGreen, 0.15),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .rectangle-red": {
          width: 120,
          height: 30,
          backgroundColor: convertHex(colors.dangerRed, 0.15),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .rectangle-yellow": {
          width: 120,
          height: 30,
          backgroundColor: convertHex("#FFDC00", 0.2),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
}));

export const FullTemplateDetailsStyled = styled("div")(() => ({
  "&.full-template-details": {
    width: "calc(100vw - 200px)",
    height: "calc(100vh - 200px)",
    overflow: "auto",
    "& .tasks-in-stage-container": {
      margin: "0px  0px 0px 32px",
    },
    "& .stage-title": {
      fontSize: 16,
      fontWeight: 700,
      color: colors.primaryDark,
      padding: "16px 0px",
      marginLeft: 16,
    },
    "& .task-displayed": {
      gridTemplateColumns: "83px 1fr 100px 271px 52px 46px 124px 153px 135px",
      gap: 21,
      display: "grid",
      backgroundColor: colors.backgroundBlue,
      padding: 16,
      borderBottom: `1px solid ${colors.dividerColor}`,
      //alignItems: "center",
      alignItems: "center",
      "& span": {
        color: colors.primaryDark,
        fontSize: 12,
        "& s": {
          color: colors.dangerRed,
        },
      },
      "& .left-justified": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      },
      "& .right-justified": {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        padding: "0px 10px 0px 0px",
      },
      "& .center-justified": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      },
    },
    "& .SALES_ORDER": {
      backgroundColor: colors.salesOrderBackgroundColor,
    },
    "& .PURCHASE_ORDER": {
      backgroundColor: colors.purchaseOrderBackgroundColor,
    },
    "& .SHIPMENT": {
      backgroundColor: colors.shipmentBadge,
    },
  },
}));

export const FullTemplateDetailTitleStyled = styled("div")(() => ({
  "&.title-detailed-container": {
    display: "flex",
    alignItems: "center",
    gap: 24,
    width: "calc(100vw - 200px)",
    "& .label": {
      textWrap: "nowrap",
    },
    "& .created-by": {
      padding: 2,
      display: "flex",
      alignItems: "center",
      gap: 4,
    },
    "& .description-tooltip": {
      overflow: "hidden",
      textOverflow: "ellipsis",

      "& .description-span": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "nowrap",
        fontStyle: "italic",
        paddingRight: 5,
      },
    },
  },
}));
