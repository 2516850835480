import { styled } from "@mui/material";
import { boxShadowModal, colors } from "../../assets/jss/variables";

export const SelectAllNoneStyled = styled("div")(() => ({
  "&.selectAllNoneContainer": {
    fontWeight: 400,
    color: "#1C53B7",
    marginLeft: 24,
    display: "flex",
    fontSize: "0.80em",
    alignItems: "center",
    "& .select-all-button": {
      margin: "0px 2px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    "& .select-none-button": {
      margin: "0px 2px",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

export const AutoCompleteFieldStyled = styled("div")(() => ({
  "&.autocomplete-root": {
    flexGrow: 0,
  },

  "& .MuiPaper-root": {
    display: "flex",
    flexWrap: "wrap !important",
    backgroundColor: "white",
    border: "1px solid #ccc",
    position: "absolute",
    minWidth: 300,
    maxWidth: 420,
    padding: "5px 10px",
    boxShadow: boxShadowModal,
    zIndex: 1,
    "& .MuiAutocomplete-listbox": {
      display: "contents",
    },
    "& .custom-option": {
      padding: "7px 10px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        background: colors.primaryLight,
      },
    },
  },
  "& .MuiAutocomplete-tag": {
    background: colors.primaryLight,
    margin: 4,
    padding: "0px 6px",
    gap: 5,
    "& .MuiChip-deleteIcon": {
      margin: 0,
    },
    "& .MuiChip-label": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  "& .user-content": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
  },
  "& .MuiAutocomplete-popupIndicatorOpen": {
    display: "none",
  },
  "& .MuiAutocomplete-clearIndicator": {
    minWidth: "unset",
    padding: 0,
    width: 30,
  },
}));
