import numeral from "numeral";
import TooltipTD from "../components/Tooltip/TooltipTD";
import { parseNumberValue } from "./orderDashboardRefactored";

export const salesOrderTriggerTypes = {
  DATE: "date",
  SHIP_DATE: "shipDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  BOOKING: "booking",
  BILL_OF_LADING: "billOfLading",
  CONTAINER: "container",
  FREIGHT_FORWARDER: "freightForwarder",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  INVOICE: "invoice",
  CUSTOMER_PO: "customerPO",
  SHIPPING_TERMS: "shippingTerms",
  PERSON: "person",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  NUMBER: "number",
  TAGS: "tags",
  COMPLETED_TASKS: "completedTasks",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  METADATA: "METADATA",
};

export const orderType = {
  SALES_ORDER: "SALES_ORDER",
  QUOTE: "QUOTE",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  PURCHASE_QUOTE: "PURCHASE_QUOTE",
  SHIPMENT: "SHIPMENT",
};

export const PO_TYPES = [orderType.PURCHASE_ORDER, orderType.PURCHASE_QUOTE];

export const SALES_ORDER_TYPES = [orderType.SALES_ORDER, orderType.QUOTE];

const TAG_STATUS = {
  WITHOUT_TAGS: "WITHOUT_TAGS",
  ACTIVE_TAGS: "ACTIVE_TAGS",
  FILTER_TAGS: "FILTER_TAGS",
  WHITE_TAG: "WHITE_TAG",
  NO_FILTER: "NO_FILTER",
};

function getTagStatus({ tags = [], currentTags = [], renderTag = false }) {
  if (tags.length === 0) {
    return TAG_STATUS.WITHOUT_TAGS;
  } else if (currentTags.length === 0 && !renderTag) {
    return TAG_STATUS.NO_FILTER;
  } else if (
    currentTags.length > 0 &&
    currentTags.some((idTag) => tags.includes(idTag))
  ) {
    return TAG_STATUS.FILTER_TAGS;
  } else {
    return TAG_STATUS.NO_FILTER;
  }
}

const TAG_COLOR = {
  [TAG_STATUS.WITHOUT_TAGS]: "gray",
  [TAG_STATUS.ACTIVE_TAGS]: "#25486D",
  [TAG_STATUS.FILTER_TAGS]: "#25486D",
  [TAG_STATUS.WHITE_TAG]: "white",
};

export const SALES_ORDER_FIELDS = {
  DATE: "date",
  SHIP_DATE: "shipDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  BOOKING: "booking",
  BILL_OF_LADING: "billOfLading",
  CONTAINER: "container",
  FREIGHT_FORWARDER: "freightForwarder",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  INVOICE: "invoice",
  CUSTOMER_PO: "customerPO",
  SHIPPING_TERMS: "shippingTerms",
  PERSON: "person",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  NUMBER: "number",
  TAGS: "tags",
  DISCOUNT: "discount",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  CREATION_DATE: "creationDate",
  COMPLETED_TASKS: "completedTasks",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  REFERENCE_NUMBER: "referenceNumber",
  SALES_REPRESENTATIVE: "salesRepresentative",
  PAY_TERMS_START: "payTermsStart",
};

export const SALES_ORDER_VIEW_FIELDS = {
  DATE: "date",
  SHIP_DATE: "shipDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  BOOKING: "booking",
  BILL_OF_LADING: "billOfLading",
  CONTAINER: "container",
  FREIGHT_FORWARDER: "freightForwarder",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  INVOICE: "invoice",
  CUSTOMER_PO: "customerPO",
  SHIPPING_TERMS: "shippingTerms",
  PERSON: "person",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  NUMBER: "number",
  TAGS: "tags",
  DISCOUNT: "discount",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  CREATION_DATE: "creationDate",
  COMPLETED_TASKS: "completedTasks",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  REFERENCE_NUMBER: "referenceNumber",
  SALES_REPRESENTATIVE: "salesRepresentative",
  PAY_TERMS_START: "payTermsStart",
  BALANCE: "balance",
  SUB_TOTAL: "subTotal",
  TOTAL: "total",
  UNIQUE_ITEMS: "uniqueItems",
  TOTAL_SHIPMENTS: "totalShipments",
  CUSTOMER: "customer",
  FAVORITE: "favorite",
  DIAMOND: "diamond",
  PROGRESS: "progress",
  MENTIONS: "mentions",
  POS: "pos",
  SHIPMENTS: "shipments",
};

export const EXPENSE_FIELDS = {
  DATE: "date",
  PAID_TO: "paidTo",
  GL_ACCOUNT_ID: "GLAccountId",
  DESCRIPTION_OF_CHARGES: "descriptionOfCharges",
  NOTES: "notes",
  FORECAST: "forecast",
  ACTUAL: "actual",
  DELTA: "delta",
};

export { TAG_STATUS, getTagStatus, TAG_COLOR };

export const percentageValue = (value) => {
  const parseValue = parseNumberValue(value);
  return parseValue ? `${parseValue}%` : "";
};

export function zeroIsBlankNumber({ value, format = "" }) {
  const parseValue = parseNumberValue(value);
  return parseValue ? numeral(value).format(format) : " ";
}

export function zeroIsBlank(item, isCBM) {
  let dformat = "$0,0.00";
  if (isCBM) {
    dformat = "0.00";
  }
  const parseValue = numeral(item).value();

  return !parseValue ||
    !isFinite(parseValue) ||
    numeral(item).format("$0,0.00") === "$0.00" ? (
    " "
  ) : (
    <TooltipTD label={numeral(item).format(dformat)}>
      {numeral(item).format(dformat)}
    </TooltipTD>
  );
}
export function zeroIsBlankText(item, isCBM) {
  let dformat = "$0,0.00";
  if (isCBM) {
    dformat = "0.00";
  }
  const parseValue = numeral(item).value();

  return !parseValue ||
    !isFinite(parseValue) ||
    numeral(item).format("$0,0.00") === "$0.00"
    ? " "
    : numeral(item).format(dformat);
}
