import React, { useState } from "react";
import { useAutoCompleteLists } from "../../hooks";
import { SettingsTabTable } from "./styles";
import { getSettingsTableHeader, sortSuplierList } from "./companyTabHelper";
import { TYPE_OF_AUTOCOMPLETE_LISTS } from "../../helpers/constants";
import { cx } from "@emotion/css";
import { TrashCanIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import TooltipTD from "../Tooltip/TooltipTD";
import { updateDoc } from "firebase/firestore";
import AttentionModal from "../Modal/AttentionModal";
import { suppliersTableheader } from "./settingsHeaders";

function SuppliersTab() {
  const uniquePaidToMiscellaneo = useAutoCompleteLists({
    id: TYPE_OF_AUTOCOMPLETE_LISTS.UNIQUE_PAID_TO,
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    supplier: "",
  });
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    user: false,
    sortedColumn: "name",
    orderBy: "ASC",
  });

  const tbodyDisplay = ({ supplierList }) => {
    return sortSuplierList(supplierList, nonLookUpfilters.orderBy).map(
      (supplierItem) => {
        return (
          <div className="rowTable" key={supplierItem}>
            <div className={cx("cellTable", "name")}>{supplierItem}</div>
            <div className={cx("cellTable", "actionButtonsContainer")}>
              <TooltipTD
                label={"Delete Supplier"}
                tooltipClick={(ev) => {
                  setDeleteModal({
                    open: true,
                    supplier: supplierItem,
                  });
                  ev.stopPropagation();
                }}
              >
                <TrashCanIcon
                  size={20}
                  color={colors.diamondRed}
                  svgClass={"trashIcon"}
                />
              </TooltipTD>
            </div>
            <div />
          </div>
        );
      }
    );
  };
  return (
    <div className="mainTableContainer">
      {deleteModal.open && (
        <AttentionModal
          title="Attention"
          description={"  Are you sure to delete this supplier?"}
          isOpen={deleteModal.open}
          onClick={() => {
            const list = uniquePaidToMiscellaneo.list;
            const newList = list.filter(
              (item) => item !== deleteModal.supplier
            );
            updateDoc(uniquePaidToMiscellaneo.ref, {
              list: newList,
            });
            setDeleteModal({
              open: false,
              supplier: "",
            });
          }}
          cancellable
          confirmationText="Ok"
          onClose={() =>
            setDeleteModal({
              open: false,
              supplier: "",
            })
          }
        />
      )}
      <div className="action-buttons-container" />
      <SettingsTabTable className="SupplierTable">
        {getSettingsTableHeader({
          headers: suppliersTableheader,
          filters: nonLookUpfilters,
          updateSorting: (nonLookUpfilters) => {
            setNonLookUpFilters({ ...nonLookUpfilters });
          },
        })}
        {tbodyDisplay({
          supplierList: uniquePaidToMiscellaneo.list,
        })}
      </SettingsTabTable>
    </div>
  );
}

export default SuppliersTab;
