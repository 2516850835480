import React, { useEffect, useState } from "react";
import Customer from "../../api/model/customer";
import CustomModal from "../Modal/Modal";
import CustomButton from "../Buttons/CustomButton";
import FormTextField from "../TextFields/FormTextField";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { getRandomId } from "../../helpers/helpers";
import IntlMessages from "../../util/IntlMessages";
import { validateCustomerData } from "../../container/screenHelpers";

function CustomerModal({
  open,
  onClose,
  customer,
  onSubmit,
  customers,
  isUpdating = false,
  disabled = false,
}) {
  const [selectedCustomer, setSelectedCustomer] = useState(
    new Customer({
      id: getRandomId(),
    })
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (!customer) {
      setSelectedCustomer(
        new Customer({
          id: getRandomId(),
        })
      );
    } else {
      setSelectedCustomer(customer);
    }
  }, [open]);

  function onChangeField(field, event) {
    setSelectedCustomer({ ...selectedCustomer, [field]: event.target.value });
  }

  function handleSave(event) {
    event.preventDefault();
    const { isValidated, message, name}= validateCustomerData ({
      customers,
      selectedCustomer
    })

    if (!isValidated) {
      setOpenSnackbar(true);
      setSnackbarMessage(message);
    } else {
      onSubmit({
        ...selectedCustomer,
        name,
      });
    }

  
  }

  function handleKeyPress(keyPress) {
    if (keyPress.key === "Enter") {
      handleSave();
    }
  }

  return (
    <div onKeyDown={handleKeyPress}>
      {openSnackbar && (
        <CustomSnackbar
          open={openSnackbar}
          message={snackbarMessage}
          variant="error"
          handleCloseSnackbar={() => setOpenSnackbar(false)}
          autoHideDuration={2500}
        />
      )}
      <CustomModal
        isOpen={open}
        onClose={onClose}
        header={
          isUpdating ? (
            <IntlMessages
              id={"components.customers.customermodal.header.updatecustomer"}
            />
          ) : (
            <IntlMessages
              id={"components.customers.customermodal.header.createcustomer"}
            />
          )
        }
      >
        <form onSubmit={handleSave}>
          <FormTextField
            label="generic.textForm.name"
            variant="standard"
            fullWidth
            value={selectedCustomer.name}
            onChange={(event) => {
              onChangeField("name", event);
            }}
            autoFocus={true}
            required
            inputProps={{ maxLength: 20, minLength: 2 }}
            disabled={disabled}
          />
          <section style={{ display: "flex" }}>
            <FormTextField
              label="customers.customermodal.latepenaltyterms.label"
              value={selectedCustomer.latePenaltyTerms}
              margin="4px 4px 4px 4px"
              fullWidth
              onChange={(event) => {
                onChangeField("latePenaltyTerms", event);
              }}
              inputProps={{ maxLength: 50, minLength: 2 }}
              disabled={disabled}
            />
            <FormTextField
              label="customers.customermodal.contactname.label"
              fullWidth
              margin="4px 0 4px 4px"
              value={selectedCustomer.contactName}
              onChange={(event) => {
                onChangeField("contactName", event);
              }}
              inputProps={{ maxLength: 20, minLength: 2 }}
              disabled={disabled}
            />
          </section>
          <FormTextField
            label="customers.customermodal.notes.label"
            fullWidth
            value={selectedCustomer.notes}
            onChange={(event) => {
              onChangeField("notes", event);
            }}
            inputProps={{ maxLength: 100, minLength: 2 }}
            disabled={disabled}
          />

          <div
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              margin="0px 8px"
              type="cancel"
              onClick={(event) => {
                event.preventDefault();
                onClose();
                event.stopPropagation();
              }}
            >
              {"generic.text.cancel"}
            </CustomButton>
            <CustomButton
              margin="0 0 0 8px"
              type="success"
              disabled={selectedCustomer.name.trim() === "" || disabled}
            >
              {customer ? "generic.text.update" : "generic.text.create"}
            </CustomButton>
          </div>
        </form>
      </CustomModal>
    </div>
  );
}

export default CustomerModal;
