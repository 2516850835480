import React, { useEffect, useState } from "react";
import {
  getCompanyUserDisplayName,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { useCompanyId } from "../../hooks/user";
import { useIsAllowedFunction } from "../../hooks/permissions";
import TagsTableRow from "../../components/CompanyTabs/RowTable/TagsTableRow";
import { useUser, useCompanyUsers } from "../../hooks/user";
import { LABEL_INFO, TYPE_OF_TAGS } from "../../helpers/constants";
import {
  tagsByType,
  useTags,
  tagsExcludes,
  createMismatchTags,
} from "../../hooks/tags";
import InactiveDivider from "../../components/Dividers/InactiveDivider";
import { SettingsTabTable } from "../../components/CompanyTabs/styles";
import { getSettingsTableHeader } from "../../components/CompanyTabs/companyTabHelper";
import { cx } from "@emotion/css";
import { TagsHeadCells } from "../../components/CompanyTabs/settingsHeaders";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function TagsScreen() {
  const tags = useTags();
  const companyId = useCompanyId();
  const companyUsers = useCompanyUsers({
    showBotUser: true,
    showInactiveUsers: true,
  });
  const user = useUser();
  const isAllowed = useIsAllowedFunction();
  const hasPermission = isAllowed("see_tags");
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    user: false,
    sortedColumn: "name",
    orderBy: "DESC",
  });
  const tbodyDisplay = (tags = []) => {
    return tags.map((tag) => {
      return (
        <TagsTableRow
          key={tag.id}
          item={tag}
          user={user}
          isAllowed={isAllowed}
          companyUsers={companyUsers}
          tags={tags}
          isReadOnly={isTradeDashEmployee(user)}
        />
      );
    });
  };

  const regularTags = tagsByType(tags, TYPE_OF_TAGS.REGULAR_TAG);
  const specialTags = tagsExcludes(tags, [TYPE_OF_TAGS.REGULAR_TAG]);
  const getParseTags = (currentTags) => {
    return currentTags
      .map((tag) => ({
        ...tag,
        createdByName: getCompanyUserDisplayName(companyUsers, tag.createdBy),
        updatedByName: getCompanyUserDisplayName(companyUsers, tag.updatedBy),
      }))
      .sort(
        sortObjectsBy(
          nonLookUpfilters.sortedColumn,
          nonLookUpfilters.orderBy === "ASC" ? true : false
        )
      );
  };
  useEffect(() => {
    createMismatchTags(tags, companyId);
  }, []);
  return (
    <div className="mainTableContainer">
      {hasPermission && (
        <SettingsTabTable className="LabelsTable">
          {getSettingsTableHeader({
            headers: TagsHeadCells,
            updateSorting: (nonLookUpfilters) => {
              setNonLookUpFilters({ ...nonLookUpfilters });
            },
            filters: nonLookUpfilters,
          })}

          {tbodyDisplay(getParseTags(regularTags))}

          <InactiveDivider text={LABEL_INFO} />
          {specialTags.map((tag, index) => {
            const addDivider = index === 1 || index === 4 || index === 5;
            return (
              <div className="rowTable">
                <div
                  className={cx("cellTable", "systemLabel", {
                    divider: addDivider,
                  })}
                >
                  {tag.name}
                </div>
              </div>
            );
          })}
        </SettingsTabTable>
      )}
      {!hasPermission && (
        <div className={"withoutPermissionTextContainer"}>
          <span className={"withoutPermissionText"}>
            You do not have permissions to see labels
          </span>
        </div>
      )}
    </div>
  );
}

export default TagsScreen;
