import React, { useState } from "react";
import { convertToTreeRefactored } from "../../helpers/helpers";
import DocumentItem from "./DocumentItem";
import { usePermissionGroups } from "../../hooks";
import { useCompanyId, useCompanyUsers, useUser } from "../../hooks/user";
import { getDocumentsDescendants } from "../ReportsTree/helpers";
import { ManagementDocumentsStyled } from "./styles";
import { TYPE_OF_FILE } from "../../helpers/constants";
import AttentionModal from "../Modal/AttentionModal";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { isOpenedFolder } from "../Documents/documentHelpers";

const IDENTATION = 60;

function TemplateTreeDocuments({
  documents = [],
  dbCollection,
  mainEntity = {},
  isVoided = false,
  isReadOnly = false,
}) {
  const companyId = useCompanyId();
  const [draggingTo, setDraggingTo] = useState(null);
  const [openedFolders, setOpenedFolders] = useState({});
  const [attentionModal, setAttentionModal] = useState({
    open: false,
    description: "",
    action: () => {},
    confirmationText: "",
    cancelText: "",
  });
  const user = useUser();
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  const documentsTree = convertToTreeRefactored({
    documents,
  });

  function recursiveDocuments({
    document = {},
    childNumber = 0,
    isRoot = false,
    isPlaceholder = false,
  }) {
    const { child = [], type } = document;
    let sortBy = "name";
    let orderBy = false;
    if (type !== TYPE_OF_FILE.FOLDER) {
      sortBy = "version";
      orderBy = true;
    }
    const parentDocument = documents.find(
      (currentDocument) => currentDocument.id === document.id
    );

    const childrenSize =
      document.type === TYPE_OF_FILE.FOLDER
        ? getDocumentsDescendants({
            documents,
            originId: document.id,
          }).length
        : document.versions?.length;

    return (
      <div
        key={document.id + "active"}
        style={{
          marginLeft: isRoot ? 0 : IDENTATION,
          width: "-webkit-fill-available",
        }}
        id={`documentItem_${document.id}`}
        className={
          openedFolders[document.id] && document.type === TYPE_OF_FILE.FOLDER
            ? "document-folder-container"
            : "document-file-container"
        }
      >
        <DocumentItem
          currentDocument={document}
          childNumber={childNumber}
          draggingTo={draggingTo}
          setDraggingTo={setDraggingTo}
          documents={documents}
          onOpenFolder={(documentId, forceOpen = false) => {
            setOpenedFolders((oldValue) => ({
              ...oldValue,
              [documentId]: forceOpen || !openedFolders[documentId],
            }));
          }}
          isRoot={isRoot}
          isOpen={isOpenedFolder({ openedFolders, document })}
          permissionGroupsDB={permissionGroups}
          currentUser={user}
          users={companyUsers}
          dbCollection={dbCollection}
          mainEntity={mainEntity}
          isVoided={isVoided}
          isReadOnly={isReadOnly}
          parentDocument={parentDocument}
          isPlaceholder={isPlaceholder}
          onModalAttention={({
            open,
            description,
            action,
            confirmationText,
            cancelText,
          }) => {
            console.log(
              "ON MODAL ATTENTION",
              open,
              description,
              action,
              confirmationText,
              cancelText
            );
            setAttentionModal({
              open,
              description,
              action,
              confirmationText,
              cancelText,
            });
          }}
        />
        <div
          className="children-container"
          style={{
            maxHeight: isOpenedFolder({ openedFolders, document })
              ? childrenSize * 190
              : "0px",
            overflow: "hidden",
            transition: "max-height 1s ease-in-out",
          }}
        >
          {child.sort(sortObjectsBy(sortBy, orderBy)).map((cDocument) =>
            recursiveDocuments({
              document: cDocument,
              childNumber: childNumber + 1,
              isPlaceholder,
            })
          )}
        </div>
      </div>
    );
  }

  return (
    <ManagementDocumentsStyled className="documents-panel-container">
      {attentionModal.open && (
        <AttentionModal
          title="Attention"
          isOpen={attentionModal.open}
          description={attentionModal.description}
          onClick={(ev) => {
            attentionModal.action();
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev.stopPropagation();
          }}
          onClose={(ev) => {
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev.stopPropagation();
          }}
          cancellable={!!attentionModal.cancelText}
          confirmationText={attentionModal.confirmationText}
          cancelText={attentionModal.cancelText}
          styleModal={{
            maxWidth: "calc(100vw - 50%)",
          }}
        />
      )}

      <div className="documentsList" style={{ height: "calc(100vh - 232px)" }}>
        {documentsTree
          .sort(sortObjectsBy("name", false))
          .map((rootDocument) => {
            return recursiveDocuments({
              document: { ...rootDocument },
              childNumber: 0,
              isRoot: true,
            });
          })}
      </div>
    </ManagementDocumentsStyled>
  );
}

export default TemplateTreeDocuments;
