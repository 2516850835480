import React from "react";
import { Button, Switch } from "@mui/material";
import { cx } from "@emotion/css";
import { DeleteIcon, EditIcon, InfoIcon } from "../../../helpers/iconSvgPath";
import InactiveDivider from "../../Dividers/InactiveDivider";
import TooltipTD from "../../Tooltip/TooltipTD";
import { sortObjectsBy } from "../../../helpers/sortingHelper";
import {
  propertyLabel,
  propertyType,
  shipmentObjFields,
} from "../../../helpers/constants";

const FieldCustomizedTable = ({
  title,
  onChangeActive = () => {},
  fieldList = [],
  buttonAction = () => {},
  buttonText,
  onEdit = () => {},
  onErase = () => {},
  tab,
}) => {
  const getBody = (items, label) => {
    return items.sort(sortObjectsBy("label")).map((customField) => {
      const isSpacer = customField.type === propertyType.SPACER;
      const isFinalDestination =
        customField.id === shipmentObjFields.FINAL_DESTINATION && tab === 2;
      return (
        <div
          key={customField.id + label + customField.source}
          style={{ display: "contents" }}
        >
          <span className="bodySpan">{customField.label}</span>
          <span className="bodySpan" style={{ wordBreak: "break-word" }}>
            {!isSpacer ? customField.id : "N/A"}
          </span>
          <span className="bodySpan">
            {propertyLabel[customField.type] || "Calculated"}
          </span>
          <span className="bodySpan">
            {!isSpacer ? customField.source : "N/A"}
          </span>

          {!isFinalDestination && (
            <TooltipTD
              className="tooltipClass"
              label={customField.active ? "Active" : "Inactive"}
            >
              <Switch
                size="small"
                onChange={() =>
                  onChangeActive(customField.id, !customField.active)
                }
                checked={customField.active}
              />
            </TooltipTD>
          )}
          {isFinalDestination && <div />}
          <span className={cx("bodySpan", "iconContainer")}>
            {!isSpacer && (
              <TooltipTD
                className="tooltipClass"
                label={"Edit"}
                tooltipClick={() => onEdit(customField)}
              >
                <EditIcon
                  style={{
                    cursor: "pointer",
                  }}
                />
              </TooltipTD>
            )}
            {isSpacer && (
              <TooltipTD
                className="tooltipClass"
                label={"Delete"}
                style={{ width: 22 }}
              >
                <DeleteIcon
                  style={{
                    cursor: "pointer",
                  }}
                  width={16}
                  height={16}
                  onClick={() => onErase(customField.id)}
                />
              </TooltipTD>
            )}
            <TooltipTD className="tooltipClass" label={customField.description}>
              {!isSpacer ? <InfoIcon width={16} height={16} /> : ""}
            </TooltipTD>
          </span>
        </div>
      );
    });
  };

  const active = fieldList.filter((field) => field.active);
  const inactive = fieldList.filter((field) => !field.active);

  return (
    <div className="bodyContainer">
      <Button className="restoreButton" onClick={buttonAction}>
        {buttonText}
      </Button>

      <span className="titleText">{title}</span>

      <div className="tableHeader">
        <span className="head">Field Label</span>
        <span className="head">Field Ref. Name</span>
        <span className="head">Type</span>
        <span className="head">Source</span>
        <span className="head">Active</span>
        <span className="head" />
      </div>

      <div className="tableBody">
        {getBody(active, "active")}
        <InactiveDivider
          text={
            <>
              <span>Deactivated fields</span>
              <span className="inactiveCount">
                {`Active: ${active.length} Deactivated:
                ${inactive.length}`}
              </span>
            </>
          }
        />
        {getBody(inactive, "inactive")}
      </div>
    </div>
  );
};

export default FieldCustomizedTable;
