import {
  ACTIVITIES_STREAM_STATUS,
  NOTIFICATION_SCOPE,
  PO_SCOPE,
  SO_SCOPE,
  activityType,
  notAllowedToSee,
  notesFilters,
} from "./constants";
import Activity from "../api/model/activity";
import { dbDocuments, dbTables } from "../api/types/dbTables";
import { firestore } from "../firebase";
import { orderType } from "./salesOrder";
import moment from "moment";
import { getCartons } from "./orderDashboardRefactored";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  CLIENT_IS_OFFLINE,
  LOAD_NOTIFICATIONS,
  MARK_READ_ACTIVITY,
} from "../actions/types";
import {
  getNotificationByActivity,
  getActivityDataRead,
} from "../components/PurchaseOrderDashboard/ActivityStream/ActivityHelpers";
import numeral from "numeral";
import { getCleanMentionsValues } from "./helpers";

const getNewItemDetail = ({ shipmentItem, item, isHidden = false }) => {
  const detail = `  <p><strong>Shipment:</strong> <span class="mentionsShipmenClassUpdated"> ${
    shipmentItem.number
  }</span> <br/>
  <p><strong>Item:</strong> ${item.itemNumber} <br/>
  <strong>Desc:</strong> ${item.description} <br/>
  <strong>Quantity:</strong> ${shipmentItem.allocation} <br/>
  <strong>Cartons:</strong> ${getCartons({
    quantity: shipmentItem.allocation,
    pieces: item.piecesPerMaster,
  }).toFixed(1)} <br/>
  <strong>Cost:</strong> ${
    isHidden ? notAllowedToSee : numeral(item.unitCost).format("$0,0.00")
  } <br/>
  <strong>Total CBM:</strong> ${item.cbmMaster} <br/>
  <strong>Total Weight:</strong> ${item.weight}</p>`;
  return detail;
};

const getUpdateItemDetail = ({
  oldValue,
  newValue,
  item,
  shipmentItem = {},
}) => {
  const detail = `<p><strong><span class="mentionsShipmenClassUpdated"> ${
    shipmentItem.number
  }</span> - ${item.itemNumber} - ${item.description} </strong></br>
   From
   <strong>Quantity </strong> from <strong>${oldValue}</strong> to <strong>${
    newValue === 0 ? 0 : newValue || "BLANK"
  }</strong>
  </p>`;
  return detail;
};

export const createShipmentActivityStream = async ({
  shipmentItem,
  oldValue,
  newValue,
  type,
  purchaseOrder,
  user,
  item,
  companyUsers = [],
  companyId = "",
  batch,
}) => {
  const activityRef = `${dbTables.COMPANIES}/${user.companyId}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ACTIVITIES}`;
  switch (type) {
    case ACTIVITIES_STREAM_STATUS.NEW:
      const newActivity = {
        ...new Activity({
          detail: getNewItemDetail({ shipmentItem, item }),
          hiddenValueDetail: getNewItemDetail({
            shipmentItem,
            item,
            isHidden: true,
          }),
          scope: purchaseOrder.type,
          type: activityType.NEW_ITEM_SHIPMENT,
          user: user.id,
          companyId: user.companyId,
          shipmentId: shipmentItem.id,
          involvedFields: ["unitCost"],
        }),
      };

      await updateCompanyUsersUnreadActivity({
        activity: newActivity,
        companyId,
        users: companyUsers,
        order: purchaseOrder,
        batch,
      });
      return batch.set(doc(firestore, `${activityRef}/${newActivity.id}`), {
        ...newActivity,
      });
    case ACTIVITIES_STREAM_STATUS.UPDATED:
      const updateActivity = {
        ...new Activity({
          detail: getUpdateItemDetail({
            item,
            oldValue,
            newValue,
            shipmentItem,
          }),
          scope: purchaseOrder.type,
          type: activityType.UPDATED_ITEM_SHIPMENT,
          user: user.id,
          companyId: user.companyId,
          shipmentId: shipmentItem.id,
        }),
      };
      await updateCompanyUsersUnreadActivity({
        activity: updateActivity,
        companyId,
        users: companyUsers,
        order: purchaseOrder,
        batch,
      });
      return batch.set(doc(firestore, `${activityRef}/${updateActivity.id}`), {
        ...updateActivity,
      });
    default:
      const eraseActivity = {
        ...new Activity({
          detail: getUpdateItemDetail({
            item,
            oldValue,
            newValue: 0,
            shipmentItem,
          }),
          scope: purchaseOrder.type,
          type: activityType.UPDATED_ITEM_SHIPMENT,
          user: user.id,
          companyId: user.companyId,
          shipmentId: shipmentItem.id,
        }),
      };
      await updateCompanyUsersUnreadActivity({
        activity: eraseActivity,
        companyId,
        users: companyUsers,
        order: purchaseOrder,
        batch,
      });
      return batch.set(doc(firestore, `${activityRef}/${eraseActivity.id}`), {
        ...eraseActivity,
      });
  }
};

export async function updateCompanyUsersUnreadActivity({
  users = [],
  activity,
  companyId,
  order = {},
  batch,
}) {
  if (!order.id) {
    return;
  }
  let dbTable = "";
  if (order.type === orderType.SALES_ORDER || order.type === orderType.QUOTE) {
    dbTable = dbTables.SALES_ORDERS;
  } else if (
    order.type === orderType.PURCHASE_ORDER ||
    order.type === orderType.PURCHASE_QUOTE
  ) {
    dbTable = dbTables.PURCHASE_ORDERS;
  } else {
    dbTable = dbTables.SHIPMENTS;
  }
  const unreadActivityUsersSnap = await getDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${dbTable}/${order.id}/${dbTables.MISCELLANEOUS}/${dbDocuments.UNREAD_ACTIVITY_USERS}`
    )
  );

  const unreadActivityUsers = unreadActivityUsersSnap.data() || {};
  let unreadActivityUsersToAdd = {};
  users.forEach((user) => {
    const unreadActivity = unreadActivityUsers[user.id];
    if (!unreadActivity) {
      unreadActivityUsersToAdd[user.id] = {
        activityId: activity.id,
        creationDate: activity.creationDate,
      };
    }
  });

  if (Object.keys(unreadActivityUsersToAdd).length === 0) {
    return;
  }
  const documentRef = doc(
    firestore,
    `${dbTables.COMPANIES}/${companyId}/${dbTable}/${order.id}/${dbTables.MISCELLANEOUS}/${dbDocuments.UNREAD_ACTIVITY_USERS}`
  );
  if (!batch) {
    setDoc(
      documentRef,
      {
        ...unreadActivityUsersToAdd,
      },
      {
        merge: true,
      }
    );
  } else {
    batch.set(
      documentRef,
      {
        ...unreadActivityUsersToAdd,
      },
      {
        merge: true,
      }
    );
  }
  return;
}

export const getActivityByNotification = async ({
  notification,
  companyId,
}) => {
  const notificationScope = notification.scope;
  let orderCollection = dbTables.SALES_ORDERS;
  let currentOrderId = notification.salesOrderId;
  if (notificationScope === NOTIFICATION_SCOPE.PO_MENTION) {
    orderCollection = dbTables.PURCHASE_ORDERS;
    currentOrderId = notification.purchaseOrderId;
  }
  if (notificationScope === NOTIFICATION_SCOPE.SHIPMENT_MENTION) {
    orderCollection = dbTables.SHIPMENTS;
    currentOrderId = notification.shipmentId;
  }
  const snapActivity = await getDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${orderCollection}/${currentOrderId}/${dbTables.ACTIVITIES}/${notification.mainDocumentId}`
    )
  );
  return { ...snapActivity.data(), ref: snapActivity.ref };
};

export async function handleMarkAsRead({
  activity,
  user,
  salesOrder,
  purchaseOrder,
  currentShipment,
  userMentions = {},
  forceValue,
  dispatch = () => {},
  type,
  notifications,
}) {
  let userActivityMention = activity.userMentions || {};
  const merge = { merge: true };
  const userRef = `${dbTables.USERS}/${user.id}`;
  const isRead = forceValue ? forceValue.value : !userActivityMention[user.id];
  const newActivityData = getActivityDataRead({
    activity,
    forceValue,
    userId: user.id,
    type,
  });
  // trackEvent(
  //   `PO Dashboard - Activity - ${activity.scope === "SALES_ORDER" ? "SO" : "PO"
  //   } - Read Indicator`
  // );
  await updateDoc(activity.ref, newActivityData);
  if (activity.notificationId && activity.user !== user.id) {
    await updateDoc(
      doc(
        firestore,
        `${userRef}/${dbTables.NOTIFICATIONS}/${activity.notificationId}`
      ),
      {
        read: isRead,
        newNotification: false,
      }
    )
      .then(() => {
        console.log("SUCCESSFULLY updated notification");
      })
      .catch((error) => {
        console.log("Notification cannot be found.", error);
      });
  }
  const notification = getNotificationByActivity({ notifications, activity });

  if (notification) {
    dispatch({
      type: LOAD_NOTIFICATIONS,
      payload: { ...notification, read: isRead },
      isFromListener: true,
    });
  }

  dispatch({
    type: MARK_READ_ACTIVITY,
    payload: {
      ...activity,
      ...newActivityData,
    },
  });
  const mentionsAddition = isRead ? -1 : 1;
  if (SO_SCOPE.includes(activity.scope)) {
    const soUserMention = userMentions[dbTables.SALES_ORDERS];
    const deleteValues = getCleanMentionsValues({
      userMentions: soUserMention,
    });
    const currentValue = soUserMention[salesOrder.id];
    const newAdditionValue =
      currentValue <= 0
        ? mentionsAddition === 1
          ? 1
          : 0
        : increment(mentionsAddition);
    await setDoc(
      doc(
        firestore,
        `${userRef}/${dbTables.MENTIONS}/${dbTables.SALES_ORDERS}`
      ),
      {
        ...deleteValues,
        [salesOrder.id]: newAdditionValue,
      },
      merge
    );
  } else if (PO_SCOPE.includes(activity.scope)) {
    const poUserMention = userMentions[dbTables.PURCHASE_ORDERS];
    const deleteValues = getCleanMentionsValues({
      userMentions: poUserMention,
    });
    await setDoc(
      doc(
        firestore,
        `${userRef}/${dbTables.MENTIONS}/${dbTables.PURCHASE_ORDERS}`
      ),

      {
        ...deleteValues,
        [purchaseOrder.id]: increment(mentionsAddition),
      },
      merge
    );
  } else {
    const shipmentUserMention = userMentions[dbTables.SHIPMENT_DOCUMENTS];
    const deleteValues = getCleanMentionsValues({
      userMentions: shipmentUserMention,
    });
    await setDoc(
      doc(firestore, `${userRef}/${dbTables.MENTIONS}/${dbTables.SHIPMENTS}`),

      {
        ...deleteValues,
        [currentShipment.id]: increment(mentionsAddition),
      },
      merge
    );
  }
}

export const TAB_LABEL = {
  [notesFilters.ALL]: "purchaseorderdashboard.allNotes",
  [notesFilters.ACTIVITY]: "purchaseorderdashboard.activity",
  [notesFilters.MENTIONS]: "purchaseorderdashboard.mineNotes",
  [notesFilters.NOTES]: "purchaseorderdashboard.notes",
  [notesFilters.THREADS]: "purchaseorderdashboard.threads",
};

const currentActivityType = [
  activityType.TASK_COMPLETED,
  activityType.TASK_MOVED,
  activityType.REASSIGNED_TASK,
  activityType.FILE_UPLOAD,
  activityType.WITHOUT_TITLE,
  activityType.ITEM_CREATED,
  activityType.ITEM_UPDATED,
  activityType.TASK_COMPLETED,
  activityType.PROPERTY_CHANGE,
  activityType.TAGS_UPDATE,
  activityType.VERSION_UPLOAD,
];

export const getSecondNewestActivity = async ({
  path,
  activeTab,
  lastDateFound,
  dispatch,
  iconIndex,
}) => {
  let secondQuery;
  if (
    path.includes(undefined) ||
    !lastDateFound ||
    (activeTab === notesFilters.THREADS && !iconIndex)
  ) {
    return;
  }
  if (activeTab === notesFilters.NOTES) {
    secondQuery = query(
      collection(firestore, path),
      where("lockNote", "==", false),
      where("type", "==", "NOTE"),
      where("creationDate", "<", lastDateFound),
      orderBy("creationDate", "desc"),
      limit(1)
    );
  } else if (activeTab === notesFilters.ACTIVITY) {
    secondQuery = query(
      collection(firestore, path),
      where("type", "in", currentActivityType),
      where("creationDate", "<", lastDateFound),
      orderBy("creationDate", "desc"),
      limit(1)
    );
  } else if (activeTab === notesFilters.THREADS) {
    secondQuery = query(
      collection(firestore, path),
      where("iconIndex", ">", iconIndex),
      where("iconIndex", "<", iconIndex),
      limit(1)
    );
  } else {
    secondQuery = query(
      collection(firestore, path),
      where("creationDate", "<", lastDateFound),
      orderBy("creationDate", "desc"),
      limit(1)
    );
  }
  try {
    const activitySnap = await getDocs(secondQuery);
    const [activity] = activitySnap.docs.map((doc) => doc.data());
    if (activity) {
      return moment(activity.creationDate).startOf("day").valueOf();
    }
    return "";
  } catch (error) {
    console.log("ERROR retriving  getFirestoreDocumentDate", error);
    dispatch({
      type: CLIENT_IS_OFFLINE,
      payload: {
        value: true,
        text: "Please wait a moment and try again",
      },
    });
    return "";
  }
};

export const getFirstNewestActivity = async ({ path, activeTab, dispatch }) => {
  if (path.includes(undefined)) {
    return {};
  }
  let firstQuery;
  if (activeTab === notesFilters.NOTES) {
    firstQuery = query(
      collection(firestore, path),
      where("type", "==", "NOTE"),
      where("lockNote", "==", false),
      orderBy("creationDate", "desc"),
      limit(1)
    );
  } else if (activeTab === notesFilters.ACTIVITY) {
    firstQuery = query(
      collection(firestore, path),
      where("type", "in", currentActivityType),
      orderBy("creationDate", "desc"),
      limit(1)
    );
  } else if (activeTab === notesFilters.THREADS) {
    firstQuery = query(
      collection(firestore, path),
      where("type", "==", "NOTE"),
      where("iconIndex", ">=", 0),
      orderBy("iconIndex"),
      orderBy("creationDate", "desc"),
      limit(1)
    );
  } else {
    firstQuery = query(
      collection(firestore, path),
      orderBy("creationDate", "desc"),
      limit(1)
    );
  }

  try {
    const activitySnap = await getDocs(firstQuery);
    const [activity] = activitySnap.docs.map((doc) => doc.data());
    if (activity) {
      return {
        date: moment(activity.creationDate).startOf("day").valueOf(),
        iconIndex: activity.iconIndex,
      };
    }
    return {};
  } catch (error) {
    console.log("ERROR retriving  getFirestoreDocumentDate", error);
    dispatch({
      type: CLIENT_IS_OFFLINE,
      payload: {
        value: true,
        text: "Please wait a moment and try again",
      },
    });
    return {};
  }
};

export const PO_STORAGE_KEY = {
  ACTIVITY_TAB: "ACTIVITY_TAB",
};

export function markUnreadActivities({
  currentUser,
  parentCollection,
  parentDocumentId,
}) {
  let companyRef = `${dbTables.COMPANIES}/${currentUser.companyId}`;
  updateDoc(
    doc(
      firestore,
      `${companyRef}/${parentCollection}/${parentDocumentId}/${dbTables.MISCELLANEOUS}/${dbDocuments.UNREAD_ACTIVITY_USERS}`
    ),
    {
      [currentUser.id]: deleteField(),
    },
    {
      merge: true,
    }
  );
}

export function getDateTitle({ date }) {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  if (date === today) {
    return "Today";
  } else if (date === yesterday) {
    return "Yesterday";
  } else {
    return moment(date).format("MMM D");
  }
}
