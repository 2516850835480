import { useContext, useEffect, useState } from "react";
import timeLineIconv2 from "../../../assets/flag-icons/timeline-icon-v2.png";
import { ProjectTaskFiltersStyled } from "../styles";
import ExpandPanelButton from "../../Buttons/ExpandPanelButton";
import {
  filters,
  GENERAL_PERMISSION_VALUE,
  LOCAL_STORAGE_KEY,
  PO_STATUS,
  TASK_FILTER,
} from "../../../helpers/constants";
import { VoidLabel } from "../../../helpers/iconSvgPath";
import IntlMessages from "../../../util/IntlMessages";
import FilterTab from "../FilterTab";
import { useQueryParams, useStorage } from "../../../hooks";

import { useIsAllowedFunction } from "../../../hooks/permissions";
import CustomCheckbox from "../../Inputs/CustomCheckbox";
import { ENTITY_TASK_ACTION, EntityTaskContext } from "./TaskContext";
import { TaskTimeline } from "./TaskTimeline";
import { getCategoryStatus } from "../../../helpers/tasks";
import CreationTaskButton from "../../Buttons/AddTaskToPO";
import { useFactories } from "../../../hooks/factories";
import { cx } from "@emotion/css";

export function TaskPanelHeader({
  activePanel,
  onActivePanel = () => {},
  SOTasks,
  POTasks,
  purchaseOrders,
  salesOrder,
  purchaseOrder,
  smallPanel,
}) {
  const factories = useFactories();
  const queryParams = useQueryParams();
  const [timelineModal, setTimelineModal] = useState(false);

  const isAllowed = useIsAllowedFunction();
  const { TaskContextState, dispatchTaskcontext } =
    useContext(EntityTaskContext);
  const { filterTabActive, showCompletedTask } = TaskContextState;
  const [projectTaskStorage, setProjectTaskStorage] = useStorage(
    LOCAL_STORAGE_KEY.projectTasks
  );
  const SOCompletedLength = SOTasks.filter((task) => task.complete).length;
  const POCompletedLength = POTasks.filter((task) => task.complete).length;

  useEffect(() => {
    if (queryParams.taskId) {
      dispatchTaskcontext({
        type: ENTITY_TASK_ACTION.COMMON,
        payload: {
          filterTabActive: TASK_FILTER.ALL,
          showCompletedTask: true,
          categoryStatus: getCategoryStatus(true),
        },
      });
    }
  }, [queryParams.taskId]);

  const setValueContext = (value, key) => {
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        [key]: value,
      },
    });
    setProjectTaskStorage(key, value);
  };

  useEffect(() => {
    if (!queryParams.taskId) {
      dispatchTaskcontext({
        type: ENTITY_TASK_ACTION.COMMON,
        payload: {
          filterTabActive:
            projectTaskStorage.filterTabActive || TASK_FILTER.MINE,
          showCompletedTask: projectTaskStorage.showCompletedTask || false,
          categoryStatus:
            projectTaskStorage.categoryStatus || getCategoryStatus(true),
        },
      });
    }
  }, []);

  const showButtons =
    purchaseOrder.status !== PO_STATUS.VOIDED &&
    isAllowed(GENERAL_PERMISSION_VALUE.CAN_CREATE_PENDING_TASK);

  const hiddenTitle = showButtons ? !smallPanel : true;
  return (
    <div className="content-card-header">
      <ProjectTaskFiltersStyled className="projectTaskFilterContainer">
        {timelineModal && (
          <TaskTimeline
            currentSalesOrder={salesOrder}
            purchaseOrders={purchaseOrders}
            timelineModal={timelineModal}
            handleModal={setTimelineModal}
          />
        )}

        <div
          className="title-and-template-container"
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
          <div className="title">
            <ExpandPanelButton
              activePanel={activePanel}
              handleActivePanel={onActivePanel}
            />
            {salesOrder.status === PO_STATUS.VOIDED && <VoidLabel />}
            <img
              className="timeline-icon"
              src={timeLineIconv2}
              onClick={() => {
                setValueContext(true, "loading");
                setTimeout(() => {
                  setTimelineModal(true);
                }, 50);
              }}
              alt="timeline2"
            />

            {hiddenTitle && (
              <span>
                <IntlMessages id="purchaseorderdashboard.tasks" />
              </span>
            )}
            {showButtons && (
              <CreationTaskButton
                currentFactory={factories.find(
                  (factory) => factory.id === purchaseOrder.factoryId
                )}
              />
            )}
          </div>
          {salesOrder.templateName && (
            <div className={cx("templateContainer", {smallTextTemplate:smallPanel})}>
              <span className="templateName">
                {salesOrder.templateEmoji && (
                  <span className="emojiText">
                    {salesOrder.templateEmoji}
                  </span>
                )}
                <span>{salesOrder.templateName}</span>
              </span>
              <span>|</span>
              <span className="templateName">
                {purchaseOrder.templateEmoji && (
                  <span className="emojiText">
                    {purchaseOrder.templateEmoji}
                  </span>
                )}
                <span>{purchaseOrder.templateName}</span>
              </span>
            </div>
          )}
        </div>

        <div className={"projectTaskFilters"}>
          <div className="general-filters">
            <FilterTab
              onClick={() => setValueContext(filters.ALL, "filterTabActive")}
              activeTab={filterTabActive}
              value={filters.ALL}
              label="purchaseorderdashboard.all"
              styles={{ fontSize: 11 }}
            />
            <FilterTab
              onClick={() => setValueContext(filters.MINE, "filterTabActive")}
              activeTab={filterTabActive}
              value={filters.MINE}
              label="purchaseorderdashboard.mine"
              noTransform={true}
              styles={{ fontSize: 11 }}
            />

            <div className="indicator" />
          </div>
          <div
            className="show-completed"
            onClick={() =>
              setValueContext(!showCompletedTask, "showCompletedTask")
            }
          >
            <CustomCheckbox
              id="od-ts-custom-checkbox"
              className={"checkboxCompleted"}
              checked={showCompletedTask}
              size="small"
              iconSize={13}
            />
            <div id="od-ts-checkbox" className="sc-text-container">
              <span style={{ whiteSpace: "nowrap" }}>
                <IntlMessages id="purchaseorderdashboard.completed" />
              </span>
              {showCompletedTask && (
                <span style={{ margin: "0px 4px" }}>
                  {`(${SOCompletedLength + POCompletedLength}/${
                    SOTasks.length + POTasks.length
                  })`}
                </span>
              )}
            </div>
          </div>
        </div>
      </ProjectTaskFiltersStyled>
    </div>
  );
}
