import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MaximizeIcon from "@mui/icons-material/Maximize";
import { getUserAvatar } from "../../helpers/users";
import { ORDER_DASHBOARD_ACTUAL_VIEWS, PO_STATUS } from "../../helpers/constants";
import { VoidLabel } from "../../helpers/iconSvgPath";
import { useQueryParams } from "../../hooks";
import {
  arrPromisesResolved,
  getRemainingPromises,
  MENTION_POPOVER_SCOPES,
  onChangeQueryParamsv2,
  QUERY_PARAMS_SCOPE,
} from "../../helpers/orderDashboard";
import {
  useCompanyId,
  useOrderDashboardActualView,
  useUserMentions,
} from "../../hooks/user";
import ShipmentPopover from "./ShipmentPopover";
import { getDashboardSearchPath, getFactoryName } from "../../helpers/helpers";
import { NavLink, useNavigate } from "react-router-dom";
import { cx } from "@emotion/css";
import { ORDER_TYPE, dbTables } from "../../api/types/dbTables";
import TooltipTD from "../Tooltip/TooltipTD";
import Mark from "mark.js";
import { DueLateDiamond } from "../../container/TaskListScreen/TaskScreenHelper";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { CircularProgress } from "@mui/material";
import MentionPopover from "../../container/SalesOrdersScreen/MentionPopover";
import { useFactories } from "../../hooks/factories";

function NavPickerItem({
  onClose,
  list,
  scope,
  handleDateActivities = () => {},
  user,
  sizeNumber,
  sizeSubtitle,
  search,
  item = {},
  selectedStyle,
}) {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const actualView = useOrderDashboardActualView();
  const userPOMentions = useUserMentions(dbTables.PURCHASE_ORDERS);
  const userSalesOrderMentions = useUserMentions(dbTables.SALES_ORDERS);
  const userShipmentMentions = useUserMentions(dbTables.SHIPMENTS);
  const factories = useFactories();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    purchaseOrdersDB: [],
    shipmentsDB: [],
  });

  useEffect(() => {
    performMark(search);
  }, [search]);

  async function loadPOsAndShipments() {
    const POVectorIds = await getRemainingPromises({
      actualList: [],
      field: "purchaseOrderIds",
      list: [item],
    });
    let remainingPOPromises = [];
    POVectorIds.forEach((POIdsArr) => {
      if (POIdsArr.length > 0) {
        const POPromise = getDocs(
          query(
            collection(
              firestore,
              `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}`
            ),
            where("id", "in", POIdsArr)
          )
        );

        remainingPOPromises.push(POPromise);
      }
    });

    const purchaseOrdersDB = await arrPromisesResolved({
      arrPromises: remainingPOPromises,
    });

    const shipmentVectorIds = getRemainingPromises({
      list: purchaseOrdersDB,
      field: "shipmentIds",
      actualList: [],
    });

    let remainingShipmentPromises = [];
    shipmentVectorIds.forEach((shipmentIdsArr) => {
      if (shipmentIdsArr.length > 0) {
        const salesOrderPromise = getDocs(
          query(
            collection(
              firestore,
              `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
            ),
            where("id", "in", shipmentIdsArr)
          )
        );

        remainingShipmentPromises.push(salesOrderPromise);
      }
    });

    const shipmentsDB = await arrPromisesResolved({
      arrPromises: remainingShipmentPromises,
    });
    setData({
      purchaseOrdersDB,
      shipmentsDB,
    });
    setLoading(false);
  }

  useEffect(() => {
    if (scope === QUERY_PARAMS_SCOPE.SALES_ORDER) {
      setLoading(true);
      loadPOsAndShipments();
    }
  }, []);

  function hasMention({ item, scope }) {
    if (scope === "purchase-order-tab-") {
      return userPOMentions[item.id] > 0;
    } else if (scope === "sales-order-tab-") {
      return userSalesOrderMentions[item.id] > 0;
    } else if (scope === "shipment-tab-") {
      return userShipmentMentions[item.id] > 0;
    }
  }

  function hasSomeMention({ items = [] }) {
    return items.some((item) => hasMention({ item, scope }));
  }

  const getMessageIcon = (item) => {
    return (
      <div
        onClick={(ev) => {
          if (hasMention({ item, scope })) {
            redirectAction({
              ...item,
              sectionPath: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
            });
            setTimeout(() => {
              handleDateActivities();
            }, 800);
            onClose();
            ev.stopPropagation();
          }
        }}
        className="avatarContainer"
        style={{
          minWidth: 32,
        }}
      >
        {hasMention({ item, scope }) ? (
          getUserAvatar({
            user,
            styles: {
              width: 20,
              height: 20,
              outline: "1px solid #000",
              fontSize: 10,
            },
            hasToolTip: false,
            className: "avatarNavLink",
          })
        ) : (
          <div style={{ minWidth: 32 }} />
        )}
      </div>
    );
  };

  const redirectAction = (item) => {
    let section = ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION;
    if (scope === "purchase-order-tab-") {
      if (actualView !== ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION) {
        section = ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER;
      }
      section = item.sectionPath || section;
      onChangeQueryParamsv2({
        scope: QUERY_PARAMS_SCOPE.PURCHASE_ORDER,
        purchaseOrderId: item.id,
        queryParams: { ...queryParams, section },
        companyId: companyId,
        navigate,
        poRedirect: item,
      });
    } else if (scope === "shipment-tab-") {
      if (actualView !== ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION) {
        section = ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT;
      }
      section = item.sectionPath || section;
      onChangeQueryParamsv2({
        scope: QUERY_PARAMS_SCOPE.SHIPMENT,
        shipmentId: item.id,
        queryParams: { ...queryParams, section },
        companyId: companyId,
        navigate,
      });
    } else if (scope === QUERY_PARAMS_SCOPE.SALES_ORDER) {
      onChangeQueryParamsv2({
        scope: QUERY_PARAMS_SCOPE.SALES_ORDER,
        salesOrderId: item.id,
        queryParams: {
          ...queryParams,
          section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
        },
        companyId: companyId,
        navigate,
      });
    }

    onClose();
  };

  const markInstance = new Mark(document.getElementById("orderList"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: [".shipmentIconPopover > *"],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  function renderMentionBadge({ scope, list, item }) {
    if (scope !== ORDER_TYPE.SALES_ORDER) {
      if (hasSomeMention({ items: list })) {
        return getMessageIcon(item);
      }
    } else {
      return (
        <MentionPopover
          currentSalesOrder={item}
          purchaseOrders={data.purchaseOrdersDB.map((po) => ({
            ...po,
            factoryName: getFactoryName(factories, po.factoryId),
          }))}
          user={user}
          scope={MENTION_POPOVER_SCOPES.ORDER_DASHBOARD}
          onClick={onClose}
        />
      );
    }
  }
  return (
    <div
      key={item.id + "navTitle"}
      className={cx("listContainer", selectedStyle)}
      onClick={(ev) => {
        const selection = window.getSelection();
        if (selection && selection.toString().length === 0) {
          redirectAction(item);
        }
        ev.stopPropagation();
      }}
    >
      {loading && (
        <CircularProgress
          style={{
            width: 14,
            height: 14,
          }}
        />
      )}
      {renderMentionBadge({
        scope,
        list,
        item,
      })}
      <DueLateDiamond item={item} />
      <div
        className="itemListNumber"
        style={{
          marginRight: item.divider ? 0 : 20,
          width: sizeNumber ? sizeNumber * 8.5 + 20 : 40,
        }}
      >
        {item.name}
      </div>
      {item.subTitle.map((subtitle, index) => (
        <React.Fragment key={subtitle}>
          {item.divider && <MaximizeIcon className="item-divider" />}
          <TooltipTD
            className="item-subtitle"
            label={subtitle}
            style={{
              width: sizeSubtitle[index] ? sizeSubtitle[index] * 9.5 + 20 : 40,
              maxWidth: 220,
            }}
          >
            {item.redirectTo ? (
              <NavLink
                className={"vendorLink"}
                to={item.redirectTo}
                onClick={(ev) => ev.stopPropagation()}
              >
                {subtitle}
              </NavLink>
            ) : (
              subtitle
            )}
          </TooltipTD>
        </React.Fragment>
      ))}
      {item.status === PO_STATUS.VOIDED && (
        <VoidLabel style={{ marginLeft: "auto" }} />
      )}
      {item.shipmentIds && item.shipmentIds.length > 0 && (
        <ShipmentPopover
          item={item}
          onShipmentChange={({ purchaseOrderId, shipmentId }) => {
            onClose();
            let section = ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION;
            if (
              actualView !== ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
            ) {
              section = ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT;
            }
            setTimeout(() => {
              navigate(
                getDashboardSearchPath({
                  ...queryParams,
                  purchaseOrderId,
                  shipmentId,
                  section,
                }),
                { replace: true }
              );
            }, 1000);
          }}
        />
      )}
    </div>
  );
}

NavPickerItem.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NavPickerItem;
