import React from "react";
import { now } from "moment";
import {
  BACKDROP_TYPE,
  MISCELLANEOUS_IDS,
  POObjFields,
} from "../../../helpers/constants";
import { useQueryParams } from "../../../hooks";
import { usePurchaseOrders } from "../../../hooks/salesOrders";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../../hooks/permissions";
import { useMiscellaneous, useUser } from "../../../hooks/user";
import { dbTables } from "../../../api/types/dbTables";
import { useShipments } from "../../../hooks/shipments";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { isEqual } from "lodash";
import { cx } from "@emotion/css";
import { BackdropDashboard } from "./BackdropDashboard";
import {
  getMiscellaneoList,
  getParseAmount,
  getPOWindowValue,
  renderMetadataTextField,
} from "../dashboardHelpers";
import { sortObjectsBy } from "../../../helpers/sortingHelper";
import { purchaseOrderTriggerTypes } from "../../../helpers/purchaseOrder";

function POMetadataInfo() {
  const queryParams = useQueryParams();
  const purchaseOrders = usePurchaseOrders();
  const shipments = useShipments();
  const currentPurchaseOrder =
    purchaseOrders.find(
      (purchaseOrder) => purchaseOrder.id === queryParams.purchaseOrderId
    ) || {};
  const user = useUser() || {};
  const companyId = user.companyId;
  const isAllowed = useIsAllowedFunction();
  const isAllowedCompany = useIsAllowedCompany();
  const isReadOnly = false;
  const dataMiscellaneous = useMiscellaneous() || [];

  const purchaseOrderMetadata = getMiscellaneoList({
    miscellaneous: dataMiscellaneous,
    miscellaneoId: MISCELLANEOUS_IDS.PURCHASE_ORDER_METADATA,
  });

  function onChangeProperty({ value, field, type, label }) {
    if (isEqual(currentPurchaseOrder[field], value)) {
      return;
    }
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    if (field === POObjFields.SHIP_DATE) {
      let fieldDates;
      if (!currentPurchaseOrder.shipDates) {
        fieldDates = [];
      } else {
        fieldDates = [...currentPurchaseOrder.shipDates];
      }
      fieldDates.push({ creationDate: now(), shipDate: value, user: user.id });
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${currentPurchaseOrder.id}`
        ),
        {
          [field]: value,
          updated_by: user.id,
          triggerType: field,
          shipDates: fieldDates,
        }
      );
    } else {
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${currentPurchaseOrder.id}`
        ),
        {
          [field]: value,
          updated_by: user.id,
          triggerTypeMetadata: {
            field: field,
            fieldType: type,
            label: label,
          },
          triggerType: purchaseOrderTriggerTypes.METADATA,
        }
      );
    }
  }

  function onChangeAmountProperty({ value, field, type, label }) {
    const parseAmountValue = getParseAmount(value, currentPurchaseOrder[field]);
    if (parseAmountValue === undefined) {
      return;
    }
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;

    updateDoc(
      doc(
        firestore,
        `${companyRef}/${dbTables.PURCHASE_ORDERS}/${currentPurchaseOrder.id}`
      ),
      {
        [field]: parseAmountValue,
        updated_by: user.id,
        triggerTypeMetadata: {
          field: field,
          fieldType: type,
          label: label,
        },
        triggerType: purchaseOrderTriggerTypes.METADATA,
      }
    );
  }

  return (
    <React.Fragment>
      {currentPurchaseOrder.id && (
        <BackdropDashboard backdropType={BACKDROP_TYPE.METADATA} />
      )}
      <div className="sales-order-info">
        <div className="metadata-info-container">
          <div className={cx("metadata-info", "poInfoGrid-new")}>
            {purchaseOrderMetadata
              .filter((item) => item.active)
              .sort(sortObjectsBy("listIndex", false))
              .map((item) =>
                renderMetadataTextField({
                  entity: currentPurchaseOrder,
                  isAllowed,
                  isReadOnly,
                  item,
                  shipments,
                  windowValue: getPOWindowValue({
                    window: item.field,
                    shipments,
                    purchaseOrder: currentPurchaseOrder,
                  }),
                  scope: dbTables.PURCHASE_ORDERS,
                  onChangeAmountProperty,
                  onChangeEntityProperty: onChangeProperty,
                  isAllowedCompany,
                })
              )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default POMetadataInfo;
