import React from "react";
import { cx } from "@emotion/css";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DraggableIcon } from "../../../helpers/iconSvgPath";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { shipmentObjFields } from "../../../helpers/constants";
import { onDragFieldCustomized } from "../companyTabHelper";

const FieldCustomizedSort = ({
  inactiveList = [],
  fieldList = [],
  handleDragEnd = () => {},
}) => {
  function SortableSpan({ id, label }) {
    const { setNodeRef, attributes, listeners, transform, transition } =
      useSortable({ id });

    const style = {
      transform: transform ? CSS.Transform.toString(transform) : undefined,
      transition,
    };
    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="tableRow"
      >
        {id === shipmentObjFields.FINAL_DESTINATION ? (
          <div style={{ width: 14 }} />
        ) : (
          <DraggableIcon svgClass="dragClass" height={14} width={14} />
        )}
        <span>{label}</span>
      </div>
    );
  }

  const onDragEnd = (event) => {
    const newList = onDragFieldCustomized({
      event,
      fieldList,
      inactiveList,
    });
    if (newList) {
      handleDragEnd(newList);
    }
  };

  return (
    <div
      className="bodyContainer"
      style={{ marginTop: 42, minWidth: 320, maxWidth: 320 }}
    >
      <span className="titleText">Order Dashboard Field Order</span>

      <div className={cx("tableHeader", "tableSortBody")}>
        <span className="head">Field Label</span>
      </div>

      <div className="tableBody tableSortBody">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
          strategy={verticalListSortingStrategy}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext items={fieldList}>
            {fieldList.map((item) => (
              <SortableSpan
                key={item.id + "sorting"}
                id={item.id}
                label={item.label}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default FieldCustomizedSort;
