import React, { useState, useEffect } from "react";
import LeftColumn from "../../components/ColumnLayout/LeftColumn";
import RightColumn from "../../components/ColumnLayout/RightColumn";
import { usePermissionGroups } from "../../hooks";
import { isTradeDashEmployee } from "../../helpers/helpers";
import CustomerInfo from "../../components/CustomersScreen/CustomerInfo";
import CustomerDocuments from "../../components/CustomersScreen/CustomerDocuments";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { useCompanyId, useUser } from "../../hooks/user";
import ReadOnlyModal from "../../components/Modal/ReadOnlyModal";
import {
  useCustomers,
  useSummaryCustomers,
  useCurrentCustomer,
} from "../../hooks/customers";
import CustomerItem from "../../api/model/customerItem";
import { GENERAL_PERMISSION_VALUE, screenPath } from "../../helpers/constants";
import { updateDoc } from "firebase/firestore";
import CustomModal from "../../components/Modal/Modal";
import CreateVendorCustomer from "../../components/General/CreateVendorCustomer";
import { createCustomer } from "../../helpers/customers";
import AttentionModal from "../../components/Modal/AttentionModal";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { CustomerViewStyled } from "./styles";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { ColumnContainerStyled } from "../../assets/jss/generalStyles";

function CustomersScreen() {
  const companyId = useCompanyId();
  const user = useUser();
  const navigate = useNavigate();
  const [showInactive, setShowInactive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [settingsSnapshot, setSettingsSnapshot] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [attentionModalData, setAttentionModalData] = useState({});
  const isReadOnly = isTradeDashEmployee(user);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const summaryCustomer = useSummaryCustomers();
  const allCustomers = useCustomers();
  const customers = allCustomers.filter((customer) => {
    if (!showInactive) {
      return !customer.inactive;
    }
    return true;
  });
  const hasPermission = (currentCustomerId) =>
    isAllowed(currentCustomerId) ||
    isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS);
  const { customerId } = useParams();
  const currentCustomer = useCurrentCustomer() || {};
  const [query, setQuery] = useState("");
  const isAllowed = useIsAllowedFunction();

  const customersWithPermission = customers.filter((customer) =>
    hasPermission(customer.id)
  );
  const allCustomerWithPermission = allCustomers.filter((customer) =>
    hasPermission(customer.id)
  );

  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  const resetTopScroll = () => {
    const element = document.getElementById("leftColumnScroller");
    if (element) {
      element.scrollTop = 0;
    }
  };

  const changeCustomerPath = () => {
    const filterCustomer = customersWithPermission.filter(
      (customer) => customer.id !== customerId
    );
    console.log(filterCustomer, allCustomerWithPermission);
    if (filterCustomer.length > 0) {
      navigate(`/app/${screenPath.CUSTOMER}/${filterCustomer[0].id}`, {
        replace: true,
      });
    } else if (allCustomerWithPermission.length === 0) {
      navigate(`/app/${screenPath.CUSTOMER}/empty`, { replace: true });
    }
    resetTopScroll();
  };

  useEffect(() => {
    if (
      (currentCustomer.inactive && !showInactive) ||
      !hasPermission(customerId)
    ) {
      console.log("change");
      changeCustomerPath();
    }
  }, [user.permissions, currentCustomer.inactive, showInactive]);

  async function handleUpdateSummary(newCustomer) {
    const findSummary = summaryCustomer.find(
      (summary) => summary.id === newCustomer.customersSummaryId
    );
    const list = findSummary.customers.map((item) => {
      if (item.id === newCustomer.id) {
        const newItem = { ...new CustomerItem({ ...newCustomer }) };
        return newItem;
      } else {
        return item;
      }
    });
    updateDoc(findSummary.ref, {
      customers: list,
    });
  }
  function filterCustomers() {
    const filterCustomers = customersWithPermission
      .filter((customer) => {
        const name = (customer.name || "").toLowerCase();
        const number = (customer.number || "").toLowerCase();
        const parseQuery = query.toLocaleLowerCase();
        return name.includes(parseQuery) || number.includes(parseQuery);
      })
      .sort(sortObjectsBy("name"));
    return filterCustomers || [];
  }
  function handleReadOnlyModal() {
    setReadOnlyModal(true);
  }

  const showInactiveCustomer = currentCustomer.inactive && !showInactive;

  return (
    <CustomerViewStyled className="customerViewContainer">
      {attentionModalData.open && (
        <AttentionModal
          title="Attention"
          description={attentionModalData.text}
          isOpen={attentionModalData.open}
          confirmationText="Done"
          onClick={() => setAttentionModalData({})}
        />
      )}
      {openModal && (
        <CustomModal
          header={"New Customer"}
          isOpen={openModal}
          onClose={() => {
            if (!isLoading) {
              setOpenModal(false);
            }
          }}
          modalStyle={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading && <LoadingBackdrop />}
          <CreateVendorCustomer
            onSubmit={async ({ item }) => {
              setIsloading(true);
              const result = await createCustomer({
                customer: item,
                companyId,
                summary: summaryCustomer,
                allCustomers: customers,
                user: user,
              });
              if (result.success) {
                setOpenModal(false);
                navigate(`/app/${screenPath.CUSTOMER}/${result.id}`);
                setIsloading(false);
              } else {
                let warningText = "";
                if (result.errorName && result.errorNumber) {
                  warningText = "name and number";
                }
                if (!result.errorName && result.errorNumber) {
                  warningText = "number";
                }
                if (result.errorName && !result.errorNumber) {
                  warningText = "name";
                }
                setAttentionModalData({
                  open: true,
                  text: `That customer ${warningText} is already in use`,
                });
                setIsloading(false);
              }
            }}
            handleClose={() => {
              setOpenModal(false);
            }}
            user={user}
            scope="Customer"
          />
        </CustomModal>
      )}
      <ColumnContainerStyled className={"columnContainer"}>
        <LeftColumn
          redirectPath={`/app/${screenPath.CUSTOMER}`}
          title={`Customers (${customersWithPermission.length})`}
          onChangeInactive={() => setShowInactive(!showInactive)}
          showInactive={showInactive}
          onChangeQuery={setQuery}
          buttonText={"Add New"}
          query={query}
          cookie={"filterCustomers"}
          checkBoxLabel={"Show inactive customers"}
          navList={filterCustomers()}
          allowToCreate={!isAllowed("can_create_customers")}
          isEmpty={allCustomerWithPermission.length === 0}
          onAddNewItem={() => {
            if (!isReadOnly) {
              setOpenModal(true);
            } else {
              handleReadOnlyModal();
            }
          }}
        />

        {customerId !== "empty" &&
          customerId === currentCustomer.id &&
          !showInactiveCustomer && (
            <RightColumn
              key={currentCustomer.id}
              title={customerId === "customers" ? "" : currentCustomer.name}
            >
              <div className="contentContainer">
                {hasPermission(customerId) && (
                  <CustomerInfo
                    customer={currentCustomer}
                    customers={customersWithPermission}
                    isReadOnly={isReadOnly}
                    handleReadOnlyModal={handleReadOnlyModal}
                    handleUpdateSummary={handleUpdateSummary}
                  />
                )}
                {customersWithPermission.length > 0 &&
                  currentCustomer.id &&
                  hasPermission(customerId) && (
                    <CustomerDocuments
                      customer={currentCustomer}
                      permissionGroups={permissionGroups}
                      isReadOnly={isReadOnly}
                      settingsSnapshot={settingsSnapshot}
                      setSettingsSnapshot={setSettingsSnapshot}
                      currentUser={user}
                    />
                  )}
              </div>
            </RightColumn>
          )}
      </ColumnContainerStyled>
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
    </CustomerViewStyled>
  );
}

export default CustomersScreen;
