export const purchaseOrderTriggerTypes = {
  DATE: "date",
  SHIPDATE: "shipDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  SHIPPING_TERMS: "shippingTerms",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  NUMBER: "number",
  TAGS: "tags",
  COMPLETED_TASKS: "completedTasks",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  BUILDING_TASKS: "BUILDING_TASKS",
  METADATA: "METADATA",
};

export const PO_VIEW_FIELDS = {
  CREATION_DATE: "creationDate",
  DATE: "date",
  SHIPDATE: "shipDate",
  ORDER_READY_DATE: "orderReadyDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  SHIPPING_TERMS: "shippingTerms",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  NUMBER: "number",
  TAGS: "tags",
  COMPLETED_TASKS: "completedTasks",
  FAVORITE: "favorite",
  DIAMOND: "diamond",
  PROGRESS: "progress",
  MENTIONS: "mentions",
  SO: "so",
  SHIPMENTS: "shipments",
  CUSTOMER: "customer",
  VENDOR: "vendor",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  TOTAL_SHIPMENTS: "totalShipments",
  UNIQUE_ITEMS: "uniqueItems",
  DISCOUNT: "discount",
  SUB_TOTAL: "subTotal",
  BALANCE: "balance",
  TOTAL_WEIGHT: "totalWeight",
};

export const DOCUMENT_TRIGGER_TYPES = {
  RENAME: "RENAME",
  DELETE: "DELETE",
  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_FILE_PLACEHOLDER: "UPLOAD_FILE_PLACEHOLDER",
  CREATE_FILE_PLACEHOLDER: "CREATE_FILE_PLACEHOLDER",
  CREATE_FOLDER: "CREATE_FOLDER",
  CREATE_FOLDER_PLACEHOLDER: "CREATE_FOLDER_PLACEHOLDER",
  DOWNLOAD: "DOWNLOAD",
  MOVE_TO_TOP: "MOVE_TO_TOP",
  MOVE_BETWEEN_FOLDERS: "MOVE_BETWEEN_FOLDERS",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  CHANGE_PERMISSIONS: "CHANGE_PERMISSIONS",
  UPLOAD_NEW_FILE_VERSION: "UPLOAD_NEW_FILE_VERSION",
};
