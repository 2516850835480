import React, { useState } from "react";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { ListItemIcon } from "@mui/material";
import { YellowFolderIcon } from "../../helpers/iconSvgPath";
import { DocumentInfoStyled } from "./styles";
import FileSelector from "./FileSelector";
import { updateDoc } from "firebase/firestore";
import AttentionModal from "../Modal/AttentionModal";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import EditableLabelv2 from "../Documentsv2/EditableLabelv2";
import {
  DOCUMENT_NAMES_ERROR_TYPES,
  getDocumentNameByType,
  getDocumentPathv2,
  verifyDocumentName,
} from "../Documents/documentHelpers";

function DocumentInfo({
  currentDocument,
  allDocuments = [],
  editing = false,
  setEditing = () => {},
}) {
  const [modalAttention, setModalAttention] = useState({
    open: false,
    message: "",
  });

  function handleEditDocumentName(newName) {
    setEditing(false);
    let newNameToSave = newName;
    if (currentDocument.type !== TYPE_OF_FILE.FOLDER) {
      newNameToSave = newName;
    }
    const isSomeError = verifyDocumentName({
      documentsInTheSameLevel: allDocuments.filter(
        (document) => document.parentId === currentDocument.parentId
      ),
      newName,
      type: currentDocument.type,
      currentDocumentId: currentDocument.id,
    });

    if (isSomeError === DOCUMENT_NAMES_ERROR_TYPES.DUPLICATED_NAME) {
      setModalAttention({
        open: true,
        message: (
          <React.Fragment>
            That{" "}
            {currentDocument.type === TYPE_OF_FILE.FOLDER ? "folder" : "file"}{" "}
            name is already in use
          </React.Fragment>
        ),
      });
      return console.log("Document with same name");
    } else if (isSomeError === DOCUMENT_NAMES_ERROR_TYPES.INVALID_CHARACTER) {
      setModalAttention({
        open: true,
        message: (
          <React.Fragment>
            The name of the{" "}
            {currentDocument.type === TYPE_OF_FILE.FOLDER ? "folder" : "file"}{" "}
            cannot contain the character "/"
          </React.Fragment>
        ),
      });
      return console.log("Document with invalid character");
    } else {
      updateDoc(currentDocument.ref, {
        name: newNameToSave,
        oldPath: getDocumentPathv2({
          currentDocument,
          documents: allDocuments,
        }),
        currentPath: getDocumentPathv2({
          currentDocument: { ...currentDocument, name: newNameToSave },
          documents: allDocuments,
        }),
        triggerType: DOCUMENT_TRIGGER_TYPES.RENAME,
      });
    }
  }

  return (
    <DocumentInfoStyled
      className="document-info-container"
      onContextMenu={(ev) => {
        const element = document.getElementById(
          "document-menu-icon-id" + currentDocument.id
        );
        if (element) {
          element.click();
        }
        ev.preventDefault();
        ev.stopPropagation();
      }}
    >
      {modalAttention.open && (
        <AttentionModal
          isOpen={modalAttention.open}
          title={"Attention"}
          description={modalAttention.message}
          onClose={(ev) => {
            setModalAttention({ open: false, message: "" });
            ev.stopPropagation();
          }}
          onClick={(ev) => {
            setModalAttention({ open: false, message: "" });
            ev.stopPropagation();
          }}
        />
      )}
      <div className="document-icon-container">
        {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
          <ListItemIcon className={"listItemIcon"}>
            {YellowFolderIcon({
              svgClass: "imgListItemIcon",
              width: 38,
              height: 38,
            })}
          </ListItemIcon>
        ) : (
          <ListItemIcon className={"listItemIcon"}>
            <FileSelector
              path={currentDocument.path}
              version={currentDocument.version}
              type={currentDocument.type}
              currentDocument={currentDocument}
            />
          </ListItemIcon>
        )}
      </div>
      <div className="document-detail-container">
        <EditableLabelv2
          className="document-name-content"
          text={getDocumentNameByType({
            currentDocument,
          })}
          editing={editing}
          onSave={handleEditDocumentName}
          onCancel={() => {
            console.log("ON CANCEL");
            setEditing(false);
          }}
        />
      </div>
    </DocumentInfoStyled>
  );
}

export default DocumentInfo;
