import { useEffect, useState } from "react";
import moment from "moment";
import TooltipTD from "../Tooltip/TooltipTD";
import {
  TradeDashDateField,
  TradeDashDateTimeField,
} from "../PurchaseOrderDashboard/sections/styles";
import { InfoIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import { cx } from "@emotion/css";
import AttentionModal from "../Modal/AttentionModal";
import { renderTimeViewClock } from "@mui/x-date-pickers";

export default function DateFieldMetadata({
  isDisabled,
  item,
  scope,
  value,
  isDateTime,
  onSave = () => {},
}) {
  const dateFormat = isDateTime ? "M/D/YYYY hh:mm A" : "M/D/YYYY";
  const description = item.description?.trim();
  const DateComponent = isDateTime
    ? TradeDashDateTimeField
    : TradeDashDateField;

  const [attentionModalData, setAttentionModalData] = useState({
    open: false,
    description: "",
  });
  const [currentValue, setCurrentValue] = useState(
    value ? moment(value) : null
  );

  const resetValue = () => {
    setCurrentValue(value ? moment(value) : null);
  };

  useEffect(() => {
    if (value !== currentValue) {
      resetValue();
    }
  }, [value]);

  const handleInputBlur = (event) => {
    if (!event.target.value || event.target.value.includes("MM/DD/YYYY")) {
      resetValue();
      return;
    }
    const inputDate = moment(event.target.value, dateFormat);
    const minDate = moment("1/1/1900", "M/D/YYYY");
    const maxDate = moment("12/31/2099", "M/D/YYYY");

    if (!inputDate.isValid()) {
      setAttentionModalData({
        open: true,
        description: "This is not a valid date time format",
      });
      resetValue();
      return;
    }
    if (inputDate.isBefore(minDate) || inputDate.isAfter(maxDate)) {
      setAttentionModalData({
        open: true,
        description: `The date must be between ${minDate.format(
          dateFormat
        )} and ${maxDate.format(dateFormat)}`,
      });
      resetValue();
      return;
    }
    onSave(inputDate.format(dateFormat));
  };

  return (
    <>
      <DateComponent
        id={item.field}
        key={item.field + scope}
        className="tradedash-date-field"
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        format={dateFormat}
        value={currentValue}
        disabled={isDisabled}
        onAccept={(newValue) => {
          onSave(newValue.format(dateFormat));
        }}
        closeOnSelect={false}
        slotProps={{
          field: { shouldRespectLeadingZeros: true },
          textField: {
            variant: "filled",
            classes: {
              root: cx(
                "tradedash-metadata-custom-textfield-root",
                isDisabled
                  ? "custom-textfield-root-disabled-" + scope
                  : "custom-textfield-root-enabled-" + scope
              ),
            },
            label: (
              <div className="custom-textfield-label-container">
                <label>{item.label}</label>
                {description && (
                  <TooltipTD
                    label={description}
                    style={{ right: 2, position: "absolute" }}
                  >
                    <InfoIcon
                      width={20}
                      height={20}
                      fillColor={colors.darkGray}
                      className="info-icon"
                    />
                  </TooltipTD>
                )}
              </div>
            ),
            InputProps: {
              classes: {
                root: "custom-textfield-input-root",
                disabled: "custom-textfield-input-disabled",
                input: "custom-textfield-input",
              },
              id: item.id,
            },
            InputLabelProps: {
              classes: {
                filled: "custom-textfield-input-filled",
              },
              shrink: true,
            },
            onBlur: handleInputBlur,
          },
          inputAdornment: {
            className: "disable-ripple",
            style: {
              display: isDisabled ? "none" : "",
            },
          },
          actionBar: {
            actions: ["cancel", "accept"],
          },
        }}
      />

      {attentionModalData.open && (
        <AttentionModal
          title={"Attention"}
          isOpen={attentionModalData.open}
          description={attentionModalData.description}
          onClick={() => {
            setAttentionModalData({ open: false, description: "" });
            resetValue();
          }}
          onClose={() => {
            setAttentionModalData({ open: false, description: "" });
            resetValue();
          }}
        />
      )}
    </>
  );
}
