import moment from "moment";
import IntlMessages from "../../util/IntlMessages";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { cx } from "@emotion/css";
import {
  CustomizedEntityDoc,
  FIELD_SCOPE,
  FIELD_SOURCE,
  GENERAL_PERMISSION_VALUE,
  propertyType,
  shipmentObjFields,
  SOObjFields,
} from "../../helpers/constants";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { getRandomId } from "../../helpers/helpers";
import { apiImportDataType, dbTables } from "../../api/types/dbTables";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import ErrorLog from "../../api/model/ErrorLog";
import { getTextFromType } from "../PurchaseOrderDashboard/ActivityStream/ActivityHelpers";
import {
  columnHeaderSetup,
  expensesHeaderSetup,
  POHeaderSetup,
  shipmentHeaderSetup,
  SOHeaderSetup,
  taskHeaderSetup,
} from "../../helpers/screenHeaderSetup";
import ColumnHeader from "../../api/model/ColumnHeader.model";

export const ERROR_MESSAGES = {
  SHORT_NAME: "The template name must be at least 3 characters long",
  DUPLICATE_NAME: "That exact template name is already in use",
  RESERVED_NAME: "This field name is reserved and cannot be used",
};

const excludeList = [
  SOObjFields.SHIPMENT_WINDOW,
  SOObjFields.DELIVERY_WINDOW,
  SOObjFields.TOTAL_POS,
];

export const restoreDescription = (
  <>
    <span>
      Are you sure you want to return all the field labels and pop-up text to
      the factory defaults?
    </span>
    <br />
    <br />

    <span style={{ color: "red" }}>This cannot be undone</span>
  </>
);

export const getAPIKey = () => {
  const size = 64;
  const keyWords =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let apiKey = "";
  for (let i = 0, n = keyWords.length; i < size; ++i) {
    apiKey += keyWords.charAt(Math.floor(Math.random() * n));
  }
  return apiKey;
};

export function getAPIDataType(type) {
  switch (type) {
    case apiImportDataType.CREATE_SALES_ORDER:
      return "created sales order";
    case apiImportDataType.UPDATE_SALES_ORDER:
      return "updated sales order";
    case apiImportDataType.CREATE_PURCHASE_ORDER:
      return "created PO";
    case apiImportDataType.UPDATE_PURCHASE_ORDER:
      return "updated PO";
    case apiImportDataType.CREATE_QUOTE:
      return "created quote";
    case apiImportDataType.UPDATE_QUOTE:
      return "updated quote";
    case apiImportDataType.QUOTE_TO_SALES_ORDER:
      return "quote became a sales order";
    case apiImportDataType.RFQ_TO_PURCHASE_ORDER:
      return "RFQ became a purchase order";
    default:
      return "DEFAULT";
  }
}

export const getDateFormat = (date) =>
  date ? moment(date).format("M/D/YY") : "";

export const getSettingsTableHeader = ({
  headers = [],
  updateSorting = () => {},
  filters,
  sortArrowsColor = "#EEEEEE",
  checked = false,
  onChangeCheckBox = () => {},
  hadSpecialStyle = false,
}) => {
  function sortActualColumn(label) {
    if (filters.sortedColumn === label) {
      if (filters.orderBy === "ASC") {
        updateSorting({ ...filters, orderBy: "DESC" });
      } else {
        updateSorting({ ...filters, orderBy: "ASC" });
      }
    } else {
      updateSorting({
        ...filters,
        sortedColumn: label,
        orderBy: "ASC",
      });
    }
  }

  function renderArrow(headerLabel) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return (
          <ArrowsToSortIcon
            style={{
              opacity: 1,
              minWidth: 13,
              minHeight: 11,
            }}
            leftColor={colors.primaryDark}
            rightColor={sortArrowsColor}
          />
        );
      }
      return (
        <ArrowsToSortIcon
          style={{
            opacity: 1,
            minWidth: 13,
            minHeight: 11,
          }}
          rightColor={colors.primaryDark}
          leftColor={sortArrowsColor}
        />
      );
    }
    return (
      <ArrowsToSortIcon
        style={{ minWidth: 13, minHeight: 11 }}
        leftColor={sortArrowsColor}
        rightColor={sortArrowsColor}
        svgClass="arrow"
      />
    );
  }

  return headers.map((itemHeader) => {
    const {
      styles = {},
      label = "",
      id = "",
      key,
      spanStyle = {},
      className = "",
    } = itemHeader;
    const permissionStyle = hadSpecialStyle
      ? itemHeader.permissionStyle || {}
      : {};
    return (
      <div
        key={id + label}
        className={cx("headerTable", className)}
        style={{ ...styles, ...permissionStyle }}
        onClick={() => {
          if (itemHeader.canBeSort) sortActualColumn(key);
        }}
      >
        <span id="headerLabel" style={spanStyle}>
          {<IntlMessages id={label} />}
        </span>
        {itemHeader.canBeSort &&
          renderArrow(itemHeader.key, itemHeader.arrowStyles)}
        {itemHeader.checkIcon && (
          <CustomCheckbox
            className={"headerCheckbox"}
            checked={checked}
            onChange={(ev) => {
              onChangeCheckBox(ev.target.value);
              ev.stopPropagation();
            }}
          />
        )}
        <div className="whiteDivider" />
      </div>
    );
  });
};

export function toCamelCase(text) {
  return text
    .trim()
    .replaceAll(/[-_]/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
}

export const getFieldTextNameError = ({
  label = "",
  isEditing,
  currentItem = {},
  currentList = [],
  entityFields = [],
}) => {
  const currentName = label.trim().toLowerCase();
  if (currentName.length <= 2 && currentName.length > 0) {
    return ERROR_MESSAGES.SHORT_NAME;
  }
  const currentfield = toCamelCase(currentName);
  const filteredList = isEditing
    ? currentList.filter((field) => currentItem.id !== field.id)
    : currentList;
  const sameName = filteredList.some(
    (template) => template.id.trim().toLowerCase() === currentfield
  );
  const isReservedField = Object.values(entityFields).includes(currentfield);

  if (sameName) {
    return ERROR_MESSAGES.DUPLICATE_NAME;
  }
  if (isReservedField && !isEditing) {
    return ERROR_MESSAGES.RESERVED_NAME;
  }
  return "";
};

export const onDragFieldCustomized = ({ event, fieldList, inactiveList }) => {
  const { active, over } = event;
  if (!over || active.id === over.id) return;
  if (
    over.id === shipmentObjFields.FINAL_DESTINATION ||
    active.id === shipmentObjFields.FINAL_DESTINATION
  )
    return;
  const sourceIndex = fieldList.findIndex((item) => item.id === active.id);
  const destinationIndex = fieldList.findIndex((item) => item.id === over.id);
  let itemsCpy = [...fieldList];
  const [removed] = itemsCpy.splice(sourceIndex, 1);
  itemsCpy.splice(destinationIndex, 0, removed);
  return [...itemsCpy, ...inactiveList].map((item, index) => ({
    ...item,
    listIndex: index + 1,
  }));
};

export const changeFieldActive = ({ fieldId, active, entityDoc }) => {
  const currentList = entityDoc.list || [];
  const updatedFields = currentList.map((field) => {
    if (field.id === fieldId) {
      return {
        ...field,
        active,
      };
    }
    return field;
  });
  return updatedFields;
};

export const restoreFieldsValues = ({ entityDoc = {}, defaultValue = [] }) => {
  const currentList = entityDoc.list || [];
  const userList = currentList.filter(
    (field) => field.scope === FIELD_SCOPE.COMPANY_CUSTOM_FIELDS
  );
  const missingDefaults = defaultValue.sort(sortObjectsBy("listIndex"));

  return [...missingDefaults, ...userList].map((field, index) => ({
    ...field,
    listIndex: index + 1,
  }));
};

export const setNewErrorLog = ({ companyId, error = {}, location = {} }) => {
  if (!companyId) {
    return;
  }
  const id = getRandomId();
  const path = `${dbTables.COMPANIES}/${companyId}/${dbTables.FRONTEND_ERROR_LOG}/${id}`;
  const { pathname = "", search = "" } = location;
  if (error.status) {
    setDoc(doc(firestore, path), {
      ...new ErrorLog({
        description: error.data,
        status: error.status,
        path: pathname + search,
        message: error.error.message,
        id,
        type: error.statusText,
      }),
    });
  } else {
    setDoc(doc(firestore, path), {
      ...new ErrorLog({
        description: error.stack,
        status: "CRASH",
        path: pathname + search,
        message: error.message,
        id,
        type: error.name,
      }),
    });
  }
};

export function filterActivities({ activities, querySearch }) {
  if (!querySearch) {
    return activities;
  }
  return activities.filter((activity) => {
    const detailElement = document.createElement("div");
    detailElement.innerHTML = activity.detail;

    const textContent = detailElement.textContent || detailElement.innerText;
    return (
      textContent.toLowerCase().includes(querySearch.toLowerCase()) ||
      getTextFromType(activity.type)
        .toLowerCase()
        .includes(querySearch.toLowerCase())
    );
  });
}

export function groupActivitiesByDate(activities) {
  return activities
    .sort(sortObjectsBy("creationDate", true))
    .reduce((acc, activity) => {
      const date = moment(activity.creationDate).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(activity);
      return acc;
    }, {});
}

export const sortSuplierList = (supplierList, orderBy = "ASC") => {
  return supplierList.slice().sort((a, b) => {
    const comparison = a.toLowerCase().localeCompare(b.toLowerCase());
    return orderBy !== "ASC" ? comparison : -comparison;
  });
};

export const getValidatePermissionName = (
  newPermissionData = {},
  uniqueName
) => {
  const baseStyle = { height: 24 };
  const { name = "" } = newPermissionData;
  const parseName = name.trim();
  if (!parseName || !uniqueName) {
    return {
      message: !parseName
        ? "*Name is required"
        : `*${parseName} already exists`,
      style: { ...baseStyle, borderColor: "red" },
    };
  }

  return { message: "", style: baseStyle };
};

export const isUniquePermissionName = ({
  permissionGroups,
  currentPermissionGroup,
  newPermissionName = "",
}) => {
  const normalizedNewName = newPermissionName.trim().toLowerCase();
  if (!normalizedNewName) return false;

  return permissionGroups
    .filter((group) => group.id !== currentPermissionGroup.id)
    .every(
      (group) => (group.name || "").trim().toLowerCase() !== normalizedNewName
    );
};

export const changeActiveCategory = ({
  categories = [],
  categoryItem = {},
  checked,
}) => {
  const currentCategory = categories.find(
    (item) => item.id === categoryItem.summaryId
  );
  if (!currentCategory) {
    return {};
  }
  const categoriesList = currentCategory.categories || [];
  const newCategory = [...categoriesList].map((item) => {
    if (item.id === categoryItem.id) {
      return { ...item, active: checked };
    }
    return item;
  });
  return { newCategory, categoryId: currentCategory.id };
};

export function removePermissionGroup({
  permissionGroup = {},
  companyUsers = [],
  updateDoc = () => {},
}) {
  updateDoc(permissionGroup.ref, {
    users: {},
    ref: "",
  });

  companyUsers.forEach((user) => {
    const permissionGroupsCpy = user.permissionGroups
      ? { ...user.permissionGroups }
      : {};
    delete permissionGroupsCpy[permissionGroup.id];
    updateDoc(user.ref, {
      permissionGroups: permissionGroupsCpy,
      ref: "",
    });
  });
}

export function addAllUsersToPermissionGroup({
  permissionGroup = {},
  companyUsers = [],
  updateDoc = () => {},
}) {
  const allUsers = {};
  companyUsers.forEach((user) => (allUsers[user.id] = true));
  updateDoc(permissionGroup.ref, {
    users: allUsers,
    ref: "",
  });

  companyUsers.forEach((user) => {
    const permissionGroupsCpy = user.permissionGroups
      ? { ...user.permissionGroups }
      : {};
    permissionGroupsCpy[permissionGroup.id] = true;
    updateDoc(user.ref, {
      permissionGroups: permissionGroupsCpy,
      ref: "",
    });
  });
}

export function addPermissionGroupToUser({
  userArray = [],
  lastUserArray = [],
  permissionGroup = {},
  updateDoc = () => {},
}) {
  let addedUser;
  userArray.forEach((user) => {
    if (!lastUserArray.find((lastUser) => lastUser.id === user.id)) {
      addedUser = user;
    }
  });
  if (!addedUser) {
    return;
  }
  const permissionGroupsCpy = addedUser.permissionGroups
    ? { ...addedUser.permissionGroups }
    : {};
  permissionGroupsCpy[permissionGroup.id] = true;
  updateDoc(addedUser.ref, {
    permissionGroups: permissionGroupsCpy,
    ref: "",
  });
}

export function removePermissionGroupFromUser({
  userArray = [],
  lastUserArray = [],
  permissionGroup = {},
  updateDoc = () => {},
}) {
  let removedUser;
  lastUserArray.forEach((lastUser) => {
    if (!userArray.find((user) => user.id === lastUser.id)) {
      removedUser = lastUser;
    }
  });
  if (!removedUser) {
    return;
  }
  const permissionGroupsCpy = removedUser.permissionGroups
    ? { ...removedUser.permissionGroups }
    : {};
  delete permissionGroupsCpy[permissionGroup.id];
  updateDoc(removedUser.ref, {
    permissionGroups: permissionGroupsCpy,
    ref: "",
  });
}

export function updateUserAccessGroup({
  user = {},
  PermissionGroupsCollection = [],
  updateDoc = () => {},
}) {
  const newPermissionGroup = [];
  const userPermission = user.permissionGroups || {};
  PermissionGroupsCollection.forEach((permissionGroup) => {
    const usersList = permissionGroup.users;
    if (userPermission[permissionGroup.id]) {
      newPermissionGroup.push({
        ...permissionGroup,
        users: {
          ...usersList,
          [user.id]: true,
        },
      });
    } else {
      delete usersList[user.id];
      newPermissionGroup.push({
        ...permissionGroup,
        users: {
          ...usersList,
        },
      });
    }
  });
  newPermissionGroup.forEach((permissionGroup) => {
    updateDoc(permissionGroup.ref, { users: permissionGroup.users, ref: "" });
  });
}

export function existCompany({ name = "", companies = [] }) {
  return companies.some(
    (company) =>
      company.name?.trim().toLowerCase() === name.trim().toLowerCase()
  );
}

export function getTabPath({ rolePermissions, allPermission, TabRoutes = [] }) {
  const projectPermission = rolePermissions.get(
    GENERAL_PERMISSION_VALUE.SETTINGS_PROJECTS
  );

  const isAllowedProject =
    allPermission || (projectPermission?.enabled ?? false);

  if (isAllowedProject) {
    return "projects";
  }

  for (const route of TabRoutes) {
    const permission = rolePermissions.get(route.permission);
    if (permission?.enabled) {
      return route.url;
    }
  }

  return "notFound";
}

export const hasMayorChanges = ({ newList, oldList }) => {
  const parseNewList = newList.filter(
    (item) => item.type !== propertyType.SPACER
  );
  const parseOldList = oldList.filter(
    (item) => item.type !== propertyType.SPACER
  );
  if (parseNewList.length !== parseOldList.length) return true;

  const mapB = new Map(parseOldList.map((item) => [item.id, item]));

  return parseNewList.some((newField) => {
    const oldField = mapB.get(newField.id);
    if (!oldField) return true;
    return (
      newField.active !== oldField.active || newField.label !== oldField.label
    );
  });
};

export const updateUserHeaders = ({ currentHeader, filterCurrentField }) => {
  const headerToUpdate = currentHeader;
  const existingHeadersMap = new Map(headerToUpdate.map((h) => [h.field, h]));
  filterCurrentField.forEach((updateField) => {
    const existingHeader = existingHeadersMap.get(updateField.field);

    if (existingHeader) {
      if (updateField.label !== existingHeader.label) {
        existingHeader.label = updateField.label;
      }

      if (!updateField.active) {
        existingHeader.hidden = true;
        existingHeader.enabled = false;
      }
    } else if (!existingHeader && updateField.active) {
      const newHeader = new ColumnHeader({
        ...updateField,
        index: headerToUpdate.length,
        enabled: false,
        source: FIELD_SOURCE.USER,
        width: 150,
      });
      headerToUpdate.push({ ...newHeader });
      existingHeadersMap.set(updateField.field, { ...newHeader });
    }
  });
  return headerToUpdate.filter((head) => {
    return !head.hidden;
  });
};

export const filterAndUpdateHeaders = ({
  userHeaders = [],
  batch,
  tabIndex = 0,
  updatedHeaderList = [],
}) => {
  const scope = {
    0: columnHeaderSetup.SO_HEADER_SETUP,
    1: columnHeaderSetup.PO_HEADER_SETUP,
    2: columnHeaderSetup.SHIPMENT_HEADER_SETUP,
  };
  const currentScope = scope[tabIndex];
  const filterUpdateList = updatedHeaderList.filter(
    (field) =>
      field.type !== propertyType.SPACER && !excludeList.includes(field.id)
  );
  userHeaders.forEach((header) => {
    const filterHeader = updateUserHeaders({
      currentHeader: header[currentScope],
      filterCurrentField: filterUpdateList,
    });
    batch.update(header.ref, {
      [currentScope]: filterHeader.map((item, index) => ({
        ...new ColumnHeader({ ...item, index }),
      })),
    });
  });
};

export const getHeaderByScreen = async ({
  scope,
  companyId,
  getDoc = () => {},
}) => {
  const staticHeader = {
    [columnHeaderSetup.EXPENSES_HEADER_SETUP]: expensesHeaderSetup,
    [columnHeaderSetup.TASK_HEADER_SETUP]: taskHeaderSetup,
  };
  if (staticHeader[scope]) {
    return staticHeader[scope];
  }
  const miscellaneoID = {
    [columnHeaderSetup.SO_HEADER_SETUP]: {
      docId: CustomizedEntityDoc.SALES_ORDER_METADATA,
      header: SOHeaderSetup,
    },
    [columnHeaderSetup.PO_HEADER_SETUP]: {
      docId: CustomizedEntityDoc.PURCHASE_ORDER_METADATA,
      header: POHeaderSetup,
    },
    [columnHeaderSetup.SHIPMENT_HEADER_SETUP]: {
      docId: CustomizedEntityDoc.SHIPMENT_METADATA,
      header: shipmentHeaderSetup,
    },
  };
  const currentValues = miscellaneoID[scope];
  if (!currentValues) {
    return [];
  }
  const docRef = doc(
    firestore,
    `${dbTables.COMPANIES}/${companyId}/${dbTables.MISCELLANEOUS}/${currentValues.docId}`
  );
  const currentFieldsSnap = await getDoc(docRef);
  if (currentFieldsSnap.exists()) {
    const list = currentFieldsSnap.data()?.list || [];
    const filterHeader = updateUserHeaders({
      currentHeader: currentValues.header,
      filterCurrentField: list.filter(
        (field) =>
          field.type !== propertyType.SPACER && !excludeList.includes(field.id)
      ),
    });
    return filterHeader;
  } else {
    return currentValues.header;
  }
};
