import React, { useEffect, useRef, useState } from "react";
import { Autocomplete } from "@mui/material";
import cx from "classnames";
import TooltipTD from "../Tooltip/TooltipTD";
import { InfoIcon } from "../../helpers/iconSvgPath";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { colors } from "../../assets/jss/variables";
import { TradeDashTextField } from "../PurchaseOrderDashboard/sections/styles";
import { getRandomId } from "../../helpers/helpers";

const MAX_CHARACTER = 40;

const TooltipInput = React.forwardRef(function TooltipInput(props, ref) {
  const { dataTooltip: tooltipText, inputValue, key, ...otherProps } = props;
  const maxSize = Math.max(tooltipText.length, inputValue.length);
  return (
    <TooltipTD
      label={tooltipText}
      style={{ width: `${maxSize + 2}ch`, display: "flex", maxWidth: "38ch" }}
    >
      <input {...otherProps} key={key} ref={ref} />
    </TooltipTD>
  );
});

function AutocompleteMetadata({
  item = {},
  value = null,
  isDisabled,
  options = [],
  onChange,
  scope = "",
  onRemove = () => {},
  onSaveNewOption = () => {},
  inputLarge = false,
}) {
  const description = item.description?.trim();
  const [filterText, setFilterText] = useState("");
  const [currentValue, setCurrentValue] = useState(value);
  const input = useRef();
  const isSelectingRef = useRef(false);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  const getNoOptionsText = () => {
    if (filterText.length > MAX_CHARACTER) {
      return `The ${item.label} name can be up to ${MAX_CHARACTER} letters only`;
    }
    return `No matching ${item.label}, press enter to save`;
  };

  const onPressEnter = () => {
    if (isSelectingRef.current) return;
    const parseFilterText = filterText.toLocaleLowerCase().trim();
    const someOption = options.find(
      (option) => option.label.toLowerCase().trim() === parseFilterText
    );
    if (someOption) {
      const selectedOption = someOption.label;
      setFilterText(selectedOption);
      onChange(selectedOption);
    } else if (!someOption && parseFilterText) {
      const newItem = {
        id: getRandomId(),
        created: Date.now(),
        name: filterText.trim(),
      };
      setFilterText(newItem.name);
      setCurrentValue(newItem.name);
      onSaveNewOption(newItem);
    }
    input.current.blur();
  };

  const handleRemove = (option) => {
    const currentOption = options.find((opt) => opt.label === option);
    onRemove(currentOption);
  };

  const handleSelect = (ev, newValue) => {
    isSelectingRef.current = true;
    setFilterText("");
    setCurrentValue(newValue);
    if (newValue) {
      onChange(newValue);
      input.current.blur();
    }
    setTimeout(() => {
      isSelectingRef.current = false;
    }, 150);
  };

  const getRenderInput = (params) => {
    return (
      <TradeDashTextField
        {...params}
        key={item.field + scope}
        inputRef={input}
        variant="filled"
        label={
          !inputLarge && (
            <div className="custom-textfield-label-container">
              <label>{item.label}</label>
              {description && (
                <TooltipTD
                  label={description}
                  style={{ right: 2, position: "absolute" }}
                >
                  <InfoIcon
                    width={20}
                    height={20}
                    fillColor={colors.darkGray}
                    className="info-icon"
                  />
                </TooltipTD>
              )}
            </div>
          )
        }
        classes={{
          root: cx(
            "tradedash-metadata-custom-textfield-root",
            isDisabled
              ? "custom-textfield-root-disabled-" + scope
              : "custom-textfield-root-enabled-" + scope,
            inputLarge ? "input-large" : ""
          ),
        }}
        InputProps={{
          ...params.InputProps,
          classes: {
            root: "custom-textfield-input-root",
            disabled: "custom-textfield-input-disabled",
            input: "custom-textfield-input",
          },
          style: {
            paddingRight: inputLarge ? 30 : "",
          },
          endAdornment: params.InputProps.endAdornment,
          startAdornment: inputLarge && (
            <label className="label-large-container">
              {item.label}
              {description && (
                <TooltipTD
                  label={description}
                  style={{ right: 12, top: 4, zIndex: 1, position: "absolute" }}
                >
                  <InfoIcon
                    width={15}
                    height={15}
                    fillColor={colors.darkGray}
                    className="info-icon"
                  />
                </TooltipTD>
              )}
            </label>
          ),
          inputComponent: TooltipInput,
          inputProps: {
            dataTooltip: currentValue || "",
            inputValue: filterText,
            key: item.field + scope + "input",
            ...params.inputProps,
            id: item.field,
          },
        }}
        InputLabelProps={{
          classes: {
            filled: inputLarge ? "" : "custom-textfield-input-filled",
          },
          shrink: true,
        }}
        disabled={isDisabled}
        value={filterText}
        onChange={(ev) => setFilterText(ev.target.value)}
      />
    );
  };

  return (
    <Autocomplete
      value={currentValue}
      blurOnSelect
      clearOnBlur
      options={options.map((option) => option.label)}
      onChange={handleSelect}
      onKeyDown={(ev) => {
        if (ev.key === "Enter") {
          setTimeout(() => {
            onPressEnter();
          }, 100);
        }
      }}
      renderInput={(params) => getRenderInput(params)}
      disableClearable={!isDisabled}
      disabled={isDisabled}
      noOptionsText={getNoOptionsText()}
      classes={{
        endAdornment: cx("disable-ripple", {
          noDescription: !description,
        }),
      }}
      renderOption={(props, option) => {
        const currentSelected = option === currentValue;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            {...props}
          >
            <div style={{ overflow: "overlay", wordBreak: "break-all" }}>
              {option}
            </div>
            <div
              style={{
                display: "flex",
                width: 20,
                height: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!currentSelected && (
                <CancelIcon
                  style={{
                    width: 15,
                    height: 15,
                    color: "darkGray",
                  }}
                  id="icon"
                  onClick={(ev) => {
                    handleRemove(option);
                    ev.stopPropagation();
                  }}
                  children={{
                    id: "iconPath",
                  }}
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

export default AutocompleteMetadata;
