import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

const basicFilterStyle = {
  height: 40,
  minWidth: 95,
  maxWidth: 270,
  borderRadius: 5,
  border: "1px solid #d2dfec",
  display: "flex",
  alignItems: "flex-start",
  cursor: "pointer",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: "3px 16px 3px 8px",
  color: colors.primaryDark,
  fontSize: "0.90rem",
  position: "relative",
};

export const DisplayFiltersInfo = styled("div")(() => ({
  "&.filterContainer": {
    ...basicFilterStyle,
  },
  "&.notificationFilter": {
    ...basicFilterStyle,
  },

  "& .legendFilter": {
    textTransform: "uppercase",
    letterSpacing: 1,
    margin: 0,
    fontSize: "0.75rem",
    color: "#4f4f4f",
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  "& .textFilter": {
    paddingInline: 6,
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontSize: "0.90rem",
  },
  "& .closeIcon": {
    color: "#E38383",
    fontSize: 18,
    userSelect: "none",
    cursor: "pointer",
    right: 3,
    top: 10,
    position: "absolute",
  },
}));
