import React, { useEffect, useState } from "react";
import { AccordionImportedDataStyled } from "./styles";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { dbTables, apiImportDataType } from "../../api/types/dbTables";
import CreateQuote from "../apiImportData/CreateQuote";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import moment from "moment";
import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { loadDataBatches } from "../../helpers/settings.helpers";
import { colors } from "../../assets/jss/variables";
import { getAPIDataType } from "./companyTabHelper";

function ImportedData() {
  const { companyId } = useParams();
  const [apiImportedData, setApiImportedData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [pendingToLoad, setPendingToLoad] = useState(true);

  useEffect(() => {
    loadApiImportedData({ lastDoc: null });
  }, [companyId]);

  function getBuilderImport(item) {
    switch (item.type) {
      case apiImportDataType.CREATE_QUOTE:
      case apiImportDataType.UPDATE_QUOTE:
      case apiImportDataType.QUOTE_TO_SALES_ORDER:
      case apiImportDataType.UPDATE_SALES_ORDER:
      case apiImportDataType.CREATE_PURCHASE_ORDER:
      case apiImportDataType.UPDATE_PURCHASE_ORDER:
      case apiImportDataType.RFQ_TO_PURCHASE_ORDER:
      case apiImportDataType.CREATE_SALES_ORDER:
        return <CreateQuote item={item} />;

      default:
        return <div>HERE DEFAULT</div>;
    }
  }

  async function loadApiImportedData({ lastDoc }) {
    const {
      docs,
      lastDoc: lastDocResult,
      pendingToLoad,
    } = await loadDataBatches({
      path: `${dbTables.COMPANIES}/${companyId}/${dbTables.API_IMPORTED_DATA}`,
      lastDoc: lastDoc,
      limitFilter: 50,
      orderByField: "date",
    });
    setLastDoc(lastDocResult);
    setPendingToLoad(pendingToLoad);
    if (!lastDoc) {
      setApiImportedData(docs);
    } else {
      setApiImportedData((prev) => [...prev, ...docs]);
    }
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {apiImportedData &&
        apiImportedData.sort(sortObjectsBy("date", true)).map((item) => {
          const { data, date } = item;
          return (
            <AccordionImportedDataStyled key={item.id}>
              <AccordionSummary
                classes={{
                  expandIcon: "expandIcon",
                  content: "content",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={"heading"} style={{ fontSize: "1rem" }}>
                  {moment(date).format("M/D/YY hh:mma")} &nbsp;&nbsp; was
                  successfully {getAPIDataType(item.type)} {data.number}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                {getBuilderImport(item)}
              </AccordionDetails>
            </AccordionImportedDataStyled>
          );
        })}
      {pendingToLoad && (
        <div
          className="load-more-button"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 60,
          }}
        >
          <button
            onClick={() => loadApiImportedData({ lastDoc })}
            style={{
              padding: "16px 32px",
              background: colors.primaryDark,
              color: "#fff",
              border: "none",
              cursor: "pointer",
              borderRadius: 4,
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default ImportedData;
