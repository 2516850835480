import React from "react";
import moment from "moment";
import TooltipTD from "../components/Tooltip/TooltipTD";

import { getCustomerName, getFactoryByField, getFactoryName } from "./helpers";
import { ORDER_TYPE } from "../api/types/dbTables";
import { GENERAL_PERMISSION_VALUE, screenPath } from "./constants";
import { verifyPermission } from "../hooks/permissions";
import { ShipmentBadge } from "../container/StyledComponent/BadgeStyled";
import { cx } from "@emotion/css";
import SalesOrderBadge from "../components/Badge/SalesOrderBadge";
import POBadge from "../components/Badge/POBadge";
import { sortObjectsBy } from "./sortingHelper";

function getShipmentTooltipLabel({ shipment = {} }) {
  const { finalDestination, shipDate, deliveryDate } = shipment;
  const parts = [];

  if (finalDestination) {
    parts.push(finalDestination);
  }
  if (shipDate && moment(shipDate).isValid()) {
    parts.push(`Shipping on ${moment(shipDate).format("M/D/YY")}`);
  }
  if (deliveryDate && moment(deliveryDate).isValid()) {
    parts.push(`Delivery on ${moment(deliveryDate).format("M/D/YY")}`);
  }

  return parts.join(", ");
}

function getShipmentInfo({ shipment = {} }) {
  const { finalDestination, shipDate, deliveryDate } = shipment;
  let prefix = shipment.number;
  let suffix = "";
  if (!finalDestination && !shipDate && !deliveryDate) {
    return { prefix, suffix };
  }
  prefix = `${prefix}: `;
  if (finalDestination) {
    suffix = suffix + finalDestination;
    if (shipDate || deliveryDate) {
      suffix = suffix + ", ";
    }
  }
  if (shipDate) {
    suffix = suffix + "Shipping on " + moment(shipDate).format("M/D/YY");
    if (deliveryDate) {
      suffix = suffix + ", ";
    }
  }
  if (deliveryDate) {
    suffix = suffix + "Delivery on " + moment(deliveryDate).format("M/D/YY");
  }
  return { prefix, suffix };
}

export function getShipmentBadge({
  shipment,
  styles = {},
  classes,
  onChangeShipment = () => {},
  tooltipStyle = {},
  showToolTip = true,
  id = "",
  label,
}) {
  if (shipment) {
    return (
      <TooltipTD
        label={label || getShipmentTooltipLabel({ shipment })}
        style={tooltipStyle}
        showToolTip={showToolTip}
      >
        <ShipmentBadge
          id={id}
          className={cx("badgeStyle", classes)}
          style={{
            ...styles,
          }}
          onClick={(ev) => {
            onChangeShipment(shipment, ev);
          }}
        >
          <p>{shipment.number}</p>
        </ShipmentBadge>
      </TooltipTD>
    );
  }
  return null;
}

export const orderNavList = ({
  salesOrders,
  queryParam,
  customers,
  shipments,
  shipmentClass,
  purchaseOrders,
  factories,
  user,
}) => {
  const salesorderList = salesOrders.map((so) => ({
    id: so.id,
    name: <SalesOrderBadge number={so.number} />,
    number: so.number,
    divider: true,
    selected: so.id === queryParam.salesOrderId,
    subTitle: [
      getCustomerName(customers, so.customerId, true),
      so.referenceNumber,
      so.customerPO,
    ],
    diamondStatus: so.diamondStatus,
    purchaseOrderIds: so.purchaseOrderIds,
    status: so.status,
    creationDate: so.creationDate,
  }));

  const shipmentList = shipments.map((shipment) => ({
    id: shipment.id,
    name: getShipmentBadge({
      shipment: shipment,
      classes: shipmentClass,
      tooltipPlacement: "bottom",
    }),
    number: `${shipment.number}`,
    divider: false,
    diamondStatus: shipment.diamondStatus,
    subTitle: [shipment.finalDestination || ""],
    creationDate: shipment.creationDate,
  }));
  let poList = purchaseOrders
    .filter((po) =>
      verifyPermission({
        user: user,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    )
    .map((po) => ({
      id: po.id,
      name: <POBadge number={po.number} />,
      number: po.number,
      divider: true,
      selected: po.id === queryParam.purchaseOrderId,
      diamondStatus: po.diamondStatus,
      subTitle: [
        getFactoryName(factories, po.factoryId) +
          " (" +
          getFactoryByField({
            factories,
            factoryId: po.factoryId,
            field: "number",
          }) +
          ")",
      ],
      status: po.status,
      shipmentIds: po.shipmentIds || [],
      creationDate: po.creationDate,
      redirectTo: `/app/${screenPath.VENDOR}/${po.factoryId}`,
    }));

  return {
    [ORDER_TYPE.PURCHASE_ORDER]: {
      list: poList,
      scope: "purchase-order-tab-",
      id: queryParam.purchaseOrderId,
      sizeNumber: Math.max(...poList.map((po) => po.number.length)),
      sizeSubtitle: [Math.max(...poList.map((po) => po.subTitle[0].length))],
      searchBox: true,
      searchBoxText: "Search for PO or Vendor",
    },
    [ORDER_TYPE.SHIPMENT]: {
      list: shipmentList,
      scope: "shipment-tab-",
      id: queryParam.shipmentId,
      searchBoxText: "Search for Shipment or Customer",
      sizeNumber: 6,
      searchBox: true,
      sizeSubtitle: [
        Math.max(
          ...shipmentList.map((shipment) => shipment.subTitle[0].length)
        ),
      ],
    },
    [ORDER_TYPE.SALES_ORDER]: {
      list: salesorderList,
      scope: ORDER_TYPE.SALES_ORDER,
      id: queryParam.shipmentId,
      sizeNumber: Math.max(...salesorderList.map((so) => so.number.length)),
      sizeSubtitle: [
        Math.max(...salesorderList.map((so) => (so.subTitle[0] || "").length)),
        Math.max(...salesorderList.map((so) => (so.subTitle[1] || "").length)),
        Math.max(...salesorderList.map((so) => (so.subTitle[2] || "").length)),
      ],
      searchBox: true,
      searchBoxSort: { key: "creationDate", asc: true },
    },
  };
};

export function getPOShipments({ shipments = [], purchaseOrder = {} }) {
  if (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds.length > 0) {
    const currentShipments = [];
    purchaseOrder.shipmentIds.forEach((shipmentId) => {
      const shipment = shipments.find((shipment) => shipment.id === shipmentId);
      if (shipment) {
        currentShipments.push(shipment);
      }
    });
    const sortShipments = currentShipments.sort(sortObjectsBy("number", false));
    return sortShipments.map((shipment, index) => ({ ...shipment, index }));
  }
  return [];
}

export { getShipmentTooltipLabel };
export { getShipmentInfo };
