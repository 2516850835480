import React, { useState, useEffect } from "react";
import { dbTables } from "../../api/types/dbTables";

import { DocumentPanelContainerStyled } from "./styles";
import { useQueryParams, useStorage } from "../../hooks";
import {
  BACKDROP_TYPE,
  documentScope,
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
} from "../../helpers/constants";
import AppConfig from "../../constants/AppConfig";
import { VoidLabel } from "../../helpers/iconSvgPath";
import { BackdropDashboard } from "./sections/BackdropDashboard";
import ExpandPanelButton from "../Buttons/ExpandPanelButton";
import ManagementDocumentsV2 from "../Documentsv2/ManagementDocumentsV2";
import { cx } from "@emotion/css";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import { isTradeDashEmployee } from "../../helpers/helpers";
import { getDocumentLength } from "../Documents/documentHelpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import ProjectDocumentsTabs from "./ProjectDocumentsTabs";

const defaults = {
  documentTab: 0,
};

function ProjectDocumentsV2({
  companyID,
  currentSalesOrder,
  currentPurchaseOrder,
  currentShipment = {},
  user,
  permissionGroups,
  panelWidth,
  handleActivePanel,
}) {
  function getDashboardPersistence(item, id) {
    if (storage) {
      const storageItem = storage[item] || defaults[item];
      const value = !id ? storageItem : storageItem[id] || defaults[item];
      return value;
    }
    return defaults[item];
  }
  const queryParams = useQueryParams();
  const allowed = useIsAllowedFunction();
  const [currentDocuments, setCurrentDocuments] = useState({});
  const [settingsSnapshot, setSettingsSnapshot] = useState({});
  const [storage, setStorage] = useStorage("projectTasks");
  const [selectedDocumentTab, setSelectedDocumentTab] = useState(0);

  useEffect(() => {
    if (queryParams.fileId) {
      const [tab] = queryParams.fileId.split("_");
      let currentTab = 0;
      if (tab === "PO") {
        currentTab = 1;
      }
      if (tab === "SH") {
        currentTab = 2;
      }
      changeDocumentTab(currentTab);
    } else {
      setSelectedDocumentTab(
        getDashboardPersistence("documentTab", currentPurchaseOrder.id) || 0
      );
    }
  }, [queryParams.fileId]);

  useEffect(() => {
    let path = "";
    let remove;
    let currentId = "";
    const poTab = getDashboardPersistence(
      "documentTab",
      currentPurchaseOrder.id
    );
    let needChangeTab = selectedDocumentTab !== poTab;
    let currentTab = needChangeTab ? poTab : selectedDocumentTab;
    if (currentPurchaseOrder.shipmentIds.length === 0 && currentTab === 2) {
      currentTab = 0;
      needChangeTab = true;
    }
    switch (currentTab) {
      case 0:
        path = [
          dbTables.COMPANIES,
          companyID,
          dbTables.SALES_ORDERS,
          currentSalesOrder.id,
          dbTables.SALES_ORDER_DOCUMENTS,
        ];
        currentId = currentSalesOrder.id;
        break;
      case 1:
        path = [
          dbTables.COMPANIES,
          companyID,
          dbTables.PURCHASE_ORDERS,
          currentPurchaseOrder.id,
          dbTables.PURCHASE_ORDER_DOCUMENTS,
        ];
        currentId = currentPurchaseOrder.id;
        break;
      default:
        path = [
          dbTables.COMPANIES,
          companyID,
          dbTables.SHIPMENTS,
          currentShipment.id,
          dbTables.SHIPMENT_DOCUMENTS,
        ];
        currentId = currentShipment.id;
        break;
    }
    if (path.includes(undefined)) {
      console.log("stay");
      return;
    }
    path = path.join("/");

    if (settingsSnapshot.path !== path) {
      if (settingsSnapshot.remove) {
        settingsSnapshot.remove();
        console.log("remove");
      }
      remove = onSnapshot(collection(firestore, path), (snapshot) => {
        const documents = snapshot.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
        }));
        setCurrentDocuments({ [currentId]: documents });
      });
      setSettingsSnapshot({
        remove,
        path,
        currentId,
      });
    }
    if (needChangeTab) {
      console.log("changeTab");
      setTimeout(() => setSelectedDocumentTab(currentTab), 150);
    }
  }, [
    selectedDocumentTab,
    currentSalesOrder.id,
    currentPurchaseOrder.id,
    currentShipment.id,
  ]);

  function changeDocumentTab(tab) {
    setSelectedDocumentTab(tab);
    let documentTab = getDashboardPersistence("documentTab") || {};
    documentTab = {
      ...documentTab,
      [currentPurchaseOrder.id]: tab === 0 ? undefined : tab,
    };
    updatePersistence("documentTab", documentTab);
  }

  function updatePersistence(item, value) {
    setStorage(item, value);
  }

  const currentTabValue = () => {
    let isCorretOrder;
    const canSeeAll = allowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
    );
    switch (selectedDocumentTab) {
      case 0:
        const soDocuments = currentDocuments[currentSalesOrder.id] || [];
        isCorretOrder = currentDocuments[currentSalesOrder.id];
        return {
          isVoid: currentSalesOrder.status === PO_STATUS.VOIDED,
          parentDocumentId: currentSalesOrder.id,
          mainEntity: currentSalesOrder,
          parentCollection: dbTables.SALES_ORDERS,
          dbCollection: dbTables.SALES_ORDER_DOCUMENTS,
          soSize: isCorretOrder
            ? getDocumentLength({
                currentDocuments: soDocuments,
                user,
                canSeeAll,
                permissionGroupsDB: permissionGroups,
              })
            : 0,
          documents: soDocuments.map((salesOrderDocument) => ({
            ...salesOrderDocument,
            scope: documentScope.SALES_ORDER,
          })),
        };
      case 1:
        isCorretOrder = currentDocuments[currentPurchaseOrder.id];
        const poDocuments = currentDocuments[currentPurchaseOrder.id] || [];
        return {
          isVoid: currentPurchaseOrder.status === PO_STATUS.VOIDED,
          mainEntity: currentPurchaseOrder,
          parentDocumentId: currentPurchaseOrder.id,
          parentCollection: dbTables.PURCHASE_ORDERS,
          dbCollection: dbTables.PURCHASE_ORDER_DOCUMENTS,
          poSize: getDocumentLength({
            currentDocuments: poDocuments,
            user,
            canSeeAll,
            permissionGroupsDB: permissionGroups,
          }),
          documents: poDocuments.map((poDocument) => ({
            ...poDocument,
            scope: documentScope.PURCHASE_ORDER,
          })),
        };
      default:
        isCorretOrder = currentDocuments[currentShipment.id];
        const shipmentDocuments = currentDocuments[currentShipment.id] || [];
        return {
          isVoid: currentShipment === PO_STATUS.VOIDED,
          mainEntity: currentShipment,
          parentDocumentId: currentShipment.id,
          parentCollection: dbTables.SHIPMENTS,
          dbCollection: dbTables.SHIPMENT_DOCUMENTS,
          shipmentSize: getDocumentLength({
            currentDocuments: shipmentDocuments,
            user,
            canSeeAll,
            permissionGroupsDB: permissionGroups,
          }),
          documents: shipmentDocuments.map((shipmentDocument) => ({
            ...shipmentDocument,
            scope: documentScope.SHIPMENT,
          })),
        };
    }
  };

  const tabValue = currentTabValue();

  return (
    <React.Fragment>
      <BackdropDashboard backdropType={BACKDROP_TYPE.FOLDER} initTimer={200} />
      <DocumentPanelContainerStyled
        className="documentPanelContainer"
        style={{
          flex: "unset",
          border: `1px solid ${AppConfig.themeColors.dividerColor}`,
          width: `calc(${panelWidth} - 24px)`,
          minWidth: `calc(${panelWidth} - 24px)`,
          maxWidth: `calc(${panelWidth} - 24px)`,
        }}
        id="dashboard-documents"
      >
        <div className="content-card-header">
          <div className="header-container">
            <ExpandPanelButton
              activePanel={panelWidth === "50%"}
              handleActivePanel={handleActivePanel}
            />
            <span className="documents-title">
              {tabValue.isVoid && <VoidLabel />}
              Documents
            </span>
          </div>
          <ProjectDocumentsTabs
            onChange={changeDocumentTab}
            SODocsLength={tabValue.soSize}
            PODocsLength={tabValue.poSize}
            shipmentDocumentsDocsLength={tabValue.shipmentSize}
            value={selectedDocumentTab}
            isShipmentActive={currentShipment.id}
          />
        </div>

        <div
          className={cx(
            "content-card-content",
            `container${tabValue.parentCollection}`
          )}
          style={{ height: "100%", flexDirection: "column" }}
        >
          <div
            className={cx(
              "documentScrollContainer",
              "documentScrollContainer_" + tabValue.parentCollection
            )}
          >
            <ManagementDocumentsV2
              permissionGroupsDB={permissionGroups}
              selectedDocumentTab={selectedDocumentTab}
              isVoided={tabValue.isVoid}
              permissionScope={
                GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
              }
              documents={tabValue.documents}
              dbCollection={tabValue.dbCollection}
              parentCollection={tabValue.parentCollection}
              parentDocumentId={tabValue.parentDocumentId}
              isReadOnly={isTradeDashEmployee(user)}
              mainEntity={tabValue.mainEntity}
            />
          </div>
        </div>
      </DocumentPanelContainerStyled>
    </React.Fragment>
  );
}

export default ProjectDocumentsV2;
