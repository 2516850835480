import React, { useState } from "react";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { SettingScreenBox, SettingsPopover } from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";
import { SettingsActiveIcon } from "../../helpers/iconSvgPath";
import { Button } from "@mui/material";
import { cx } from "@emotion/css";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { SortableItem } from "./SortableItem";

export default function MultipleSelect({
  items,
  onChange,
  onClose = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  function handleDragEnd(event) {
    const { active, over } = event;
    if (!over || active.id === over.id) return;
    const sourceIndex = items.findIndex((item) => item.id === active.id);
    const destinationIndex = items.findIndex((item) => item.id === over.id);
    let itemsCpy = [...items];
    const [sourceElement] = itemsCpy.splice(sourceIndex, 1);
    itemsCpy.splice(destinationIndex, 0, sourceElement);
    const updatedItems = itemsCpy.map((item, index) => ({ ...item, index }));
    onChange(updatedItems);
  }

  const menuItems = items
    .sort(sortObjectsBy("index", false))
    .filter((item) => item.filter);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <SettingScreenBox
      id="list-view-table-content-selectcolumns"
      className={"multiSelectContainer"}
      onClick={handleClick}
    >
      <TooltipTD label="Column settings" style={{ display: "flex" }}>
        <Button color="inherit" style={{ minWidth: 36 }}>
          <SettingsActiveIcon svgClass={cx("gearClose", { gearOpen: open })} />
        </Button>
      </TooltipTD>
      {open && (
        <SettingsPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          classes={{ paper: "paperPopover" }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DndContext
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            <SortableContext
              items={items.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              {menuItems.map((item) => (
                <SortableItem
                  key={item.id}
                  item={item}
                  items={items}
                  onChange={onChange}
                />
              ))}
            </SortableContext>
          </DndContext>
        </SettingsPopover>
      )}
    </SettingScreenBox>
  );
}
