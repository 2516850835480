import React, { useState, useEffect } from "react";
import ProgressStage from "./ProgressStage";
import { buildHeaderCells } from "../../components/Managers/TableHeaderProvider";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { addFavorite, removeFavorite } from "../../helpers/favoritesHelper";
import { useUser } from "../../hooks/user";
import FooterTable from "../../components/Tables/FooterTable";
import { footerOrdersByPhaseShipmentsWithinSO } from "../../components/Managers/FooterTableManager";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import { TableCell } from "@mui/material";
import { orderByPhaseShipmentGroupBySOSetup } from "../../helpers/screenHeaderSetup";
import {
  generalPermissions,
  headerColumns,
  propertyType,
} from "../../helpers/constants";
import ShipmentItem from "./ShipmentItem";
import { useFavorites, useFavoritesList } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import {
  getDiamondStatus,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import CustomTableRowSelector from "../SalesOrdersScreen/CustomTableRowSelector";
import { colors } from "../../assets/jss/variables";
import { SHIPMENT_VIEW_FIELDS } from "../../helpers/shipment";

function FilterTableShipment({
  shipmentList,
  headerStages,
  filtersTable,
  onUpdateFilters,
  tags,
  onUpdateItem,
  isAllowed,
  filters = { factory: [] },
  salesOrder,
  expanded,
}) {
  const user = useUser();
  const [headerCells, setHeaderCells] = useState([]);
  const filterTag = filters.tag || [];
  const favorites = useFavoritesList({ dbTable: dbTables.SHIPMENT_FAVORITES });
  const favoritesDB = useFavorites({ dbTable: dbTables.SHIPMENT_FAVORITES });

  useEffect(() => {
    let userHeaderCell = orderByPhaseShipmentGroupBySOSetup || [];
    let newHeaderCells = buildHeaderCells({
      userHeaderSetup: userHeaderCell,
    });
    let cpyHeaderCell = newHeaderCells.map((header) => {
      const staticHeader = orderByPhaseShipmentGroupBySOSetup.find(
        (h) => h.field === header.field
      );
      if (
        header.type === propertyType.CURRENCY &&
        !isAllowed(generalPermissions.SHIPMENT_AMOUNT)
      ) {
        return {
          ...header,
          resizable: false,
          styles: {
            ...header.styles,
            width: staticHeader.width,
            display: "none",
          },
        };
      }
      return {
        ...header,
        resizable: false,
        styles: { ...header.styles, width: staticHeader.width },
      };
    });
    setHeaderCells(cpyHeaderCell);
  }, [isAllowed]);

  function filterTableShipmentSelector({ header, index, itemShipment }) {
    const { field, enabled } = header;
    const purchaseOrderId = itemShipment.purchaseOrderIds[0];
    switch (field) {
      case headerColumns.EXPAND:
        return enabled && <TableCell className="emptyTableCell"></TableCell>;
      case SHIPMENT_VIEW_FIELDS.FAVORITE:
        return (
          enabled && (
            <TableCell key={index} center classes={{ root: "favorite" }}>
              {getStarFavorite({
                favorites,
                itemId: itemShipment.id,
                addFavorite: () =>
                  addFavorite({
                    dbTable: dbTables.SHIPMENT_FAVORITES,
                    favoritesDB,
                    id: itemShipment.id,
                    user,
                  }),
                removeFavorite: () =>
                  removeFavorite({ favoritesDB, id: itemShipment.id }),
              })}
            </TableCell>
          )
        );

      case SHIPMENT_VIEW_FIELDS.DIAMOND:
        return (
          enabled && (
            <TableCell key={index} className="diamond">
              {getDiamondStatus({
                diamondStatus: itemShipment.diamondStatus,
                itemStatus: itemShipment.status,
              })}
            </TableCell>
          )
        );

      case SHIPMENT_VIEW_FIELDS.NUMBER: {
        return (
          enabled && (
            <TableCell key={index} className="number">
              <ShipmentItem
                tags={tags}
                itemShipment={itemShipment}
                filterTag={filterTag}
                isAllowed={isAllowed}
                user={user}
                onUpdateItem={onUpdateItem}
                salesOrder={salesOrder}
                purchaseOrderId={purchaseOrderId}
              />
            </TableCell>
          )
        );
      }

      case SHIPMENT_VIEW_FIELDS.FINAL_DESTINATION:
        return (
          enabled && (
            <TableCell key={index} className="finalDestination">
              <TooltipTD
                label={itemShipment.finalDestination}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {itemShipment.finalDestination}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.BLANK:
        return enabled && <TableCell key={index} className="emptyTableCell" />;

      case headerColumns.PROPOSAL_QUOTE:
        return (
          enabled && (
            <TableCell key={index} className="proposalTasks">
              <ProgressStage
                tasks={itemShipment.proposalTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.PRE_PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="preProductionTasks">
              <ProgressStage
                tasks={itemShipment.preProductionTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="productionTasks">
              <ProgressStage
                tasks={itemShipment.productionTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.BOOKING_TRANSIT:
        return (
          enabled && (
            <TableCell key={index} className="bookingTransitTasks">
              <ProgressStage
                tasks={itemShipment.bookingTransitTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.PAYMENT_BILLING:
        return (
          enabled && (
            <TableCell key={index} className="paymentBillingTasks">
              <ProgressStage
                tasks={itemShipment.paymentBillingTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell key={index} />;

      default:
        return (
          <CustomTableRowSelector
            index={itemShipment.id + header.field}
            header={header}
            item={itemShipment}
          />
        );
    }
  }

  return (
    <MainTableRefactor
      className={cx("shipmentGroupBySO", { showTable: expanded })}
      headCells={headerCells.map((cell) => {
        if (cell.label === headerColumns.PROPOSAL_QUOTE) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.proposalQuoteMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRE_PRODUCTION) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.preProductionMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRODUCTION) {
          return {
            ...cell,
            styles: { ...cell.styles, width: headerStages.productionMinWidh },
          };
        } else if (cell.label === headerColumns.BOOKING_TRANSIT) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.bookingTransitMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PAYMENT_BILLING) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.paymentBillingMinWidh,
            },
          };
        } else {
          return cell;
        }
      })}
      stylesTable={{
        boxShadow: "0px 2px 14px 0px rgba(27,64,100,0.2)",
        zIndex: 1,
      }}
      backgroundHeaderColor={colors.shipmentBadge}
      bodyRows={shipmentList}
      filters={filtersTable}
      onUpdateFilters={onUpdateFilters}
      footer={
        <FooterTable
          footer={footerOrdersByPhaseShipmentsWithinSO({
            headers: headerCells,
            list: shipmentList,
            isAllowed,
          })}
          headerCells={headerCells}
        />
      }
      renderRow={(itemShipment) => (
        <MainRowStyled
          className={cx(mainRowClasses.SubTableRow, mainRowClasses.hoverEffect)}
          key={
            itemShipment.purchaseOrderId
              ? itemShipment.id + itemShipment.purchaseOrderId
              : itemShipment.id + itemShipment.salesOrderId
          }
        >
          {headerCells.map((header) => {
            if (!!header.type) {
              return (
                <CustomTableRowSelector
                  index={itemShipment.id + header.field}
                  header={header}
                  item={itemShipment}
                />
              );
            } else {
              return filterTableShipmentSelector({
                header,
                index: itemShipment.id + header.label,
                itemShipment,
              });
            }
          })}
          <TableCell className="LAST_100_WIDTH_COLUMN" />
          <TableCell className="LAST_100_WIDTH_COLUMN" />
        </MainRowStyled>
      )}
      key={"shipment-table"}
      sortArrowsColor="#FFF"
      displayLastBlankColumn={false}
    />
  );
}

export default FilterTableShipment;
