import React, { useState } from "react";
import { getCompanyUserDisplayName } from "../../../helpers/helpers";
import { getUserAvatar } from "../../../helpers/users";
import { deleteDoc, updateDoc } from "firebase/firestore";
import TooltipTD from "../../Tooltip/TooltipTD";
import { DeleteIcon, EditIcon } from "../../../helpers/iconSvgPath";
import { GENERAL_PERMISSION_VALUE } from "../../../helpers/constants";
import Tag from "../../../api/model/tag";
import CreateEditLabel from "../../Modal/CreateEditLabel";
import { now } from "lodash";
import { cx } from "@emotion/css";
import { getDateFormat } from "../companyTabHelper";
import AttentionModal from "../../Modal/AttentionModal";

function TagsTableRow({
  item = {},
  user,
  isAllowed = () => {},
  companyUsers = [],
  tags,
  isReadOnly = false,
}) {
  const [newTagData, setNewTagData] = useState({
    open: false,
    currentTag: { ...new Tag({}) },
    isUpdating: false,
  });
  const [modalAttention, setModalAttention] = useState({
    open: false,
    description: "",
  });
  const onClose = () =>
    setNewTagData({
      open: false,
      currentTag: { ...new Tag({}) },
      isUpdating: false,
    });

  function handleDeleteModal(item) {
    console.log("Deleting tag", item);
    setModalAttention({ open: false, description: "" });
    deleteDoc(item.ref);
  }

  return (
    <div className="rowTable">
      {modalAttention.open && (
        <AttentionModal
          isOpen={modalAttention.open}
          description={modalAttention.description}
          onClose={() => setModalAttention({ open: false, description: "" })}
          onClick={() => handleDeleteModal(item)}
          confirmationText="Proceed"
          cancelText="Cancel"
          cancellable={true}
          title={"Attention"}
        />
      )}
      {newTagData.open && (
        <CreateEditLabel
          openModal={newTagData.open}
          handleClose={onClose}
          currentTag={newTagData.currentTag}
          isUpdating={newTagData.isUpdating}
          onSubmit={(name, description) => {
            updateDoc(item.ref, {
              name: name,
              description: description || "",
              updatedBy: user.id,
              updatedDate: now(),
            });
            onClose();
          }}
          tags={tags}
        />
      )}
      <div className={cx("cellTable", "name")}>
        <span className="labelContainer">
          <span className="spanText">{item.name}</span>
        </span>
      </div>
      <TooltipTD
        className={cx("cellTable", "name")}
        label={item.description || ""}
      >
        <span className="labelContainer">
          <span className="spanText">{item.description}</span>
        </span>
      </TooltipTD>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getUserAvatar({
          user: companyUsers.find(
            (companyUser) => companyUser.id === item.createdBy
          ),
          avatarClassName: "avatarTable",
        })}
        <span style={{ position: "relative", paddingLeft: 5 }}>
          {getCompanyUserDisplayName(companyUsers, item.createdBy)}
        </span>
      </div>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getDateFormat(item.createdDate)}
      </div>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getUserAvatar({
          user: companyUsers.find(
            (companyUser) => companyUser.id === item.updatedBy
          ),
          avatarClassName: "avatarTable",
        })}
        <span style={{ position: "relative", paddingLeft: 5 }}>
          {getCompanyUserDisplayName(companyUsers, item.updatedBy)}
        </span>
      </div>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getDateFormat(item.updatedDate)}
      </div>
      <div className={cx("cellTable", "actionButtonsContainer")}>
        {!isReadOnly && isAllowed(GENERAL_PERMISSION_VALUE.DELETE_TAGS) && (
          <TooltipTD
            label="Delete Label"
            style={{ display: "flex", alignItems: "center" }}
          >
            <DeleteIcon
              onClick={(ev) => {
                setModalAttention({
                  open: true,
                  description: (
                    <React.Fragment>
                      Are you sure you want to delete <i>{item.name}</i> label?{" "}
                      <br /> Labels already applied will be deleted permanently{" "}
                    </React.Fragment>
                  ),
                });
                ev.preventDefault();
                ev.stopPropagation();
              }}
            />
          </TooltipTD>
        )}
        {!isReadOnly && isAllowed(GENERAL_PERMISSION_VALUE.RENAME_TAGS) && (
          <TooltipTD
            label="Edit"
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
            tooltipClick={() => {
              setNewTagData({
                open: true,
                currentTag: item,
                isUpdating: true,
              });
            }}
          >
            <EditIcon />
          </TooltipTD>
        )}
      </div>
    </div>
  );
}

export default TagsTableRow;
