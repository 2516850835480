import React, { useCallback, useRef, useState } from "react";
import {
  getTagsLabel,
  isTradeDashEmployee,
  getProgressValue,
  getActivitiesNotes,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import { getShipmentBadge } from "../../helpers/shipments";
import { NavLink } from "react-router-dom";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  GENERAL_PERMISSION_VALUE,
  SHIPMENT_STATUS,
  BACKDROP_TYPE,
  PO_DIAMOND_STATUS,
  taskStatus,
  screenPath,
  ORDER_DASHBOARD_ACTUAL_VIEWS,
} from "../../helpers/constants";
import TagsModal from "../../components/Modal/TagsModal";
import userTypes from "../../api/types/userTypes";
import { dbTables } from "../../api/types/dbTables";
import { useDispatch } from "react-redux";
import {
  setBackdropPanelSection,
  setGlobalNoteDate,
} from "../../actions/DataActions";

import { getTagStatus, orderType } from "../../helpers/salesOrder";
import {
  checkSpecificPermissions,
  verifyPermission,
} from "../../hooks/permissions";
import { useFactories } from "../../hooks/factories";
import SalesOrdersItem from "./SalesOrderItem";
import POsDetailItem from "../SalesOrdersScreen/PODetailsItems";
import { TableCell } from "@mui/material";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import {
  getDiamondStatus,
  getDisclouserArrow,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { useUserMentions } from "../../hooks/user";
import TaskListPopover from "../../components/DatePicker/TaskListPopover";
import CustomTableRowSelector from "../SalesOrdersScreen/CustomTableRowSelector";
import { SHIPMENT_VIEW_FIELDS } from "../../helpers/shipment";

function ShipmentsTableRow({
  item,
  favorites,
  user,
  isAllowed = () => {},
  customers,
  headers = [],
  tags,
  onUpdateItem,
  filters = { factory: [] },
  onChangeSetupStorage = () => {},
  shipmentScreenSetup = {},
  favoritesDB = [],
  nonLookUpfilters = {},
}) {
  const filterTag = filters.tag || [];
  const isVoided = item.status === SHIPMENT_STATUS.VOIDED;
  const customer = customers.find((cus) => cus.id === item.customerId);
  const factories = useFactories();
  const dispatch = useDispatch();
  const [taskPopoverData, setTaskPopoverData] = useState({
    open: false,
    diamondStatus: "",
  });
  const diamondRef = useRef(null);
  const shipmentMentions = useUserMentions(dbTables.SHIPMENTS);
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };

  const setNoteDate = useCallback(({ type, creationDate, noteId }) =>
    setGlobalNoteDate({
      type,
      creationDate,
      noteId,
    })(dispatch)
  );

  function handleChangeShipmentSetup() {
    const shipmentSetup = shipmentScreenSetup[item.id] || {};
    if (shipmentSetup.POs || shipmentSetup.SOs) {
      onChangeSetupStorage({ item, poValue: false, soValue: false });
    } else {
      onChangeSetupStorage({ item, poValue: true, soValue: true });
    }
  }

  function shipmentTableRowSelector({ header = {}, index }) {
    const { field, enabled } = header;
    const purchaseOrders = item.purchaseOrders || [];
    const filterPurchaseOrders = purchaseOrders.filter((po) =>
      verifyPermission({
        user: user,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    );
    switch (field) {
      case SHIPMENT_VIEW_FIELDS.FAVORITE:
        return (
          <TableCell key={index} className="favorite">
            {getDisclouserArrow({
              currentSetup: shipmentScreenSetup[item.id],
              handleChangeSetup: handleChangeShipmentSetup,
              purchaseOrders: item.salesOrders,
              salesOrders: filterPurchaseOrders,
              type: orderType.SHIPMENT,
            })}
          </TableCell>
        );

      case SHIPMENT_VIEW_FIELDS.DIAMOND:
        return (
          <TableCell key={index} className={"diamond"}>
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
              }}
              ref={diamondRef}
            >
              {getStarFavorite({
                favorites: favorites,
                itemId: item.id,
                removeFavorite: () =>
                  removeFavorite({
                    favoritesDB,
                    id: item.id,
                  }),
                addFavorite: () =>
                  addFavorite({
                    id: item.id,
                    user,
                    favoritesDB,
                    dbTable: dbTables.SHIPMENT_FAVORITES,
                  }),
              })}
              {getDiamondStatus({
                label: (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {item.diamondStatus === PO_DIAMOND_STATUS.LATE
                      ? "There are one or more late task"
                      : "There are one or more tasks due today or tomorrow"}
                    <br />
                    <span style={{ textAlign: "center" }}>
                      click to see more
                    </span>
                  </div>
                ),
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
                style: { cursor: "pointer" },
                onClick: () => {
                  setTaskPopoverData({
                    open: true,
                    diamondStatus:
                      item.diamondStatus === PO_DIAMOND_STATUS.LATE
                        ? taskStatus.LATE
                        : taskStatus.NEAR_DUE,
                  });
                },
              })}
            </div>
          </TableCell>
        );

      case SHIPMENT_VIEW_FIELDS.PROGRESS:
        return isVoided ? (
          <TableCell key={index} />
        ) : (
          <TableCell key={index} className="circularProgress">
            <CircularStaticProgress
              label={`${item.completedTasks}/${item.totalTasks} Tasks`}
              value={+getProgressValue(item)}
              thickness={16}
              style={{
                transform: "rotate(270deg)",
                color: "#39F",
              }}
              size={18}
            />
          </TableCell>
        );

      case SHIPMENT_VIEW_FIELDS.MENTIONS:
        const [poItem = {}] = filterPurchaseOrders;
        const [soItem = {}] = item.salesOrders;
        return (
          <TableCell key={field + index} className={"mention"}>
            {shipmentMentions[item.id] > 0 && (
              <TooltipTD label={"Shipment notes only"}>
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: soItem.id,
                    purchaseOrderId: poItem.id,
                    shipmentId: item.id,
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                  })}
                  onClick={async () => {
                    const shipmentActivity = await getActivitiesNotes({
                      companyId: item.companyId,
                      orderId: item.id,
                      userId: user.id,
                      orderTable: dbTables.SHIPMENTS,
                      dispatch: dispatch,
                    });
                    if (shipmentActivity) {
                      setNoteDate({
                        type: dbTables.SHIPMENTS,
                        creationDate: shipmentActivity.creationDate,
                        noteId: shipmentActivity.id,
                      });
                    }
                    setBackdrop({
                      type: BACKDROP_TYPE.BADGE_NUMBER,
                      id: BACKDROP_TYPE.BADGE_NUMBER,
                    });
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>
                    {shipmentMentions[item.id]}
                  </span>
                </NavLink>
              </TooltipTD>
            )}
          </TableCell>
        );

      case SHIPMENT_VIEW_FIELDS.NUMBER:
        const [pursaseOrderItem = {}] = filterPurchaseOrders;
        const [salesOrderItem = {}] = item.salesOrders || [];
        const shipmentTags = item.tags || [];
        return (
          enabled && (
            <TableCell key={index} className={"number"}>
              <div className="number-table-container">
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={item.field}
                    label={getTagsLabel(tags, shipmentTags).tagsString}
                    tags={getTagsLabel(tags, shipmentTags).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.SHIPMENTS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: shipmentTags,
                      currentTags: filterTag,
                    })}
                  />
                )}
                <NavLink
                  to={
                    isVoided
                      ? false
                      : getDashboardSearchPath({
                          salesOrderId: salesOrderItem.id,
                          purchaseOrderId: pursaseOrderItem.id,
                          shipmentId: item.id,
                          section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                        })
                  }
                  style={{
                    overflow: "visible",
                    marginLeft: 0,
                    textDecoration: "none",
                  }}
                  id="list-view-content-shipment"
                >
                  {getShipmentBadge({
                    shipment: item,
                    classes: shipmentBadgeClass.medium,
                  })}
                </NavLink>
                <ThreePanelButton
                  soId={salesOrderItem.id}
                  poId={pursaseOrderItem.id}
                  shipmentId={item.id}
                  tab={2}
                />
              </div>
            </TableCell>
          )
        );

      case SHIPMENT_VIEW_FIELDS.SO:
        return (
          enabled && (
            <TableCell key={index} className={"number"}>
              <SalesOrdersItem
                item={{ ...item, purchaseOrders: filterPurchaseOrders }}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={shipmentScreenSetup || {}}
                classContainer="SOs-container"
                onChangeSalesOrderSetup={() => {
                  const itemRow = shipmentScreenSetup[item.id] || {};
                  onChangeSetupStorage({
                    item,
                    soValue: !itemRow.SOs,
                  });
                }}
                query={nonLookUpfilters.query}
                iconStyle={{ paddingLeft: 34 }}
              />
            </TableCell>
          )
        );

      case SHIPMENT_VIEW_FIELDS.PO:
        return (
          enabled && (
            <TableCell key={index} className="poNumber">
              <POsDetailItem
                item={item}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={shipmentScreenSetup || {}}
                factories={factories}
                onChangeSalesOrderSetup={() => {
                  const itemRow = shipmentScreenSetup[item.id] || {};
                  onChangeSetupStorage({
                    item,
                    poValue: !itemRow.POs,
                  });
                }}
                query={nonLookUpfilters.query}
                
              />
            </TableCell>
          )
        );

      case SHIPMENT_VIEW_FIELDS.CUSTOMER:
        return (
          enabled &&
          customer && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={customer.name} className="customized-tooltip">
                <NavLink
                  to={
                    !(
                      user.role === userTypes.SUPER_ADMIN ||
                      user.role === userTypes.TRADEDASH_EMPLOYEE ||
                      checkSpecificPermissions({
                        userPermissions: user.permissions,
                        permissionToCheck: [
                          item.customerId,
                          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                        ],
                      })
                    )
                      ? "#"
                      : `/app/${screenPath.CUSTOMER}/${item.customerId}`
                  }
                  className="navLink"
                  id="list-view-content-customer"
                >
                  <span className="span-description">{customer.name}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      default:
        return (
          <CustomTableRowSelector
            index={item.id + header.field}
            header={header}
            item={item}
          />
        );
    }
  }
  return (
    item && (
      <MainRowStyled
        key={item.field + "MainRowStyled"}
        className={cx(
          mainRowClasses.ShipmentTableRow,
          mainRowClasses.hoverEffect
        )}
      >
        {taskPopoverData.open && (
          <TaskListPopover
            item={item}
            anchorEl={diamondRef}
            diamondStatus={taskPopoverData.diamondStatus}
            entityDB={dbTables.SHIPMENTS}
            entityId={item.id}
            onClose={() => {
              setTaskPopoverData({
                open: false,
                diamondStatus: "",
              });
            }}
          />
        )}
        {headers.map((header) => {
          if (!!header.type) {
            return (
              <CustomTableRowSelector
                index={item.id + header.field}
                header={header}
                item={item}
              />
            );
          } else {
            return shipmentTableRowSelector({
              header,
              index: item.id + header.label,
            });
          }
        })}
        <TableCell className="LAST_BLANK_COLUMN" />
        <TableCell className="LAST_100_WIDTH_COLUMN" />
      </MainRowStyled>
    )
  );
}

export default ShipmentsTableRow;
