import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import DocumentTemplate from "../DocumentsTemplatev2/DocumentTemplate";
import { dbTables } from "../../api/types/dbTables";
import { getNestedCollectionFromFirestoreTable } from "../../actions/DataActions";
import { pathToString } from "../../helpers/helpers";
import LegendDocumentOptions from "../DocumentsTemplatev2/LegendDocumentOptions";
import {
  usePODocumentTemplate,
  useShipmentDocumentTemplate,
  useSODocumentTemplate,
  useUser,
} from "../../hooks/user";
import userTypes from "../../api/types/userTypes";
import { useParams } from "react-router-dom";
import { DocumentPlaceholderStyled } from "./styles";
import { colors } from "../../assets/jss/variables";
import { documentScope } from "../../helpers/constants";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function Documents() {
  const user = useUser();
  const isCompanyUser = user.role !== userTypes.TRADEDASH_EMPLOYEE;
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const listenSalesOrderDocuments = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.SALES_ORDER_DOCUMENTS_TEMPLATE,
      ]),
    })(dispatch)
  );
  const listenPurchaseOrderDocuments = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.PURCHASE_ORDER_DOCUMENTS_TEMPLATE,
      ]),
    })(dispatch)
  );
  const listenShipmentDocuments = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.SHIPMENT_DOCUMENTS_TEMPLATE,
      ]),
    })(dispatch)
  );

  const salesOrderDocuments = useSODocumentTemplate(companyId);
  const purchaseOrderDocuments = usePODocumentTemplate(companyId);
  const shipmentDocuments = useShipmentDocumentTemplate(companyId);

  if (salesOrderDocuments) {
    salesOrderDocuments.sort(sortObjectsBy("listIndex"));
  }
  if (purchaseOrderDocuments) {
    purchaseOrderDocuments.sort(sortObjectsBy("listIndex"));
  }

  useEffect(() => {
    listenSalesOrderDocuments();
    listenPurchaseOrderDocuments();
    listenShipmentDocuments();
  }, [companyId]);

  return (
    <DocumentPlaceholderStyled
      className="documentPlaceholderContainer"
      style={
        isCompanyUser
          ? { maxWidth: "calc(100vw - 303px)", zIndex: 0 }
          : { maxWidth: "calc(100vw - 630px)", zIndex: 0 }
      }
    >
      <LegendDocumentOptions />
      <div className="document-template-container">
        <DocumentTemplate
          title="companytabs.documents.salesorders.title"
          companyId={companyId}
          documents={salesOrderDocuments.map((soDocument) => ({
            ...soDocument,
            scope: documentScope.SALES_ORDER,
          }))}
          dbTable={dbTables.SALES_ORDER_DOCUMENTS_TEMPLATE}
          style={{
            background: colors.salesOrderBackgroundColor,
            border: `${colors.salesOrderBorderColor} 2px solid`,
            width: "33%",
          }}
        />
        <DocumentTemplate
          title="companytabs.documents.purchasesorders.title"
          companyId={companyId}
          documents={purchaseOrderDocuments.map((poDocument) => ({
            ...poDocument,
            scope: documentScope.PURCHASE_ORDER,
          }))}
          dbTable={dbTables.PURCHASE_ORDER_DOCUMENTS_TEMPLATE}
          style={{
            margin: "0px 16px",
            background: colors.purchaseOrderBackgroundColor,
            border: `${colors.purchaseOrderBorderColor} 2px solid`,
            width: "33%",
          }}
        />
        <DocumentTemplate
          title="companytabs.documents.shipments.title"
          companyId={companyId}
          documents={shipmentDocuments.map((shipmentDocument) => ({
            ...shipmentDocument,
            scope: documentScope.SHIPMENT,
          }))}
          dbTable={dbTables.SHIPMENT_DOCUMENTS_TEMPLATE}
          style={{
            background: colors.shipmentBadge,
            border: `${colors.shipmentBorderColor} 2px solid`,
            width: "33%",
          }}
        />
      </div>
    </DocumentPlaceholderStyled>
  );
}

export default Documents;
