import moment from "moment";
import userTypes from "../api/types/userTypes";
import {
  FILTER_NOTIFICATION,
  GENERAL_PERMISSION_VALUE,
  propertyType,
  SHIPMENT_STATUS,
} from "../helpers/constants";
import { getProgressPercentage, stringLowerCase } from "../helpers/helpers";
import { dateFilterOptionLabel } from "../helpers/notifications";
import { sortObjectsBy } from "../helpers/sortingHelper";
import numeral from "numeral";
import { getListValuesFromArray } from "../helpers/purchaseOrderList";

const formatDate = (date, format = "M/D/YY") =>
  date ? moment(date).format(format) : "";
const formatCurrency = (value, isAllowed) =>
  isAllowed ? numeral(value).format("0,0.00") : "";

export const validateCustomerData = ({ customers, selectedCustomer }) => {
  let isValidated = true;
  let message = "";

  const normalizeName = (name) =>
    name.toLowerCase().replace(/\s\s+/g, " ").trim();

  const parsedName = normalizeName(selectedCustomer.name);

  const isDuplicate = customers.some(
    (customer) =>
      customer.id !== selectedCustomer.id &&
      normalizeName(customer.name) === parsedName
  );

  if (isDuplicate) {
    isValidated = false;
    message = "components.customers.customermodal.customsnackbar.alreadyexists";
  }

  if (!/[a-zA-Z]/.test(parsedName)) {
    isValidated = false;
    message =
      "components.companytabs.factorymodal.customsnackbar.message.isblank";
  }

  if (parsedName.length > 50) {
    isValidated = false;
    message =
      "components.companytabs.factorymodal.customsnackbar.message.tooLong";
  }

  return { isValidated, message, name: parsedName };
};

export const getFilterListPicker = ({
  listToSearch,
  query,
  filterBy,
  customerId,
}) => {
  const filterList = listToSearch.filter((itemList) => {
    const parseQuery = stringLowerCase(query);
    const number = stringLowerCase(`${itemList.number}`);
    const destination = stringLowerCase(itemList.finalDestination);
    const customer = stringLowerCase(itemList.customer);

    const filter =
      !filterBy.includes(itemList.id) &&
      itemList.customerId === customerId &&
      itemList.status !== SHIPMENT_STATUS.VOIDED;
    const filterByQuery = query
      ? number.includes(parseQuery) ||
        destination.includes(parseQuery) ||
        customer.includes(parseQuery)
      : true;
    return filterByQuery && filter;
  });
  return filterList;
};

export const getNavPickerFilter = ({
  list,
  searchBoxSort,
  searchBox,
  search,
}) => {
  const { key = "number", asc = false } = searchBoxSort;
  return list.sort(sortObjectsBy(key, asc)).filter((order) => {
    if (!searchBox) {
      return true;
    }
    const parseSearch = stringLowerCase(search);
    const subtitles = order.subTitle || [];
    return (
      stringLowerCase(order.number || order.name).includes(parseSearch) ||
      subtitles.some((subtitle) =>
        stringLowerCase(subtitle).includes(parseSearch)
      )
    );
  });
};

export const verifyUserPickerPermision = ({
  companyUser,
  permissionToVerify,
  isCustomer,
}) => {
  const globalPermission = isCustomer
    ? GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS
    : GENERAL_PERMISSION_VALUE.ALL_VENDORS;
  const userPermissions = companyUser.permissions || {};
  if (companyUser.role === userTypes.SUPER_ADMIN) {
    return false;
  } else if (
    !userPermissions[permissionToVerify] &&
    !userPermissions[globalPermission]
  ) {
    return true;
  } else return false;
};

export const getNotificationsStorage = ({ storageNotifications = {} }) => {
  const {
    activeTab = FILTER_NOTIFICATION.ALL,
    storageFilterUnread = false,
    assignedTo = [],
    customer = [],
    factory = [],
    date = "",
    filterDateOption = dateFilterOptionLabel.CHOOSE_A_DATE_RANGE,
    dateRange = { start: null, end: null },
    searchQuery = "",
  } = storageNotifications;

  return {
    storageActiveTab: activeTab,
    storageFilterUnread,
    storageAssignedTo: assignedTo,
    storageCustomer: customer,
    storageFactory: factory,
    storageDate: date,
    storageFilterDateOption: filterDateOption,
    storageDateRange: dateRange,
    storageSearchQuery: searchQuery,
  };
};

export const filterNotifications = ({
  currentNotifications = [],
  filters = {},
}) => {
  if (currentNotifications.length === 0) {
    return [];
  }
  let currentNotificationsCpy = [...currentNotifications];
  const { customer = [], factory = [], assignedTo = [] } = filters;
  currentNotificationsCpy = currentNotificationsCpy.filter((notification) => {
    let result = true;
    if (customer.length > 0) {
      result = result && customer.includes(notification.customerId);
    }
    if (factory.length > 0) {
      result = result && factory.includes(notification.factoryId);
    }
    if (assignedTo.length > 0) {
      result =
        result &&
        (assignedTo.includes(notification.createdBy) ||
          assignedTo.includes(notification.userId));
    }

    return result;
  });
  return currentNotificationsCpy;
};
const getItemValue = ({ item, type, field, isAllowed }) => {
  switch (type) {
    case propertyType.DATE:
      return formatDate(item[field]);
    case propertyType.DATE_TIME:
      return formatDate(item[field], "M/D/YY hh:mm A");

    case propertyType.CURRENCY:
      return formatCurrency(item[field], isAllowed);

    default:
      return item[field] || "";
  }
};

export const getCSVBodySO = ({
  salesOrders = [],
  favorites,
  isAllowed,
  tags,
  headerCells,
}) => {
  
  return salesOrders.map((item) => {
    const baseRow = headerCells.reduce((row, header) => {
      const value = getItemValue({
        field: header.field,
        isAllowed,
        item,
        type: header.type,
      });

      return { ...row, [header.key || header.label]: value };
    }, {});

    const customValues = {
      favorite: favorites.includes(item.id) ? "YES" : "NO",
      status: item.late ? "Red" : item.nearDue ? "Orange" : "",
      progress: getProgressPercentage(item),
      mentions: item.totalMentions,
      SONumber: item.number,
      pos: item.purchaseOrders.map((po) => po.number).join(", "),
      shipments: item.shipments.map((shipment) => shipment.number).join(", "),
      customerName: item.customerName,
      CBM: formatCurrency(item.CBM, true),
      factoryName: item.factoryName,
      salesOrderLabels: getListValuesFromArray({
        arr: item.tags || [],
        list: tags || [],
        field: "name",
      }),
    };
    return { ...baseRow, ...customValues };
  });
};

export const getCSVBodyPO = ({
  purchaseOrders = [],
  favorites,
  tags,
  isAllowed,
  headerCells,
}) => {
  return purchaseOrders.map((item) => {
    const baseRow = headerCells.reduce((row, header) => {
      const value = getItemValue({
        field: header.field,
        isAllowed,
        item,
        type: header.type,
      });

      return { ...row, [header.key || header.label]: value };
    }, {});

    const customValues = {
      favorite: favorites.includes(item.id) ? "YES" : "NO",
      status: item.late ? "Red" : item.nearDue ? "Orange" : "",
      progress: getProgressPercentage(item),
      CBM: formatCurrency(item.CBM, true),
      mentions: item.totalMentions,
      SONumber: item.SONumber,
      shipments: item.shipments.map((shipment) => shipment.number).join(", "),
      customerName: item.customerName,
      factoryName: item.factoryName,
      poLabels: getListValuesFromArray({
        arr: item.tags || [],
        list: tags || [],
        field: "name",
      }),
    };
    return { ...baseRow, ...customValues };
  });
};

export const getCSVBodyShipments = ({
  shipments = [],
  favorites,
  tags,
  isAllowed,
  headerCells,
}) => {
  return shipments.map((item) => {
    const baseRow = headerCells.reduce((row, header) => {
      const value = getItemValue({
        field: header.field,
        isAllowed,
        item,
        type: header.type,
      });

      return { ...row, [header.key || header.label]: value };
    }, {});

    const customValues = {
      favorite: favorites.includes(item.id) ? "YES" : "NO",
      status: item.late ? "Red" : item.nearDue ? "Orange" : "",
      progress: getProgressPercentage(item),
      CBM: formatCurrency(item.CBM, true),
      salesOrder: item.salesOrders.map((so) => so.number).join(", "),
      PONumber: item.purchaseOrders.map((po) => po.number).join(", "),
      customerName: item.customerName,
      shipmentLabels: getListValuesFromArray({
        arr: item.tags || [],
        list: tags || [],
        field: "name",
      }),
    };
    return { ...baseRow, ...customValues };
  });
};
