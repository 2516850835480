import {
  formatCash,
  formatWithComma,
  getCustomerName,
  getShipmentDestination,
} from "./helpers";
import {
  COMPANY_PERMISSION_VALUE,
  PREFIX_ITEM_TABLE,
  generalPermissions,
  itemTableReference,
} from "./constants";
import {
  getCartons,
  getExtendedCostPrice,
  getTotalCBM,
  getTotalWeight,
  parseNumberValue,
} from "./orderDashboardRefactored";
import { colors } from "../assets/jss/variables";
import { getEntityByField } from "../components/PurchaseOrderDashboard/dashboardHelpers";

export const SOSKUheader = [
  {
    id: 0,
    key: "itemNumber",
    label: "skuTable.itemNumber",
    canBeSort: true,
  },
  {
    id: 1,
    key: "image",
    label: "skuTable.image",
    canBeSort: false,
    checkIcon: true,
  },
  {
    id: 2,
    key: "description",
    label: "skuTable.description",
    canBeSort: true,
    showToolTip: true,
    permissionStyle: { gridColumn: "3 span" },
  },
  {
    id: 3,
    key: "salesOrderQuantity",
    label: "skuTable.salesOrderQuantity",
    canBeSort: true,
    styles: { justifyContent: "flex-end" },
    spanStyle: { width: 54 },
    complexValue: (row, item = {}) => {
      const salesOrderQuantity = row.salesOrderQuantity || {};
      const salesOrderId = item.currentSalesOrderId || "";
      if (!salesOrderQuantity) {
        return 0;
      }
      return salesOrderQuantity[salesOrderId] || 0;
    },
  },
  {
    id: 4,
    key: "salesOrderPrice",
    label: "skuTable.salesOrderPrice",
    canBeSort: true,
    styles: { justifyContent: "flex-end" },
    spanStyle: { width: 34 },
    complexValue: (row, item) => {
      const salesOrderQuantity = row.salesOrderPrice || {};
      const salesOrderId = item.currentSalesOrderId || "";
      return parseNumberValue(salesOrderQuantity[salesOrderId]);
    },
    permission: generalPermissions.SALES_ORDER_AMOUNT,
  },

  {
    id: 4,
    key: "extendedPrice",
    label: "skuTable.extendedPrice",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 51 },
    complexValue: (row, item) => {
      const salesOrderQuantity = row.salesOrderQuantity || {};
      const salesOrderId = item.currentSalesOrderId || "";
      return getExtendedCostPrice({
        cost: row.salesOrderPrice[salesOrderId],
        quantity: salesOrderQuantity[salesOrderId],
      });
    },
    permission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    id: 5,
    key: "cbmMaster",
    label: "skuTable.cbmMaster",
    canBeSort: true,
    format: ({ value }) => parseFloat(value).toFixed(2),
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 45 },
  },
  {
    id: 6,
    key: "piecesPerMaster",
    label: "skuTable.piecesPerMaster",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 69, whiteSpace: "break-spaces" },
  },
  {
    id: 6,
    key: "cartons",
    label: "skuTable.cartons",
    canBeSort: true,
    checkRound: true,
    showFormatValue: true,
    styles: {
      justifyContent: "flex-end",
    },
    complexValue: (row, item) => {
      const salesOrderId = item.currentSalesOrderId || "";
      return getCartons({
        pieces: row.piecesPerMaster,
        quantity: row.salesOrderQuantity[salesOrderId],
      });
    },
  },
  {
    id: 7,
    key: "totalCbm",
    label: "skuTable.totalCbm",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 31, whiteSpace: "break-spaces" },
    complexValue: (row, item) => {
      const salesOrderId = item.currentSalesOrderId || "";
      const salesOrderQuantity = row.salesOrderQuantity || {};
      return getTotalCBM({
        cbm: row.cbmMaster,
        pieces: row.piecesPerMaster,
        quantity: salesOrderQuantity[salesOrderId],
      });
    },
  },
  {
    id: 8,
    label: "generic.text.blank",
    key: "",
  },
];

export const shipmentSKUHeader = [
  {
    id: 0,
    key: "itemNumber",
    label: "skuTable.itemNumber",
    canBeSort: true,
  },
  {
    id: 1,
    key: "image",
    label: "skuTable.image",
    canBeSort: false,
    checkIcon: true,
  },
  {
    id: 2,
    key: "purchaseOrders",
    label: "skuTable.",
    dinamyLabel: true,
    complexValue: (row, item = {}, purchaseOrders) => {
      const currentPO =
        purchaseOrders.find((po) => po.id === item.purchaseOrderId) || {};
      return currentPO.number || "";
    },
  },
  {
    id: 3,
    key: "description",
    label: "skuTable.description",
    canBeSort: true,
    showToolTip: true,
    permissionStyle: { gridColumn: "3 span" },
  },
  {
    id: 4,
    key: "shipmentQuantity",
    label: "skuTable.shipmentQuantity",
    canBeSort: true,
    styles: { justifyContent: "flex-end" },
    spanStyle: { width: 60 },
    complexValue: (row, item = {}) => {
      const shipmentQuantity = row.shipmentQuantity || {};
      const shipmentId = item.currentShipmentId || "";
      return parseNumberValue(shipmentQuantity[shipmentId]);
    },
  },

  {
    id: 5,
    key: "unitCost",
    label: "skuTable.unitCost",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 30 },
    permission: generalPermissions.SHIPMENT_AMOUNT,
    complexValue: (row, item) => {
      const salesOrderQuantity = row.salesOrderQuantity || {};
      const salesOrderId = item.currentSalesOrderId || "";
      return getExtendedCostPrice({
        cost: row.salesOrderPrice[salesOrderId],
        quantity: salesOrderQuantity[salesOrderId],
      });
    },
  },
  {
    id: 6,
    key: "extendedCost",
    label: "skuTable.extendedCost",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 55 },
    permission: generalPermissions.SHIPMENT_AMOUNT,
    complexValue: (row, item) => {
      const shipmentQuantity = row.shipmentQuantity || {};
      const shipmentId = item.currentShipmentId || "";
      return getExtendedCostPrice({
        cost: row.unitCost,
        quantity: shipmentQuantity[shipmentId],
      });
    },
  },
  {
    id: 7,
    key: "cbmMaster",
    label: "skuTable.cbmMaster",
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 64, whiteSpace: "break-spaces" },
  },
  {
    id: 8,
    key: "piecesPerMaster",
    label: "skuTable.piecesPerMaster",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 69, whiteSpace: "break-spaces" },
  },
  {
    id: 9,
    key: "cartons",
    label: "skuTable.cartons",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    complexValue: (row, item) => {
      return getCartons({
        quantity: row.shipmentQuantity[item.currentShipmentId],
        pieces: row.piecesPerMaster,
      });
    },
  },
  {
    id: 10,
    key: "totalCbm",
    label: "skuTable.totalCbm",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    spanStyle: { width: 31, whiteSpace: "break-spaces" },
    complexValue: (row, item) => {
      const salesOrderId = item.currentSalesOrderId || "";
      const salesOrderQuantity = row.salesOrderQuantity || {};
      return getTotalCBM({
        cbm: row.cbmMaster,
        pieces: row.piecesPerMaster,
        quantity: salesOrderQuantity[salesOrderId],
      });
    },
  },
  {
    id: 11,
    key: "weight",
    label: "skuTable.weight",
    canBeSort: true,
    styles: {
      justifyContent: "flex-end",
    },
    complexValue: (row, item) => {
      return getTotalWeight({
        weight: row.weight,
        pieces: row.piecesPerMaster,
        quantity: row.shipmentQuantity[item.currentShipmentId],
      });
    },
  },
];

export const POSKUHeader = [
  {
    id: 0,
    key: "blank",
    label: "generic.text.blank",
    className: "fullTitle",
    styles: { padding: 0 },
  },
  {
    id: 1,
    key: "itemNumber",
    label: "skuTable.itemNumber",
    canBeSort: true,
    className: "fullTitle",
  },
  {
    id: 2,
    key: "image",
    label: "skuTable.image",
    checkIcon: true,
    className: "fullTitle",
  },
  {
    id: 3,
    key: "description",
    label: "skuTable.description",
    canBeSort: true,
    showToolTip: true,
    className: "fullTitle",
    permissionStyle: { gridColumn: "3 span" },
  },
  {
    id: 4,
    key: "purchaseOrderQuantity",
    label: "skuTable.orderQuantity",
    canBeSort: true,
    reference: itemTableReference.ITEM,
    companyPermission: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_QUANTITY,
    format: ({ value }) => formatWithComma(value),
    orderQuantityCheck:
      "This item quantity does not match the quantity in the Sales Order",
    className: "fullTitle",
    styles: { justifyContent: "flex-end" },
    spanStyle: { width: 59, whiteSpace: "break-spaces" },
  },
  {
    id: 5,
    key: "unitCost",
    label: "skuTable.unitCost",
    canBeSort: true,
    reference: itemTableReference.ITEM,
    format: ({ value }) => formatCash(value),
    companyPermission: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_PRICE,
    permission: generalPermissions.PURCHASE_ORDER_AMOUNT,
    className: "fullTitle",
    styles: { justifyContent: "flex-end" },
    spanStyle: { width: 62 },
  },
  {
    id: 6,
    key: "extendedPrice",
    label: "skuTable.extendedCost",
    canBeSort: true,
    complexValue: (item) => {
      return getExtendedCostPrice({
        cost: item.unitCost,
        quantity: item.purchaseOrderQuantity,
      });
    },
    format: ({ value }) => formatCash(value),
    permission: generalPermissions.PURCHASE_ORDER_AMOUNT,

    className: "fullTitle",
    styles: { justifyContent: "flex-end" },
    spanStyle: { width: 84, whiteSpace: "pre" },
  },
  {
    id: 6,
    key: "salesOrders",
    label: "salesorders.title",
    className: "skuTitle",
    styles: {
      height: "100%",
      background: colors.salesOrderBackgroundColor,
      borderInline: `2px solid ${colors.salesOrderBorderColor}`,
    },
  },
  {
    id: 7,
    key: "shipments",
    label: "Shipments",
    className: "skuTitle",
    styles: {
      height: "100%",
      background: colors.shipmentBadge,
      borderLeft: `2px solid ${colors.shipmentBorderColor}`,
      gridColumn: "span 3",
    },
  },

  {
    id: 8,
    key: "soAllocation",
    label: "skuTable.quantity",
    companyPermission: COMPANY_PERMISSION_VALUE.SALES_ORDER_QUANTITY,
    format: ({ value }) => formatWithComma(value),
    styles: {
      height: "100%",
      background: colors.salesOrderBackgroundColor,
      borderLeft: `2px solid ${colors.salesOrderBorderColor}`,
      justifyContent: "flex-end",
    },
    spanStyle: { width: 62 },
  },
  {
    id: 9,
    key: "soNumber",
    label: "widgets.number",
    reference: itemTableReference.SALES_ORDERS,
    format: ({ value, salesOrders = [] }) =>
      getEntityByField({
        itemList: salesOrders,
        itemId: value,
        field: "number",
      }),
    styles: {
      height: "100%",
      background: colors.salesOrderBackgroundColor,
    },
  },
  {
    id: 10,
    key: "customerId",
    label: "skuTable.customer",
    format: ({ value, customers = [] }) =>
      getCustomerName(customers, value, true),
    styles: {
      height: "100%",
      background: colors.salesOrderBackgroundColor,
      borderRight: `2px solid ${colors.salesOrderBorderColor}`,
    },
  },

  {
    id: 11,
    key: "shipmentQuantity",
    label: "skuTable.quantity",
    format: ({ value }) => formatWithComma(value),
    styles: {
      height: "100%",
      background: colors.shipmentBadge,
      borderLeft: `2px solid ${colors.shipmentBorderColor}`,
      justifyContent: "flex-end",
    },
    spanStyle: { width: 62 },
  },
  {
    id: 12,
    key: "shipmentNumber",
    label: "widgets.number",
    reference: itemTableReference.SHIPMENTS,
    styles: {
      height: "100%",
      background: colors.shipmentBadge,
      justifyContent: "left",
    },
  },
  {
    id: 13,
    key: "finalDestination",
    label: "skuTable.finalDestination",
    reference: itemTableReference.SHIPMENTS,
    icon: { visibility: "hidden" },
    format: ({ value, shipments = [] }) =>
      getShipmentDestination(shipments, value, true),
    styles: {
      height: "100%",
      background: colors.shipmentBadge,
      gridColumn: "span 1",
    },
  },
];

export const csvPOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "purchaseOrderQuantity",
    label: "Order Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "extendedCost",
    label: "Extented Cost",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "salesOrderItemQuantity",
    label: "Sales Order Quantity",
  },
  {
    key: "salesOrderItemNumber",
    label: "Sales Order Number",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "shipmentItemQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "shipmentItemNumber",
    label: "Shipment Number",
  },
  {
    key: "finalDestination",
    label: "Final Destination",
  },
];

export const csvSOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "salesOrderQuantity",
    label: "Sales Order Quantity",
  },
  {
    key: "salesOrderPrice",
    label: "Sales Order Price",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "extendedPrice",
    label: "Extented Price",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: "Pieces Per Master",
  },
  {
    key: "soCartons",
    label: "Cartons",
  },
  {
    key: "totalCbm",
    label: "Total CBM",
  },
];

export const csvShipmentHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "shipmentQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "shipmentExtendedCost",
    label: "Extented Cost",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: `Pieces per Master`,
  },
  {
    key: "shipmentCartons",
    label: "Cartons",
  },
  {
    key: "shipmentTotalCbm",
    label: "Total CBM",
  },
  {
    key: "weight",
    label: "Weight",
  },
];

export const pdfPOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "image",
    label: "Image",
  },
  {
    key: "description",
    label: "Description",
    style: { halign: "left" },
  },
  {
    key: "purchaseOrderQuantity",
    label: "Order Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    type: "money",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "extendedCost",
    label: "Extented Cost",
    type: "money",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "salesOrderItemQuantity",
    label: "Sales Order Quantity",
  },
  {
    key: "salesOrderItemNumber",
    label: "Sales Order Number",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "shipmentItemQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "shipmentItemNumber",
    label: "Shipment Number",
  },
  {
    key: "finalDestination",
    label: "Final Destination",
    style: {
      cellWidth: 100,
      halign: "left",
    },
  },
];

export const pdfSOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "image",
    label: "Image",
  },
  {
    key: "description",
    label: "Description",
    style: { halign: "left" },
  },
  {
    key: "salesOrderQuantity",
    label: "Order Quantity",
  },
  {
    key: "salesOrderPrice",
    label: "Price",
    type: "money",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "extendedPrice",
    label: "Extented Price",
    type: "money",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: "Pieces Per Master",
  },
  {
    key: "soCartons",
    label: "Cartons",
  },
  {
    key: "totalCbm",
    label: "Total CBM",
  },
];

export const pdfShipmentHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "image",
    label: "Image",
  },
  {
    key: "description",
    label: "Description",
    style: { halign: "left" },
  },
  {
    key: "shipmentQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    type: "money",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "shipmentExtendedCost",
    label: "Extented Cost",
    type: "money",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: `Pieces per Master`,
  },
  {
    key: "shipmentCartons",
    label: "Cartons",
  },
  {
    key: "shipmentTotalCbm",
    label: "Total CBM",
  },
  {
    key: "weight",
    label: "Weight",
  },
];

export const excelStyles = ({ headerRows, bodyRows, prefix }) => {
  const headerByType =
    prefix === PREFIX_ITEM_TABLE.SHIPMENT
      ? Array.from(headerRows[0].childNodes)
      : Array.from(headerRows[0].childNodes).slice(0, -1);
  const headerHTML = headerByType
    .map((childHTML) => {
      const firstChild = childHTML.firstElementChild;
      const childClone = firstChild.cloneNode(true);
      return `<th class="headerTable">${childClone.outerHTML}</th>`;
    })
    .join("");
  const bodyHTML = Array.from(bodyRows)
    .map((row) => {
      const cells = Array.from(row.childNodes)
        .map((cell, cellIndex) => {
          const cellClone = cell.cloneNode(true);
          return `<td class="cellStyle">${
            cellIndex !== 1 ? cellClone.outerHTML : ""
          }</td>`;
        })
        .join("");
      return `<tr>${cells}</tr>`;
    })
    .join("");
  return `
  <html
  <head>
  <style>
    .headerTable {
      gap: 5px;
      display: flex;
      align-items: center;
      padding-inline: 11px 5px;
      height: 51px;
      background: #c5dbf6;
      font-weight: 700;
      color: #25486D;
      align-items: center;
      border: 1px solid #87A3C2;
    }
    .cellStyle {
      border-bottom: 1px solid #87A3C2;
      border-right: 1px solid #87A3C2;
      border-left: 1px solid #87A3C2;
    }
    .dangerStyle {
      color: red;
    }
  </style>
  </head>
  <body>
  <table>
    <thead>
      <tr>
      ${headerHTML}
      </tr>
    </thead>
    <tbody class="bodyTable">
      ${bodyHTML}
    </tbody>
  </table>
  </body>
  </html>
`;
};

const generateHeader = (headerRows) => {
  const headerHTMLRow1 = [];
  const headerHTMLRow2 = [];

  const childList = Array.from(headerRows[0].childNodes);
  const totalColumns = childList.length - 15;

  childList.forEach((childHTML, index) => {
    if (index === 0) {
      return;
    }

    const firstChild = childHTML.firstElementChild;
    const childClone = firstChild.cloneNode(true);
    const generalDataIndex = 6 + totalColumns;
    const salesOrderIndex = 7 + totalColumns;
    const shipmentIndex = 8 + totalColumns;
    const rowspan = index <= generalDataIndex ? 2 : 1;
    const colspan =
      index === salesOrderIndex || index === shipmentIndex ? 3 : 1;

    let className = "";
    if (
      index === salesOrderIndex ||
      (index > shipmentIndex && index <= 11 + totalColumns)
    ) {
      className = "salesOrderCell";
    } else if (index === shipmentIndex || index >= 12 + totalColumns) {
      className = "shipmentCell";
    }

    const headerHTML = `<th class="headerTable ${className}" rowspan="${rowspan}" colspan="${colspan}">
          ${childClone.outerHTML}
      </th>`;

    if (index <= shipmentIndex) {
      headerHTMLRow1.push(headerHTML);
    } else {
      headerHTMLRow2.push(headerHTML);
    }
  });

  return [headerHTMLRow1.join(""), headerHTMLRow2.join("")];
};

export const excelPOStyles = ({ headerRows, bodyRows, currentRows }) => {
  const [headerHTMLRow1, headerHTMLRow2] = generateHeader(headerRows);
  const childList = Array.from(headerRows[0].childNodes);
  const totalColumns = childList.length - 15;
  const bodyHTML = Array.from(bodyRows)
    .map((row) => {
      const cellsData = Array.from(row.childNodes);
      const cells = [];
      cellsData.forEach((cell) => {
        const classList = cell.classList;
        const classesToCheck = ["soRowContainer", "shipmentRowContainer"];
        if (classesToCheck.some((className) => classList.contains(className))) {
          const children = Array.from(cell.children);

          cells.push(...children);
        } else {
          cells.push(cell);
        }
      });
      const currentRow = currentRows.find((itemSKU) =>
        itemSKU.itemNumber.includes(cells[1].innerHTML)
      );
      let totalShipments = 0;
      currentRow.salesOrders.forEach((so) => {
        if (so.shipments.length <= 1) {
          totalShipments += 1;
        } else {
          totalShipments += so.shipments.length;
        }
      });
      const rowSize =
        Math.max(totalShipments, currentRow.salesOrderIds.length) || 1;
      let soPosition = 0;
      let shipmentPosition = 0;
      let lastIndexCell;
      const rows = [];
      let onlyShipments = true;
      for (let i = 0; i < rowSize; i++) {
        const cellsRow = [];
        const currentSO = currentRow.salesOrders[soPosition] || {};
        let shipments = currentSO.shipments || [];
        if (i === 0) {
          const firstRow = [...cells].slice(0, 14 + totalColumns);
          firstRow.forEach((childHTML, index) => {
            if (index === 0 || index === 13 + totalColumns) {
              return;
            }
            const soIndex = 6 + totalColumns;
            const shipmentIndex = 10 + totalColumns;
            let rowspan = index <= soIndex ? rowSize : 1;
            let className = "";
            if (index > soIndex && index < shipmentIndex) {
              className = "salesOrderCell";
              rowspan = shipments.length || 1;
            } else if (index >= shipmentIndex) {
              className = "shipmentCell";
              rowspan = 1;
            }
            const outerHTML =
              index === 11 + totalColumns || index === 8 + totalColumns
                ? `<div>
              ${childHTML.firstElementChild?.outerHTML || ""}
               </div>`
                : childHTML.outerHTML;
            cellsRow.push(
              `<th class="bodyTable ${className}" rowspan="${rowspan}">
                ${
                  index === 2 ||
                  (shipments.length === 0 && index >= shipmentIndex)
                    ? ""
                    : outerHTML
                }
              </th>`
            );
          });
          if (currentRow.salesOrders.length === 0) {
            for (let i = 0; i < 4; i++) {
              const className = i === 0 ? "salesOrderCell" : "shipmentCell";
              cellsRow.push(`<th class="bodyTable ${className}" />`);
            }
          }
          shipmentPosition += 1;
          lastIndexCell = 13 + totalColumns + 1;
          if (shipments.length > 1) {
            lastIndexCell += 1;
          }
          onlyShipments = shipmentPosition < shipments.length;
        } else {
          if (!onlyShipments) {
            soPosition++;
            shipmentPosition = 0;
            onlyShipments = false;
          }
          const currentSO = currentRow.salesOrders[soPosition] || {};
          const currentShipments = currentSO.shipments || [];

          let numberOfCells = onlyShipments ? 3 : 6;
          const nextRow = [...cells].slice(
            lastIndexCell,
            lastIndexCell + numberOfCells
          );
          nextRow.forEach((childHTML, index) => {
            let rowspan =
              !onlyShipments && index < 3 ? currentShipments.length : 1;
            let className =
              onlyShipments || index >= 3 ? "shipmentCell" : "salesOrderCell";
            cellsRow.push(
              `<th class="bodyTable ${className}" rowspan="${rowspan}">
                  ${childHTML ? childHTML.outerHTML : ""}
                </th>`
            );
          });

          const totalPosition =
            currentShipments.length > 1 &&
            !onlyShipments &&
            shipmentPosition === 0 &&
            soPosition > 0
              ? 1
              : 0;

          lastIndexCell = lastIndexCell + numberOfCells + totalPosition;
          onlyShipments = shipmentPosition !== currentShipments.length - 1;
          shipmentPosition += 1;
        }
        rows.push(`<tr>${cellsRow.join("")}</tr>`);
      }
      return rows.join("");
    })
    .join("");
  return `
  <html
  <head>
  <style>
    body {
      font-size: 12px !important;
    }
    .bodyTable {
      gap: 5px;
      display: flex;
      align-items: center;
      padding-inline: 11px 5px;
      color: #25486D;
      align-items: center;
      border: 1px solid #87A3C2;
      font-weight:400;
    }
    .headerTable {
      gap: 5px;
      display: flex;
      align-items: center;
      padding-inline: 11px 5px;
      height: 51px;
      background: #c5dbf6;
      font-weight: 700;
      color: #25486D;
      align-items: center;
      border: 1px solid #87A3C2;
    }
    .dangerStyle {
      color: red;
    }
    .salesOrderCell {
      background: rgb(235, 243, 212);
    }
    .shipmentCell {
      background: rgb(246, 230, 249);
    }
  </style>
  </head>
  <body>
  <table>
    <thead>
      <tr>
      ${headerHTMLRow1}
      </tr>
      <tr>
      ${headerHTMLRow2}
      </tr>
    </thead>
    <tbody >
      ${bodyHTML}
    </tbody>
  </table>
  </body>
  </html>
`;
};
