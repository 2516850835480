import React, { useRef, useState } from "react";
import { getMaxDate, getTheDate } from "../../helpers/helpers";
import { useCustomers } from "../../hooks/customers";
import GeneralFilters from "../FilterTable/GeneralFilters";
import { isEqual } from "lodash";
import StatusOrderButtons from "../PurchaseOrderList/StatusOrderButtons";
import FilterDisplay from "../PurchaseOrderList/FilterDisplay";
import DateRangePicker from "../DatePicker/DateRangePicker";
import SearchBox from "../SearchBox/SearchBox";
import { getToolTipArrayValue } from "../../helpers/purchaseOrderList";

const hiddenStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
};

function SOManagerFilters({ filters, setFilters, setQuery, query = "" }) {
  const customers = useCustomers();
  const dateRef = useRef(null);
  const [existsDate, setExistsDate] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  function onOpenDatePicker(field) {
    setExistsDate(true);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 800);
  }

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      setFilters({ ...newFilters });
    }
  }

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }
  function handleCloseDatePicker() {
    setOpenDate(false);
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filters.date,
    });
    setOpenDate(false);
  }

  const filterCustomerActive = filters.customer?.length > 0;

  return (
    <>
      <div className="sales-order-title">
        <GeneralFilters
          onChange={changeFilters}
          dateFields={["Created Date", "Document Date"]}
          currentFilters={filters}
          enabledFields={{
            customer: true,
            date: true,
          }}
          onOpenDatePicker={onOpenDatePicker}
          filterData="name"
        />

        <SearchBox
          placeholder="Search for Sales Order or customer"
          value={query}
          hasBeenReseted={() => {
            setQuery("");
          }}
          filters={filters}
          onDebounceValue={(nonLookUpfilters, value) => {
            setQuery(value);
          }}
        />
      </div>
      <div
        className="sales-order-title"
        style={{ paddingTop: 0, justifyContent: "flex-start", gap: 20 }}
      >
        <StatusOrderButtons
          changeButtonFilter={changeButtonFilter}
          filters={{ ...filters }}
        />
        <div style={{ display: "flex", gap: 20 }}>
          {filterCustomerActive && (
            <FilterDisplay
              clearIconId="list-view-filter-badge-clear-customer"
              onClear={() => setFilters({ ...filters, customer: [] })}
              label={filters.customer.length === 1 ? "Customer" : "Customers"}
              labelStyle={hiddenStyle}
              tooltip={
                getToolTipArrayValue(filters.customer, customers).tooltip
              }
              value={getToolTipArrayValue(filters.customer, customers).value}
            />
          )}
          {existsDate && (
            <>
              <div
                onClick={() => {
                  setOpenDate(true);
                }}
                ref={dateRef}
              >
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-date"
                  onClear={() => {
                    setExistsDate(false);
                    setFilters({
                      ...filters,
                      date: "",
                      dateRange: { start: new Date(), end: new Date() },
                    });
                  }}
                  label={filters.date}
                  labelStyle={hiddenStyle}
                  tooltip={
                    filters.dateRange
                      ? `${getTheDate(filters.dateRange.start)} - ${getTheDate(
                          filters.dateRange.end
                        )}`
                      : "Pick a date"
                  }
                  value={
                    filters.dateRange
                      ? `${getTheDate(filters.dateRange.start)} - ${getTheDate(
                          filters.dateRange.end
                        )}`
                      : "Pick a date"
                  }
                />
              </div>
              {openDate && (
                <DateRangePicker
                  open={openDate}
                  el={dateRef.current}
                  onClose={handleCloseDatePicker}
                  onChange={changeDate}
                  value={filters.dateRange}
                  cancelIcon
                  maxDate={getMaxDate(filters.date)}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SOManagerFilters;
