import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField, Chip } from "@mui/material";
import { getUserAvatar } from "../../helpers/users";
import { AutoCompleteFieldStyled } from "./styles";

export default function UserAutocomplete({
  users = [],
  onChange = () => {},
  currentValue = [],
  isMulti = true,
  id = "autocomplete-id",
  disabled = false,
}) {
  const handleChange = (ev, newValue) => {
    onChange(newValue, currentValue);
  };
  return (
    <AutoCompleteFieldStyled className="autocomplete-root">
      <Autocomplete
        disablePortal
        id={id}
        multiple={isMulti}
        disableCloseOnSelect={isMulti}
        options={users.filter(
          (user) => !currentValue.some((selected) => selected.id === user.id)
        )}
        getOptionLabel={(option) => option.id || ""}
        value={currentValue}
        onClose={() => {}}
        onChange={handleChange}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(options, state) =>
          options.filter((option) =>
            option.displayName.toLowerCase().includes(state.inputValue.toLowerCase())
          )
        }
        renderOption={(props, option) => (
          <span {...props} key={option.value} className="custom-option">
            <div className="user-content">
              {getUserAvatar({
                user: option,
                styles: {
                  width: 30,
                  height: 30,
                  fontSize: 15,
                  outline: "2px solid #000",
                  outlineOffset: -2,
                  margin: "4px 0px",
                },
              })}
              <span>{option.displayName}</span>
            </div>
          </span>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              key={option.id}
              {...getTagProps({ index })}
              label={
                <div className="user-content">
                  {getUserAvatar({
                    user: option,
                    styles: {
                      width: 30,
                      height: 30,
                      fontSize: 15,
                      outline: "2px solid #000",
                      outlineOffset: -2,
                      margin: "4px 0px",
                    },
                  })}
                  <span>{option.displayName}</span>
                </div>
              }
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={currentValue.length === 0 && "Select User..."}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
          />
        )}
      />
    </AutoCompleteFieldStyled>
  );
}

UserAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string,
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
  isMulti: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};
