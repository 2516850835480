import React, { useState } from "react";
import { ReadByPopoverContainerStyled } from "./styles";
import { getUserAvatar } from "../../../helpers/users";
import moment from "moment";
import unreadByUsersIcon from "../../../assets/flag-icons/unreadByUsers.svg";
import readByUsersIcon from "../../../assets/flag-icons/readByUsers.svg";
import { orderTypeSort } from "../../../helpers/salesOrderHelpers";
import TooltipTD from "../../Tooltip/TooltipTD";
import { sortObjectsBy } from "../../../helpers/sortingHelper";

function ReadByPopover({ activity, users, onMarkNote = () => {} }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState({
    sortedColumn: "displayName",
    orderBy: "ASC",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onMarkNote();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function readyByUsers({ readByUsers = {}, users = [] }) {
    const allUsers = [];
    Object.keys(readByUsers).forEach((key) => {
      const user = users.find((user) => user.id === key);
      if (user) {
        allUsers.push({
          ...user,
          hasBeenRead: !!readByUsers[key],
          readDate: readByUsers[key],
        });
      }
    });
    const readByUsersArr = allUsers.filter((user) => user.hasBeenRead);
    const unreadByUsersArr = allUsers.filter((user) => !user.hasBeenRead);
    return { readByUsersArr, unreadByUsersArr };
  }

  const { readByUsersArr, unreadByUsersArr } = readyByUsers({
    readByUsers: activity.readByUsers,
    users,
  });
  return (
    <div>
      <div
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{
          textDecoration: "underline",
          display: "flex",
          justifyContent: "center",
          color: "#25486D",
          cursor: "pointer",
        }}
      >
        <TooltipTD label={"List of who has read this note"}>
          <img
            src={
              Object.values(activity.readByUsers || {}).every(
                (value) => !!value
              )
                ? readByUsersIcon
                : unreadByUsersIcon
            }
            alt={"unread icon"}
          />
        </TooltipTD>
      </div>
      {anchorEl && (
        <ReadByPopoverContainerStyled
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "popoverContainer",
          }}
          className="readByPopoverContainer"
        >
          <div className={"readByPopoverContainer"}>
            {readByUsersArr
              .sort(
                sortObjectsBy(
                  filterColumn.sortedColumn,
                  filterColumn.orderBy === orderTypeSort.ASC ? false : true
                )
              )
              .map((user, index) => {
                return (
                  <div
                    className="read-user-item"
                    key={user.id + "read-user-item"}
                  >
                    {getUserAvatar({
                      user,
                      styles: {
                        width: 16,
                        height: 16,
                        outline: "1px solid #000",
                        fontSize: 8,
                        marginRight: 4,
                      },
                    })}
                    <span
                      className="item-value"
                      style={{
                        marginLeft: 2,
                        cursor: index === 0 ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (index === 0) {
                          setFilterColumn({
                            orderBy:
                              filterColumn.orderBy === orderTypeSort.ASC
                                ? orderTypeSort.DESC
                                : orderTypeSort.ASC,
                            sortedColumn: "displayName",
                          });
                        }
                      }}
                    >
                      @{user.displayName}
                    </span>
                    {user.hasBeenRead && (
                      <span
                        className="item-value"
                        style={{ cursor: index === 0 ? "pointer" : "default" }}
                        onClick={() => {
                          if (index === 0) {
                            setFilterColumn({
                              orderBy:
                                filterColumn.orderBy === orderTypeSort.ASC
                                  ? orderTypeSort.DESC
                                  : orderTypeSort.ASC,
                              sortedColumn: "readDate",
                            });
                          }
                        }}
                      >
                        {moment(activity.readByUsers[user.id]).format(
                          "M/D/YY"
                        ) +
                          " at " +
                          moment(activity.readByUsers[user.id]).format(
                            "h:mmA"
                          )}
                      </span>
                    )}
                  </div>
                );
              })}

            {unreadByUsersArr
              .sort(sortObjectsBy("displayName", false))
              .map((user) => {
                return (
                  <div
                    className="unread-user-item"
                    key={user.id + "unread-user-item"}
                  >
                    {getUserAvatar({
                      user,
                      styles: {
                        width: 16,
                        height: 16,
                        outline: "1px solid #000",
                        fontSize: 8,
                        marginRight: 4,
                      },
                    })}
                    <span className="item-value" style={{ marginLeft: 2 }}>
                      @{user.displayName}
                    </span>
                    {user.hasBeenRead && (
                      <span className="item-value">
                        {moment(activity.readByUsers[user.id]).format(
                          "M/D/YY"
                        ) +
                          " at " +
                          moment(activity.readByUsers[user.id]).format("H:M A")}
                      </span>
                    )}
                  </div>
                );
              })}
          </div>
        </ReadByPopoverContainerStyled>
      )}
    </div>
  );
}

export default ReadByPopover;
