import React, { useEffect, useState } from "react";
import { dbTables } from "../../../api/types/dbTables";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../../hooks/permissions";
import {
  BACKDROP_TYPE,
  MISCELLANEOUS_IDS,
  SOObjFields,
} from "../../../helpers/constants";
import { now } from "moment";
import { useCurrentShipments } from "../../../hooks/shipments";
import { firestore } from "../../../firebase";
import { salesOrderTriggerTypes } from "../../../helpers/salesOrder";
import { doc, updateDoc } from "firebase/firestore";
import { usePurchaseOrders } from "../../../hooks/salesOrders";
import { useCompanyId, useMiscellaneous } from "../../../hooks/user";
import { isEqual } from "lodash";
import { cx } from "@emotion/css";
import { BackdropDashboard } from "./BackdropDashboard";
import {
  getMiscellaneoList,
  getParseAmount,
  getSOWindowValue,
  renderMetadataTextField,
} from "../dashboardHelpers";
import { sortObjectsBy } from "../../../helpers/sortingHelper";
import { getShipmentsByPOs } from "../../../helpers/salesOrderHelpers";
import { pathToString } from "../../../helpers/helpers";
import { useDispatch } from "react-redux";
import { useQueryParams } from "../../../hooks";

// import { trackEvent } from "../../helpers/analytics";

function SOMetadataInfo({ salesOrder, isReadOnly = false, user }) {
  const companyId = useCompanyId();
  const queryParams = useQueryParams();
  const purchaseOrderId = queryParams.purchaseOrderId;
  const [signShipment, setSignShipment] = useState(false);
  const [shipmentsDB, setShipmentsDB] = useState([]);
  const isAllowed = useIsAllowedFunction();
  const isAllowedCompany = useIsAllowedCompany();
  const purchaseOrders = usePurchaseOrders();
  const shipments = useCurrentShipments({ purchaseOrders });
  const dataMiscellaneous = useMiscellaneous() || [];
  const salesOrderMetadata = getMiscellaneoList({
    miscellaneous: dataMiscellaneous,
    miscellaneoId: MISCELLANEOUS_IDS.SALES_ORDER_METADATA,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (purchaseOrders.length > 0 && shipmentsDB.length === 0) {
      setSignShipment(true);
    }
  }, [purchaseOrders.length]);

  useEffect(() => {
    if (signShipment) {
      setShipments();
      setSignShipment(false);
    }
  }, [signShipment]);

  const setShipments = async () => {
    const newShipments = await getShipmentsByPOs({
      purchaseOrders: purchaseOrders.filter((po) => po.id !== purchaseOrderId),
      path: pathToString([dbTables.COMPANIES, companyId, dbTables.SHIPMENTS]),
      dispatch: dispatch,
      excludeIds: purchaseOrders.find((po) => po.id === purchaseOrderId)
        ?.shipmentIds,
    });
    if (newShipments?.length > 0) {
      setShipmentsDB(newShipments);
    }
  };

  if (!salesOrder) {
    return null;
  }

  function onChangeProperty({ value, field, type, label }) {
    if (isEqual(salesOrder[field], value)) return;
  
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    const salesOrderRef = doc(
      firestore,
      `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}`
    );
  
    const triggerTypeMetadata = { field, fieldType: type, label };
    const updateData = {
      [field]: value,
      updated_by: user.id,
      triggerTypeMetadata,
      triggerType: salesOrderTriggerTypes.METADATA,
    };
  
    if (field === SOObjFields.SHIP_DATE) {
      const fieldDates = salesOrder.shipDates ? [...salesOrder.shipDates] : [];
      fieldDates.push({ creationDate: now(), shipDate: value, user: user.id });
      updateData.shipDates = fieldDates;
    }
  
    updateDoc(salesOrderRef, updateData);
  }

  function onChangeAmountProperty({ value, field, type, label }) {
    const parseAmountValue = getParseAmount(value, salesOrder[field]);
    if (parseAmountValue === undefined) {
      return;
    }
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;

    updateDoc(
      doc(firestore, `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}`),
      {
        [field]: parseAmountValue,
        updated_by: user.id,
        triggerTypeMetadata: {
          field: field,
          fieldType: type,
          label: label,
        },
        triggerType: salesOrderTriggerTypes.METADATA,
      }
    );
  }

  return (
    <React.Fragment>
      {salesOrder.id && (
        <BackdropDashboard backdropType={BACKDROP_TYPE.METADATA} />
      )}

      <div className={cx("metadata-info", "salesOrderInfoGrid-new")}>
        {salesOrderMetadata
          .filter((item) => item.active)
          .sort(sortObjectsBy("listIndex", false))
          .map((item) =>
            renderMetadataTextField({
              entity: salesOrder,
              isAllowed,
              isReadOnly,
              item,
              shipments: [...shipmentsDB, ...shipments],
              windowValue: getSOWindowValue({
                window: item.field,
                shipments: [...shipmentsDB, ...shipments],
                purchaseOrders,
              }),
              onChangeAmountProperty,
              onChangeEntityProperty: onChangeProperty,
              setSignShipment,
              isAllowedCompany,
            })
          )}
      </div>
    </React.Fragment>
  );
}

export default SOMetadataInfo;
