import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const AnimatedListStyled = styled("div")(() => ({
  "&.listContainer": {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    transition: "height 0.8s ease-in-out",
    height: 24,
    color: colors.primaryDark,
  },
  "& .orderList": {
    height: "-webkit-fill-available",
    "& .buttonContainerThreePanel": {
      opacity: 0,
    },
    "&:hover": {
      "& .buttonContainerThreePanel": {
        opacity: 1,
      },
      "& .hideShowTags": {
        opacity: 1,
      },
    },
  },
  "& .listItemExpenses": {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& .totalList": {
      borderTop: "2px #578EC7 solid",
      paddingTop: 6,
      width: "100%",
      paddingInline: "0px 17px",
    },
    "& .opacityListAnimation": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      transition: "opacity 0.8s ease-in-out",
    },
    "& .opacitiyTotal": {
      position: "absolute",
      transition: "opacity 0.8s ease-in-out",
      paddingInline: "0px 17px",
    },
  },
  "& .folder-shipment-icon": {
    opacity: 0,
  },
  "& a": {
    color: colors.primaryDark,
    textDecoration: "underline",
  },
  "& .displayItems": {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    transition: "opacity 0.8s ease-in-out",
    gap: 5,
  },
  "& .infoOrder": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  "& .iconAnimated": {
    display: "flex",
    justifyContent: "left",
    transition: "opacity 0.8s ease-in-out",
    top: 0,
    position: "absolute",
    cursor: "pointer",
    "& .animateText": {
      marginLeft: 6,
      fontWeight: 500,
    },

    "&:hover": {
      "& .animateText": {
        fontWeight: 700,
      },
      "& .expandRow": {
        "& path": {
          strokeWidth: 2.5,
        },
      },
    },
    "&:active": {
      "& .animateText": {
        fontWeight: 800,
      },
      "& .expandRow": {
        "& .item1": {
          transform: "translateY(1px)",
        },
        "& .item3": {
          transform: "translateY(-1px)",
        },
        "& path": {
          strokeWidth: 3,
        },
      },
      "& .csvIcon": {
        "& .arrow": {
          transform: "translateY(3px)",
        },
      },
    },
  },
  "& .labelText": {
    display: "flex",
    alignItems: "center",
    gap: 10,
    "& .diamondContainer": {
      width: 10,
      height: 15,
      display: "flex",
      alignItems: "center",
    },
    "& .progressContainer": {
      width: 15,
      height: 15,
      display: "flex",
      alignItems: "center",
    },
    "&:hover": {
      "& .folder-shipment-icon": {
        opacity: 1,
      },
    },
  },
}));
