import React from "react";
import {
  useIsAllowedFunction,
  verifyPermission,
} from "../../hooks/permissions";
import TagsModal from "../Modal/TagsModal";
import { dbTables } from "../../api/types/dbTables";
import { getTagsLabel } from "../../helpers/helpers";
import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import NearDueIcon from "../../assets/flag-icons/orange_diamond.svg";
import { TabLabelStyled } from "./styles";
import { useTags } from "../../hooks/tags";
import {
  GENERAL_PERMISSION_VALUE,
  PO_DIAMOND_STATUS,
  PO_STATUS,
} from "../../helpers/constants";
import { getTagStatus } from "../../helpers/salesOrder";
import { getUserAvatar } from "../../helpers/users";
import TooltipTD from "../Tooltip/TooltipTD";
import { addFavorite, removeFavorite } from "../../helpers/favoritesHelper";
import { VoidLabel } from "../../helpers/iconSvgPath";
import { getStarFavorite } from "../../helpers/ScreenComponetHelper/componetHelper";

function TabLabel({
  label,
  progress,
  itemId,
  po = {},
  user,
  isReadOnly = false,
  onSetBackdrop = () => {},
  favorites = [],
  favoritesDB = [],
  favoriteTable = "",
  hasUserMention = false,
}) {
  const tags = useTags();
  const isAllowed = useIsAllowedFunction();
  const permissions = po.type
    ? [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS]
    : [po.customerId, GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS];
  const isAvalible = verifyPermission({
    user,
    permissionToCheck: permissions,
  });
  const showTag = isAllowed("see_tags") && isAvalible;
  return (
    <TabLabelStyled className="tabLabelContainer">
      <div className={"tabInfoContainer"}>
        <div className="tab-label-container">
          <span className="tabLabel">
            {po.status === PO_STATUS.VOIDED && <VoidLabel />}
            {showTag && tags && (
              <TagsModal
                key={po.id}
                label={getTagsLabel(tags, po.tags).tagsString}
                tags={getTagsLabel(tags, po.tags).tagsArray}
                allTags={tags}
                item={po}
                currentUser={user}
                tagReference={dbTables.PURCHASE_ORDERS}
                onUpdateItem={() => {}}
                isThereItemRef={true}
                isReadOnly={isReadOnly}
                status={getTagStatus({
                  tags: po.tags,
                  currentTags: [],
                  renderTag: true,
                })}
                withBackground={false}
              />
            )}
            {!showTag && <span style={{ width: 18, minWidth: 18 }} />}

            {label}
          </span>
        </div>
        <div className="tab-po-information">
          {hasUserMention && (
            <div
              onClick={onSetBackdrop}
              style={{
                width: 20,
                height: 20,
                color: "rgb(37, 72, 109)",
              }}
            >
              {getUserAvatar({
                user,
                styles: {
                  width: 20,
                  height: 20,
                  fontSize: 11,
                  outline: "1px solid #000",
                },
                hasToolTip: false,
                className: "avatarHoverEffect",
              })}
            </div>
          )}
          {getStarFavorite({
            itemId,
            favorites,
            addFavorite: () =>
              addFavorite({
                dbTable: favoriteTable,
                favoritesDB,
                id: itemId,
                user: user,
              }),
            removeFavorite: () => removeFavorite({ favoritesDB, id: itemId }),
            height: 12,
            width: 13,
          })}
          {po.status !== PO_STATUS.VOIDED &&
            po.diamondStatus === PO_DIAMOND_STATUS.LATE && (
              <img
                style={{
                  opacity: 1,
                  height: 15,
                }}
                src={LateIcon}
                alt={"LateIcon"}
              />
            )}
          {po.status !== PO_STATUS.VOIDED &&
            po.diamondStatus === PO_DIAMOND_STATUS.NEAR_DUE && (
              <img
                style={{
                  opacity: 1,
                  height: 15,
                }}
                src={NearDueIcon}
                alt={"NearDueIcon"}
              />
            )}
        </div>
      </div>
      {po.status !== PO_STATUS.VOIDED && (
        <div className={"progress"}>
          <TooltipTD
            label={`${po.completedTasks}/${po.totalTasks} Tasks`}
            placement="bottom"
            style={{ display: "block", width: "100%" }}
          >
            <div style={{ width: (progress || 0) + "%" }} />
          </TooltipTD>
        </div>
      )}
    </TabLabelStyled>
  );
}

export default TabLabel;
