import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const FactoryDocumentsStyled = styled("div")(() => ({
  "&.documentFactoryContainer": {
    flex: 1,
    position: "relative",
    height: "100%",
    paddingLeft: 12,
    maxWidth: "calc(100vw - 1110px)",
    minWidth: 350,
    fontSize: 13,
    paddingTop: 70,
    overflow: "hidden",
    "& .stickyHeader": {
      paddingRight: 12,
    },
    "& .documents-panel-container": {
      overflow: "scroll",
    },
  },
}));

export const FactoryInfoStyled = styled("div")(() => ({
  "&.container": {
    flex: 1.2,
    height: "100%",
    padding: "50px 10px 5px 60px",
    maxWidth: 800,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
  "& .documents-header": {
    color: colors.primaryDark,
    fontSize: 17,
    fontWeight: 700,
    marginBottom: 45,
  },

  "& .infoField": {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid #D4DFED",
    gap: 12,
    "& .fieldNotes": {
      width: "70%",
      color: colors.darkGray,
      fontSize: 14,
      borderColor: "#D4DFED",
      maxHeight: "350px !important",
      minHeight: "100px !important",
      height: 100,
      resize: "vertical",
    },
    "& .fieldName": {
      width: "30%",
      margin: "0 2px",
      color: colors.darkGray,
      textAlign: "right",
      fontSize: 14,
      fontWeight: 400,
    },
    "& .editable-label": {
      color: colors.primaryDark,
      fontSize: 15,
      fontWeight: 400,
    },
  },
  "& .initStyle": {
    borderTop: "1px solid #D4DFED",
  },
}));
