import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getDateTitle } from "../../helpers/activitiesStream";
import moment from "moment";
import ActivityToDelete from "../PurchaseOrderDashboard/ActivityStream/ActivityToDelete";
import { ActivityStreamProvider } from "./DeleteNotesContext";
import Loader from "../General/Loader";
import { AutoCompleteEntityStyled, DeleteNotesStyled } from "./styles";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import SearchBox from "../SearchBox/SearchBox";
import Mark from "mark.js";
import {
  PurchaseOrderIcon,
  SalesOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { useCompanyId } from "../../hooks/user";
import { activityType } from "../../helpers/constants";
import { filterActivities, groupActivitiesByDate } from "./companyTabHelper";

function DeleteNotes() {
  const [scope, setScope] = useState(null);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentEntity, setCurrentEntity] = useState({ id: null });
  const [activityNotes, setActivityNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const companyId = useCompanyId();

  useEffect(() => {
    if (scope) {
      loadData(scope);
    }
  }, [scope]);

  useEffect(() => {
    if (currentEntity.id) {
      loadActivities({
        parentCollection: scope,
        parentDocumentId: currentEntity.id,
      });
    } else {
      setActivityNotes([]);
    }
  }, [currentEntity.id]);

  async function loadData(scope) {
    setLoading(true);
    const dataSnap = await getDocs(
      collection(firestore, `${dbTables.COMPANIES}/${companyId}/${scope}`)
    );
    const data = dataSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setData(data);
    setActivityNotes([]);
    setSearchText("");
    setCurrentEntity({ id: null });
    setLoading(false);
  }

  async function loadActivities({ parentCollection, parentDocumentId }) {
    setLoading(true);
    const dataSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}`
        ),
        where("type", "==", activityType.NOTE)
      )
    );

    const data = dataSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setActivityNotes(data);
    setSearchText("");
    setLoading(false);
  }

  const LABEL = {
    [dbTables.SALES_ORDERS]: "Sales Order/Quote",
    [dbTables.PURCHASE_ORDERS]: "PO/Purchase Quote",
    [dbTables.SHIPMENTS]: "Shipment",
  };

  function handleDelete({ activityId, deletedStatus }) {
    let activituNotesCpy = [...activityNotes];
    activituNotesCpy = activituNotesCpy.map((activity) => {
      if (activity.id === activityId) {
        return { ...activity, deletedStatus };
      }
      return activity;
    });
    setActivityNotes(activituNotesCpy);
    updateDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${scope}/${currentEntity.id}/${dbTables.ACTIVITIES}/${activityId}`
      ),
      {
        deletedStatus,
      }
    );
  }

  const markInstance = new Mark(
    document.getElementById("activityStreamByDateContainer")
  );
  function performMark(keyword = "") {
    const options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [".scope", ".readMore", ".activity-scope-left"],
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => {
          markInstance.mark(keyword, options);
        }, 500);
      },
    });
  }
  performMark();

  useEffect(() => {
    setTimeout(() => {
      performMark(searchText);
    }, 5);
  }, [searchText]);

  useEffect(() => {
    setTimeout(() => {
      performMark(searchText);
    }, 5);
  }, [activityNotes]);

  const autocompleteOptions = data
    .sort(sortObjectsBy("number", false))
    .map((option) => ({ id: option.id, label: option.number }));
  return (
    <ActivityStreamProvider>
      {loading && <Loader />}
      <DeleteNotesStyled className="deleteNotesContainer">
        <div className="select-options-container">
          <div style={{ width: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                className="select-scope-container"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={scope}
                label="Type"
                onChange={(ev) => {
                  setScope(ev.target.value);
                }}
                classes={{
                  select: "select-scope",
                }}
              >
                <MenuItem value={dbTables.SALES_ORDERS} style={{ gap: 12 }}>
                  <SalesOrderIcon /> {LABEL[dbTables.SALES_ORDERS]}
                </MenuItem>
                <MenuItem value={dbTables.PURCHASE_ORDERS} style={{ gap: 12 }}>
                  <PurchaseOrderIcon /> {LABEL[dbTables.PURCHASE_ORDERS]}
                </MenuItem>
                <MenuItem value={dbTables.SHIPMENTS} style={{ gap: 12 }}>
                  <ShipmentOrderIcon /> {LABEL[dbTables.SHIPMENTS]}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {scope && (
            <div className="autocomplete-container" style={{ width: 250 }}>
              <AutoCompleteEntityStyled
                id="entities-by-scope"
                freeSolo
                options={autocompleteOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Enter ${LABEL[scope]} number`}
                  />
                )}
                getOptionKey={(option) => option.id}
                onChange={(ev, value) => {
                  console.log({ value });
                  if (!value) {
                    setCurrentEntity({ id: null });
                  } else {
                    const entity = data.find((d) => d.id === value.id) || {
                      id: null,
                    };
                    setCurrentEntity(entity);
                  }
                }}
                className="autocomplete-entity"
                classes={{
                  clearIndicator: "clear-indicator",
                }}
                clearIcon={
                  <CancelIcon
                    style={{ width: 15, height: 15, color: "darkgray" }}
                  />
                }
                slotProps={{
                  clearIndicator: {
                    style: {
                      padding: 0,
                    },
                  },
                }}
                onKeyUp={(ev) => {
                  if (ev.key === "Enter") {
                    const entity = data.find(
                      (d) => d.number.toString() === ev.target.value.toString()
                    ) || { id: null };
                    setCurrentEntity(entity);
                  }
                }}
              />
            </div>
          )}
          {currentEntity.id && (
            <SearchBox
              placeholder="Search for anything in the Activity Stream"
              value={searchText}
              hasBeenReseted={() => {
                setSearchText("");
              }}
              onDebounceValue={(nonLookUpfilters, value) => {
                console.log({ nonLookUpfilters });
                setSearchText(value);
              }}
              style={{ height: 53, width: 400 }}
            />
          )}
        </div>
        <div
          className="body-content-activities"
          id="activityStreamByDateContainer"
        >
          {Object.keys(
            groupActivitiesByDate(
              filterActivities({
                activities: activityNotes,
                querySearch: searchText,
              })
            )
          ).map((date) => (
            <div
              className="group-activities-content"
              id="group-activities-content"
              key={date + moment(date).format("MMM D")}
            >
              <div className="group-activity-date">
                {getDateTitle({ date })}
              </div>
              {groupActivitiesByDate(
                filterActivities({
                  activities: activityNotes,
                  querySearch: searchText,
                })
              )[date].map((activity) => {
                return (
                  <Fragment key={activity.id + "fragment"}>
                    <ActivityToDelete
                      key={activity.id}
                      activity={activity}
                      sizeThreadChilds={Math.max(
                        activity.numberOfReplies || 0,
                        activityNotes.filter(
                          (currentActivity) =>
                            currentActivity.threadId === activity.id
                        ).length
                      )}
                      handleDelete={handleDelete}
                      hasThreadPermission={true}
                      handleShowThread={() => {}}
                    />
                  </Fragment>
                );
              })}
            </div>
          ))}
        </div>
      </DeleteNotesStyled>
    </ActivityStreamProvider>
  );
}

export default DeleteNotes;
