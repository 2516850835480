import React from "react";
import { TableCell } from "@mui/material";
import { FooterRowStyled } from "../filterTableRefactor/MainRowStyled";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";

function FooterTable({ footer = [] }) {
  function footerText(value) {
    if (value === "Totals:") {
      return <span className="totals">{value}</span>;
    } else {
      return <span className="dollars">{value}</span>;
    }
  }
  return (
    <FooterRowStyled>
      {footer.map((cell) => {
        let response = null;
        const footerClassName = cell?.footerClass || "";
        if (cell.value) {
          response = (
            <TableCell
              key={cell.id}
              style={{
                ...cell.styles,
                borderBottom: "white 1px solid",
              }}
              className={cx(
                {
                  blueDivider: cell.divider,
                  grayDivider: !cell.divider,
                },
                footerClassName
              )}
              colSpan={cell.colSpan ? cell.colSpan : 1}
              id={"footerCell"}
            >
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <TooltipTD
                  className="customized-tooltip"
                  label={footerText(cell.value)}
                >
                  {footerText(cell.value)}
                </TooltipTD>
              </span>
            </TableCell>
          );
        }
        return response;
      })}
      <TableCell
        className="grayDivider"
        style={{
          borderBottom: "white 1px solid",
        }}
      />
      <TableCell
        className="grayDivider"
        style={{
          borderBottom: "white 1px solid",
        }}
      />
    </FooterRowStyled>
  );
}

export default FooterTable;
