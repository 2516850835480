import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  ShipmentDateWindowPopoverStyled,
  ShipmentDateWindowStyled,
} from "./styles";
import WindowTable from "./WindowTable";
import { TableContainer } from "@mui/material";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { Menu } from "@mui/icons-material";
import { dbTables } from "../../api/types/dbTables";

function ShipmentDateWindow({
  shipments = [],
  title = "Shipment Window",
  field = "shipDate",
  headCells,
  propertyScope,
  setSignShipment,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsLoading(true);
  };

  const handleClose = () => {
    if (!isLoading) setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      if (propertyScope === dbTables.SALES_ORDERS) {
        setSignShipment(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [anchorEl]);




  return (
    <ShipmentDateWindowStyled
      className="shipmentDateWindowContainer"
      style={{ cursor: "pointer" }}
    >
      <div className="valueContainer" onClick={handleClick}>
        <TooltipTD label={"Click to see more"}>
          <Menu style={{ height: 18 }} />
        </TooltipTD>
      </div>
      {!!anchorEl && (
        <ShipmentDateWindowPopoverStyled
          id="simple-popover"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "shipmentDateWindowPaperContainer" }}
          className="shipmentDateWindowPopoverContainer"
        >
          <div className="sdw-title-container">{title}</div>

          <div className="sdw-divider" />

          <div className="sdw-body-container">
            {isLoading && <LoadingBackdrop withLogo={true} />}

            <TableContainer>
              <WindowTable
                shipmentsToRender={shipments.sort(sortObjectsBy(field, false))}
                headCells={headCells}
             
                onClose={handleClose}
              />
            </TableContainer>
          </div>
        </ShipmentDateWindowPopoverStyled>
      )}
    </ShipmentDateWindowStyled>
  );
}

export default ShipmentDateWindow;
