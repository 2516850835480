import { FIELD_SCOPE } from "../../helpers/constants";

export default class ColumnHeader {
  constructor({
    index,
    label,
    field = "",
    unmovable = false,
    width,
    enabled, // this is used for non displaying column in the view (this is the checkbox value in the column menu)
    source = FIELD_SCOPE.BUILD_IN_FIELDS,
    type = "",
  }) {
    this.enabled = !!enabled;
    this.index = index;
    this.label = label;
    this.field = field;
    this.unmovable = unmovable;
    this.width = width;
    this.source = source;
    this.type = type;
  }
}
