import React from "react";
import { isEqual } from "lodash";
import { dbTables } from "../../../api/types/dbTables";
import {
  BACKDROP_TYPE,
  MISCELLANEOUS_IDS,
  shipmentObjFields,
} from "../../../helpers/constants";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../../hooks/permissions";
import { useMiscellaneous, useUser } from "../../../hooks/user";
import { useCurrentCustomer } from "../../../hooks/customers";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { cx } from "@emotion/css";
import { BackdropDashboard } from "./BackdropDashboard";
import {
  getMiscellaneoList,
  getParseAmount,
  renderMetadataTextField,
} from "../dashboardHelpers";
import { sortObjectsBy } from "../../../helpers/sortingHelper";
import { shipmentTriggerTypes } from "../../../helpers/shipment";
import { firestore } from "../../../firebase";
import AutocompleteMetadata from "../../TextFields/AutocompleteMetadata";

function ShipmentMetadataInfo({ shipment = {} }) {
  const currentCustomer = useCurrentCustomer() || {};
  const isAllowedCompany = useIsAllowedCompany();
  const isAllowed = useIsAllowedFunction();
  const user = useUser();
  const dataMiscellaneous = useMiscellaneous() || [];
  const shipmentMetadata = getMiscellaneoList({
    miscellaneous: dataMiscellaneous,
    miscellaneoId: MISCELLANEOUS_IDS.SHIPMENT_METADATA,
  });
  const finalDestinationMetadata =
    shipmentMetadata.find(
      (item) => item.field === shipmentObjFields.FINAL_DESTINATION
    ) || {};
  const id = finalDestinationMetadata?.listName || finalDestinationMetadata?.id;
  const options = currentCustomer[id] || {};
  const optionsList = Object.keys(options).map((key) => ({
    id: key,
    label: options[key].name,
  }));

  const companyId = user.companyId;
  const isReadOnly = false;

  function onChangeShipmentProperty({ value, field, type, label }) {
    if (isEqual(shipment[field], value)) {
      return;
    }
    updateDoc(shipment.ref, {
      [field]: value,
      updated_by: user.id,
      triggerTypeMetadata: {
        field: field,
        fieldType: type,
        label: label,
      },
      triggerType: shipmentTriggerTypes.METADATA,
    });
  }
  function onChangeAmountProperty({ value, field, type, label }) {
    const parseAmountValue = getParseAmount(value, shipment[field]);
    if (parseAmountValue === undefined) {
      return;
    }
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;

    updateDoc(
      doc(firestore, `${companyRef}/${dbTables.SHIPMENTS}/${shipment.id}`),
      {
        [field]: parseAmountValue,
        updated_by: user.id,
        triggerTypeMetadata: {
          field: field,
          fieldType: type,
          label: label,
        },
        triggerType: shipmentTriggerTypes.METADATA,
      }
    );
  }

  return (
    <React.Fragment>
      {shipment.number && (
        <BackdropDashboard backdropType={BACKDROP_TYPE.METADATA} />
      )}

      <div className="sales-order-info">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 0px",
          }}
        >
          <AutocompleteMetadata
            item={finalDestinationMetadata}
            value={shipment[finalDestinationMetadata.field]}
            editable={false}
            scope={dbTables.SHIPMENTS}
            inputLarge={true}
            onChange={(value) =>
              onChangeShipmentProperty({
                value,
                field: finalDestinationMetadata.field,
                label: finalDestinationMetadata.label,
                type: finalDestinationMetadata.type,
              })
            }
            onSaveNewOption={async (newItem) => {
              updateDoc(currentCustomer.ref, {
                [`${id}.${newItem.id}`]: {
                  ...newItem,
                },
              });
              onChangeShipmentProperty({
                value: newItem.name,
                field: finalDestinationMetadata.field,
                label: finalDestinationMetadata.label,
                type: finalDestinationMetadata.type,
              });
            }}
            options={optionsList.sort(sortObjectsBy("label", false))}
            onRemove={async (newItem) => {
              updateDoc(currentCustomer.ref, {
                [`${id}.${newItem.id}`]: deleteField(),
              });
            }}
          />
        </div>
        <div className="metadata-info-container">
          <div className={cx("metadata-info", "shipmentInfoGrid-new")}>
            {shipmentMetadata
              .filter(
                (item) =>
                  item.active &&
                  item.field !== shipmentObjFields.FINAL_DESTINATION
              )
              .sort(sortObjectsBy("listIndex", false))
              .map((item) =>
                renderMetadataTextField({
                  item,
                  isAllowed,
                  isReadOnly,
                  onChangeAmountProperty,
                  onChangeEntityProperty: onChangeShipmentProperty,
                  entity: shipment,
                  currentCustomer,
                  scope: dbTables.SHIPMENTS,
                  isAllowedCompany,
                })
              )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShipmentMetadataInfo;
