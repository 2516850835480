import React, { useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import CustomButton from "../Buttons/CustomButton";
import { dbTables } from "../../api/types/dbTables";
import { isTradeDashEmployee } from "../../helpers/helpers";
import PermissionGroupRow from "./RowTable/PermissionGroupRow";
import PermissionGroup from "../../api/model/permissionGroup";
import CustomModal from "../Modal/Modal";
import FormTextField from "../TextFields/FormTextField";
import { useCompanyUsers, useUser } from "../../hooks/user";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { firestore } from "../../firebase";
import { usePermissionGroups } from "../../hooks";
import { doc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { validAccessGroup } from "../../helpers/users";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { SettingsTabTable } from "./styles";
import {
  getSettingsTableHeader,
  getValidatePermissionName,
  isUniquePermissionName,
} from "./companyTabHelper";
import { TextareaAutosize } from "@mui/material";
import InactiveDivider from "../Dividers/InactiveDivider";
import { AccessGroupHeadCells } from "./settingsHeaders";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function PermissionGroupsAdmin() {
  const user = useUser();
  const { companyId } = useParams();
  const permissionGroups = usePermissionGroups({
    companyId: companyId,
    filteringByInactive: false,
  });

  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });

  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [newPermissionData, setNewPermissionData] = useState({
    name: "",
    description: "",
  });
  const [currentPermissionGroup, setCurrentPermissionGroup] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    user: false,
    sortedColumn: "name",
    orderBy: "DESC",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    description: <></>,
  });
  const uniqueName = isUniquePermissionName({
    currentPermissionGroup,
    newPermissionName: newPermissionData.name,
    permissionGroups,
  });

  function addPermissionGroup() {
    if (validAccessGroup(newPermissionData.name)) {
      let permissionGroup = "";
      if (isUploading) {
        permissionGroup = new PermissionGroup({
          id: currentPermissionGroup.id,
          name: newPermissionData.name,
          description: newPermissionData.description,
          users: currentPermissionGroup.users,
        });
      } else {
        permissionGroup = new PermissionGroup({
          name: newPermissionData.name,
          description: newPermissionData.description,
        });
      }
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.PERMISSION_GROUPS}/${permissionGroup.id}`
        ),
        { ...permissionGroup }
      );
      closeModal();
      setIsUploading(false);
      setCurrentPermissionGroup({});
    } else {
      setSnackbar({
        open: true,
        description:
          "Invalid access group name. Please ensure the name only includes letters, numbers, hyphens, and underscores; and is between 2 and 50 characters in length.",
      });
    }
  }

  function closeModal() {
    setIsEdit(false);
    setCustomModalOpen(false);
    setNewPermissionData({
      name: "",
      description: "",
    });
  }
  function openModal() {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
    } else {
      setCurrentPermissionGroup({});
      setCustomModalOpen(true);
    }
  }

  function handleReadOnlyModal() {
    setReadOnlyModal(true);
  }

  function onEditGroup(item) {
    const currentName = item.name || "";
    const currentDecription = item.description || "";
    setCurrentPermissionGroup(item);
    setCustomModalOpen(true);
    setNewPermissionData({
      name: currentName.trim(),
      description: currentDecription.trim(),
    });
    setIsEdit(true);
    setIsUploading(true);
  }

  const tbodyDisplay = (permissionGroupItem = []) => {
    return permissionGroupItem
      .sort(
        sortObjectsBy(
          nonLookUpfilters.sortedColumn,
          nonLookUpfilters.orderBy === "ASC" ? true : false
        )
      )
      .map((permissionGroup) => {
        return (
          <PermissionGroupRow
            permissionGroup={permissionGroup}
            onEditGroup={onEditGroup}
            companyId={companyId}
            disabled={isTradeDashEmployee(user)}
            handleReadOnlyModal={handleReadOnlyModal}
            key={permissionGroup.id}
            companyUsers={companyUsers}
          />
        );
      });
  };

  const validatePermissionName = getValidatePermissionName(
    newPermissionData,
    uniqueName
  );
  return (
    <div className="mainTableContainer">
      {customModalOpen && (
        <CustomModal
          header={
            !isEdit ? (
              <IntlMessages id={"permissionGroup.create.custommodal"} />
            ) : (
              <IntlMessages id={"permissionGroup.edit.custommodal"} />
            )
          }
          isOpen={customModalOpen}
          onClose={closeModal}
          className={"PermissionGroupData"}
        >
          <div className="bodyContainer">
            <FormTextField
              value={newPermissionData.name}
              onChange={(ev) =>
                setNewPermissionData((oldData) => ({
                  ...oldData,
                  name: ev.target.value,
                }))
              }
              label="generic.textForm.name"
              disabled={isTradeDashEmployee(user)}
              containerClass="permissionGroupContainerForm"
              inputError={validatePermissionName.message}
              inputStyle={validatePermissionName.style}
              labelStyle={{
                display: "flex",
                minWidth: 90,
                flexDirection: "row",
                padding: "5px 0px",
              }}
              required={true}
              spanErrorStyle={{
                top: 32,
              }}
            />
            <div className={"formContainer"}>
              <span className="descriptionLabel">Description</span>
              <TextareaAutosize
                className="descriptionText"
                value={newPermissionData.description}
                onChange={(ev) =>
                  setNewPermissionData((oldData) => ({
                    ...oldData,
                    description: ev.target.value,
                  }))
                }
                placeholder="Permission Group description"
                required={false}
                minRows={2}
                maxRows={10}
                maxLength={250}
                style={{
                  resize: "vertical",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              onClick={addPermissionGroup}
              disabled={
                !newPermissionData.name.trim() ||
                isTradeDashEmployee(user) ||
                !uniqueName
              }
              type="success"
            >
              {`generic.text.${!isEdit ? "create" : "submit"}`}
            </CustomButton>
            <CustomButton onClick={closeModal} type="cancel">
              {"generic.text.cancel"}
            </CustomButton>
          </div>
        </CustomModal>
      )}
      {snackbar.open && (
        <CustomSnackbar
          open={snackbar.open}
          message={snackbar.description}
          variant="error"
          handleCloseSnackbar={() =>
            setSnackbar({
              open: false,
              description: <></>,
            })
          }
          autoHideDuration={2500}
        />
      )}
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <div
        className="actionButtonContainer"
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <div className="tabTitle">
          <IntlMessages id="components.companytabs.permissionGroups.title" />
        </div>
        <CustomButton type="create" onClick={openModal}>
          {"components.companytabs.permissionGroups.custombutton.addGroup"}
        </CustomButton>
      </div>

      <SettingsTabTable className="AccessGroupTable">
        {getSettingsTableHeader({
          headers: AccessGroupHeadCells,
          filters: nonLookUpfilters,
          updateSorting: (nonLookUpfilters) => {
            setNonLookUpFilters({ ...nonLookUpfilters });
          },
        })}
        {tbodyDisplay(
          permissionGroups.filter(
            (permissionGroup) => !permissionGroup.isInactive
          )
        )}
        <InactiveDivider text="Deactivated Access Groups" />
        {tbodyDisplay(
          permissionGroups.filter(
            (permissionGroup) => permissionGroup.isInactive
          )
        )}
      </SettingsTabTable>
    </div>
  );
}

export default PermissionGroupsAdmin;
