import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
export const TabRoutes = [
  {
    url: "companyPermission",
    label: "Company Permissions",
    onlyEmployee: true,
  },
  {
    url: "featureFlags",
    label: "Feature Flags",
    onlyEmployee: true,
  },
  {
    url: "labels",
    label: "Labels",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_LABELS,
  },
  {
    url: "categories",
    label: "Categories",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_CATEGORIES,
  },
  {
    url: "reports",
    label: "Reports",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_REPORTS,
  },
  {
    url: "users",
    label: "Users",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_USERS,
  },
  {
    url: "roles",
    label: "Roles",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_ROLES,
  },
  {
    url: "documents",
    label: "Document Placeholders",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_PLACEHOLDER,
  },
  {
    url: "factories",
    label: "Vendors",
    onlyEmployee: true,
  },
  {
    url: "customers",
    label: "Customers",
    onlyEmployee: true,
  },
  {
    url: "permission_groups",
    label: "Access Groups",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_ACCESS_GROUPS,
  },
  {
    url: "sales_orders_template",
    label: "Sales Order Templates",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_TEMPLATE,
  },
  {
    url: "purchase_orders_template",
    label: "PO Templates",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_PO_TEMPLATE,
    warningIcon: true
  },
  {
    url: "gl_accounts",
    label: "G/L Accounts",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_GL_ACCOUNTS,
  },
  {
    url: "suppliers",
    label: "Suppliers",
    permission: GENERAL_PERMISSION_VALUE.SUPPLIER,
  },
  {
    url: "field_customization",
    label: "Field Customization",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_FIELDS_CUSTOM,
  },
  {
    url: "projects",
    label: "Projects",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_PROJECTS,
  },
  {
    url: "imported_api__data",
    label: "API Imported Data",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_API_DATA,
  },
  {
    url: "api_error_log",
    label: "API Error Log",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_API_ERROR_LOG,
  },
  {
    url: "api_settings",
    label: "API Settings",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_API_CONFIGURATION,
  },
  {
    url: "back-end_error_log",
    label: "Back-End Error Log",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_BACKEND_ERRORLOG,
  },
  {
    url: "front-end_error_log",
    label: "Front-End Error Log",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_FRONTEND_ERRORLOG,
  },
  {
    url: "delete_note",
    label: "Delete Note",
    onlySuperAdmin: true,
  },
];
