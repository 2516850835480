import React, { useState } from "react";
import { CompanyPermissionsStyled } from "./styles";
import { CompanyPermissionsData } from "../../helpers/constants";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import CustomButton from "../Buttons/CustomButton";
import { useCompanyUsers } from "../../hooks/user";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import { firestore } from "../../firebase";
import userTypes from "../../api/types/userTypes";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { Tooltip } from "@mui/material";
import {
  getHeaderByScreen,
  setNewErrorLog,
} from "../CompanyTabs/companyTabHelper";
import { useLocation } from "react-router-dom";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";

function CompanyPermissions({ companyId, permissions = [] }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const companyUsers = useCompanyUsers({ id: companyId });
  const list = CompanyPermissionsData.map((currentPermission) => {
    const permission =
      permissions.find(
        (companyPermission) => currentPermission.value === companyPermission.id
      ) || {};
    return { ...currentPermission, enabled: !!permission.enabled };
  });
  async function changePermission(permission) {
    const value = permission.value;
    const checked = permission.enabled;
    try {
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.COMPANY_PERMISSION}/${value}`
        ),
        {
          enabled: !checked,
          permission: value,
          id: value,
        }
      );
    } catch (error) {
      setNewErrorLog({ companyId, error, location });
      console.log(error, "error");
    }
  }

  function changeAllPermissionFromCategory(list, enabled) {
    list.forEach((permission) => {
      const id = permission.value;
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.COMPANY_PERMISSION}/${id}`
        ),
        {
          enabled: enabled,
          permission: permission.value,
          id,
        }
      );
    });
  }

  const syncUserHeader = async (scope) => {
    setLoading(true);
    const tradedashEmployeesSnapDB = await getDocs(
      query(
        collection(firestore, dbTables.USERS),
        where("role", "==", userTypes.TRADEDASH_EMPLOYEE)
      )
    );
    const tradedashEmployeesDB = tradedashEmployeesSnapDB.docs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
    }));

    const currentUsers = [...tradedashEmployeesDB, ...companyUsers];
    const newHeader = await getHeaderByScreen({ companyId, getDoc, scope });
    currentUsers.forEach((user) => {
      const pathRef = `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`;

      updateDoc(doc(firestore, pathRef), {
        [scope]: newHeader,
      }).then(() => {
        updateDoc(user.ref, {
          shouldUpdateHeader: !user.shouldUpdateHeader,
        });
      });
    });
    setLoading(false);
  };

  return (
    <CompanyPermissionsStyled className="companyPermissionsContainer">
      {loading && <LoadingBackdrop />}
      <div className="permissions-header-tab">
        <h6 className="permission-header-text">Company permission</h6>
        <div className="selectAllNoneContainer">
          <span
            className="select-all-button"
            onClick={() => changeAllPermissionFromCategory(list, true)}
          >
            Select all
          </span>
          -
          <span
            className="select-none-button"
            onClick={() => changeAllPermissionFromCategory(list, false)}
          >
            Clear
          </span>
        </div>
      </div>
      <ul className="permission-list">
        {list.map((permission, index) => (
          <li
            className="permission-list-item"
            key={permission.value + index}
            style={{ padding: 3, width: "100%" }}
          >
            <label
              className="switch"
              style={{ margin: 0, minWidth: 77 }}
              onClick={(ev) => {
                changePermission(permission);
                ev.stopPropagation();
              }}
            >
              <input
                checked={permission.enabled}
                value={permission.value}
                type="checkbox"
              />
              <span
                data-positive="YES"
                data-negative="NO"
                className="slider round"
              />
            </label>
            <div
              className="permission-label-container"
              style={{ marginLeft: 8 }}
            >
              <strong>
                {permission.prefix ? (
                  <span className="permission-label-prefix">
                    {permission.prefix}
                  </span>
                ) : (
                  <span className="permission-label-prefix-empty"></span>
                )}
              </strong>
              <span className="permission-label">{permission.label}</span>
              <Tooltip placement="right-end" title={permission.tooltip}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    backgroundColor: "#ccc",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
      <div className="permissions-header-tab" style={{ display: "block" }}>
        <h6 className="permission-header-text">Custom Settings</h6>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            rowGap: 5,
          }}
        >
          <CustomButton
            onClick={() => syncUserHeader(columnHeaderSetup.SO_HEADER_SETUP)}
          >
            update.column.headers.sales.order
          </CustomButton>
          <CustomButton
            onClick={() => syncUserHeader(columnHeaderSetup.PO_HEADER_SETUP)}
          >
            update.column.headers.purchase.order
          </CustomButton>
          <CustomButton
            onClick={() =>
              syncUserHeader(columnHeaderSetup.SHIPMENT_HEADER_SETUP)
            }
          >
            update.column.headers.shipment
          </CustomButton>
          <CustomButton
            onClick={() =>
              syncUserHeader(columnHeaderSetup.EXPENSES_HEADER_SETUP)
            }
          >
            update.column.headers.expenses
          </CustomButton>
          <CustomButton
            onClick={() => syncUserHeader(columnHeaderSetup.TASK_HEADER_SETUP)}
          >
            update.column.headers.tasks
          </CustomButton>
        </div>
      </div>
    </CompanyPermissionsStyled>
  );
}

export default CompanyPermissions;
