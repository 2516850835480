import React, {  useEffect, useState } from "react";
import { motion } from "framer-motion";

import { useOrderDashboardActualView } from "../../hooks/user";

import ShipmentMetadata from "./sections/ShipmentMetadata";
import ShipmentNavigation from "./sections/ShipmentNavigation";

import { colors } from "../../assets/jss/variables";
import { cx } from "@emotion/css";
import { ORDER_DASHBOARD_ACTUAL_VIEWS, PERMISSION_TEXT } from "../../helpers/constants";

function ShipmentDashboardContent({ purchaseOrder, vendorPermission }) {
  const [exitShipment, setExitShipment] = useState(true);
  const [isTabActive, setIsTabActive] = useState(!document.hidden);
  const actualView = useOrderDashboardActualView();
  const isShipmentView = actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT;
  const isSOView = actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER;

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);


  useEffect(() => {
    const { shipmentIds = [] } = purchaseOrder;
    if (shipmentIds.length > 0 && exitShipment) {
      setExitShipment(false);
    }
    if (shipmentIds.length === 0 && !exitShipment) {
      const timer = isShipmentView ? 1000 : 50;
      setTimeout(() => setExitShipment(true), timer);
    }
  }, [purchaseOrder.shipmentIds]);

  const ShipmentContent = () => (
    <>
      <ShipmentNavigation />
      <div
        className={cx(
          "shipment-info-container",
          isShipmentView
            ? "shipment-info-container-visible"
            : "shipment-info-container-hidden"
        )}
      >
        <div className="min-height-metadata-content-shipment">
          {isShipmentView &&
            (vendorPermission ? (
              <ShipmentMetadata />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "inherit",
                  fontSize: 21,
                  color: colors.dangerRed,
                }}
              >
                {purchaseOrder.id
                  ? PERMISSION_TEXT.SHIPMENT_IN_VENDOR_THAT_ARE_NOT_ALLOWED
                  : ""}
              </div>
            ))}
        </div>
      </div>
    </>
  );

  const motionValue = {
    initial: { x: 0 },
    animate: exitShipment ? { x: "120%" } : { x: 0 },
    exit: { x: exitShipment ? 300 : 0 },
    transition: {
      duration: purchaseOrder.id ? 1.5 : 0,
      ease: "easeInOut",
    },
    style: {
      height: isShipmentView || isSOView ? "100%" : 59.1,
      transition: "opacity 0.8s, margin-top 1.2s, height 0.8s ease, top 1.2s",
      opacity: isSOView ? 0 : 1,
      marginTop: isSOView ? "100%" : 0,
      top: isSOView ? "100%" : 0,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      willChange: "unset",
    },
  };

  return (
    <motion.div
      {...motionValue}
      id={"shipmentContentMotion"}
      key={`motion-${isTabActive}`}
    >
      <ShipmentContent />
    </motion.div>
  );
}

export default ShipmentDashboardContent;
