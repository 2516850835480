import React, { useState } from "react";
import { getRandomId, isTradeDashEmployee } from "../../helpers/helpers";
import { dbTables } from "../../api/types/dbTables";
import CustomButton from "../Buttons/CustomButton";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { firestore } from "../../firebase";
import { useCategories, useCategoryItems } from "../../hooks";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import AttentionModal from "../Modal/AttentionModal";
import { useParams } from "react-router-dom";
import CategoryItem from "../../api/model/CategoryItem.model";
import Category from "../../api/model/Category.model";
import { now } from "moment/moment";
import CreateCategory from "../Categories/CreateCategory";
import { SettingsTabTable } from "./styles";
import InactiveDivider from "../Dividers/InactiveDivider";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import CategoryTableRow from "./RowTable/CategoryTableRow";
import { categoriesTableheader } from "./settingsHeaders";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { getSettingsTableHeader } from "./companyTabHelper";

export const maxCategories = 50;

function Categories() {
  const { companyId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const user = useUser();
  const isAllowed = useIsAllowedFunction();
  const [isUpdating, setIsUpdating] = useState(false);
  const [categoryItem, setCategoryItem] = useState({});
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    user: false,
    sortedColumn: "name",
    orderBy: "DESC",
  });
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );

  const categories = useCategories();
  const categoryItems = useCategoryItems({});
  const users = useCompanyUsers({});
  const onlyRead = isTradeDashEmployee(user);

  function handleAddGLAccount() {
    setCategoryItem({ ...new CategoryItem({}) });
    setIsUpdating(false);
    setOpenModal(true);
  }

  function createNewCategory({ categoryItem }) {
    const id = getRandomId();
    const newCategory = new Category({
      id,
      categories: [
        {
          ...new CategoryItem({
            id: categoryItem.id,
            name: categoryItem.name,
            description: categoryItem.description,
            summaryId: id,
            active: true,
            createdBy: user.id,
            createdDate: now(),
            updatedBy: "",
            updatedDate: "",
          }),
        },
      ],
      size: 1,
    });
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.CATEGORIES}/${newCategory.id}`
      ),
      { ...newCategory }
    );
    setOpenModal(false);
  }

  async function handleSubmit({ currentCategoryItem }) {
    const foundCurrentCategory = categoryItems
      .filter((item) => item.id !== currentCategoryItem.id)
      .find(
        (category) =>
          category.name.toString().toLowerCase().trim() ===
          currentCategoryItem.name.toString().toLowerCase().trim()
      );
    if (foundCurrentCategory) {
      setDescriptionModalAttention(
        <React.Fragment>That category name is already in use</React.Fragment>
      );
      setOpenModalAttention(true);
      return;
    }
    if (categories.length === 0) {
      createNewCategory({ categoryItem: currentCategoryItem });
      return;
    }
    if (isUpdating) {
      const category = categories.find(
        (item) => item.id === currentCategoryItem.summaryId
      );
      let categoriesCpy = [...category.categories];
      categoriesCpy = categoriesCpy.map((item) => {
        if (item.id === currentCategoryItem.id) {
          return {
            ...currentCategoryItem,
            updatedBy: user.id,
            updatedDate: now(),
          };
        }
        return item;
      });
      updateDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.CATEGORIES}/${category.id}`
        ),
        {
          categories: categoriesCpy,
        }
      );
      setOpenModal(false);
    } else {
      const lastCategory = categories.sort(
        sortObjectsBy("creationDate", true)
      )[0];
      if (+lastCategory.size < maxCategories) {
        const categoriesCpy = [...lastCategory.categories];
        categoriesCpy.push({
          ...currentCategoryItem,
          summaryId: lastCategory.id,
          createdBy: user.id,
          createdDate: now(),
        });
        setDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.CATEGORIES}/${lastCategory.id}`
          ),
          {
            ...lastCategory,
            categories: categoriesCpy,
            size: categoriesCpy.length,
          }
        );
        setOpenModal(false);
      } else {
        createNewCategory({ categoryItem: currentCategoryItem });
        return;
      }
    }
  }

  function handleCloseModal() {
    setOpenModal(false);
  }
  const tbodyDisplay = ({ categoryItems = [] }) =>
    categoryItems
      .sort(
        sortObjectsBy(
          nonLookUpfilters.sortedColumn,
          nonLookUpfilters.orderBy === "ASC" ? true : false
        )
      )
      .map((categoryItem) => (
        <CategoryTableRow
          canChangeActive={
            !onlyRead &&
            isAllowed(GENERAL_PERMISSION_VALUE.CAN_ACTIVE_INACTIVE_CATEGORIES)
          }
          canRename={
            !onlyRead && isAllowed(GENERAL_PERMISSION_VALUE.RENAME_CATEGORIES)
          }
          categories={categories}
          companyId={companyId}
          categoryItem={categoryItem}
          users={users}
          onEditData={() => {
            setCategoryItem({ ...categoryItem });
            setIsUpdating(true);
            setOpenModal(true);
          }}
        />
      ));

  return (
    <div className="mainTableContainer">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => {
            setOpenModalAttention(false);
            const categoryEl = document.getElementById(
              "category-id-" + categoryItem.id
            );
            setTimeout(() => {
              categoryEl.focus();
              categoryEl.select();
            }, 5);
          }}
          cancellable={false}
          confirmationText="Ok"
          onClose={() => setOpenModalAttention(false)}
        />
      )}
      {openModal && (
        <CreateCategory
          openModal={openModal}
          handleClose={handleCloseModal}
          isUpdating={isUpdating}
          categoryItem={categoryItem}
          onSubmit={handleSubmit}
          user={user}
        />
      )}
      <div className="actionButtonContainer">
        {isAllowed(GENERAL_PERMISSION_VALUE.CREATE_CATEGORIES) && (
          <CustomButton type="create" onClick={handleAddGLAccount}>
            {"companytabs.user.button.addCategory"}
          </CustomButton>
        )}
      </div>

      <SettingsTabTable className="CategoriesTable">
        {getSettingsTableHeader({
          headers: categoriesTableheader,
          filters: nonLookUpfilters,
          updateSorting: (nonLookUpfilters) => {
            setNonLookUpFilters({ ...nonLookUpfilters });
          },
        })}
        {tbodyDisplay({
          categoryItems: categoryItems.filter(
            (categoryItem) => categoryItem.active
          ),
        })}

        <InactiveDivider text="Inactive Categories" />
        {tbodyDisplay({
          categoryItems: categoryItems.filter(
            (categoryItem) => !categoryItem.active
          ),
        })}
      </SettingsTabTable>
    </div>
  );
}

export default Categories;
