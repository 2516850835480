import moment from "moment";
import numeral from "numeral";
import {
  EXPENSES_HEADER_LABELS,
  GENERAL_PERMISSION_VALUE,
  headerColumns,
} from "./constants";
import { sortObjectsBy } from "./sortingHelper";
import { parseNumberValue } from "./orderDashboardRefactored";
import { EXPENSE_FIELDS, orderType, zeroIsBlankText } from "./salesOrder";
import { orderTypeSort } from "./salesOrderHelpers";

const TYPE = {
  [orderType.SALES_ORDER]: "Sales Order",
  [orderType.SHIPMENT]: "Shipment",
  [orderType.PURCHASE_ORDER]: "Purchase Order",
  [orderType.PURCHASE_QUOTE]: "Purchase Quote",
  [orderType.QUOTE]: "Quote",
};

const IS_DATE = {
  creationDate: true,
  exportedDate: true,
  date: true,
};

export const TYPE_GL_ITEMS = {
  TEXT: "text",
  DOLAR: "dolar",
  PERCENTAGE: "percentage",
  GL: "gl",
};

export const getValueByType = ({ type, value, GLAccountItems }) => {
  let textColor = {};
  let currentValue = value;
  if (TYPE_GL_ITEMS.DOLAR === type) {
    currentValue = zeroIsBlankText(currentValue);
  } else if (TYPE_GL_ITEMS.PERCENTAGE === type) {
    const percentageValue = parseNumberValue(currentValue) * 100;
    if (percentageValue < 0) {
      textColor = { color: "red" };
    }
    currentValue =
      !percentageValue || percentageValue === -100
        ? ""
        : `${percentageValue.toFixed(1)}%`;
  } else if (TYPE_GL_ITEMS.GL === type) {
    const currentGL = getGLAccount({
      itemId: currentValue,
      items: GLAccountItems,
    });
    currentValue = `${currentGL.name || ""} - ${currentGL.description || ""}`;
  }
  return { styleText: textColor, currentValue };
};

export const parseCSVExpenses = ({ expenses, glItems }) => {
  const csvExpensesList = [];
  for (let i = 0; i < expenses.length; i++) {
    const expense = expenses[i];
    const items = expense.items || [];
    const totalForecast = items.reduce(
      (sum, item) => sum + Number(item.forecast),
      0
    );
    items.forEach((item, index) => {
      const currentExpense = index === 0 ? expense : {};
      const currentGlAccount = getGLAccount({
        items: glItems,
        itemId: item.GLAccountId,
      });
      const { forecast = 0, actual = 0 } = item;
      let delta = parseNumberValue((forecast - actual) / totalForecast) * -100;
      delta = delta.toFixed(2).replaceAll(".", " .");
      const createdDate = moment(currentExpense.creationDate || "");
      let exportedDate = moment(
        currentExpense.exportedDate || moment().valueOf()
      );
      const date = moment(currentExpense.date).format("YYYY-MM-DD");
      exportedDate = !exportedDate.isValid()
        ? ""
        : exportedDate.format("YYYY-MM-DD");
      let csvData = {
        date: currentExpense.date ? date : "",
        paidTo: currentExpense.paidTo || "",
        itemType: TYPE[currentExpense.type] || "",
        orderNumber: currentExpense.orderNumber || "",
        GLAccountName: `${currentGlAccount.name} - ${currentGlAccount.description}`,
        descriptionOfCharges: item.descriptionOfCharges || "",
        notes: item.notes || "",
        actual: numeral(actual).format("0,0.00"),
        delta: item.actual ? delta + "%" : "",
        forecast: numeral(forecast).format("0,0.00"),
        customerName: currentExpense.currentCustomerName || "",
        currentCustomerPO: currentExpense.currentCustomerPO || "",
        currentReferenceNumber: currentExpense.currentReferenceNumber || "",
        creationDate: !createdDate.isValid()
          ? ""
          : createdDate.format("YYYY-MM-DD"),
        exportedDate: index === 0 ? exportedDate : "",
      };
      csvExpensesList.push(csvData);
    });
  }
  return csvExpensesList;
};

export function footerExpensesScreen({
  headers = [],
  list = [],
  totalAmounts = {},
}) {
  return headers.map((header) => {
    if (header.label === headerColumns.PAID_TO) {
      return {
        id: header.id,
        value: `Totals of ${list.length} Expenses:`,
        styles: {
          padding: "9px 16px",
          height: 45,
        },
      };
    } else if (header.label === headerColumns.FORECAST) {
      return {
        id: header.id,
        value: totalAmounts.totalForecast || "",
        divider: true,
        styles: {
          padding: "9px 16px",
          height: 45,
        },
      };
    } else if (header.label === headerColumns.ACTUAL) {
      return {
        id: header.id,
        value: totalAmounts.totalActual || "",
        divider: true,
        styles: {
          padding: "9px 16px",
          height: 45,
        },
      };
    } else {
      return {
        id: header.id,
        value: " ",
        styles: {
          padding: "9px 16px",
          height: 45,
        },
      };
    }
  });
}

export const filterExpenseList = ({
  list = [],
  nonLookUpfilters = {},
  customers = [],
  glItems = [],
  isAllowed = () => {},
  user = { id: "" },
}) => {
  let filteredList = [];
  if (isAllowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_EXPENSES)) {
    filteredList = [...list];
  } else if (
    isAllowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF)
  ) {
    filteredList = [...list.filter((el) => el.createdBy === user.id)];
  }
  if (nonLookUpfilters.query) {
    const query = nonLookUpfilters.query;
    filteredList = filteredList.filter((expense) => {
      const parseQuery = query.toLowerCase();
      const {
        paidTo = "",
        order = {},
        type,
        items = [],
        glAccountIds = [],
      } = expense;
      const parsePaidTo = paidTo.toLowerCase();
      if (parsePaidTo.includes(parseQuery)) {
        return true;
      }
      const parseOrderNumber = `${order.number || ""}`.toLowerCase();
      if (parseOrderNumber.includes(parseQuery)) {
        return true;
      }
      const parseType = (TYPE[type] || "").toLowerCase();
      if (parseType.includes(parseQuery)) {
        return true;
      }
      const parseCustomerPO = (order.customerPO || "").toLowerCase();
      if (parseCustomerPO.includes(parseQuery)) {
        return true;
      }
      const parseReferenceNumber = (order.referenceNumber || "").toLowerCase();
      if (parseReferenceNumber.includes(parseQuery)) {
        return true;
      }
      const customersNames = (order.customerId || []).map((id) => {
        const currentCustomer = customers.find((cus) => cus.id === id) || {};
        return (currentCustomer.name || "").toLowerCase();
      });
      const hasCustomerName = customersNames.some((name) =>
        name.includes(parseQuery)
      );
      if (hasCustomerName) {
        return true;
      }
      const descriptions = items.map((item) =>
        (item.descriptionOfCharges || "").toLowerCase()
      );

      const hasDescription = descriptions.some((description) =>
        description.includes(parseQuery)
      );
      if (hasDescription) {
        return true;
      }

      const notes = items.map((item) => (item.notes || "").toLowerCase());
      const hasNote = notes.some((note) => note.includes(parseQuery));
      if (hasNote) {
        return true;
      }
      const glName = glAccountIds.map((id) => {
        const currentGL = glItems.find((gl) => gl.id === id) || {};
        return `${currentGL.name || ""} - ${
          currentGL.description || ""
        }`.toLowerCase();
      });
      const hasGLname = glName.some((name) => name.includes(parseQuery));
      if (hasGLname) {
        return true;
      }
      return false;
    });
  }
  filteredList.sort(
    sortObjectsBy(
      nonLookUpfilters.sortedColumn,
      nonLookUpfilters.orderBy === orderTypeSort.ASC ? true : false,
      IS_DATE[nonLookUpfilters.sortedColumn]
    )
  );

  let totalItems = 0;
  let totalActual = 0;
  let totalForecast = 0;

  filteredList.forEach((expense) => {
    totalItems += (expense.items || []).length;
    totalActual += getTotal({
      list: expense.items,
      field: EXPENSE_FIELDS.ACTUAL,
    });
    totalForecast += getTotal({
      list: expense.items,
      field: EXPENSE_FIELDS.FORECAST,
    });
  });

  const totalAmounts = {
    totals: totalItems,
    totalActual: numeral(totalActual).format("$ 0,0.00"),
    totalForecast: numeral(totalForecast).format("$ 0,0.00"),
  };
  return { filteredList, totalAmounts, totalExpenses: filteredList.length };
};

export function getGLAccount({ items = [], itemId = "" }) {
  const item = items.find((item) => item.id === itemId);
  if (item) {
    return {
      name: item.name,
      description: item.description,
    };
  }
  return {
    name: "",
    description: "",
  };
}

export function getTotal({ list = [], field = "", format = false }) {
  let total = 0;
  if (field === EXPENSE_FIELDS.DELTA) {
    const forecast = list.reduce((accumulator, currentValue) => {
      if (currentValue[EXPENSE_FIELDS.FORECAST])
        return (
          accumulator + numeral(currentValue[EXPENSE_FIELDS.FORECAST]).value()
        );
      return accumulator;
    }, 0);
    const actual = list.reduce((accumulator, currentValue) => {
      if (currentValue[EXPENSE_FIELDS.ACTUAL])
        return (
          accumulator + numeral(currentValue[EXPENSE_FIELDS.ACTUAL]).value()
        );
      return accumulator;
    }, 0);
    total = parseNumberValue(((forecast - actual) / forecast) * -1);
  } else {
    total = list.reduce((accumulator, currentValue) => {
      if (currentValue[field])
        return accumulator + numeral(currentValue[field]).value();
      return accumulator;
    }, 0);
  }

  if (format) {
    return numeral(total).format(format);
  }
  return parseFloat(total);
}

export function canDeleteExpense({ isAllowed, createdBy, userId }) {
  if (isAllowed(GENERAL_PERMISSION_VALUE.CAN_DELETE_ANY_EXPENSE)) {
    return true;
  } else if (
    isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_ONLY_DELETE_EXPENSE_ENTERED_BY_SELF
    ) &&
    createdBy === userId
  ) {
    return true;
  }
  return false;
}

export function canEditExpense({ isAllowed, createdBy, userId }) {
  if (isAllowed(GENERAL_PERMISSION_VALUE.CAN_EDIT_ANY_EXPENSE)) {
    return true;
  } else if (
    isAllowed(GENERAL_PERMISSION_VALUE.CAN_ONLY_EDIT_EXPENSE_ENTERED_BY_SELF) &&
    createdBy === userId
  ) {
    return true;
  }
  return false;
}

export function verifyRequiredFields({
  expense = {
    items: [],
  },
}) {
  const requiredFields = [];
  expense.items.forEach((item) => {
    const { actual = 0, forecast = 0 } = item;
    if (
      !expense.date &&
      !requiredFields.includes(EXPENSES_HEADER_LABELS.DATE)
    ) {
      requiredFields.push(EXPENSES_HEADER_LABELS.DATE);
    }
    if (
      !expense.paidTo &&
      !requiredFields.includes(EXPENSES_HEADER_LABELS.PAID_TO)
    ) {
      requiredFields.push(EXPENSES_HEADER_LABELS.PAID_TO);
    }
    if (
      !item.GLAccountId &&
      !requiredFields.includes(EXPENSES_HEADER_LABELS.GL_ACCOUNT)
    ) {
      requiredFields.push(EXPENSES_HEADER_LABELS.GL_ACCOUNT);
    }
    if (
      !item.descriptionOfCharges.trim() &&
      !requiredFields.includes(EXPENSES_HEADER_LABELS.DESCRIPTION_OF_CHARGES)
    ) {
      requiredFields.push(EXPENSES_HEADER_LABELS.DESCRIPTION_OF_CHARGES);
    }
    if (
      actual <= 0 &&
      forecast <= 0 &&
      !requiredFields.includes(EXPENSES_HEADER_LABELS.FORECAST_OR_ACTUAL)
    ) {
      requiredFields.push(EXPENSES_HEADER_LABELS.FORECAST_OR_ACTUAL);
    }
  });
  return requiredFields;
}

export function isEqualTotalAmount({ items = [], totalActual = 0 }) {
  const sumTotalActual = getTotal({
    list: items,
    field: EXPENSE_FIELDS.ACTUAL,
    format: "$0,0.00",
  });
  if (sumTotalActual === numeral(totalActual).format("$0,0.00")) {
    return true;
  }
  return false;
}

export const EXCLUDE_PERFORM_MARK = [
  ".id-Actual",
  ".id-Forecast",
  ".id-Date",
  ".totals-content",
  ".totals-footer",
];

export function performMark({
  keyword = "",
  exclude = [],
  accuracy = "partially",
  markInstance = {},
}) {
  var options = {
    separateWordSearch: false,
    diacritics: false,
    debug: false,
    acrossElements: true,
    accuracy: accuracy,
    exclude: exclude,
  };
  markInstance.unmark({
    done: () => {
      markInstance.mark(keyword, options);
    },
  });
}

export const expensesHeader = [
  {
    id: "id-" + EXPENSES_HEADER_LABELS.DATE,
    label: EXPENSES_HEADER_LABELS.DATE,
    field: EXPENSE_FIELDS.DATE,
    sortBy: EXPENSE_FIELDS.DATE,
    headerStyles: { justifyContent: "flex-end" },
    bodyStyles: {
      justifyContent: "flex-end",
    },
  },
  {
    id: "id-" + EXPENSES_HEADER_LABELS.PAID_TO,
    label: EXPENSES_HEADER_LABELS.PAID_TO,
    field: EXPENSE_FIELDS.PAID_TO,
    sortBy: EXPENSE_FIELDS.PAID_TO,
    headerStyles: { paddingLeft: 6 },
    bodyStyles: {
      justifyContent: "flex-start",
    },
  },
  {
    id: "id-" + EXPENSES_HEADER_LABELS.GL_ACCOUNT,
    label: EXPENSES_HEADER_LABELS.GL_ACCOUNT,
    field: EXPENSE_FIELDS.GL_ACCOUNT_ID,
    sortBy: "GLAccountName",
    headerStyles: { paddingLeft: 13 },
    bodyStyles: {
      justifyContent: "flex-start",
    },
  },
  {
    id: "id-" + EXPENSES_HEADER_LABELS.DESCRIPTION_OF_CHARGES,
    label: EXPENSES_HEADER_LABELS.DESCRIPTION_OF_CHARGES,
    field: EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
    sortBy: EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
    headerStyles: { paddingLeft: 8 },
    bodyStyles: {
      justifyContent: "flex-start",
    },
  },
  {
    id: "id-" + EXPENSES_HEADER_LABELS.NOTES,
    label: EXPENSES_HEADER_LABELS.NOTES,
    field: EXPENSE_FIELDS.NOTES,
    sortBy: EXPENSE_FIELDS.NOTES,
    headerStyles: { paddingLeft: 9 },
    bodyStyles: {
      justifyContent: "flex-start",
    },
  },
  {
    id: "id-" + EXPENSES_HEADER_LABELS.FORECAST,
    label: EXPENSES_HEADER_LABELS.FORECAST,
    field: EXPENSE_FIELDS.FORECAST,
    sortBy: EXPENSE_FIELDS.FORECAST,
    headerStyles: { justifyContent: "flex-end", marginRight: -20 },
    bodyStyles: {
      justifyContent: "flex-end",
    },
  },
  {
    id: "id-" + EXPENSES_HEADER_LABELS.ACTUAL,
    label: EXPENSES_HEADER_LABELS.ACTUAL,
    field: EXPENSE_FIELDS.ACTUAL,
    sortBy: EXPENSE_FIELDS.ACTUAL,
    headerStyles: { justifyContent: "flex-end", marginRight: -20 },
    bodyStyles: {
      justifyContent: "flex-end",
    },
  },
  {
    id: "id-blank-space-1",
    label: "",
    field: "",
    headerStyles: {},
    bodyStyles: {
      justifyContent: "flex-start",
    },
  },
];
