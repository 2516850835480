import { toCamelCase } from "../../components/CompanyTabs/companyTabHelper";
import {
  FIELD_SCOPE,
  FIELD_SOURCE,
  propertyType,
} from "../../helpers/constants";

export default class Fielditem {
  constructor({
    id = "",
    active = true,
    label = "",
    field,
    description = "",
    listIndex = 999,
    type = propertyType.TEXT,
    source = FIELD_SOURCE.USER,
    scope = FIELD_SCOPE.COMPANY_CUSTOM_FIELDS,
    companyPermission = "",
  }) {
    this.id = id || toCamelCase(label);
    this.label = label.trim();
    this.field = field || toCamelCase(label);
    this.listIndex = listIndex;
    this.active = active;
    this.type = type;
    this.source = source;
    this.description = description.trim();
    this.scope = scope;
    this.companyPermission = companyPermission;
  }
}
