import React from "react";

import TooltipTD from "../../Tooltip/TooltipTD";
import { VoidLabel } from "../../../helpers/iconSvgPath";
import { SALES_ORDER_STATUS } from "../../../helpers/constants";

import { cx } from "@emotion/css";
import { getDateFormat } from "../companyTabHelper";
import CustomButton from "../../Buttons/CustomButton";
import SalesOrderBadge from "../../Badge/SalesOrderBadge";

function ProjectTableRow({
  salesOrder = {},
  onOpenVoidPO,
  onCompletedProject,
}) {
  return (
    <div className="rowTable">
      <span className="cellTable">
        <TooltipTD className={"name"} label={salesOrder.number || ""}>
          <SalesOrderBadge number={salesOrder.number || ""} />
          {salesOrder.status === SALES_ORDER_STATUS.VOIDED && <VoidLabel />}
        </TooltipTD>
      </span>
      <span className="cellTable">
        <TooltipTD className={"name"} label={salesOrder.customerName || ""}>
          <span className="spanText">{salesOrder.customerName}</span>
        </TooltipTD>
      </span>
      <div className={cx("cellTable", "date")}>
        <span className="spanText">
          {getDateFormat(salesOrder.creationDate)}
        </span>
      </div>
      <div className={cx("cellTable", "date")}>
        <span className="spanText"> {getDateFormat(salesOrder.date)}</span>
      </div>

      <div className={cx("cellTable", "name", "buttonAction")}>
        <CustomButton
          onClick={(ev) => onOpenVoidPO(salesOrder, ev)}
          containerColor="#f7fcff"
          disabled={
            salesOrder.status === SALES_ORDER_STATUS.VOIDED ||
            salesOrder.status === SALES_ORDER_STATUS.COMPLETED
          }
          id="voidButton"
        >
          {"components.saleOrders.ActionsModal.voidPO"}
        </CustomButton>
      </div>
      <div className={cx("cellTable", "name", "buttonAction")}>
        <CustomButton
          onClick={() => onCompletedProject(salesOrder)}
          containerColor="#f7fcff"
          disabled={
            salesOrder.status === SALES_ORDER_STATUS.VOIDED ||
            salesOrder.status === SALES_ORDER_STATUS.COMPLETED
          }
          id="completedButton"
        >
          {"components.saleOrders.ActionsModal.completeAllTasks"}
        </CustomButton>
      </div>
    </div>
  );
}

export default ProjectTableRow;
