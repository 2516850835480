import React from "react";
import EditableLabel from "../TextFields/EditableLabel";
import { TextareaAutosize } from "@mui/material";
import CustomCheckbox from "./CustomCheckbox";

const lockFields = ["number", "name"];

function getFieldByProperty({
  property,
  currentItem,
  isReadOnly,
  handleSave = () => {},
  handleChange = () => {},
  handleReadOnlyModal = () => {},
  handleInactive = () => {},
  type = "text",
}) {
  const valueProperty = currentItem[property] || "";
  switch (property) {
    case "notes":
      return (
        <TextareaAutosize
          className="fieldNotes"
          onChange={(ev) => handleChange(ev, property)}
          disabled={isReadOnly}
          value={valueProperty}
          onBlur={() => handleSave(property)}
          maxRows={20}
          minRows={1}
          minLength={10}
        />
      );
    case "inactive":
      return (
        <div
          className="fieldProperty"
          style={{ margin: "0px 17px 0px 0px", height: 16 }}
        >
          <CustomCheckbox
            checked={!!valueProperty}
            disabled={isReadOnly}
            onChange={(ev) => handleInactive(ev.target.checked, property)}
            iconSize={20}
            styles={{
              padding: 0,
            }}
            color={"#757575"}
            className={"grayColor"}
          />
        </div>
      );
    default:
      const lockField = lockFields.includes(property);
      const blockField = lockField || isReadOnly;
      return (
        <EditableLabel
          key={currentItem.id + property}
          onValid={() => {
            handleSave(property);
          }}
          children={valueProperty}
          onChange={(event) => handleChange(event, property)}
          editable={blockField}
          disabled={blockField}
          style={{ marginTop: 0, cursor: blockField ? "default" : "text" }}
          isReadOnly={isReadOnly}
          handleReadOnlyModal={handleReadOnlyModal}
          type={type}
          labelStyle={{ fontWeight: !blockField ? "700" : "400" }}
        />
      );
  }
}

export default getFieldByProperty;
