import React, { useEffect, useRef, useState } from "react";
import { CreateTemplateModal, PopoverEmoji } from "./styles";
import {
  Button,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { cx } from "@emotion/css";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import SalesOrderTemplateObj from "../../api/model/salesOrderTemplate";
import { doc, getDoc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbDocuments, dbTables } from "../../api/types/dbTables";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import {
  cloneTasksWithNewIds,
  createChangeLog,
  createNewTemplateVersion,
  getLastChangelog,
  getTemplateContructor,
  saveBatchTaskList,
  TYPE_NEW_TEMPLATE,
  updateEmojiRef,
} from "./soTemplateHelper";
import TaskTemplateVersion from "../../api/model/TaskTemplateVersion";
import { getRandomId } from "../../helpers/helpers";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { useMiscellaneous, useUser } from "../../hooks/user";
import taskStages from "../../api/types/taskStages";
import SalesOrderTaskTemplate from "../../api/model/SalesOrderTaskTemplate";
import TooltipTD from "../Tooltip/TooltipTD";
import { GANTT_CHART_ACTION } from "../../helpers/constants";

const TITLE_HEADER = {
  [TYPE_NEW_TEMPLATE.NEW]: "Create Sales Order Template",
  [TYPE_NEW_TEMPLATE.CLONE]: "Duplicate Sales Order Template",
  [TYPE_NEW_TEMPLATE.UPDATE]: "Update New Sales Order Template",
};

const PO_TITLE_HEADER = {
  [TYPE_NEW_TEMPLATE.NEW]: "Create New Purchase Order Template",
  [TYPE_NEW_TEMPLATE.CLONE]: "Duplicate Purchase Order Template",
  [TYPE_NEW_TEMPLATE.UPDATE]: "Update Purchase Order Template",
};

function NewTemplateModal({
  companyId,
  userId,
  dispatchGanttChartTemplate,
  entityTemplates = [],
  newTemplateData = {},
  currentTemplate,
  currentTemplateVersion,
  tasks,
  setCurrentTemplate = () => {},
  setCurrentTemplateVersion = () => {},
  handleModal = () => {},
  templateContructor = SalesOrderTemplateObj,
  templateDB = dbTables.SALES_ORDER_TEMPLATES,
  salesOrderTemplates = [],
}) {
  const oldData = newTemplateData.formData || {};
  const [formData, setFormData] = useState({
    emoji: oldData.emoji || "",
    templateName: oldData.templateName || "",
    templateDescription: oldData.templateDescription || "",
  });
  const dataMiscellaneous = useMiscellaneous();
  const user = useUser();
  const emojiRef = useRef(null);
  const nameRef = useRef(null);
  const [emojiExcludeList, setEmojiExcludeList] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [currentSOTemplateId, setCurrentSOTemplateId] = useState("");
  const [loading, setLoading] = useState(false);

  const docEmojis =
    dataMiscellaneous.find(
      (misc) => misc.id === dbDocuments.USED_EMOJIS_IN_TEMPLATED
    ) || {};

  useEffect(() => {
    if (!docEmojis.id) {
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.MISCELLANEOUS}/${dbDocuments.USED_EMOJIS_IN_TEMPLATED}`
        ),
        {
          id: dbDocuments.USED_EMOJIS_IN_TEMPLATED,
          list: [],
        }
      );
    }
    const emojiList = docEmojis.list || [];
    if (emojiList.length > 0) {
      setEmojiExcludeList(
        emojiList.map((currentEmoji) => {
          for (const emojiData of Object.values(data.emojis)) {
            const skins = emojiData.skins;
            const emoji = skins.find((emoji) => emoji.native === currentEmoji);

            if (emoji) {
              return emojiData.id;
            }
          }
          return null;
        })
      );
    }
  }, []);

  const handleClose = () => {
    if (!loading) {
      handleModal({ open: false });
    }
  };

  const handleChange = (e, validateName) => {
    let { name, value } = e.target;
    if (validateName) {
      value = value
        .replace(/[^A-Za-z0-9-_ ]/g, "")
        .replace(/\s{2,}/g, " ")
        .trimStart();
      if (value.length <= 24) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const createTemplate = ({
    templatePath,
    newEntitytemplate,
    currentSOTemplate,
  }) => {
    const batch = writeBatch(firestore);
    let newTasks = [];

    let newVersionTemplate = createNewTemplateVersion({
      batch,
      templatePath,
      currentTemplate: newEntitytemplate,
      user,
      currentTemplateVersion: { version: 0 },
      currentSOTemplate,
      templateIndexes: {},
    });

    if (templateDB === dbTables.SALES_ORDER_TEMPLATES) {
      newTasks.push({
        ...new SalesOrderTaskTemplate({
          description: "New Task",
          listIndex: 1,
          stage: taskStages.PROPOSAL,
          duration: 1,
          offset: 0,
          assignedTo: userId,
          number: 1,
        }),
        index: 0,
      });
      saveBatchTaskList({
        batch,
        tasks: newTasks,
        path: `${templatePath}/${newEntitytemplate.id}/${dbTables.VERSIONS}/${newVersionTemplate.id}/${dbTables.TASKS_TEMPLATE}`,
        scope: templateDB,
      });
    } else {
      const currentSOTemplate = salesOrderTemplates.find(
        (soTemplate) => soTemplate.id === currentSOTemplateId
      );

      createChangeLog({
        batch,
        templatePath: templatePath,
        newVersionTemplate,
        currentTemplate: newEntitytemplate,
        user,
        commitDescription: "",
        oldTemplate: {},
        soTemplates: salesOrderTemplates,
        scope: dbTables.PURCHASE_ORDER_TEMPLATES,
        oldChangeLog: {},
        taskChanged: {},
      });
      newEntitytemplate = {
        ...newEntitytemplate,
        salesOrderTemplateId: currentSOTemplate.id,
        salesOrderTemplateVersion: currentSOTemplate.currentVersionId,
      };
    }
    batch.commit().then(async () => {
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          tasks: newTasks,
        },
      });
      updateEmojiRef({ docEmojis, formData, oldData, updateDoc });

      handleModal({ open: false });
      setCurrentTemplate({
        ...newEntitytemplate,
        currentVersionId: newVersionTemplate.id,
      });
      const newEntitytemplateDB = await getDoc(
        doc(firestore, `${templatePath}/${newEntitytemplate.id}`)
      );
      setCurrentTemplate({
        ...newEntitytemplateDB.data(),
        ref: newEntitytemplateDB.ref,
      });
      setLoading(false);
    });

    return;
  };

  const cloneTemplate = async ({
    newVersionTemplateId,
    newEntitytemplate,
    templatePath,
    currentSOTemplate = {},
    newTemplateIndexes,
    newTaks,
  }) => {
    const newTemplateVersion = `${templatePath}/${newEntitytemplate.id}/${dbTables.VERSIONS}/${newVersionTemplateId}`;
    const batch = writeBatch(firestore);
    const newVersionTemplate = {
      ...new TaskTemplateVersion({
        id: newVersionTemplateId,
        version: 1,
        createdBy: userId,
        description: `Duplicate from ${currentTemplate.emoji || ""} ${
          currentTemplate.name
        }`,
        salesOrderTemplateId: currentSOTemplate.id,
        salesOrderTemplateVersion: currentSOTemplate.currentVersionId,
        templateIndexes: newTemplateIndexes,
      }),
    };
    batch.set(doc(firestore, newTemplateVersion), newVersionTemplate);
    saveBatchTaskList({
      batch,
      tasks: newTaks,
      path: `${newTemplateVersion}/${dbTables.TASKS_TEMPLATE}`,
      scope: templateDB,
    });

    createChangeLog({
      batch,
      templatePath: `${dbTables.COMPANIES}/${companyId}/${templateDB}`,
      newVersionTemplate,
      currentTemplate: newEntitytemplate,
      user,
      commitDescription: `Duplicate from ${currentTemplate.emoji || ""} ${
        currentTemplate.name
      }`,
      soTemplates: salesOrderTemplates,
      scope: templateDB,
    });

    batch.commit().then(async () => {
      const newEntitytemplateDB = await getDoc(
        doc(firestore, `${templatePath}/${newEntitytemplate.id}`)
      );
      setCurrentTemplate({
        ...newEntitytemplateDB.data(),
        ref: newEntitytemplateDB.ref,
      });
      setCurrentTemplateVersion(newVersionTemplate);
      updateEmojiRef({ docEmojis, formData, oldData, updateDoc });
      setLoading(false);
      handleModal({ open: false });
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        typeChange: "reset",
      });
    });
  };

  async function handleCreateTemplate() {
    setLoading(true);
    const isNew = newTemplateData.type === TYPE_NEW_TEMPLATE.NEW;
    const currentSoId = isNew
      ? currentSOTemplateId
      : currentTemplate.salesOrderTemplateId;

    const currentSOTemplate = salesOrderTemplates.find(
      (soTemplate) => soTemplate.id === currentSoId
    );

    const templatePath = `${dbTables.COMPANIES}/${companyId}/${templateDB}`;
    const newVersionTemplateId = getRandomId();
    const newTaks = isNew
      ? tasks
      : cloneTasksWithNewIds(
          tasks,
          templateDB === dbTables.PURCHASE_ORDER_TEMPLATES
        );
    let newTemplateIndexes = {};
    if (!isNew) {
      newTaks.forEach((task) => (newTemplateIndexes[task.id] = task.listIndex));
    }
    const newEntitytemplate = getTemplateContructor({
      currentSOTemplateData: currentSOTemplate,
      formData,
      newVersionTemplateId: isNew ? "" : newVersionTemplateId,
      userId,
      templateContructor,
      isOutdated: isNew ? true : false,
      templateIndexes: newTemplateIndexes,
    });
    await setDoc(doc(firestore, `${templatePath}/${newEntitytemplate.id}`), {
      ...newEntitytemplate,
    });
    if (isNew) {
      createTemplate({ newEntitytemplate, templatePath, currentSOTemplate });
      return;
    }

    cloneTemplate({
      newEntitytemplate,
      newVersionTemplateId,
      templatePath,
      currentSOTemplate,
      newTemplateIndexes,
      newTaks,
    });
  }

  const handleEditTemplate = async () => {
    setLoading(true);
    const dataToUpdate = {
      emoji: formData.emoji || "",
      name: formData.templateName || "",
      description: formData.templateDescription || "",
    };

    updateEmojiRef({ docEmojis, formData, oldData, updateDoc });
    const lastChangeLog = await getLastChangelog(currentTemplate.ref.path);
    const batch = writeBatch(firestore);
    const newVersionTemplate = createNewTemplateVersion({
      batch,
      templatePath: `${dbTables.COMPANIES}/${companyId}/${templateDB}`,
      currentTemplate,
      currentTemplateVersion,
      user,
      updateOutdate: false,
      templateIndexes: currentTemplateVersion.templateIndexes,
      currentSOTemplate: salesOrderTemplates.find(
        (soTemplate) => soTemplate.id === currentTemplate.salesOrderTemplateId
      ),
    });

    createChangeLog({
      batch,
      templatePath: `${dbTables.COMPANIES}/${companyId}/${templateDB}`,
      newVersionTemplate: newVersionTemplate,
      currentTemplate: { ...currentTemplate, ...dataToUpdate },
      user,
      commitDescription: "",
      oldTemplate: currentTemplate,
      soTemplates: salesOrderTemplates,
      scope: templateDB,
      oldChangeLog: lastChangeLog,
    });
    await batch.commit();
    updateDoc(currentTemplate.ref, {
      ...dataToUpdate,
    });
    setCurrentTemplate((oldData) => ({
      ...oldData,
      ...dataToUpdate,
    }));
    setCurrentTemplateVersion(newVersionTemplate);
    setLoading(false);
    handleModal({ open: false });
  };

  const onOpenEmojiPicker = () => {
    setShowPicker(true);
  };

  const onClosePicker = () => {
    setShowPicker(false);
  };

  const getTextNameError = () => {
    const currentName = formData.templateName.trim().toLowerCase();
    const filteredTemplate =
      newTemplateData.type === TYPE_NEW_TEMPLATE.UPDATE
        ? entityTemplates.filter(
            (template) => currentTemplate.id !== template.id
          )
        : entityTemplates;

    const sameName = filteredTemplate.some(
      (template) => template.name.trim().toLowerCase() === currentName
    );
    if (currentName.length <= 2 && currentName.length > 0) {
      return "The template name must be at least 2 characters long";
    }
    if (sameName && !loading) {
      return "That exact template name is already in use";
    }
    return "";
  };

  const getEmojiNameError = () => {
    const emojisUsed = docEmojis.list || [];
    const filteredEmoji =
      newTemplateData.type === TYPE_NEW_TEMPLATE.UPDATE
        ? emojisUsed.filter((emoji) => emoji !== currentTemplate.emoji)
        : emojisUsed;
    if (filteredEmoji.includes(formData.emoji) && formData.emoji && !loading) {
      return "The emojis is already in use";
    }
    return "";
  };

  const hasMetadataChange = () => {
    if (oldData.emoji !== formData.emoji) return false;
    if (oldData.templateName !== formData.templateName) return false;
    if (oldData.templateDescription !== formData.templateDescription)
      return false;
    return true;
  };

  const getSOTemplateSelector = () => {
    if (templateDB === dbTables.SALES_ORDER_TEMPLATES) {
      return <></>;
    }
    const currentSOTemplate =
      salesOrderTemplates.find(
        (soTemplate) => soTemplate.id === currentTemplate.salesOrderTemplateId
      ) || {};
    if (newTemplateData.type !== TYPE_NEW_TEMPLATE.NEW) {
      return (
        <>
          <span className="labelData">Based on</span>
          <span className="readOnlyTitle">
            {currentSOTemplate.emoji && (
              <span style={{ fontSize: 20 }}>{currentSOTemplate.emoji}</span>
            )}
            <span>{currentSOTemplate.name}</span>
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className="labelData">
            <span className="red">*</span>Based on <br />
            Sales Order Template
          </span>
          <Select
            className="selectSOTemplate"
            size="small"
            value={currentSOTemplateId}
            onChange={(ev) => {
              setCurrentSOTemplateId(ev.target.value);
            }}
            style={{ maxWidth: 415 }}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              PaperProps: {
                style: { maxHeight: "50vh" },
              },
            }}
          >
            {salesOrderTemplates.map((soTemplate) => (
              <MenuItem
                value={soTemplate.id}
                style={{
                  boxShadow:
                    currentSOTemplateId === soTemplate.id
                      ? "4px 0px 0px 0px #218cff inset"
                      : "",
                }}
              >
                <TooltipTD style={{ display: "flex" }} label={soTemplate.name}>
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 415,
                      display: "flex",
                      gap: 5,
                      alignItems: "center",
                    }}
                  >
                    {soTemplate.emoji && (
                      <span style={{ fontSize: 20 }}>{soTemplate.emoji}</span>
                    )}
                    <span>{soTemplate.name}</span>
                  </span>
                </TooltipTD>
              </MenuItem>
            ))}
          </Select>
        </>
      );
    }
  };

  const currentHeader =
    templateDB === dbTables.SALES_ORDER_TEMPLATES
      ? TITLE_HEADER
      : PO_TITLE_HEADER;

  const checkEditTemplate =
    newTemplateData.type === TYPE_NEW_TEMPLATE.UPDATE
      ? hasMetadataChange()
      : false;

  const checkNewTemplate =
    templateDB === dbTables.PURCHASE_ORDER_TEMPLATES &&
    newTemplateData.type === TYPE_NEW_TEMPLATE.NEW
      ? !currentSOTemplateId
      : false;

  return (
    <CreateTemplateModal
      className="TemplateModalContainer"
      isOpen={newTemplateData.open}
      onClose={handleClose}
      header={
        <span className="headerText">
          {currentHeader[newTemplateData.type]}
        </span>
      }
      footer={
        <div className="footerTemplate">
          <span className="mandatoryText">
            <span className="red">*</span>Mandatory
          </span>
          <div className="buttonContainer">
            <Button
              disabled={
                checkEditTemplate ||
                checkNewTemplate ||
                !formData.templateName ||
                !formData.emoji ||
                getTextNameError() ||
                getEmojiNameError()
              }
              onClick={() =>
                newTemplateData.type === TYPE_NEW_TEMPLATE.UPDATE
                  ? handleEditTemplate()
                  : handleCreateTemplate()
              }
              variant="contained"
              className={cx("saveButton", "buttonAction")}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={"buttonAction"}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      }
    >
      {showPicker && (
        <PopoverEmoji
          open={showPicker}
          anchorEl={emojiRef.current}
          onClose={onClosePicker}
          className="popoverEmojiPicker"
        >
          <Picker
            title="Pick your emoji…"
            emoji="point_up"
            categories={[
              "people",
              "nature",
              "foods",
              "activity",
              "places",
              "objects",
              "symbols",
              "flags",
            ]}
            exceptEmojis={emojiExcludeList}
            data={data}
            onEmojiSelect={(emojiData) => {
              setFormData((prevData) => ({
                ...prevData,
                emoji: emojiData.native,
              }));
              onClosePicker();
            }}
            theme="light"
            emojiButtonSize={30}
            previewPosition="none"
            skinTonePosition="none"
            defaultSkin={1}
          />
        </PopoverEmoji>
      )}
      {loading && <LoadingBackdrop withLogo />}
      <div className="gridBody">
        {getSOTemplateSelector()}
        <span className="labelData">
          <span className="red">*</span>Template name Emoji (visual shortcut)
        </span>
        <TextField
          ref={emojiRef}
          className="textDataTemplate"
          name="emoji"
          value={formData.emoji}
          size="small"
          sx={{ width: "50px", }}
          InputProps={{
            readOnly: true,
            inputProps: { style: { textAlign: "center", fontSize: 20 , height:35} },
          }}
          onClick={onOpenEmojiPicker}
          helperText={getEmojiNameError()}
          error={getEmojiNameError()}
          onKeyUp={(ev) => {
            if (ev.key === "Tab") {
              onOpenEmojiPicker();
            }
          }}
        />
        <span className="labelData">
          <span className="red">*</span>Template name
        </span>
        <TextField
          className="textDataTemplate"
          name="templateName"
          value={formData.templateName}
          onChange={(ev) => handleChange(ev, true)}
          fullWidth
          size="small"
          inputRef={nameRef}
          helperText={getTextNameError()}
          error={getTextNameError()}
        />
        <span className="labelData">Template description</span>
        <TextareaAutosize
          className="textDataTemplateAutosize"
          name="templateDescription"
          value={formData.templateDescription}
          onChange={(ev) => handleChange(ev, false)}
          minRows={4}
        />
      </div>
    </CreateTemplateModal>
  );
}

export default NewTemplateModal;
