import React, { useEffect, useState } from "react";
import { globalScopes } from "../../../api/types/dbTables";
import { ORDER_DASHBOARD_ACTUAL_VIEWS, PREFIX_ITEM_TABLE } from "../../../helpers/constants";
import { getDashboardSearchPath } from "../../../helpers/helpers";
import { shipmentItemList, useItemsList } from "../../../hooks/itemTable";
import { usePurchaseOrders } from "../../../hooks/salesOrders";
import { mismatchLabel } from "../dashboardHelpers";
import ShipmentSKUTable from "../ShipmentSKUTable";
import { useNavigate } from "react-router-dom";
import { getShipmentMismatch } from "../SKUTables/SKUHelper";
import { shipmentSKUHeader } from "../../../helpers/itemsTableColumns";
import Mark from "mark.js";

function SectionShipmentSKU({
  currentPurchaseOrder,
  currentSalesOrder,
  user,
  shipment,
  queryParams,
}) {
  const purchaseOrders = usePurchaseOrders();
  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  const [search, setSearch] = useState("");
  const markInstance = new Mark(document.getElementById("itemTableshipment"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: [
        ".headerTable, .headerTable *",
        ".alignRight, .alignRight *",
        ".totalNumber, .totalNumber *",
      ],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    setTimeout(() => performMark(search), 200);
  }, [search]);

  const navigate = useNavigate();
  const poItemsList = useItemsList({
    currentPurchaseOrder: currentPurchaseOrder,
    user: user,
  });
  const soItemsList = useItemsList({
    salesOrder: currentSalesOrder,
    purchaseOrders,
    user: user,
  });
  const shipmentsItems = shipmentItemList({
    poItemsList,
    currentShipment: shipment,
    soItemsList,
    purchaseOrders,
    user,
  });

  const hasShipmentMismatch = getShipmentMismatch({
    currentShipment: shipment,
    shipmentsItems,
  });

  return (
    <div
      id="item-table-Shipment"
    >
      <ShipmentSKUTable
        columnsTable={shipmentSKUHeader.map((header) => ({
          ...header,
          label: header.dinamyLabel
            ? `${header.label}${currentPurchaseOrder.type || ''}`
            : header.label,
        }))}
        prefix={PREFIX_ITEM_TABLE.SHIPMENT}
        currentPurchaseOrder={currentPurchaseOrder}
        currentSalesOrder={currentSalesOrder}
        allPoItems={poItemsList}
        parentShipment={shipment}
        dataTable={shipmentsItems}
        purchaseOrders={purchaseOrders}
        modalCleanSearchBox={cleanSearchBox}
        modalOnWriteSearch={setSearch}
        modalHandleSearchBox={setCleanSearchBox}
        modalFilterText={search}
        onChangeShipment={(data) => {
          if (!data.erased) {
            console.log("stop");
            return;
          }
          const shipmentIds = currentPurchaseOrder.shipmentIds || [];
          const newShipmentId = shipmentIds.find((id) => id !== data.id);
          if (newShipmentId) {
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                shipmentId: newShipmentId,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
              })
            );
          } else {
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                shipmentId: "",
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
              })
            );
          }
        }}
        hasMismatchTag={
          hasShipmentMismatch ? mismatchLabel[globalScopes.SHIPMENT] : false
        }
      />
    </div>
  );
}

export default SectionShipmentSKU;
