/**
 * App.js Layout Start Here
 */
import React, { useEffect, useState } from "react";
import IconPO from "../assets/flag-icons/purchaseordericon.png";
import IconExpenses from "../assets/flag-icons/expensesicon.png";
import IconSO from "../assets/flag-icons/salesordersicon.png";
import IconShipment from "../assets/flag-icons/shipmenticon.png";
import IconTask from "../assets/flag-icons/tasksicon.png";
import IconReport from "../assets/flag-icons/reporticon.png";
import IconTradedash from "../assets/flag-icons/tradedash.png";
import IconByPhase from "../assets/flag-icons/orderbyphaseicon.png";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  getDataFromFirestore,
  getCompanyUsersDataFromFirestore,
  getUsersDataFromFirestore,
} from "../actions/DataActions";
import { dbTables } from "../api/types/dbTables";
import { dispatchUser } from "../actions/AuthActions";
import userTypes from "../api/types/userTypes";
import {
  globalEnvironment,
  globalEnvironments,
} from "../constants/globalVariables";
import { auth, firestore } from "../firebase";
import {
  identifyFullStory,
  identifyGoogleAnalytics,
  identifyPendo,
} from "../helpers/analytics";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged, updateEmail } from "firebase/auth";
import { Helmet } from "react-helmet";
import { getCustomerName } from "../helpers/helpers";
import { useCustomers } from "../hooks/customers";
import { useCurrentSalesOrder } from "../hooks/salesOrders";
import { CLIENT_IS_OFFLINE } from "../actions/types";
import AttentionModal from "../components/Modal/AttentionModal";
import LoadingBackdrop from "../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { screenPath } from "../helpers/constants";
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const customers = useCustomers();
  const currentSalesOrder = useCurrentSalesOrder({});
  const [isLoading, setIsLoading] = useState(true);
  const [companies] = useSelector((state) => state.data.companies);
  const stateUser = useSelector((state) => state.data.currentUser) || {};
  const dispatch = useDispatch();
  const clientIsOffline = useSelector((state) => state.data.clientIsOffline);
  useEffect(() => {
    if (
      companies &&
      stateUser.role &&
      stateUser.role !== userTypes.TRADEDASH_EMPLOYEE &&
      !companies.enabled
    ) {
      sendUser(null);
      navigate("/signin");
      localStorage.clear();
      auth.signOut();
    }
  }, [companies]);

  const PENDO_ENVIRONMENTS = [
    globalEnvironments.STAGING,
    globalEnvironments.TRAINING,
    globalEnvironments.EXHART_PRODUCTION,
  ];

  const FULL_STORY_ENVIRONMENTS = [globalEnvironments.EXHART_PRODUCTION];
  const GOOGLE_ANALYTICS_ENVIRONMENTS = [
    globalEnvironments.DEV,
    globalEnvironments.EXHART_PRODUCTION,
  ];

  const handleAppAuth = async ({ currentUser, userAuth }) => {
    if (currentUser.companyId) {
      const companyDBSnap = await getDoc(
        doc(firestore, `${dbTables.COMPANIES}/${currentUser.companyId}`)
      );
      const companyData = companyDBSnap.data();
      if (
        currentUser.role !== userTypes.TRADEDASH_EMPLOYEE &&
        companyData &&
        !companyData.enabled
      ) {
        handleUserNotAuthenticated();
        return;
      }

      if (companyData && FULL_STORY_ENVIRONMENTS.includes(globalEnvironment)) {
        identifyFullStory({
          company: companyData,
          currentUser: currentUser,
        });
      }
      if (companyData && PENDO_ENVIRONMENTS.includes(globalEnvironment)) {
        identifyPendo({
          company: companyData,
          currentUser: currentUser,
        });
      }
      if (
        companyData &&
        GOOGLE_ANALYTICS_ENVIRONMENTS.includes(globalEnvironment)
      ) {
        identifyGoogleAnalytics({
          company: companyData,
          currentUser: currentUser,
        });
      }
    }
    if (!currentUser.magicMailActive) {
      updateEmail(userAuth, currentUser.email)
        .then(() => {
          updateDoc(doc(firestore, `${dbTables.USERS}/${userAuth.uid}`), {
            magicMailActive: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleUserNotAuthenticated = () => {
    sendUser(null);
    navigate("/signin");
    localStorage.clear();
    auth?.signOut();
    setIsLoading(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        try {
          const snapUser = await getDoc(
            doc(firestore, `${dbTables.USERS}/${user.uid}`)
          );
          if (!snapUser.data() || Object.keys(snapUser.data()).length === 0) {
            handleUserNotAuthenticated();
            return;
          }
          const currentUser = snapUser.data();
          const isEmployee = currentUser.role === userTypes.TRADEDASH_EMPLOYEE;
          await handleAppAuth({ currentUser, userAuth: user });
          setBasicInformation(currentUser);
          setIsLoading(false);
          if (user && location.pathname.includes("/links")) {
            return;
          } else if (!location.pathname.includes("/app/")) {
            const pathRedirect = isEmployee
              ? "/app/admin"
              : `/app/${screenPath.TASK}`;
            navigate(pathRedirect);
            return;
          }
        } catch (error) {
          handleUserNotAuthenticated();
          console.log("ERROR FOUND: ", error);
        }
      } else if (location.pathname.includes("/links")) {
        setIsLoading(false);
        return;
      } else {
        setTimeout(() => setIsLoading(false), 800);
        sendUser(null);
        if (!location.pathname.includes("/signin")) return navigate("signin");
      }
    });
  }, []);

  function listenToCompanies(user) {
    getDataFromFirestore(dbTables.COMPANIES, user)(dispatch);
  }

  function sendUser(user) {
    dispatchUser(user)(dispatch);
  }

  function listenToAllUsers(user) {
    getDataFromFirestore(dbTables.USERS, user)(dispatch);
  }

  function listenToUsers(companyId) {
    getCompanyUsersDataFromFirestore(dbTables.USERS, companyId)(dispatch);
  }

  function listenCurrentUser(user) {
    getUsersDataFromFirestore(user.id)(dispatch);
  }

  function setBasicInformation(currentUser) {
    listenCurrentUser(currentUser);
    sendUser(currentUser ? currentUser : null);
    listenToCompanies(currentUser);
    if (currentUser.role === userTypes.TRADEDASH_EMPLOYEE) {
      listenToAllUsers(currentUser);
    } else {
      listenToUsers(currentUser.companyId);
    }
  }

  function getTitleBar() {
    const parsePathName = location.pathname;

    if (parsePathName.includes("/app/admin")) {
      return { title: "Admin Settings" };
    }
    if (parsePathName.includes(`/app/${screenPath.VENDOR}`)) {
      return { title: "Vendors", icon: IconTradedash };
    }
    if (parsePathName.includes(`/app/${screenPath.CUSTOMER}`)) {
      return { title: "Customers", icon: IconTradedash };
    }
    if (parsePathName.includes("/links")) {
      return { title: "Documents Download", icon: IconTradedash };
    }
    if (parsePathName.includes(`/${screenPath.REPORT}`)) {
      return { title: "Reports", icon: IconReport };
    }
    if (parsePathName.includes("/app/dashboard")) {
      const number = currentSalesOrder.number || "Sales Order";
      return {
        title: `${number.toString()} |
      ${getCustomerName(customers, currentSalesOrder.customerId, true)}`,
        icon: IconTradedash,
        personalTitle: true,
      };
    }

    const routeToTitle = {
      [`/app/${screenPath.EXPENSE}`]: { title: "Expenses", icon: IconExpenses },
      [`/app/${screenPath.INBOX}`]: {
        title: "Sales Order Inbox",
        icon: IconTradedash,
      },
      [`/app/${screenPath.ORDER_PHASE}`]: {
        title: "Orders By Phase",
        icon: IconByPhase,
      },
      [`/app/${screenPath.PO}`]: { title: "Purchase Orders", icon: IconPO },
      "/app/dashboard": { title: "" },
      [`/app/${screenPath.SO}`]: { title: "Sales Orders", icon: IconSO },
      [`/app/${screenPath.SHIPMENT}`]: {
        title: "Shipments",
        icon: IconShipment,
      },
      [`/app/${screenPath.TASK}`]: { title: "Tasks", icon: IconTask },
      "/signin": { title: "Sign in", icon: IconTradedash },
    };

    return routeToTitle[parsePathName] || {};
  }
  const { title = "", icon, personalTitle } = getTitleBar();
  return (
    <div className="content-wrapper">
      {!!clientIsOffline.value && (
        <AttentionModal
          isOpen={!!clientIsOffline.value}
          title="TradeDash cannot be reached"
          description={clientIsOffline.text}
          onClose={() => {
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: {
                value: false,
                text: "",
              },
            });
          }}
          onClick={() => {
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: {
                value: false,
                text: "",
              },
            });
          }}
        />
      )}
      {!isLoading && <Outlet />}
      {isLoading && <LoadingBackdrop withLogo />}
      <Helmet>
        {!personalTitle && <title>{`Tradedash | ${title}`}</title>}
        {personalTitle && <title>{title}</title>}
        <meta name="description" content="Tradedash Maps" />
        <link rel="shortcut icon" href={icon || IconTradedash} />
      </Helmet>
    </div>
  );
}
export default Layout;
