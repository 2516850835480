import React from "react";
import { useCompanyUsers } from "../../hooks/user";
import UserAutocomplete from "../UserManagement/UserAutocomplete";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function AutocompleteUsers({
  userSelected = [],
  onChange = () => {},
  focused,
  disabled = false,
  showAll = false,
  users,
}) {
  const companyUsers = useCompanyUsers({
    showBotUser: showAll,
    showInactiveUsers: showAll,
  });

  const currentUsers = users || companyUsers;

  return (
      <UserAutocomplete
        users={currentUsers.sort(sortObjectsBy("displayName"))}
        currentValue={userSelected.sort(sortObjectsBy("displayName"))}
        onChange={onChange}
        disabled={disabled}
        focused={focused}
      />
  );
}

export default AutocompleteUsers;
