import React from "react";
import { ShipmentInfoGridStyled } from "./styles";
import CustomButton from "../Buttons/CustomButton";
import { useCurrentCustomer } from "../../hooks/customers";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../hooks/permissions";
import { MISCELLANEOUS_IDS, shipmentObjFields } from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import { useMiscellaneous } from "../../hooks/user";
import {
  getMiscellaneoList,
  renderMetadataTextField,
} from "./dashboardHelpers";
import { cx } from "@emotion/css";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { dbTables } from "../../api/types/dbTables";
import AutocompleteMetadata from "../TextFields/AutocompleteMetadata";
import { deleteField, updateDoc } from "firebase/firestore";

function NewShipmentInfo({
  currentShipment = {},
  currentNumber = 1000,
  isReadOnly = false,
  isLoading = false,
  onSave = () => {},
  onClose = () => {},
  onUpdate = () => {},
}) {
  const currentCustomer = useCurrentCustomer() || {};
  const dataMiscellaneous = useMiscellaneous() || [];
  const shipmentMetadata = getMiscellaneoList({
    miscellaneous: dataMiscellaneous,
    miscellaneoId: MISCELLANEOUS_IDS.SHIPMENT_METADATA,
  });
  const isAllowed = useIsAllowedFunction();
  const isAllowedCompany = useIsAllowedCompany();
  const updateShipment = ({ field, value }) => {
    onUpdate(value, field);
  };
  const finalDestinationMetadata = shipmentMetadata.find(
    (item) => item.field === shipmentObjFields.FINAL_DESTINATION
  );
  const id = finalDestinationMetadata.listName;
  const options = currentCustomer[id] || {};
  const optionsList = Object.keys(options).map((key) => ({
    id: key,
    label: options[key].name,
  }));
  return (
    <ShipmentInfoGridStyled className="newShipmentDetailContainer">
      <div className="shipment-title-container">
        <div className="stc-shipment-number">
          <span
            style={{
              fontSize: 20,
              color: colors.darkGray,
            }}
          >
            Shipment:
          </span>
          <span className="shipmentBadge">{currentNumber}</span>
        </div>
        <div>
          <AutocompleteMetadata
            item={finalDestinationMetadata}
            value={currentShipment[finalDestinationMetadata.field]}
            editable={false}
            scope={dbTables.SHIPMENTS}
            inputLarge={true}
            onChange={(value) =>
              updateShipment({
                value,
                field: finalDestinationMetadata.field,
                label: finalDestinationMetadata.label,
                type: finalDestinationMetadata.type,
              })
            }
            onSaveNewOption={async (newItem) => {
              updateDoc(currentCustomer.ref, {
                [`${id}.${newItem.id}`]: {
                  ...newItem,
                },
              });
              updateShipment({
                value: newItem.name,
                field: finalDestinationMetadata.field,
                label: finalDestinationMetadata.label,
                type: finalDestinationMetadata.type,
              });
            }}
            options={optionsList.sort(sortObjectsBy("label", false))}
            onRemove={async (newItem) => {
              updateDoc(currentCustomer.ref, {
                [`${id}.${newItem.id}`]: deleteField(),
              });
            }}
          />
        </div>
      </div>
      <div className="sales-order-info">
        <div className="metadata-info-container">
          <div className={cx("metadata-info", "shipmentInfoGrid-new")}>
            {shipmentMetadata
              .filter(
                (item) =>
                  item.active &&
                  item.field !== shipmentObjFields.FINAL_DESTINATION
              )
              .sort(sortObjectsBy("listIndex", false))
              .map((item) =>
                renderMetadataTextField({
                  item,
                  isAllowed,
                  isAllowedCompany,
                  onChangeAmountProperty: updateShipment,
                  onChangeEntityProperty: updateShipment,
                  isReadOnly,
                  entity: currentShipment,
                  currentCustomer,
                  scope: dbTables.SHIPMENTS,
                })
              )}
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", right: 27, display: "flex" }}>
        <CustomButton
          type="success"
          onClick={onSave}
          className={"doneButton"}
          disabled={isLoading}
          containerColor={"transparent"}
        >
          {"generic.text.done"}
        </CustomButton>
        <CustomButton
          type="cancel"
          onClick={onClose}
          className={"cancelButton"}
          containerColor={"transparent"}
        >
          {"generic.text.cancel"}
        </CustomButton>
      </div>
    </ShipmentInfoGridStyled>
  );
}

export default NewShipmentInfo;
