import React from "react";
import { NavLink } from "react-router-dom";
import { dbTables } from "../../api/types/dbTables";
import TagsModal from "../../components/Modal/TagsModal";
import { ORDER_DASHBOARD_ACTUAL_VIEWS, SHIPMENT_STATUS } from "../../helpers/constants";
import {
  getDashboardSearchPath,
  getTagsLabel,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { getTagStatus } from "../../helpers/salesOrder";
import { getShipmentBadge } from "../../helpers/shipments";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";

function ShipmentItem({
  itemShipment,
  tags,
  isAllowed,
  user,
  filterTag,
  onUpdateItem = () => {},
  salesOrder,
  purchaseOrderId,
}) {
  const shipmentTags = itemShipment.tags || [];
  const isVoided = itemShipment.status === SHIPMENT_STATUS.VOIDED;

  return (
    <div className={"number-table-container"}>
      {isAllowed("see_tags") && (
        <TagsModal
          key={itemShipment.id}
          label={getTagsLabel(tags, shipmentTags).tagsString}
          tags={getTagsLabel(tags, shipmentTags).tagsArray}
          allTags={tags}
          item={itemShipment}
          currentUser={user}
          tagReference={dbTables.SHIPMENTS}
          onUpdateItem={onUpdateItem}
          isThereItemRef={false}
          isReadOnly={isTradeDashEmployee(user)}
          status={getTagStatus({
            tags: shipmentTags,
            currentTags: filterTag,
          })}
        />
      )}
      <NavLink
        to={
          isVoided
            ? false
            : getDashboardSearchPath({
                salesOrderId: salesOrder.id,
                purchaseOrderId: purchaseOrderId,
                shipmentId: itemShipment.id,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
              })
        }
        style={{
          color: "#25486D",
          textDecoration: "none",
          overflow: "visible",
          marginLeft: 0,
        }}
        id="list-view-content-shipment"
      >
        {getShipmentBadge({
          shipment: itemShipment,
          classes: shipmentBadgeClass.mediumInverted,
        })}
      </NavLink>
      <ThreePanelButton
        soId={salesOrder.id}
        poId={purchaseOrderId}
        shipmentId={itemShipment.id}
        tab={2}
      />
    </div>
  );
}

export default ShipmentItem;
