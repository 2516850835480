import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import TagsModal from "../../components/Modal/TagsModal";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
} from "../../helpers/helpers";

import { dbTables } from "../../api/types/dbTables";
import { SmallVoidLabel, VendorsIcon } from "../../helpers/iconSvgPath";
import {
  GENERAL_PERMISSION_VALUE,
  ORDER_DASHBOARD_ACTUAL_VIEWS,
  PERMISSION_TEXT,
  PO_STATUS,
} from "../../helpers/constants";
import { verifyPermission } from "../../hooks/permissions";
import { getTagStatus } from "../../helpers/salesOrder";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { AnimatedListStyled } from "../StyledComponent/AnimatedOpenList";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { getDiamondStatus } from "../../helpers/ScreenComponetHelper/componetHelper";
import POBadge from "../../components/Badge/POBadge";
import AttentionModal from "../../components/Modal/AttentionModal";
import { getPOMetadada } from "../../helpers/salesOrderHelpers";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function POsItem({
  item,
  user,
  onUpdateItem = () => {},
  isTradeDashEmployee,
  filters = {},
  poProgress,
  poDiamonds,
  tags,
  isAllowed,
  salesOrderSetup = {},
  factories,
  onChangeSalesOrderSetup = () => {},
  iconStyle = {},
  query = "",
}) {
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const currentTags = filters.tag || [];
  const purchaseOrders = item.purchaseOrders.sort(
    sortObjectsBy("number", false)
  );
  let hasTag = false;
  const isOpen =
    (salesOrderSetup[item.id] && salesOrderSetup[item.id].POs) ||
    purchaseOrders.length <= 1;
  if (!isOpen) {
    purchaseOrders.forEach((po) => {
      const poTags = po.tags || [];
      hasTag = poTags.some((tag) => currentTags.includes(tag)) || hasTag;
    });
  }

  const getProgress = (purchaseOrder) => {
    if (purchaseOrder.status === PO_STATUS.VOIDED) {
      return <div className="progressContainer" />;
    }

    return (
      <CircularStaticProgress
        label={`${purchaseOrder.completedTasks}/${purchaseOrder.totalTasks} Tasks`}
        value={+getProgressValue(purchaseOrder)}
        thickness={16}
        style={{
          transform: "rotate(270deg)",
          color: "#39F",
        }}
        size={15}
        className="progressContainer"
      />
    );
  };
  let paddingValue = 0;
  if (!poDiamonds) {
    paddingValue = paddingValue + 15;
  }
  if (!poProgress) {
    paddingValue = paddingValue + 15;
  }
  const hiddenStyle = { paddingRight: paddingValue };
  const factoryFilter = filters.factory || [];
  const hasFactory = purchaseOrders.some((po) => {
    return factoryFilter.includes(po.factoryId);
  });
  return (
    <>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          title={"Attention"}
          description={PERMISSION_TEXT.VENDOR}
          onClick={() => {
            setOpenAttentionModal(false);
          }}
        />
      )}
      <AnimatedListStyled
        className="listContainer"
        style={{
          height: isOpen
            ? purchaseOrders.length * 22.1 + (purchaseOrders.length - 1) * 5
            : 24,
        }}
      >
        <div
          className="displayItems"
          style={{
            opacity: isOpen ? 1 : 0,
            zIndex: isOpen ? 1 : 0,
            pointerEvents: isOpen ? "" : "none",
          }}
        >
          {purchaseOrders.map((purchaseOrder, index) => {
            const currentVendor = factories.find(
              (factory) => factory.id === purchaseOrder.factoryId
            );
            const {
              shipmentIds = [],
              salesOrders = [],
              tags: purchaseOrderTags = [],
              factoryId,
            } = purchaseOrder;

            const showIconVendor =
              !!currentVendor && factoryFilter.includes(factoryId);

            const salesOrder =
              salesOrders.find((so) => {
                const purchaseOrderIds = so.purchaseOrderIds || [];
                return purchaseOrderIds.includes(purchaseOrder.id);
              }) || {};

            const salesOrderId = salesOrder.id || item.id;
            const isAvalible = verifyPermission({
              user,
              permissionToCheck: [
                purchaseOrder.factoryId,
                GENERAL_PERMISSION_VALUE.ALL_VENDORS,
              ],
            });
            const showTag = isAllowed("see_tags") && isAvalible;

            return (
              <div
                key={purchaseOrder.id + index}
                style={{
                  ...hiddenStyle,
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  paddingLeft: 4,
                }}
                className="orderList"
              >
                <div className="labelText">
                  {poDiamonds &&
                    getDiamondStatus({
                      diamondStatus: purchaseOrder.diamondStatus,
                      itemStatus: purchaseOrder.status,
                    })}
                  {poProgress && getProgress(purchaseOrder)}
                  {showTag && (
                    <TagsModal
                      key={purchaseOrder.id}
                      label={getTagsLabel(tags, purchaseOrderTags).tagsString}
                      tags={getTagsLabel(tags, purchaseOrderTags).tagsArray}
                      allTags={tags}
                      item={{ ...purchaseOrder, salesOrderId }}
                      currentUser={user}
                      tagReference={dbTables.PURCHASE_ORDERS}
                      onUpdateItem={onUpdateItem}
                      isThereItemRef={false}
                      containerStyle={{ margin: 0, top: 0 }}
                      isReadOnly={isTradeDashEmployee(user)}
                      status={getTagStatus({
                        tags: purchaseOrderTags,
                        currentTags: currentTags,
                      })}
                    />
                  )}
                  {!showTag && <span style={{ width: 22, minWidth: 22 }} />}
                  {showIconVendor && (
                    <TooltipTD
                      label={currentVendor.name}
                      style={{
                        display: "flex",
                        marginInline: 5,
                        marginBotton: 2,
                      }}
                    >
                      <VendorsIcon style={{}} width={11} height={15} />
                    </TooltipTD>
                  )}
                  {!showIconVendor && hasFactory && (
                    <span style={{ width: 11, height: 15, marginInline: 5 }} />
                  )}
                  <TooltipTD
                    label={
                      isAvalible
                        ? getPOMetadada(purchaseOrder)
                        : "You do not have permission to see this vendor"
                    }
                    className="customized-tooltip"
                  >
                    <NavLink
                      to={
                        !isAvalible
                          ? "#"
                          : getDashboardSearchPath({
                              salesOrderId: salesOrderId,
                              purchaseOrderId: purchaseOrder.id,
                              shipmentId: shipmentIds[0],
                              section:
                                ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                            })
                      }
                      onClick={(ev) => {
                        if (!isAvalible) {
                          setOpenAttentionModal(true);
                          ev.preventDefault();
                          ev.stopPropagation();
                        }
                      }}
                      id="list-view-content-purchaseorder"
                      className={"navLink"}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <POBadge
                        number={purchaseOrder.number}
                        isDisable={!isAvalible}
                      />
                    </NavLink>
                  </TooltipTD>
                  {purchaseOrder.status === PO_STATUS.VOIDED && (
                    <SmallVoidLabel style={{ minWidth: 25 }} />
                  )}
                </div>
                {isAvalible && (
                  <ThreePanelButton
                    soId={salesOrderId}
                    poId={purchaseOrder.id}
                    shipmentId={shipmentIds[0]}
                    tab={1}
                  />
                )}
              </div>
            );
          })}
        </div>
        {purchaseOrders.length > 0 && (
          <div
            onClick={(ev) => {
              onChangeSalesOrderSetup({
                ...salesOrderSetup,
                [item.id]: {
                  ...salesOrderSetup[item.id],
                  POs: !salesOrderSetup[item.id]
                    ? true
                    : !salesOrderSetup[item.id].POs,
                },
              });
              ev.stopPropagation();
            }}
            className="iconAnimated"
            style={{
              opacity: !isOpen ? 1 : 0,
              zIndex: !isOpen ? 1 : 0,
              pointerEvents: !isOpen ? "" : "none",
              ...iconStyle,
            }}
          >
            {HiddenDataIconAnimated({
              styles: {
                display: "flex",
              },
              showHighlight: query
                ? purchaseOrders.some((po) =>
                    `${po.number}`.toLowerCase().includes(query.toLowerCase())
                  )
                : false,
            })}
            <span className="animateText">{purchaseOrders.length}</span>
          </div>
        )}
      </AnimatedListStyled>
    </>
  );
}

export default POsItem;
