import React, { useEffect, useState } from "react";
import { TradeDashTextField } from "../PurchaseOrderDashboard/sections/styles";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import { MenuItem } from "@mui/material";
import isURL from "validator/lib/isURL";
import AttentionModal from "../Modal/AttentionModal";
import { colors } from "../../assets/jss/variables";
import { InfoIcon } from "../../helpers/iconSvgPath";

function TextFieldMetadata({
  item = {},
  value = "",
  isDisabled = true,
  endAdornment,
  onChange = () => {},
  scope = "",
  select = false,
  options = [],
  isLink = false,
}) {
  const description = item.description?.trim();
  const [currentValue, setCurrentValue] = useState(value);
  const [attentionModalData, setAttentionModalData] = useState({
    open: false,
    description: "",
  });

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  const onBlurValidation = (e) => {
    const link = e.target.value;
    if (isLink) {
      const parseLink = link.trim();
      if (!parseLink || isURL(parseLink)) {
        onChange(e);
      } else {
        setCurrentValue(value);
        setAttentionModalData({
          open: true,
          description: "This is not a valid web link",
        });
      }
    } else {
      onChange(e);
    }
  };
  const textFieldProps = select
    ? { onChange }
    : {
        onBlur: onBlurValidation,
        onChange: (e) => setCurrentValue(e.target.value),
      };
  return (
    <>
      {attentionModalData.open && (
        <AttentionModal
          title={"Attention"}
          isOpen={attentionModalData.open}
          description={attentionModalData.description}
          onClick={() =>
            setAttentionModalData({ open: false, description: "" })
          }
          onClose={() =>
            setAttentionModalData({ open: false, description: "" })
          }
        />
      )}
      <TradeDashTextField
        variant="filled"
        select={select}
        label={
          <div className="custom-textfield-label-container">
            <label>{item.label}</label>
            {description && (
              <TooltipTD
                label={description}
                style={{ right: 2, position: "absolute" }}
              >
                <InfoIcon
                  width={20}
                  height={20}
                  fillColor={colors.darkGray}
                  className="info-icon"
                />
              </TooltipTD>
            )}
          </div>
        }
        classes={{
          root: cx(
            "tradedash-metadata-custom-textfield-root",
            isDisabled
              ? "custom-textfield-root-disabled-" + scope
              : "custom-textfield-root-enabled-" + scope
          ),
        }}
        SelectProps={{
          classes: {
            icon: cx("selectIcon", {
              noSelectDescription: !description,
            }),
          },
        }}
        InputProps={{
          classes: {
            root: "custom-textfield-input-root",
            disabled: "custom-textfield-input-disabled",
            input: "custom-textfield-input",
          },
          endAdornment,
          id: item.id,
        }}
        InputLabelProps={{
          classes: {
            filled: "custom-textfield-input-filled",
          },
          shrink: true,
        }}
        disabled={isDisabled}
        type={item.type}
        value={currentValue}
        {...textFieldProps}
      >
        {select &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <TooltipTD label={option.label}>{option.label}</TooltipTD>
              {option.id === value && (
                <span
                  className="select-indicator"
                  style={{
                    position: "absolute",
                    borderLeft: `4px solid ${colors.radioCheck}`,
                    width: 4,
                    left: 0,
                    height: "60%",
                  }}
                />
              )}
            </MenuItem>
          ))}
      </TradeDashTextField>
    </>
  );
}
export default TextFieldMetadata;
