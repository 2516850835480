import React, { Fragment, useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import { getCustomerName, isTradeDashEmployee } from "../../helpers/helpers";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { useUser } from "../../hooks/user";
import ActionsModal from "./ActionsModal";
import AttentionModal from "../Modal/AttentionModal";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useCustomers } from "../../hooks/customers";
import { firestore } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { useIsAllowedFunction } from "../../hooks/permissions";
import userTypes from "../../api/types/userTypes";
import { SettingsTabTable } from "../CompanyTabs/styles";
import { ProjectHeadCells } from "../CompanyTabs/settingsHeaders";
import { getSettingsTableHeader } from "../CompanyTabs/companyTabHelper";
import ProjectTableRow from "../CompanyTabs/RowTable/ProjectTableRow";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { filterSalesOrders } from "./soTemplateHelper";
import Mark from "mark.js";
import { colors } from "../../assets/jss/variables";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import SOManagerFilters from "./SOManagerFilter";
import BasicPagination from "../Tables/BasicPagination";
import EmptyMessage from "../General/EmptyMessage";

function SalesOrdersManager({ companyId, salesOrderTemplateId }) {
  const [snackbarMessage] = useState("");
  const [snackbarType] = useState("success");
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [salesOrders, setSalesOrders] = useState([]);
  const user = useUser();
  const customers = useCustomers();
  const isAllowed = useIsAllowedFunction();
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    sortedColumn: "number",
    orderBy: "DESC",
    rowsPerPage: 10,
    page: 1,
  });
  const [query, setQuery] = useState("");
  const [actionModalType, setActionModalType] = useState("");
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [filters, setFilters] = useState({
    customer: [],
    inProgress: true,
    completed: false,
    voided: false,
    date: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
  const [attentionModalData, setAttentionModalData] = useState({
    open: false,
    description: "",
    confirmText: "",
    cancellable: false,
  });

  useEffect(() => {
    setTimeout(() => performMark(query), 150);
    setNonLookUpFilters((oldData) => ({
      ...oldData,
      page: 1,
    }));
  }, [query, filters]);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}`
      ),
      (snap) => {
        setSalesOrders(
          snap.docs.map((doc) => ({
            ...doc.data(),
            ref: doc.ref,
          }))
        );
      }
    );

    return () => {
      unsubscribe();
    };
  }, [companyId]);

  if (!companyId || !salesOrderTemplateId) {
    return null;
  }

  const completedProject = async (salesOrder) => {
    setAttentionModalData({ open: false, description: "" });
    const functions = getFunctions();
    const callableEndpoint = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: "adjustproject",
        params: "/completedSO",
        env: globalEnvironment,
      })
    );
    setLoading(true);
    try {
      await callableEndpoint({
        companyId,
        salesOrderId: salesOrder.id,
        userId: user.id,
      });
      setLoading(false);
    } catch (err) {
      console.log(err.stack);
      setLoading(false);
    }
  };

  function openVoidPO(so, ev) {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
    } else if (user.role !== userTypes.SUPER_ADMIN) {
      const voidPOPermission = isAllowed(
        GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_ORDERS
      );
      const voidPQPermission = isAllowed(
        GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_QUOTES
      );
      if (voidPOPermission || voidPQPermission) {
        setSelectedSalesOrder(so);
        setActionModalType("voidPO");
      } else {
        setAttentionModalData({
          description: "You do not have permission to void POs",
          open: true,
        });
      }
    } else {
      setSelectedSalesOrder(so);
      setActionModalType("voidPO");
    }
    ev.stopPropagation();
  }

  function getPOsString(purchaseOrders = []) {
    let poString = "";
    if (purchaseOrders.length === 1) {
      return purchaseOrders[0].number;
    }
    purchaseOrders.forEach((po, index) => {
      if (index === purchaseOrders.length - 1) {
        poString += ` and ${po.number}`;
      } else if (index === purchaseOrders.length - 2) {
        poString += `${po.number}`;
      } else {
        poString += `${po.number}, `;
      }
    });
    return poString;
  }

  const tbodyDisplay = () => {
    const currentSOs = filterSalesOrders(
      salesOrders.map((so) => ({
        ...so,
        customerName: getCustomerName(customers, so.customerId),
      })),
      filters,
      query
    );

    if (currentSOs.length === 0) {
      return (
        <EmptyMessage
          emptyFilter={
            !filters.inProgress && !filters.completed && !filters.voided
          }
          style={{ widith: "100%", gridColumn: "span 6" }}
        />
      );
    }
    return currentSOs
      .sort(
        sortObjectsBy(
          nonLookUpfilters.sortedColumn,
          nonLookUpfilters.orderBy === "ASC" ? true : false
        )
      )
      .slice(
        (nonLookUpfilters.page - 1) * nonLookUpfilters.rowsPerPage,
        nonLookUpfilters.page * nonLookUpfilters.rowsPerPage
      )

      .map((so) => {
        return (
          <ProjectTableRow
            key={so.id}
            customer={customers.find(
              (customer) => customer.id === so.customerId
            )}
            onOpenVoidPO={openVoidPO}
            onCompletedProject={(so) =>
              setAttentionModalData({
                open: true,
                description: (
                  <span>
                    Are you sure you want to complete all the tasks in this
                    project? <br />
                    <span style={{ color: colors.dangerRed }}>
                      This cannot be undone
                    </span>
                  </span>
                ),
                cancellable: true,
                confirmText: "Procced",
                cancelText: "Cancel",
                action: () => {
                  completedProject(so);
                },
              })
            }
            salesOrder={so}
          />
        );
      });
  };

  const onCloseActionModal = ({ hasError, purchaseOrders, salesOrder }) => {
    setSelectedSalesOrder(null);
    setActionModalType(null);
    if (hasError) {
      setAttentionModalData({
        description: (
          <Fragment>
            An error occurred while processing your request. Please try again
            later or contact our support team for assistance
          </Fragment>
        ),
        open: true,
      });
      return;
    }
    const open = !!purchaseOrders;
    if (salesOrder) {
      const hasPOs = purchaseOrders?.length > 0;
      const SOText = hasPOs
        ? ` And Sales Order ${salesOrder.number} has been voided as well`
        : `Sales order ${salesOrder.number} has been successfully
      voided `;
      setAttentionModalData({
        description: (
          <Fragment>
            {hasPOs &&
              `${getPOsString(purchaseOrders)} ${
                purchaseOrders.length && purchaseOrders.length === 1
                  ? "has"
                  : "have"
              } been successfully voided `}
            <br />
            <br />
            {SOText}
          </Fragment>
        ),
        open,
      });
    } else if (purchaseOrders) {
      setAttentionModalData({
        description: (
          <Fragment>
            {`${
              purchaseOrders.length && purchaseOrders.length === 1
                ? "PO"
                : "POs"
            } 
          ${getPOsString(purchaseOrders)} 
          ${
            purchaseOrders.length && purchaseOrders.length === 1
              ? "has"
              : "have"
          } been successfully voided`}
          </Fragment>
        ),
        open,
      });
    }
  };

  const markInstance = new Mark(
    document.getElementById("mainTableContainerProject")
  );

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: true,
      acrossElements: false,
      accuracy: "partially",
      exclude: [
        "#headerLabel",
        ".buttonAction > *",
        ".date > *",
        "#completedButton > *",
        "#voidButton > *",
      ],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  return (
    <>
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      {attentionModalData.open && (
        <AttentionModal
          title="Attention"
          description={attentionModalData.description}
          isOpen={attentionModalData.open}
          onClick={() => {
            if (attentionModalData.action) {
              attentionModalData.action();
            }
            setAttentionModalData({ description: "", open: false });
          }}
          cancellable={attentionModalData.cancellable}
          onClose={() =>
            setAttentionModalData({ description: "", open: false })
          }
          confirmationText={attentionModalData.confirmText}
          cancelText={attentionModalData.cancelText}
        />
      )}
      <CustomSnackbar
        open={openSnackbarSuccess}
        message={snackbarMessage}
        handleCloseSnackbar={() => setOpenSnackbarSuccess(false)}
        variant={snackbarType}
      />
      <SOManagerFilters
        filters={filters}
        setFilters={setFilters}
        setQuery={setQuery}
        query={query}
      />
      <div className="mainTableContainer">
        <SettingsTabTable
          className="ProjectTable"
          id={"mainTableContainerProject"}
        >
          {loading && <LoadingBackdrop size={60} withLogo={true} />}
          {getSettingsTableHeader({
            headers: ProjectHeadCells,
            updateSorting: (nonLookUpfilters) => {
              setNonLookUpFilters({ ...nonLookUpfilters });
            },
            filters: nonLookUpfilters,
          })}

          {tbodyDisplay()}
        </SettingsTabTable>
      </div>
      <BasicPagination
        style={{ position: "relative" }}
        rowsPerPage={nonLookUpfilters.rowsPerPage}
        page={nonLookUpfilters.page}
        count={getCountPagination(
          filterSalesOrders(salesOrders, filters, query),
          nonLookUpfilters.rowsPerPage
        )}
        onChangePagination={(event, newPage) => {
          setNonLookUpFilters((oldData) => ({ ...oldData, page: newPage }));
        }}
        onChangeRowsPerPage={(event) => {
          setNonLookUpFilters((oldData) => ({
            ...oldData,
            rowsPerPage: +event.target.value,
            page: 1,
          }));
        }}
      />
      {selectedSalesOrder && !!actionModalType && (
        <ActionsModal
          open={!!actionModalType}
          isAllowed={isAllowed}
          onClose={({ purchaseOrders, salesOrder, hasError }) => {
            onCloseActionModal({ purchaseOrders, salesOrder, hasError });
          }}
          salesOrder={selectedSalesOrder}
        />
      )}
    </>
  );
  function getCountPagination(purchaseOrders, rowsPerPage) {
    return Math.ceil(purchaseOrders.length / rowsPerPage);
  }
}

export default SalesOrdersManager;
