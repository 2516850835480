import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { TableCell, TableRow } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import moment from "moment";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { ErrorLogStyled } from "./styles";
import { loadDataBatches } from "../../helpers/settings.helpers";

function BackEndErrorLog() {
  const { companyId } = useParams();
  const [errorLogDB, setErrorLogDB] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [pendingToLoad, setPendingToLoad] = useState(true);

  const companies = useSelector((state) => state.data.companies);
  const currentCompany = companies.find((company) => company.id === companyId);

  async function loadErrorLog({ lastDoc }) {
    const {
      docs,
      lastDoc: lastDocResult,
      pendingToLoad,
    } = await loadDataBatches({
      path: `${dbTables.COMPANIES}/${companyId}/${dbTables.BACKEND_ERROR_LOG}`,
      lastDoc: lastDoc,
      limitFilter: 50,
      orderByField: "date",
    });
    setLastDoc(lastDocResult);
    setPendingToLoad(pendingToLoad);
    if (!lastDoc) {
      setErrorLogDB(docs);
    } else {
      setErrorLogDB((prevErrorLogs) => [...prevErrorLogs, ...docs]);
    }
  }

  useEffect(() => {
    loadErrorLog({ lastDoc: null });
  }, [companyId]);

  const tbodyDisplay =
    errorLogDB &&
    errorLogDB.sort(sortObjectsBy("date", true)).map((item) => {
      return (
        <TableRow key={item.id}>
          <TableCell
            style={{
              fontSize: "0.875rem",
            }}
          >
            {moment(item.date).format("M/D/YY hh:mm:ssa")}
          </TableCell>
          <TableCell style={{ textAlign: "left", fontSize: "0.875rem" }}>
            {item.error_message_id}
          </TableCell>
          <TableCell
            style={{
              fontSize: "0.875rem",
            }}
          >
            {item.description}{" "}
            <span
              style={{
                background: "lightgray",
                borderRadius: 9,
                padding: "2px 6px",
                fontSize: 12,
                fontWeight: 500,
                color: "#25486d",
              }}
            >
              {item.end_point}
            </span>
            {item.end_point_type && (
              <span
                style={{
                  background: "lightgray",
                  borderRadius: 9,
                  padding: "2px 6px",
                  fontSize: 10,
                  fontWeight: 500,
                  color: "white",
                  backgroundColor: "#39F",
                }}
              >
                {item.end_point_type}
              </span>
            )}
          </TableCell>
        </TableRow>
      );
    });

  const thead = ["Date and Time", "Error ID", "Description"];

  const theadDisplay = thead.map((thead) => {
    return (
      <TableCell
        style={
          thead === "Description"
            ? { width: "80%", top: 40, fontSize: "0.875rem" }
            : { width: "10%", top: 40, fontSize: "0.875rem" }
        }
      >
        {thead}
      </TableCell>
    );
  });

  return (
    <ErrorLogStyled className="errorLogContainer">
      <div
        style={{
          textAlign: "right",
          height: 40,
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: "white",
        }}
      >
        <span>Enable Back-End error log</span>
        <Switch
          onChange={(ev, value) => {
            console.log("COMPANY", currentCompany);
            updateDoc(
              doc(firestore, `${dbTables.COMPANIES}/${currentCompany.id}`),
              { backend_error_log: value }
            );
          }}
          checked={currentCompany.backend_error_log}
        />
      </div>

      <DataTable header={theadDisplay} className="row-hover-over">
        {tbodyDisplay}
      </DataTable>
      {pendingToLoad && (
        <div className="load-more-button">
          <button onClick={() => loadErrorLog({ lastDoc })}>Load More</button>
        </div>
      )}
    </ErrorLogStyled>
  );
}

export default BackEndErrorLog;
