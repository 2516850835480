import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useJSONLocalStorage, useQueryParams, useStorage } from "../../hooks";
import { useCompanyId, useCompanyUsers, useUser } from "../../hooks/user";
import { getUserAvatar } from "../../helpers/users";
import {
  BACKDROP_TYPE,
  LOCAL_STORAGE_KEY,
  NOTIFICATION_SCOPE,
  ORDER_DASHBOARD_ACTUAL_VIEWS,
} from "../../helpers/constants";
import TradeDashLogo from "../../assets/flag-icons/TradeDash_logo.svg";
import { getDoc, getDocs, updateDoc } from "firebase/firestore";
import { useCallback } from "react";
import { setGlobalNoteDate } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import Mark from "mark.js";
import { NotificationContainerStyled } from "./styles";
import { CLIENT_IS_OFFLINE } from "../../actions/types";
import { cx } from "@emotion/css";
import {
  getNotificationText,
  PO_NOTIFICATION_SCOPES,
  SALES_ORDER_NOTIFICATION_SCOPES,
  SHIPMENT_NOTIFICATION_SCOPES,
  verifyPermissionNotification,
  verifyRouteTaskPermission,
} from "./NotificationHelpers";
import { getDashboardSearchPath } from "../../helpers/helpers";

const DASHBOARD = "dashboard";

function Notification({
  notification,
  onToggle = () => {},
  closeDrawer = () => {},
  isAbleToModifyTaskDueDate = false,
  params,
  purchaseOrders = [],
  isAllowed = () => {},
  handleBackdrop = () => {},
  handleOpenModal = () => {},
  handleLoading,
  searchText = "",
}) {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const users = useCompanyUsers({ showBotUser: true });
  const currentUser = useUser();
  const [, setBackdropStorage] = useStorage("backdrop");
  const { set } = useJSONLocalStorage(LOCAL_STORAGE_KEY.projectTasks);

  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const setNoteDate = useCallback(
    ({ type = "", creationDate = "", noteId = "" }) =>
      setGlobalNoteDate({
        type,
        creationDate,
        noteId,
      })(dispatch)
  );

  const notificationUser =
    users.find((user) =>
      notification.scope === NOTIFICATION_SCOPE.PO_MENTION ||
      notification.scope === NOTIFICATION_SCOPE.SO_MENTION ||
      notification.scope === NOTIFICATION_SCOPE.SHIPMENT_MENTION
        ? user.id === notification.userId
        : user.id === notification.completedBy
    ) || {};

  const handleBackdropStorage = (type, id, infoCard = true) => {
    setBackdropStorage("lastBackdrop", {
      type: type,
      isOpen: true,
      id: id || type,
      infoCard: infoCard,
    });
  };

  const getDocumentsQuery = async (query) => {
    const snapDocuments = await getDocs(query);
    return snapDocuments.docs.map((so) => ({
      ...so.data(),
    }));
  };

  const markInstance = new Mark(
    document.getElementById("notification-scroll-container")
  );
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [".item-header"],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  const getDocumentQuery = async (query) => {
    const snapDocument = await getDoc(query);
    return snapDocument.data() || {};
  };

  const searchPathOnDashboard = async (idKey = "taskId") => {
    const newGlobalNote = {
      ...notification,
      creationDate: moment(notification.creationDate)
        .subtract(1, "day")
        .startOf("day")
        .valueOf(),
    };
    const inDashboard =
      params.dashboard === DASHBOARD || params.dashboard === "";
    const sameSO = params.salesOrderId === notification.salesOrderId;
    const samePO = params.purchaseOrderId === notification.purchaseOrderId;
    const sameShipment = params.shipmentId === notification.shipmentId;

    if (inDashboard && sameSO) {
      const { canRedirect, path, block } = verifyRouteTaskPermission({
        notification,
        isAllowed,
        currentShipment: notification.shipment,
        purchaseOrders,
        idKey,
      });
      if (canRedirect) {
        if (
          SALES_ORDER_NOTIFICATION_SCOPES.includes(notification.scope) ||
          (PO_NOTIFICATION_SCOPES.includes(notification.scope) && samePO) ||
          (SHIPMENT_NOTIFICATION_SCOPES.includes(notification.scope) &&
            sameShipment)
        ) {
          setNoteDate(newGlobalNote);
          setTimeout(() => {
            handleLoading(false);
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                [idKey]: notification.mainDocumentId,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
              }),
              { replace: true }
            );
            closeDrawer();
          }, 500);
          return;
        }
        setNoteDate(newGlobalNote);
        setTimeout(() => {
          handleLoading(false);
          navigate(path, { replace: true });
          closeDrawer();
        }, 500);
      } else {
        handleLoading(false);
        handleOpenModal(block);
      }
    } else {
      const canRedirectExternal = await verifyPermissionNotification({
        notification,
        isAllowed,
        idKey,
        companyId,
        getDocumentsQuery,
        getDocumentQuery,
        currentUser,
      });
      if (canRedirectExternal.newBlock) {
        handleLoading(false);
        handleOpenModal(canRedirectExternal.newBlock);
      } else {
        if (inDashboard) {
          navigate({
            pathname: "/app/dashboard",
            search: `salesOrderId=${newGlobalNote.salesOrderId}&&section=sales-order-info-to-scroll`,
          });
          setTimeout(() => {
            navigate(canRedirectExternal.newPath, { replace: true });
            setNoteDate(newGlobalNote);
            handleLoading(false);
            closeDrawer();
          }, 300);
        } else {
          setNoteDate(newGlobalNote);
          setTimeout(() => {
            navigate(canRedirectExternal.newPath);
            handleLoading(false);
            closeDrawer();
          }, 500);
        }
      }
    }
  };

  async function searchForPODashboard() {
    const { canRedirect, path, block } = verifyRouteTaskPermission({
      notification: { ...notification, mainDocumentId: "" },
      isAllowed,
      currentShipment: notification.shipment,
      purchaseOrders,
    });
    if (
      (params.dashboard === DASHBOARD || params.dashboard === "") &&
      params.salesOrderId === notification.salesOrderId
    ) {
      updateDoc(notification.ref, { read: true });
      if (canRedirect) {
        closeDrawer();
        handleBackdrop(BACKDROP_TYPE.PURCHASE_ORDER_INFO);
        navigate(path, { replace: true });
      } else {
        handleOpenModal(block);
      }
    } else {
      await updateDoc(notification.ref, { read: true });
      closeDrawer();
      navigate(
        getDashboardSearchPath({
          salesOrderId: notification.salesOrderId,
          purchaseOrderId: notification.purchaseOrderId,
        })
      );
      handleBackdropStorage(BACKDROP_TYPE.PURCHASE_ORDER_INFO);
    }
  }

  async function toggleRead(ev) {
    ev.stopPropagation();
    handleLoading(true);
    await onToggle(notification);
    handleLoading(false);
  }

  useEffect(() => {
    if (searchText) {
      performMark(searchText);
    }
  }, []);

  useEffect(() => {
    if (searchText) {
      performMark(searchText);
    }
  }, [searchText]);

  const withoutActionNotificationScopes = [NOTIFICATION_SCOPE.API_ERROR_LOG];

  function getHeader({ creationDate }) {
    const isToday = moment(creationDate).isSame(moment(), "day");
    const isYesterday = moment(creationDate).isSame(
      moment().subtract(1, "day"),
      "day"
    );
    const isThisYear = moment(creationDate).isSame(moment(), "year");

    if (isToday) {
      return (
        <label>
          <strong>Today </strong> {moment(creationDate).format("h:mmA")}
        </label>
      );
    } else if (isYesterday) {
      return (
        <label>
          {" "}
          <strong>Yesterday </strong> {moment(creationDate).format("h:mmA")}
        </label>
      );
    } else if (isThisYear) {
      return <label>{moment(creationDate).format("MMMM DD, h:mmA")} </label>;
    } else {
      return (
        <label>{moment(creationDate).format("MMMM DD YYYY, h:mmA")} </label>
      );
    }
  }

  return (
    <NotificationContainerStyled
      className="notificationContainer"
      onClick={async (ev) => {
        if (window.navigator.onLine === false) {
          dispatch({
            type: CLIENT_IS_OFFLINE,
            payload: {
              value: true,
              text: "Please wait a moment and try again",
            },
          });
          return;
        } else if (
          withoutActionNotificationScopes.includes(notification.scope)
        ) {
          return;
        } else if (
          notification.scope ===
          NOTIFICATION_SCOPE.TRADEDASH_WARNING_NOTIFICATION
        ) {
          return searchForPODashboard();
        }
        handleLoading(true);
        if (!notification.read) {
          toggleRead(ev);
        }

        set({ taskFilter: "ALL", showCompleted: true });

        let keyId = "taskId";
        if (
          notification.scope === NOTIFICATION_SCOPE.PO_MENTION ||
          notification.scope === NOTIFICATION_SCOPE.SO_MENTION ||
          notification.scope === NOTIFICATION_SCOPE.SHIPMENT_MENTION
        ) {
          keyId = "noteId";
        }
        return searchPathOnDashboard(keyId);
      }}
      key={notification.id}
      style={{ borderLeft: "6px solid #fff", borderBottom: "9px solid #fff" }}
    >
      <div className={cx("item", { unreadStyled: !notification.read })}>
        <div className={"itemWrapper"}>
          <section className="item-header">
            {getHeader({ creationDate: notification.creationDate })}
          </section>
          <section className={"itemContent"}>
            {notification.scope ===
            NOTIFICATION_SCOPE.TRADEDASH_WARNING_NOTIFICATION ? (
              <div className="tradedash-logo-container">
                <img src={TradeDashLogo} alt="tradedash-logo" />
              </div>
            ) : (
              notificationUser &&
              getUserAvatar({
                user: notificationUser,
                styles: {
                  width: 36,
                  height: 36,
                  marginRight: 12,
                  fontSize: 20,
                  outline: "3px solid #000",
                },
              })
            )}
            <div
              className="activity-body"
              dangerouslySetInnerHTML={{
                __html: getNotificationText(
                  {
                    ...notification,
                    isAbleToModifyTaskDueDate,
                  },
                  users
                ),
              }}
              style={{ display: "inline" }}
            />
          </section>
        </div>
        <div
          onClick={toggleRead}
          className="unreadIndicator"
          style={{ backgroundColor: !notification.read ? "#39F" : "#fff" }}
        ></div>
      </div>
    </NotificationContainerStyled>
  );
}

export default Notification;
