import React from "react";
import { cx } from "@emotion/css";
import { CloseIcon } from "../../helpers/iconSvgPath";
import { StyledTooltip } from "./styles";



function CloseButton({ onClick, label, className, size }) {
  return (
    <StyledTooltip
      tooltipClick={onClick}
      label={label}
      showToolTip={!!label}
      className={cx("TooltipBase", className)}
      style={{ width: size, height: size }}
    >
      <CloseIcon style={{ minWidth: size, minHeight: size }} />
    </StyledTooltip>
  );
}

export default CloseButton;
