import React, { useState } from "react";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  GENERAL_PERMISSION_VALUE,
  ORDER_DASHBOARD_ACTUAL_VIEWS,
  PO_STATUS,
  headerColumns,
  screenPath,
} from "../../helpers/constants";
import { getShipmentBadge } from "../../helpers/shipments";
import { getDashboardSearchPath } from "../../helpers/helpers";
import { NavLink } from "react-router-dom";
import userTypes from "../../api/types/userTypes";
import { checkSpecificPermissions } from "../../hooks/permissions";
import {
  DeleteIcon,
  EditIcon,
  ExpenseFileIcon,
} from "../../helpers/iconSvgPath";
import {
  canDeleteExpense,
  canEditExpense,
  getTotal,
  getValueByType,
  TYPE_GL_ITEMS,
} from "../../helpers/expenses";
import { EXPENSE_FIELDS, orderType, PO_TYPES } from "../../helpers/salesOrder";

import { Modal, TableCell } from "@mui/material";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import { getDisclouserArrow } from "../../helpers/ScreenComponetHelper/componetHelper";
import { AnimatedListStyled } from "../StyledComponent/AnimatedOpenList";
import { ORDER_TYPE } from "../../api/types/dbTables";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";
import POBadge from "../../components/Badge/POBadge";
import CustomTableRowSelector from "../SalesOrdersScreen/CustomTableRowSelector";

const TYPE = {
  [orderType.SALES_ORDER]: "Sales Order",
  [orderType.SHIPMENT]: "Shipment",
  [orderType.PURCHASE_ORDER]: "Purchase Order",
};

const renderIcon = (IconComponent, show, onClickHandler) => {
  if (show) {
    return (
      <IconComponent
        style={{ cursor: "pointer", margin: "0px 4px" }}
        onClick={(ev) => {
          onClickHandler();
          ev.preventDefault();
          ev.stopPropagation();
        }}
      />
    );
  }
  return (
    <label
      style={{
        width: 28,
        position: "relative",
        margin: 0,
        height: 10,
      }}
    />
  );
};

function ExpensesTableRow({
  item,
  user,
  isAllowed = () => {},
  customers,
  headers = [],
  expensesSetup = {},
  onChangeExpensesSetup,
  GLAccountItems = [],
  handleExpensesDelete = () => {},
  handleExpenseEdit = () => {},
  nonLookUpfilters = {},
}) {
  const [showPDF, setShowPDF] = useState(false);

  function handleChangeSalesOrderSetup() {
    if (nonLookUpfilters.query) {
      return;
    }
    if (expensesSetup[item.id]) {
      return onChangeExpensesSetup({
        ...expensesSetup,
        [item.id]: false,
      });
    } else {
      return onChangeExpensesSetup({
        ...expensesSetup,
        [item.id]: true,
      });
    }
  }

  const getNavAction = ({ order = {}, type }) => {
    let path = "";
    let shipment;
    switch (type) {
      case orderType.SALES_ORDER:
        const purchaseOrderIds = order.purchaseOrderIds || [];
        const [poId] = purchaseOrderIds;
        if (poId) {
          path = getDashboardSearchPath({
            salesOrderId: order.salesOrderId,
            purchaseOrderId: poId,
            // shipmentId: order.shipmentId, // PERNDING
            section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
          });
        } else {
          path = `/app/${screenPath.EXPENSE}`;
        }
        break;
      case orderType.PURCHASE_ORDER:
        const salesOrderIds = order.salesOrderIds || [];
        const [soId] = salesOrderIds;
        if (soId) {
          path = getDashboardSearchPath({
            salesOrderId: soId,
            purchaseOrderId: order.purchaseOrderId,
            // shipmentId: order.shipmentId, // PENDING
            section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
          });
        } else {
          path = `/app/${screenPath.EXPENSE}`;
        }
        break;
      default:
        const poShipmentId = order.purchaseOrderIds[0];
        const soShipmentId = order.salesOrderIds[0];

        shipment = {
          number: order.number,
          purchaseOrderId: poShipmentId,
          salesOrderId: soShipmentId,
          id: order.shipmentId,
          finalDestination: order.finalDestination,
          shipDate: order.shipDate,
          deliveryDate: order.deliveryDate,
        };
        if (poShipmentId && shipment && soShipmentId) {
          path = getDashboardSearchPath({
            salesOrderId: soShipmentId,
            purchaseOrderId: poShipmentId,
            shipmentId: shipment.id,
            section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
          });
          shipment = { ...shipment, purchaseOrderId: poShipmentId };
        } else {
          path = `/app/${screenPath.EXPENSE}`;
        }

        break;
    }
    return { path, shipment };
  };

  const expandItems = ({
    items = [],
    field,
    withTotal,
    hasLotItems,
    type,
    isOpen,
    showTotal,
    id,
  }) => {
    const isAmount =
      type === TYPE_GL_ITEMS.DOLAR || TYPE_GL_ITEMS.PERCENTAGE === type;
    const [firstItem = {}] = items;
    const textStyle = {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: isAmount ? 17 : 0,
      display: "flex",
      alignItems: "center",
      justifyContent: isAmount ? "flex-end" : "flex-start",
    };
    if (!hasLotItems) {
      const { currentValue: firstValue, styleText: firstStyleColor } =
        getValueByType({ type, value: firstItem[field], GLAccountItems });
      return (
        <TooltipTD
          label={firstValue || ""}
          style={{ ...firstStyleColor, ...textStyle }}
          className="customized-tooltip"
          showToolTip={!!firstValue.trim()}
        >
          <span>{firstValue || ""}</span>
        </TooltipTD>
      );
    } else {
      const { currentValue: total, styleText: styleTotal } = getValueByType({
        type,
        value: getTotal({ list: items, field }),
        GLAccountItems,
      });
      const mapValue = items.map((item) => {
        const { currentValue: itemValue, styleText: itemStyle } =
          getValueByType({ type, value: item[field], GLAccountItems });
        return (
          <TooltipTD
            label={itemValue || ""}
            style={{
              height: 20,
              ...itemStyle,
              ...textStyle,
            }}
            showToolTip={!!itemValue.trim()}
            id={id}
            className="customized-tooltip"
          >
            <span>{itemValue || ""}</span>
          </TooltipTD>
        );
      });
      const autoHeight = mapValue.length;

      return (
        <AnimatedListStyled
          className="listContainer"
          style={{
            textDecoration: "unset",
            height: isOpen
              ? autoHeight * 20.1 + (autoHeight - 1) * 16 + 23
              : 20,
          }}
        >
          <div
            className="listItemExpenses"
            style={{
              alignItems: isAmount ? "flex-end" : "",
            }}
          >
            <div
              style={{
                opacity: isOpen ? 1 : 0,
                zIndex: isOpen ? 1 : 0,
              }}
              className="opacityListAnimation"
            >
              {mapValue}
            </div>
            <span
              id="total"
              className="totalList"
              style={{
                height: isOpen && withTotal ? 23 : 0,
                opacity: isOpen && withTotal ? 1 : 0,
                ...styleTotal,
              }}
            >
              {withTotal && total}
            </span>
            <span
              id="total"
              className="opacitiyTotal"
              style={{
                width: isOpen ? "" : "100%",
                height: !isOpen ? 23 : 0,
                opacity: !isOpen ? 1 : 0,
                zIndex: !isOpen ? 1 : 0,
                ...styleTotal,
              }}
            >
              {showTotal && total}
            </span>
          </div>
        </AnimatedListStyled>
      );
    }
  };

  function expensesTableRowSelector({ column, enabled, item, index }) {
    const items = item.items || [];
    const hasLotItems = items.length > 1;
    const isOpen = expensesSetup[item.id] || nonLookUpfilters.query;
    const order = item.order || {};
    switch (column) {
      case headerColumns.EXPAND:
        return (
          <TableCell key={index} className="favorite">
            {getDisclouserArrow({
              handleChangeSetup: handleChangeSalesOrderSetup,
              currentSetup: expensesSetup[item.id],
              items,
              type: "expenses",
              forceOpen: nonLookUpfilters.query,
            })}
          </TableCell>
        );

      case headerColumns.ORDER_NUMBER: {
        const SALES_ORDER_TYPE = [orderType.SALES_ORDER, orderType.QUOTE];
        const { path, shipment } = getNavAction({
          order,
          type: item.type,
        });
        return (
          enabled && (
            <TableCell key={index} className="orderNumber">
              <TooltipTD
                className="customized-tooltip"
                label={order.number}
                showToolTip={order.type === ORDER_TYPE.SHIPMENT}
              >
                <NavLink
                  to={path}
                  className="navLink"
                  style={{
                    textDecoration: "none",
                  }}
                  id="list-view-content-ordernumber"
                >
                  {SALES_ORDER_TYPE.includes(item.type) ? (
                    <SalesOrderBadge number={item.orderNumber} />
                  ) : PO_TYPES.includes(item.type) ? (
                    <POBadge number={item.orderNumber} />
                  ) : (
                    getShipmentBadge({
                      shipment,
                      classes: shipmentBadgeClass.medium,
                    })
                  )}
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );
      }

      case headerColumns.GL_ACCOUNT:
        return (
          enabled && (
            <TableCell key={index} className="text">
              {expandItems({
                items,
                hasLotItems,
                isOpen,
                field: EXPENSE_FIELDS.GL_ACCOUNT_ID,
                type: TYPE_GL_ITEMS.GL,
              })}
            </TableCell>
          )
        );
      //

      case headerColumns.ITEM_TYPE:
        return (
          enabled && (
            <TableCell key={index} className="text">
              <TooltipTD label={TYPE[item.type]} className="customized-tooltip">
                <span>{TYPE[item.type]}</span>
              </TooltipTD>
            </TableCell>
          )
        );

      //
      case headerColumns.DESCRIPTION:
        return (
          enabled && (
            <TableCell key={index} className="text">
              {expandItems({
                items,
                hasLotItems,
                isOpen,
                field: EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
                type: TYPE_GL_ITEMS.TEXT,
              })}
            </TableCell>
          )
        );
      case headerColumns.NOTES:
        return (
          enabled && (
            <TableCell key={index} className="text">
              {expandItems({
                items,
                hasLotItems,
                isOpen,
                field: EXPENSE_FIELDS.NOTES,
                type: TYPE_GL_ITEMS.TEXT,
              })}
            </TableCell>
          )
        );
      case headerColumns.FORECAST:
        return (
          enabled && (
            <TableCell key={index} className="amount" id="forecast">
              {expandItems({
                items,
                hasLotItems,
                isOpen,
                field: EXPENSE_FIELDS.FORECAST,
                type: TYPE_GL_ITEMS.DOLAR,
                withTotal: true,
                showTotal: true,
                id: "forecastExpanded",
              })}
            </TableCell>
          )
        );
      case headerColumns.ACTUAL:
        return (
          enabled && (
            <TableCell key={index} className="amount" id="actual">
              {expandItems({
                items,
                hasLotItems,
                isOpen,
                field: EXPENSE_FIELDS.ACTUAL,
                type: TYPE_GL_ITEMS.DOLAR,
                withTotal: true,
                showTotal: true,
                id: "actualExpanded",
              })}
            </TableCell>
          )
        );
      case headerColumns.DELTA:
        return (
          enabled && (
            <TableCell key={index} className="amount" id="delta">
              {expandItems({
                items,
                hasLotItems,
                isOpen,
                field: EXPENSE_FIELDS.DELTA,
                type: TYPE_GL_ITEMS.PERCENTAGE,
                withTotal: true,
                showTotal: true,
                id: "deltaExpanded",
              })}
            </TableCell>
          )
        );
      case headerColumns.CUSTOMER:
        const currentCustomer = order.customerId.map(
          (id) => customers.find((cus) => cus.id === id) || {}
        );

        return (
          enabled && (
            <TableCell key={index} className="customerName">
              {currentCustomer.map((customer, index) => (
                <TooltipTD label={customer.name} className="customized-tooltip">
                  <NavLink
                    key={customer.id + index}
                    to={
                      !(
                        user.role === userTypes.SUPER_ADMIN ||
                        user.role === userTypes.TRADEDASH_EMPLOYEE ||
                        checkSpecificPermissions({
                          userPermissions: user.permissions,
                          permissionToCheck: [
                            customer.id,
                            GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                          ],
                        })
                      )
                        ? "#"
                        : `/app/${screenPath.CUSTOMER}/${customer.id}`
                    }
                    className="navLink"
                    style={{ textDecoration: "underline" }}
                    id="list-view-content-customer"
                  >
                    <span className="span-description">
                      {`${customer.name}${
                        index === currentCustomer.length - 1 ? "" : ","
                      }`}
                    </span>
                  </NavLink>
                </TooltipTD>
              ))}
            </TableCell>
          )
        );

      case headerColumns.ED_TOOL:
        const isVoided = order.status === PO_STATUS.VOIDED;
        const isEditAllowed = canEditExpense({
          isAllowed,
          createdBy: item.createdBy,
          userId: user.id,
        });
        const isDeleteAllowed = canDeleteExpense({
          isAllowed,
          createdBy: item.createdBy,
          userId: user.id,
        });
        return (
          enabled && (
            <TableCell key={index} className="tools">
              {renderIcon(ExpenseFileIcon, item.pdfURL, () => {
                setShowPDF(true);
              })}
              {renderIcon(EditIcon, isEditAllowed && !isVoided, () => {
                handleExpenseEdit(item);
              })}
              {renderIcon(DeleteIcon, isDeleteAllowed && !isVoided, () => {
                handleExpensesDelete({
                  ...item,
                  description: (
                    <React.Fragment>
                      Do you want to delete this expense? <br /> This cannot be
                      undone
                    </React.Fragment>
                  ),
                });
              })}
            </TableCell>
          )
        );
      default:
        return <TableCell key={index} />;
    }
  }
  return (
    item && (
      <MainRowStyled
        key={item.id + "MainRowStyled"}
        className={cx(
          mainRowClasses.ExpensesTableRow,
          mainRowClasses.hoverEffect
        )}
      >
        {headers.map((header) => {
          if (!!header.type) {
            return (
              <CustomTableRowSelector
                index={item.id + header.field}
                header={header}
                item={item}
              />
            );
          } else {
            return expensesTableRowSelector({
              column: header.label,
              enabled: header.enabled,
              item,
              index: item.id + header.label,
            });
          }
        })}
        {showPDF && (
          <Modal open={showPDF} onClose={() => setShowPDF(false)}>
            <div
              className="pdf-viewer-container"
              style={{
                width: "40%",
                height: "100%",
                margin: "auto",
              }}
            >
              <iframe
                title="pdf"
                src={item.pdfURL}
                className={"expense-pdf"}
                style={{
                  width: "-webkit-fill-available",
                  height: "90%",
                  marginTop: "6%",
                  marginBottom: "6%",
                }}
              />
            </div>
          </Modal>
        )}
        <TableCell className="LAST_BLANK_COLUMN" />
        <TableCell className="LAST_100_WIDTH_COLUMN" />
      </MainRowStyled>
    )
  );
}

export default ExpensesTableRow;
