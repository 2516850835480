export const shipmentTriggerTypes = {
  DATE: "date",
  SHIP_DATE: "shipDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  BOOKING: "booking",
  BILL_OF_LADING: "billOfLading",
  CONTAINER: "container",
  FREIGHT_FORWARDER: "freightForwarder",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  INVOICE: "invoice",
  CUSTOMER_PO: "customerPO",
  SHIPPING_TERMS: "shippingTerms",
  PERSON: "person",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  NUMBER: "number",
  TAGS: "tags",
  COMPLETED_TASKS: "completedTasks",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  PENDING_FILES: "PENDING_FILES",
  VOID: "VOID",
  METADATA: "METADATA",
};

export const SHIPMENT_VIEW_FIELDS = {
  DATE: "date",
  CREATION_DATE: "creationDate",
  SHIP_DATE: "shipDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  BOOKING: "booking",
  BILL_OF_LADING: "billOfLading",
  CONTAINER: "container",
  FREIGHT_FORWARDER: "freightForwarder",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  INVOICE: "invoice",
  CUSTOMER_PO: "customerPO",
  SHIPPING_TERMS: "shippingTerms",
  PERSON: "person",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  NUMBER: "number",
  TAGS: "tags",
  COMPLETED_TASKS: "completedTasks",
  UNIQUE_ITEMS: "uniqueItems",
  TOTAL_SHIPMENTS: "totalShipments",
  CUSTOMER: "customer",
  FAVORITE: "favorite",
  DIAMOND: "diamond",
  PROGRESS: "progress",
  MENTIONS: "mentions",
  PO: "po",
  SO: "so",
  BALANCE: "balance",
  SUB_TOTAL: "subTotal",
  TOTAL: "total",
  TOTAL_WEIGHT: "totalWeight",
};
