import { Popover, styled, TextField } from "@mui/material";
import { colors } from "../../../assets/jss/variables";
import { NumericFormat } from "react-number-format";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";

const sharedStyles = {
  "&.tradedash-metadata-custom-textfield-root": {
    width: 230,
    "& .select-indicator": {
      display: "none",
    },
    "& .info-icon": {
      opacity: 0,
    },
    "& .custom-textfield-input-root": {
      height: 53,
      color: colors.primaryDark,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "& .MuiAutocomplete-endAdornment": {
        right: 8,
        top: 37,
        "& .MuiButtonBase-root": {
          minWidth: "unset",
          transition: "transform 0.3s ease-in-out",
        },
      },
      "& .selectIcon": {
        right: 8,
        top: 24,
        transition: "transform 0.3s ease-in-out",
      },
    },
    "& .custom-textfield-input-filled": {
      fontSize: 16,
      fontWeight: 400,
      color: colors.darkGray,
      width: "119%",
      "& .custom-textfield-label-container": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        "& label": {
          maxWidth: 250,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  "& .custom-textfield-input-root": {
    backgroundColor: colors.salesOrderBorderColor,
    borderRadius: "0px !Important",
    "& .custom-textfield-input": {
      color: colors.primaryDark,
      fontWeight: 500,
    },
    "&::after": {
      borderBottom: `2px solid ${colors.salesOrderSelectedBorderColor} !Important`,
    },
    "&::before": {
      borderBottom: "none !Important",
    },
  },
  "&.custom-textfield-root-enabled-sales_orders": {
    "& .custom-textfield-input-root": {
      backgroundColor: colors.salesOrderDocBackgroundColor,
      borderRadius: 0,
      "&::after": {
        borderBottom: `2px solid ${colors.salesOrderSelectedBorderColor} !Important`,
      },
    },
    "&.tradedash-metadata-custom-textfield-root": {
      "&:hover": {
        "& .custom-textfield-input-root": {
          backgroundColor: colors.salesOrderHoverColor,
        },
        "& .info-icon": {
          opacity: "1 !important",
        },
      },
    },
  },
  "&.custom-textfield-root-disabled-sales_orders": {
    "& .custom-textfield-input-root": {
      backgroundColor: colors.salesOrderDocBackgroundColor,
      borderRadius: 0,
      "& .custom-textfield-input": {
        fontWeight: 300,
        color: colors.primaryDark,
        WebkitTextFillColor: colors.primaryDark,
      },
      "&::after": {
        borderBottom: `2px solid ${colors.salesOrderSelectedBorderColor} !Important`,
      },
    },
    "&.tradedash-metadata-custom-textfield-root": {
      "&:hover": {
        "& .info-icon": {
          opacity: "1 !important",
        },
      },
    },
  },
  "&.custom-textfield-root-enabled-purchase_orders": {
    "& .custom-textfield-input-root": {
      backgroundColor: colors.purchaseOrderDocBackgroundColor,
      borderRadius: 0,
      "&::after": {
        borderBottom: `2px solid ${colors.purchaseOrderSelectedBorderColor} !Important`,
      },
    },
    "&.tradedash-metadata-custom-textfield-root": {
      "&:hover": {
        "& .custom-textfield-input-root": {
          backgroundColor: colors.purchaseOrderHoverColor,
        },
        "& .info-icon": {
          opacity: "1 !important",
        },
      },
    },
  },
  "&.custom-textfield-root-disabled-purchase_orders": {
    "& .custom-textfield-input-root": {
      backgroundColor: colors.purchaseOrderDocBackgroundColor,
      borderRadius: 0,
      "& .custom-textfield-input": {
        fontWeight: 300,
        color: colors.primaryDark,
        WebkitTextFillColor: colors.primaryDark,
      },
      "&::after": {
        borderBottom: `2px solid ${colors.purchaseOrderSelectedBorderColor} !Important`,
      },
    },
    "&.tradedash-metadata-custom-textfield-root": {
      "&:hover": {
        "& .info-icon": {
          opacity: "1 !important",
        },
      },
    },
  },
  "&.custom-textfield-root-enabled-shipments": {
    "& .custom-textfield-input-root": {
      backgroundColor: colors.shipmentDocBackgroundColor,
      borderRadius: 0,
      "&::after": {
        borderBottom: `2px solid ${colors.shipmentSelectedBorderColor} !Important`,
      },
    },
    "&.tradedash-metadata-custom-textfield-root": {
      "&:hover": {
        "& .custom-textfield-input-root": {
          backgroundColor: colors.shipmentHoverColor,
        },
        "& .info-icon": {
          opacity: "1 !important",
        },
      },
    },
  },
  "&.custom-textfield-root-disabled-shipments": {
    "& .custom-textfield-input-root": {
      backgroundColor: colors.shipmentDocBackgroundColor,
      borderRadius: 0,
      "& .custom-textfield-input": {
        fontWeight: 300,
        color: colors.primaryDark,
        WebkitTextFillColor: colors.primaryDark,
      },
      "&::after": {
        borderBottom: `2px solid ${colors.shipmentSelectedBorderColor} !Important`,
      },
    },
    "&.tradedash-metadata-custom-textfield-root": {
      "&:hover": {
        "& .info-icon": {
          opacity: "1 !important",
        },
      },
    },
  },
};

const baseDatePickerStyle = () => ({
  ...sharedStyles,
  "& .disable-ripple": {
    "& .MuiButtonBase-root": {
      background: "transparent",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      width: 18,
      marginTop: 20,
      marginRight: 4,
    },
  },
});

export const DatePopperStyled = styled(Popover)(() => ({
  "&.calendarPopover": {
    backgroundColor: colors.radioCheck,
  },
}));

export const TradeDashTextField = styled(TextField)(() => ({
  ...sharedStyles,
  "&.input-large": {
    width: "723px!Important",
    "& .custom-textfield-input-root": {
      padding: "8px 12px",
      fontSize: 18,
    },
    "& .custom-textfield-input": {
      marginBottom: 2,
    },
    "& .label-large-container": {
      color: colors.darkGray,
      fontSize: 18,
    },
  },
  "& .MuiInputBase-input": {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .disable-ripple": {
    "& .MuiButtonBase-root": {
      background: "transparent",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      width: 27,
    },
  },
  "& .noDescription": {
    top: "50% !important ",
  },
  "& .noSelectDescription": {
    top: "calc(50% - .5em) !important",
  },
}));

export const TradeDashDateTimeField =
  styled(DateTimePicker)(baseDatePickerStyle);

export const TradeDashDateField = styled(DatePicker)(baseDatePickerStyle);

export const TradeDashNumericTextField = styled(NumericFormat)(() => ({
  ...sharedStyles,
  "& .percentageAdorment": {
    position: "relative",
    top: 11,
    right: 4,
    color: colors.darkGray,
    "& .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 600,
      color: colors.primaryDark,
    },
  },
}));
