import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import AppLocale from "../lang";
import primaryTheme from "./themes/primaryTheme";
import darkTheme from "./themes/darkTheme";
import secondaryTheme from "./themes/secondaryTheme";
import warningTheme from "./themes/warningTheme";
import dangerTheme from "./themes/dangerTheme";
import infoTheme from "./themes/infoTheme";
import successTheme from "./themes/successTheme";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function RctThemeProvider({ children }) {
  const settings = useSelector((state) => state.settings);
  const { locale, darkMode, rtlLayout, activeTheme } = settings;
  const currentAppLocale = AppLocale[locale.locale];
  // theme changes
  let theme = "";
  switch (activeTheme.id) {
    case 1:
      theme = primaryTheme;
      break;
    case 2:
      theme = secondaryTheme;
      break;
    case 3:
      theme = warningTheme;
      break;
    case 4:
      theme = infoTheme;
      break;
    case 5:
      theme = dangerTheme;
      break;
    case 6:
      theme = successTheme;
      break;
    default:
      break;
  }

  if (darkMode) {
    theme = darkTheme;
  }

  if (rtlLayout) {
    theme.direction = "rtl";
  } else {
    theme.direction = "ltr";
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <Fragment>{children}</Fragment>
          </IntlProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default RctThemeProvider;
