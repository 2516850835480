import React, { useState, useEffect } from "react";
import ItemTableButtons from "../Buttons/ItemTableButtons";
import { useTags } from "../../hooks/tags";
import { useStorage } from "../../hooks/index";
import { useCustomers } from "../../hooks/customers";
import {
  addItemActivityStream,
  filterShipmentRowTableItem,
  formatCsvPOtable,
  parseRowTableItem,
  saveItem,
  updateShipmentItems,
  updateShipmentTag,
} from "./SKUTables/SKUHelper";
import { useShipments } from "../../hooks/shipments";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { useCompanyId, useCompanyUsers, useUser } from "../../hooks/user";
import {
  csvShipmentHeader,
  pdfShipmentHeader,
} from "../../helpers/itemsTableColumns";
import {
  onChangeSKUShipment,
  parseNumberValue,
} from "../../helpers/orderDashboardRefactored";

import ExpandSimpleTableSKUModal from "../Modal/ExpandSimpleTableSKUModal";
import { PREFIX_ITEM_TABLE, itemTableReference } from "../../helpers/constants";
import { trace } from "firebase/performance";
import { firestore, performanceFirebase } from "../../firebase";
import { difference } from "lodash";

import { useAllPOItems } from "../../hooks/itemTable";
import SKUTableSimple from "./SKUTables/SKUTableSimple";
import AttentionModal from "../Modal/AttentionModal";
import { writeBatch } from "firebase/firestore";
import { setNewErrorLog } from "../CompanyTabs/companyTabHelper";
import { useLocation } from "react-router-dom";

function ShipmentSKUTable({
  prefix,
  columnsTable = [],
  dataTable = [],
  currentPurchaseOrder = {},
  currentSalesOrder = {},
  purchaseOrders = [],
  allPoItems = [],
  handleShowImage = () => {},
  isModal,
  parentShipment,
  modalShowImage,
  modalCleanSearchBox,
  modalOnWriteSearch,
  modalHandleSearchBox,
  modalFilterText,
  onChangeShipment,
  hasMismatchTag = false,
}) {
  const location = useLocation();
  const [imagesTableStorage, setImagesTableStorage] =
    useStorage("item_table_images");
  const companyId = useCompanyId();
  const customers = useCustomers();
  const tags = useTags();
  const shipments = useShipments();
  const currentUser = useUser();
  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );
  const [rowTable, setRowTable] = useState(dataTable);
  const [attentionModalData, setAttentionModalData] = useState({
    open: false,
    cache: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyTable, setIsCopyTable] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [sortTable, setSortTable] = useState({
    sortedColumn: "itemNumber",
    orderBy: "ASC",
  });
  const isAllowed = useIsAllowedFunction();
  const [expandModal, setExpandModal] = useState(false);

  const alItemList = useAllPOItems();
  const companyUsers = useCompanyUsers({ id: companyId });

  const currentShipmentDB = parentShipment;

  useEffect(() => {
    if (difference(dataTable, rowTable)) {
      setRowTable(dataTable);
    }
  }, [dataTable]);

  const saveTags = ({ shipmentRow, row, currentShipmentId, batch }) => {
    const currentPO = purchaseOrders.find(
      (po) => po.id === row.purchaseOrderId
    );
    const currentPOList = alItemList[currentPO.itemTableId];
    try {
      updateShipmentTag({
        items: currentPOList.map((item) => {
          const matchingItem = shipmentRow.find((row) => row.id === item.id);
          return matchingItem ? matchingItem : item;
        }),
        allPurchaseOrders: purchaseOrders,
        currentItem: row,
        currentPurchaseOrder,
        currentShipmentId,
        tags,
        batch,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  async function onChangeData({ item, row, skipShipmentCheck = false }) {
    try {
      const allocation = parseNumberValue(row.shipmentQuantity);
      const items = dataTable?.filter((itemData) => item.id !== itemData.id);
      if (allocation === 0 && items.length === 0 && !skipShipmentCheck) {
        setAttentionModalData({
          open: true,
          cache: {
            item,
            row,
            skipShipmentCheck: true,
          },
        });
        return;
      }
      const batch = writeBatch(firestore);
      const traceInstance = trace(
        performanceFirebase,
        "dashboard_update_shipment_sku"
      );
      traceInstance.start();
      setIsLoading(true);
      const currentShipmentId = currentShipmentDB.id;
      const newRow = onChangeSKUShipment({
        item,
        row,
        currentShipmentId,
      });
      saveItem({ newRow, batch });
      const allRows = rowTable.map((row) =>
        row.id === newRow.id ? newRow : row
      );
      setRowTable(allRows);
      saveTags({ shipmentRow: allRows, row: newRow, currentShipmentId, batch });
      const rowShipment = currentShipmentDB;
      await addItemActivityStream({
        row,
        item,
        reference: itemTableReference.MAIN_SHIPMENT,
        currentShipmentId,
        user: currentUser,
        currentPurchaseOrder,
        companyUsers: companyUsers,
        currentShipment: rowShipment,
        batch,
      });

      updateShipmentItems({
        row,
        reference: itemTableReference.MAIN_SHIPMENT,
        allRows,
        currentPurchaseOrder,
        currentShipment: rowShipment,
        currentItem: item,
        setRedirectShipment: (row) => {
          onChangeShipment({
            ...rowShipment,
            erased:
              row.erased && currentPurchaseOrder.id === row.purchaseOrderId,
          });
        },
        batch,
      });
      await batch.commit();
      setIsLoading(false);
      traceInstance.stop();
    } catch (error) {
      setNewErrorLog({ companyId, error, location });
    }
  }

  const handleChange = (event, forceStatus) => {
    const showImage = event ? event.target.checked : forceStatus;
    setImagesTableStorage(prefix, !showImage);
    setShowImage(showImage);
    handleShowImage(showImage);
  };

  const currentRowTable = filterShipmentRowTableItem({
    rowTable,
    searchText: modalFilterText,
    purchaseOrders,
  });

  const actualRows = parseRowTableItem({
    rows: currentRowTable,
    resetNew: false,
    sortTable,
    currentIdPath: {
      currentShipmentId: currentShipmentDB.id,
      currentSalesOrderId: currentSalesOrder.id,
      currentPurchaseOrder: currentPurchaseOrder.id,
    },
    columnsTable,
    purchaseOrders,
  });

  const getHeaderByScope = () =>
    csvShipmentHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const getPDFHeaderByScope = () =>
    pdfShipmentHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const handleChangeShowImage = (value) => {
    setShowImage(value);
    handleShowImage(value);
  };

  const idExpandModal = isModal ? "ExpandModal" : "";
  return (
    <>
      {attentionModalData.open && (
        <AttentionModal
          isOpen={attentionModalData.open}
          title={"Attention"}
          description={
            <span>
              Are you sure you want to set this quantity to zero?
              <br /> <br />
              Deleting this SKU means the shipment will be voided
            </span>
          }
          onClick={() => {
            onChangeData({ ...attentionModalData.cache });
            setAttentionModalData({ open: false, cache: false });
          }}
          cancellable
          onClose={() => setAttentionModalData({ open: false, cache: false })}
        />
      )}
      {expandModal && !isModal && (
        <ExpandSimpleTableSKUModal
          isOpen={expandModal}
          columnsTable={columnsTable}
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          currentShipment={currentShipmentDB}
          rowTable={rowTable}
          setExpandModal={setExpandModal}
          key={`sh_expand`}
          purchaseOrders={purchaseOrders}
          handleShowImage={(show) => {
            handleChange(false, show);
          }}
          modalShowImage={showImage}
          prefix={prefix}
          parentShipment={currentShipmentDB}
          onChangeShipment={onChangeShipment}
          allPoItems={allPoItems}
          hasMismatchTag={hasMismatchTag}
        />
      )}

      <ItemTableButtons
        isModal={isModal}
        idExpandModal={idExpandModal}
        isAllOpen={true}
        handleHiddenImages={() => {
          let currentShowImage = showImage;
          handleChangeShowImage(false);
          if (currentShowImage) {
            setTimeout(() => {
              handleChangeShowImage(currentShowImage);
            }, 500);
          }
        }}
        csvData={formatCsvPOtable({
          rows: actualRows,
          shipments,
          customers,
          currentShipment: currentShipmentDB,
          currentSalesOrder,
        })}
        prefix={prefix}
        tableHeader={getHeaderByScope()}
        onExpandModal={() => setExpandModal(true)}
        pdfTableHeader={getPDFHeaderByScope()}
        handleOnCopyTable={(value) => setIsCopyTable(value)}
        cleanSearchBox={modalCleanSearchBox}
        onWriteSearch={modalOnWriteSearch}
        handleSearchBox={modalHandleSearchBox}
        handleOpenSnackBar={(value) =>
          setTimeout(() => {
            setOpenSnackBar(value);
          }, 50)
        }
        hasMismatchTag={hasMismatchTag}
      />
      {(isModal || !expandModal) && (
        <SKUTableSimple
          actualRows={actualRows}
          entity={currentShipmentDB}
          imagesTableStorage={imagesTableStorage}
          isCopyTable={isCopyTable}
          isLoading={isLoading}
          isModal={isModal}
          modalFilterText={modalFilterText}
          modalShowImage={modalShowImage}
          onChangeData={onChangeData}
          prefix={PREFIX_ITEM_TABLE.SHIPMENT}
          purchaseOrders={purchaseOrders}
          tableHeader={columnsTable}
          openSnackBar={openSnackBar}
          handleSnackBar={(value) => setOpenSnackBar(value)}
          handleSorting={(sorting) => setSortTable(sorting)}
          sorting={sortTable}
          classTable="tableShipmentSKUContainer"
        />
      )}
    </>
  );
}

export default ShipmentSKUTable;
