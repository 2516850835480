import React, { useState } from "react";
import { Button, TextareaAutosize, TextField } from "@mui/material";
import { propertyLabel, propertyType } from "../../../helpers/constants";
import { CreateTemplateModal } from "../../SalesOrders/styles";
import { cx } from "@emotion/css";
import { getRandomId } from "../../../helpers/helpers";
import Fielditem from "../../../api/model/CustomizedItemField.model";
import FieldTypeSelector from "./FieldTypeSelector";
import { getFieldTextNameError, toCamelCase } from "../companyTabHelper";

function FieldCustomizationModal({
  onClose,
  onCreate,
  data = {},
  isEditing,
  currentList,
  entityFields = {},
}) {
  const [type, setType] = useState(data.type || "");
  const [label, setLabel] = useState(data.label || "");
  const [description, setDescription] = useState(data.description || "");

  const handleCreate = () => {
    if (isSpacer || (type && label)) {
      const id = isSpacer ? getRandomId() : toCamelCase(label);
      const currentLabel = isSpacer ? propertyLabel[type] : label.trim();
      const newFieldItem = {
        ...new Fielditem({
          ...data,
          label: currentLabel,
          type,
          description,
          field: isEditing ? data.field : toCamelCase(label),
          id: isEditing ? data.id : id,
        }),
      };
      onCreate(newFieldItem);
      onClose();
    }
  };
  const error = getFieldTextNameError({
    label,
    isEditing,
    currentItem: data,
    currentList,
    entityFields,
  });

  const isSpacer = type === propertyType.SPACER;

  return (
    <CreateTemplateModal
      className="TemplateModalContainer"
      isOpen={true}
      onClose={onClose}
      header={
        <span className="headerText">
          {isEditing ? "Modify Custom Field" : "Create New Custom Field"}
        </span>
      }
      footer={
        <div className="footerTemplate">
          <span className="mandatoryText">
            <span className="red">*</span>Mandatory
          </span>
          <div className="buttonContainer">
            <Button
              disabled={isSpacer ? false : !type || !label.trim() || error}
              onClick={() => handleCreate()}
              variant="contained"
              className={cx("saveButton", "buttonAction")}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={"buttonAction"}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      }
    >
      <div className="gridBody">
        <span className="labelData">
          <span className="red">*</span>Type
        </span>
        <FieldTypeSelector type={type} setType={setType} disabled={isEditing} />
        <span className="labelData">
          {!isSpacer && <span className="red">*</span>}Field Label
        </span>
        {!isSpacer && (
          <TextField
            className="textDataTemplate"
            name="*Field Label"
            value={label}
            onChange={(ev) => {
              const value = ev.target.value;
              if (/^[\w\s-_]*$/.test(value)) {
                setLabel(value);
              }
            }}
            fullWidth
            size="small"
            placeholder="Choose name that will appear in Order Dashboard"
            helperText={error}
            error={error}
          />
        )}
        {isSpacer && <span className="labelData">Not applicable</span>}
        <span className="labelData">
          Field Description <br /> for Pop-up
        </span>
        {!isSpacer && (
          <TextareaAutosize
            className="textDataTemplateAutosize"
            name="templateDescription"
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
            minRows={4}
            placeholder="Explanatory notes that users can see in the Order Dashboard go here"
          />
        )}
        {isSpacer && <span className="labelData">Not applicable</span>}
        <span className="labelData">Field Ref. Name</span>
        {!isSpacer && (
          <TextField
            className="textDataTemplate"
            name="Ref. Name"
            value={isEditing ? data.field : toCamelCase(label)}
            fullWidth
            size="small"
            disabled
          />
        )}
        {isSpacer && <span className="labelData">Not applicable</span>}
      </div>
    </CreateTemplateModal>
  );
}

export default FieldCustomizationModal;
