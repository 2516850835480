import React, { useCallback, useState } from "react";
import { updateDoc } from "firebase/firestore";
import { cx } from "@emotion/css";
import FolderDocument from "../../api/model/FolderDocument";
import { REPORTS_TYPE, TYPE_OF_FILE } from "../../helpers/constants";
import { canDrop, updateDocumentPermissions } from "../ReportsTree/helpers";
import { ListItemDocumentStyled } from "./styles";
import DocumentInfo from "./DocumentInfo";
import {
  getDocumentId,
  getDocumentPathv2,
  getDuplicatedNamesInDestination,
  verifyFolderDuplicateNames,
} from "../Documents/documentHelpers";
import AttentionModal from "../Modal/AttentionModal";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId } from "../../hooks/user";
import ModalCreateFolderDocument from "../Documents/ModalCreateFolderDocument";
import CreateNewFolder from "../Documents/CreateNewFolder";
import { addGenericDataToFirestore } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";

import MenuOptions from "./MenuOptions";
import { useBackdropState } from "../../hooks/user";
import FileDocument from "../../api/model/FileDocument";
import { now } from "moment";

function DocumentItem({
  currentDocument,
  documents,
  draggingTo,
  setDraggingTo,
  onOpenFolder = () => {},
  currentUser = {},
  users = [],
  permissionGroupsDB,
  dbCollection = "",
  onModalAttention = () => {},
}) {
  const companyId = useCompanyId();
  const [hoverDrag, setHoverDrag] = useState("");
  const backdropState = useBackdropState();
  const [openAccessGroup, setOpenAccesGroup] = useState(false);
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [fileType, setFileType] = useState("");
  const dispatch = useDispatch();
  const [attentionModal, setAttentionModal] = useState({
    open: false,
    description: "",
    action: () => {},
    confirmationText: "",
    cancelText: "",
  });
  const [editing, setEditing] = useState(false);
  const documentPath = `${dbTables.COMPANIES}/${companyId}/${dbCollection}`;
  const addDocument = useCallback((newDocument) =>
    addGenericDataToFirestore(
      [...documentPath.split("/"), newDocument.id],
      newDocument
    )(dispatch)
  );

  function handleDragEnd(e) {
    setHoverDrag(false);
    if (!draggingTo) {
      return console.log("You can not drag to nowhere");
    }
    if (draggingTo.type !== REPORTS_TYPE.FOLDER) {
      return console.log("You can only drag into folders");
    }
    if (draggingTo.id === currentDocument.id) {
      return console.log("You can not drag into the same folder");
    }
    if (draggingTo.id === currentDocument.parentId) {
      return console.log("You can not drag into the same folder");
    }
    if (
      !canDrop({
        documents: documents,
        originId: currentDocument.id,
        destinationId: draggingTo.id,
      })
    ) {
      return console.log("You can not drag into the child folder");
    }
    let documentList = [];
    let folderList = [];
    const { isDuplicatedName, attentionModalDescription } =
      getDuplicatedNamesInDestination({
        documentsInTheSameLevel: documents.filter(
          (doc) => doc.parentId === draggingTo.id
        ),
        documentsToMove: [currentDocument],
      });

    if (isDuplicatedName) {
      setAttentionModal({
        open: true,
        description: attentionModalDescription,
        action: () => {},
        confirmationText: "Ok",
        cancelText: "",
      });
      return;
    }

    if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
      folderList.push(currentDocument);
    } else {
      documentList.push(currentDocument);
    }
    updateDocumentPermissions({
      permissionGroups: draggingTo.permissionGroups,
      permissionGroupsDB,
      permissionUsers: draggingTo.permissionUsers,
      currentDocument,
      documents: documents,
      updateDoc,
      users,
      parentId: draggingTo.id,
      clonePermission: true,
      userId: currentUser.id,
    });

    let description = "";
    if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
      description = (
        <React.Fragment>
          Moving folder(s) to a different folder <br />
          will assign it that folder's access permission
        </React.Fragment>
      );
    } else {
      description = (
        <React.Fragment>
          Succesfully moved to{" "}
          <strong>
            {getDocumentPathv2({
              currentDocument: draggingTo,
              documents: documents,
            })}
          </strong>
        </React.Fragment>
      );
    }
    onModalAttention({
      open: true,
      description,
      action: () => {},
      confirmationText: "Ok",
      cancelText: "",
    });

    e.stopPropagation();
  }

  function handleDragEnter(e) {
    setDraggingTo(currentDocument);
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragOver(ev) {
    if (!hoverDrag) {
      setHoverDrag(true);
    }
    ev.preventDefault();
    ev.stopPropagation();
  }

  function getDocumentState({ currentDocument }) {
    return currentDocument.type === TYPE_OF_FILE.FOLDER
      ? "folderDocument_" + currentDocument.scope
      : currentDocument.scope;
  }

  function handleClickDocumentItem({ currentDocument = {} }) {
    if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
      onOpenFolder(currentDocument.id);
    }
  }

  const handleSaveFolder = (name) => {
    const CurrentModalType =
      fileType === TYPE_OF_FILE.FOLDER ? FolderDocument : FileDocument;
    addDocument({
      ...new CurrentModalType({
        name: verifyFolderDuplicateNames(
          name.trim() || "New Folder",
          documents.filter((doc) => doc.parentId === currentDocument.id)
        ),
        permissionUsers: currentDocument.permissionUsers,
        permissionGroups: currentDocument.permissionGroups,
        parentId: currentDocument.id || "",
        createdBy: currentUser.id,
        created: now(),
        mainFile: true,
        currentPath:
          getDocumentPathv2({
            currentDocument,
            documents: documents,
          }) + "/New Folder",
        triggerType: DOCUMENT_TRIGGER_TYPES.CREATE_FOLDER,
      }),
    });

    setOpenNewFolder(false);
  };

  return (
    <>
      {!!attentionModal.open && (
        <AttentionModal
          isOpen={!!attentionModal.open}
          title="Attention"
          description={attentionModal.description}
          onClick={() => {
            attentionModal.action();
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
          }}
          onClose={() => {
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
          }}
          cancellable={!!attentionModal.cancellable}
          cancelText={attentionModal.cancelText}
          confirmationText={attentionModal.confirmationText}
        />
      )}
      {openAccessGroup && (
        <ModalCreateFolderDocument
          open={openAccessGroup}
          title="Access Group"
          orderPath={documentPath}
          isNew={false}
          documentTree={documents.filter(
            (doc) => doc.type === TYPE_OF_FILE.FOLDER
          )}
          handleClose={() => {
            setOpenAccesGroup(false);
          }}
          folderData={currentDocument}
          parentDocument={documents.find(
            (doc) => doc.id === currentDocument.parentId
          )}
        />
      )}

      <ListItemDocumentStyled
        draggable
        className={cx("listItemDocumentContainer", {
          hoverPlaceholderDrag: hoverDrag,
        })}
        id={`document-item_${getDocumentId(currentDocument)}`}
        style={
          backdropState.id === getDocumentId(currentDocument)
            ? {
                zIndex: 1202,
                position: "relative",
                borderRadius: 4,
              }
            : {}
        }
        onDragEnd={handleDragEnd}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={() => setHoverDrag(false)}
        onDrop={() => setHoverDrag(false)}
        onClick={(ev) => {
          handleClickDocumentItem({
            currentDocument,
          });
          ev.stopPropagation();
        }}
      >
        <div
          className={cx("listemItemDocument", {
            [getDocumentState({ currentDocument })]: true,
          })}
          onClick={(ev) => {
            handleClickDocumentItem({
              currentDocument,
            });
            ev.stopPropagation();
          }}
        >
          <DocumentInfo
            currentDocument={currentDocument}
            allDocuments={documents}
            editing={editing}
            setEditing={setEditing}
          />
        </div>
        <MenuOptions
          currentDocument={currentDocument}
          onRename={() => {
            setEditing(true);
          }}
          allDocuments={documents}
          onAddNewFolder={() => {
            setOpenNewFolder(true);
            setFileType(TYPE_OF_FILE.FOLDER);
            onOpenFolder(currentDocument.id, true);
          }}
          onUploadFiles={() => {
            setOpenNewFolder(true);
            setFileType(TYPE_OF_FILE.FILE);
            onOpenFolder(currentDocument.id, true);
          }}
          onCloseNewFile={() => setOpenNewFolder(false)}
          onPermission={() => setOpenAccesGroup(true)}
          onModalAttention={onModalAttention}
        />
      </ListItemDocumentStyled>
      {openNewFolder && (
        <CreateNewFolder
          folderName={
            fileType === TYPE_OF_FILE.FOLDER ? "New Folder" : "New File"
          }
          type={fileType}
          handleSaveName={(name) => handleSaveFolder(name)}
          handleClose={() => setOpenNewFolder(false)}
        />
      )}
    </>
  );
}

export default DocumentItem;
