import React from "react";
import { PERMISSION_TEXT } from "../../../helpers/constants";
import { useQueryParams } from "../../../hooks";
import { useCurrentPurchaseOrder } from "../../../hooks/salesOrders";
import { POMetadataContainterStyled } from "../styles";
import { useCompanyId, useUser } from "../../../hooks/user";

import POMetadataInfo from "./POMetadataInfo";
import POSKUSection from "./POSKUSection";
import { cx } from "@emotion/css";

function PurchaseOrderMetadata({ vendorPermission }) {
  const purchaseOrder = useCurrentPurchaseOrder();
  const queryParams = useQueryParams();
  const user = useUser() || {};
  const companyId = useCompanyId();

  if (queryParams.toVerify) {
    return "";
  }

  if (!vendorPermission) {
    return (
      <div className="blockContainer">
        <p className="blockText">
          {purchaseOrder.id && PERMISSION_TEXT.VENDOR}
        </p>
      </div>
    );
  }

  return (
    <div className={"purchase-order-info"} id="purchase-order-info">
      {purchaseOrder.id && (
        <POMetadataContainterStyled
          className={cx(
            "poContainer",
            `content-card-content purchase-order-content`
          )}
        >
          <POMetadataInfo />
          <POSKUSection
            purchaseOrder={purchaseOrder}
            companyId={companyId}
            user={user}
            key={"SectionPoSKU"}
          />
        </POMetadataContainterStyled>
      )}
    </div>
  );
}

export default PurchaseOrderMetadata;
