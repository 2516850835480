import ColumnHeader from "../api/model/ColumnHeader.model";
import {
  headerColumns,
  POObjFields,
  propertyType,
  shipmentObjFields,
  SOObjFields,
} from "./constants";
import { PO_VIEW_FIELDS } from "./purchaseOrder";
import { SALES_ORDER_VIEW_FIELDS } from "./salesOrder";
import { SHIPMENT_VIEW_FIELDS } from "./shipment";

export const POHeaderSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: headerColumns.FAVORITE,
      field: PO_VIEW_FIELDS.FAVORITE,
      enabled: true,
      unmovable: true,
      width: 40,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.DIAMOND,
      field: PO_VIEW_FIELDS.DIAMOND,
      enabled: true,
      unmovable: true,
      width: 50,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: headerColumns.PROGRESS,
      field: PO_VIEW_FIELDS.PROGRESS,
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.MENTIONS,
      field: PO_VIEW_FIELDS.MENTIONS,
      unmovable: true,
      enabled: true,
      width: 65,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.PO,
      field: POObjFields.NUMBER,
      enabled: true,
      unmovable: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.SO,
      field: PO_VIEW_FIELDS.SO,
      enabled: true,
      width: 205,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.SHIPMENTS,
      field: PO_VIEW_FIELDS.SHIPMENTS,
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 7,
      label: headerColumns.CUSTOMER,
      field: PO_VIEW_FIELDS.CUSTOMER,
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: headerColumns.CREATION_DATE,
      field: POObjFields.CREATION_DATE,
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 9,
      label: headerColumns.ORDER_READY_DATE,
      field: POObjFields.ORDER_READY_DATE,
      enabled: true,
      width: 130,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.VENDOR,
      field: PO_VIEW_FIELDS.VENDOR,
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 11,
      label: headerColumns.DISCOUNT_PERCENTAGE,
      field: POObjFields.DISCOUNT,
      enabled: true,
      width: 120,
      type: propertyType.PERCENTAGE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 12,
      label: headerColumns.TOTAL,
      field: POObjFields.TOTAL,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 13,
      label: headerColumns.POTENTIAL_LATE_FEE,
      field: POObjFields.POTENTIAL_LATE_FEE,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 14,
      label: headerColumns.CBM,
      field: POObjFields.CBM,
      enabled: true,
      width: 110,
      type: propertyType.FLOAT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 15,
      label: headerColumns.SHIPPING_TERMS,
      field: POObjFields.SHIPPING_TERMS,
      enabled: true,
      width: 130,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 16,
      label: headerColumns.TOTAL_SHIPMENTS,
      field: POObjFields.TOTAL_SHIPMENTS,
      enabled: true,
      width: 100,
      type: propertyType.INTEGER,
    }),
  },
  {
    ...new ColumnHeader({
      index: 17,
      label: headerColumns.VENDOR_INVOICE,
      field: POObjFields.INVOICE,
      enabled: true,
      width: 130,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 18,
      label: headerColumns.UNIQUE_ITEMS,
      field: POObjFields.UNIQUE_ITEMS,
      enabled: true,
      width: 90,
      type: propertyType.INTEGER,
    }),
  },
  {
    ...new ColumnHeader({
      index: 19,
      label: headerColumns.DISCOUNT,
      field: POObjFields.TOTAL_DISCOUNT,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 20,
      label: headerColumns.SUB_TOTAL,
      field: POObjFields.AMOUNT,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 21,
      label: headerColumns.DEPOSIT,
      field: POObjFields.DEPOSIT,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 22,
      label: headerColumns.BALANCE,
      field: POObjFields.BALANCE,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 23,
      label: headerColumns.TOTAL_WEIGHT,
      field: POObjFields.WEIGHT,
      enabled: true,
      width: 120,
      type: propertyType.FLOAT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 24,
      label: headerColumns.DOCUMENT_DATE,
      field: POObjFields.DATE,
      enabled: true,
      width: 125,
      type: propertyType.DATE,
    }),
  },
];

export const SOHeaderSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: headerColumns.FAVORITE,
      field: SALES_ORDER_VIEW_FIELDS.FAVORITE,
      enabled: true,
      unmovable: true,
      width: 40,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.DIAMOND,
      field: SALES_ORDER_VIEW_FIELDS.DIAMOND,
      enabled: true,
      unmovable: true,
      width: 50,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: headerColumns.PROGRESS,
      field: SALES_ORDER_VIEW_FIELDS.PROGRESS,
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.MENTIONS,
      field: SALES_ORDER_VIEW_FIELDS.MENTIONS,
      unmovable: true,
      enabled: true,
      width: 65,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.SO,
      field: SOObjFields.NUMBER,
      enabled: true,
      unmovable: true,
      width: 205,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.POS,
      field: SALES_ORDER_VIEW_FIELDS.POS,
      enabled: true,
      width: 270,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.SHIPMENTS,
      field: SALES_ORDER_VIEW_FIELDS.SHIPMENTS,
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 7,
      label: headerColumns.CUSTOMER,
      field: SALES_ORDER_VIEW_FIELDS.CUSTOMER,
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: headerColumns.CREATION_DATE,
      field: SOObjFields.CREATION_DATE,
      enabled: true,
      width: 123,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 9,
      label: headerColumns.SHIP_BY_DATE,
      field: SOObjFields.SHIP_DATE,
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.REQUIRED_DELIVERY,
      field: SOObjFields.DELIVERY_DATE,
      enabled: true,
      width: 110,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 11,
      label: headerColumns.PAY_TERMS_START,
      field: SOObjFields.PAY_TERMS_START,
      enabled: true,
      width: 130,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 12,
      label: headerColumns.DISCOUNT_PERCENTAGE,
      field: SOObjFields.DISCOUNT,
      enabled: true,
      width: 125,
      type: propertyType.PERCENTAGE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 13,
      label: headerColumns.TOTAL,
      field: SOObjFields.TOTAL,
      enabled: true,
      width: 135,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 14,
      label: headerColumns.POTENTIAL_LATE_FEE,
      field: SOObjFields.POTENTIAL_LATE_FEE,
      enabled: true,
      width: 135,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 15,
      label: headerColumns.CBM,
      field: SOObjFields.CBM,
      enabled: true,
      width: 110,
      type: propertyType.FLOAT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 16,
      label: headerColumns.TOTAL_SHIPMENTS,
      field: SOObjFields.TOTAL_SHIPMENTS,
      enabled: true,
      width: 105,
      type: propertyType.INTEGER,
    }),
  },
  {
    ...new ColumnHeader({
      index: 17,
      label: headerColumns.SALES_INVOICE,
      field: SOObjFields.INVOICE,
      enabled: true,
      width: 120,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 18,
      label: headerColumns.CUSTOMER_PO,
      field: SOObjFields.CUSTOMER_PO,
      enabled: true,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 19,
      label: headerColumns.SALES_REP,
      field: SOObjFields.SALES_REPRESENTATIVE,
      enabled: true,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 20,
      label: headerColumns.UNIQUE_ITEMS,
      field: SOObjFields.UNIQUE_ITEMS,
      enabled: true,
      width: 95,
      type: propertyType.INTEGER,
    }),
  },
  {
    ...new ColumnHeader({
      index: 21,
      label: headerColumns.DISCOUNT,
      field: SOObjFields.TOTAL_DISCOUNT,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 22,
      label: headerColumns.SUB_TOTAL,
      field: SOObjFields.AMOUNT,
      enabled: true,
      width: 130,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 23,
      label: headerColumns.DEPOSIT,
      field: SOObjFields.DEPOSIT,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 24,
      label: headerColumns.BALANCE,
      field: SOObjFields.BALANCE,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 25,
      label: headerColumns.DOCUMENT_DATE,
      field: SOObjFields.DATE,
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 26,
      label: headerColumns.REFERENCE_NUMBER,
      field: SOObjFields.REFERENCE_NUMBER,
      enabled: true,
      width: 115,
      type: propertyType.TEXT,
    }),
  },
];

export const InboxHeaderSetup = [
  {
    ...new ColumnHeader({
      index: 1,
      label: "SO",
      enabled: true,
      unmovable: true,
      width: 205,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: "Customer",
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.TOTAL,
      field: SALES_ORDER_VIEW_FIELDS.AMOUNT,
      enabled: true,
      width: 170,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.CREATION_DATE,
      field: SALES_ORDER_VIEW_FIELDS.CREATION_DATE,
      enabled: true,
      width: 150,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.DOCUMENT_DATE,
      field: SALES_ORDER_VIEW_FIELDS.DATE,
      enabled: true,
      width: 150,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.SALES_ORDER_TEMPLATE,
      enabled: true,
      width: 300,
      type: null,
    }),
  },
];

export const taskHeaderSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: "diamond",
      field: "diamond",
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.TYPE,
      field: "type",
      enabled: true,
      width: 205,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: "Task",
      field: "description",
      enabled: true,
      width: 260,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: "Customer",
      field: "customer",
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: "Vendor",
      field: "vendor",
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: "Assigned To",
      field: "assignedTo",
      enabled: true,
      width: 180,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: "Start Date",
      field: "startDate",
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 7,
      label: "Due Date",
      field: "finishDate",
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: "Action",
      field: "action",
      enabled: true,
      width: 150,
      type: null,
    }),
  },
];

export const orderByPhaseSOSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: headerColumns.EXPAND,
      enabled: true,
      unmovable: true,
      width: 25,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.FAVORITE,
      enabled: true,
      unmovable: true,
      width: 40,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: headerColumns.DIAMOND,
      enabled: true,
      unmovable: true,
      width: 50,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.SALES_ORDER,
      enabled: true,
      unmovable: false,
      width: 205,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.CUSTOMER,
      unmovable: false,
      enabled: true,
      width: 210,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.TOTAL,
      field: SALES_ORDER_VIEW_FIELDS.TOTAL,
      enabled: true,
      width: 170,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.SO_SHIP_DATE,
      field: SALES_ORDER_VIEW_FIELDS.SHIP_DATE,
      enabled: true,
      width: 170,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 7,
      label: headerColumns.POS,
      field: "quantityPOs",
      enabled: true,
      width: 130,
      type: propertyType.INTEGER,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: headerColumns.SHIPMENTS,
      field: "quantityShipments",
      enabled: true,
      width: 130,
      type: propertyType.INTEGER,
    }),
  },
  {
    ...new ColumnHeader({
      index: 9,
      label: headerColumns.PROPOSAL_QUOTE,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.PRE_PRODUCTION,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 11,
      label: headerColumns.PRODUCTION,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 12,
      label: headerColumns.BOOKING_TRANSIT,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 13,
      label: headerColumns.PAYMENT_BILLING,
      enabled: true,
      type: null,
    }),
  },
];

export const orderByPhasePOGroupBySOSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: headerColumns.EXPAND,
      field: headerColumns.EXPAND,
      enabled: true,
      unmovable: true,
      width: 30,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.FAVORITE,
      field: PO_VIEW_FIELDS.FAVORITE,
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: headerColumns.DIAMOND,
      field: PO_VIEW_FIELDS.DIAMOND,
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.PO,
      field: PO_VIEW_FIELDS.NUMBER,
      enabled: true,
      unmovable: false,
      width: 210,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.VENDOR,
      field: PO_VIEW_FIELDS.VENDOR,
      unmovable: false,
      enabled: true,
      width: 215,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.PO_VALUE,
      field: POObjFields.TOTAL,
      enabled: true,
      width: 170,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.ORDER_READY_DATE,
      field: PO_VIEW_FIELDS.ORDER_READY_DATE,
      enabled: true,
      width: 170,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: headerColumns.BLANK,
      field: headerColumns.BLANK,
      enabled: true,
      width: 130,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 9,
      label: headerColumns.BLANK,
      field: headerColumns.BLANK,
      enabled: true,
      width: 130,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.PROPOSAL_QUOTE,
      field: headerColumns.PROPOSAL_QUOTE,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 11,
      label: headerColumns.PRE_PRODUCTION,
      field: headerColumns.PRE_PRODUCTION,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 12,
      label: headerColumns.PRODUCTION,
      field: headerColumns.PRODUCTION,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 13,
      label: headerColumns.BOOKING_TRANSIT,
      field: headerColumns.BOOKING_TRANSIT,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 14,
      label: headerColumns.PAYMENT_BILLING,
      field: headerColumns.PAYMENT_BILLING,
      enabled: true,
      type: null,
    }),
  },
];

export const orderByPhaseShipmentGroupBySOSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: headerColumns.EXPAND,
      field: headerColumns.EXPAND,
      enabled: true,
      unmovable: true,
      width: 30,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.FAVORITE,
      field: SHIPMENT_VIEW_FIELDS.FAVORITE,
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: headerColumns.DIAMOND,
      field: SHIPMENT_VIEW_FIELDS.DIAMOND,
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.SHIPMENT,
      field: SHIPMENT_VIEW_FIELDS.NUMBER,
      enabled: true,
      unmovable: false,
      width: 215,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.FINAL_DESTINATION,
      field: SHIPMENT_VIEW_FIELDS.FINAL_DESTINATION,
      unmovable: false,
      enabled: true,
      width: 210,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.SHIPMENT_VALUE,
      field: SHIPMENT_VIEW_FIELDS.TOTAL,
      enabled: true,
      width: 170,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.SHIP_DATE,
      field: SHIPMENT_VIEW_FIELDS.SHIP_DATE,
      enabled: true,
      width: 170,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: headerColumns.DELIVERY_DATE,
      field: SHIPMENT_VIEW_FIELDS.DELIVERY_DATE,
      enabled: true,
      width: 170,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 9,
      label: headerColumns.BLANK,
      field: headerColumns.BLANK,
      enabled: true,
      width: 90,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.PROPOSAL_QUOTE,
      field: headerColumns.PROPOSAL_QUOTE,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.PRE_PRODUCTION,
      field: headerColumns.PRE_PRODUCTION,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 12,
      label: headerColumns.PRODUCTION,
      field: headerColumns.PRODUCTION,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 13,
      label: headerColumns.BOOKING_TRANSIT,
      field: headerColumns.BOOKING_TRANSIT,
      enabled: true,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 14,
      label: headerColumns.PAYMENT_BILLING,
      field: headerColumns.PAYMENT_BILLING,
      enabled: true,
      type: null,
    }),
  },
];

export const shipmentHeaderSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: headerColumns.FAVORITE,
      field: SHIPMENT_VIEW_FIELDS.FAVORITE,
      enabled: true,
      unmovable: true,
      width: 40,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.DIAMOND,
      field: SHIPMENT_VIEW_FIELDS.DIAMOND,
      enabled: true,
      unmovable: true,
      width: 50,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: headerColumns.PROGRESS,
      field: SHIPMENT_VIEW_FIELDS.PROGRESS,
      enabled: true,
      unmovable: true,
      width: 35,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.MENTIONS,
      field: SHIPMENT_VIEW_FIELDS.MENTIONS,
      unmovable: true,
      enabled: true,
      width: 65,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.SHIPMENT,
      field: shipmentObjFields.NUMBER,
      unmovable: true,
      enabled: true,
      width: 140,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.SALES_ORDER,
      field: SHIPMENT_VIEW_FIELDS.SO,
      enabled: true,
      width: 205,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.CUSTOMER,
      field: SHIPMENT_VIEW_FIELDS.CUSTOMER,
      enabled: true,
      width: 110,
    }),
  },
  {
    ...new ColumnHeader({
      index: 7,
      label: headerColumns.PO,
      field: SHIPMENT_VIEW_FIELDS.PO,
      enabled: true,
      width: 374,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: headerColumns.CREATION_DATE,
      field: shipmentObjFields.DATE,
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 9,
      label: headerColumns.SHIP_DATE,
      field: shipmentObjFields.SHIP_DATE,
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.DELIVERY_DATE,
      field: shipmentObjFields.DELIVERY_DATE,
      enabled: true,
      width: 120,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 11,
      label: headerColumns.CBM,
      field: shipmentObjFields.CBM,
      enabled: true,
      width: 100,
      type: propertyType.FLOAT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 12,
      label: headerColumns.BOOKING,
      field: shipmentObjFields.BOOKING,
      enabled: true,
      width: 190,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 13,
      label: headerColumns.BOL,
      field: shipmentObjFields.BILL_OF_LADING,
      enabled: true,
      width: 190,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 14,
      label: headerColumns.CONTAINER,
      field: shipmentObjFields.CONTAINER,
      enabled: true,
      width: 135,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 15,
      label: headerColumns.SHIPPING_TERMS,
      field: shipmentObjFields.SHIPPING_TERMS,
      enabled: true,
      width: 130,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 16,
      label: headerColumns.TOTAL,
      field: shipmentObjFields.TOTAL,
      enabled: true,
      width: 150,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 17,
      label: headerColumns.POTENTIAL_LATE_FEE,
      field: shipmentObjFields.POTENTIAL_LATE_FEE,
      enabled: true,
      width: 160,
      type: propertyType.CURRENCY,
    }),
  },
  {
    ...new ColumnHeader({
      index: 18,
      label: headerColumns.CUSTOMER_PO,
      field: shipmentObjFields.CUSTOMER_PO,
      enabled: true,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 19,
      label: headerColumns.LOADING_PORT,
      field: shipmentObjFields.LOADING_PORT,
      enabled: true,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 20,
      label: headerColumns.UNLOADING_PORT,
      field: shipmentObjFields.UNLOADING_PORT,
      enabled: true,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 21,
      label: headerColumns.FINAL_DESTINATION,
      field: shipmentObjFields.FINAL_DESTINATION,
      enabled: true,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 22,
      label: headerColumns.UNIQUE_ITEMS,
      field: shipmentObjFields.UNIQUE_ITEMS,
      enabled: true,
      width: 95,
      type: propertyType.INTEGER,
    }),
  },
  {
    ...new ColumnHeader({
      index: 23,
      label: headerColumns.FREIGHT_FORWARDER,
      field: shipmentObjFields.FREIGHT_FORWARDER,
      enabled: true,
      width: 120,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 24,
      label: headerColumns.SALES_INVOICE,
      field: shipmentObjFields.INVOICE,
      enabled: true,
      width: 135,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 25,
      label: headerColumns.TOTAL_WEIGHT,
      field: shipmentObjFields.WEIGHT,
      enabled: true,
      width: 130,
      type: propertyType.FLOAT,
    }),
  },
];

export const expensesHeaderSetup = [
  {
    ...new ColumnHeader({
      index: 0,
      label: headerColumns.EXPAND,
      field: headerColumns.EXPAND,
      enabled: true,
      unmovable: true,
      width: 35,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 1,
      label: headerColumns.DOCUMENT_DATE,
      field: "date",
      enabled: true,
      unmovable: true,
      width: 150,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 2,
      label: headerColumns.PAID_TO,
      field: "paidTo",
      enabled: true,
      unmovable: true,
      width: 210,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 3,
      label: headerColumns.ITEM_TYPE,
      field: "type",
      enabled: true,
      unmovable: false,
      width: 160,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 4,
      label: headerColumns.ORDER_NUMBER,
      field: "orderNumber",
      enabled: true,
      unmovable: false,
      width: 185,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 5,
      label: headerColumns.GL_ACCOUNT,
      field: "currentGLAccountName",
      enabled: true,
      unmovable: false,
      width: 170,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 6,
      label: headerColumns.DESCRIPTION,
      field: "currentDescription",
      enabled: true,
      unmovable: false,
      width: 170,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 7,
      label: headerColumns.NOTES,
      field: "currentNotes",
      enabled: true,
      unmovable: false,
      width: 150,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 8,
      label: headerColumns.FORECAST,
      field: "forecast",
      enabled: true,
      unmovable: false,
      width: 160,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 9,
      label: headerColumns.ACTUAL,
      field: "actual",
      enabled: true,
      unmovable: false,
      width: 160,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 10,
      label: headerColumns.DELTA,
      field: "delta",
      enabled: true,
      unmovable: false,
      width: 110,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 11,
      label: headerColumns.CUSTOMER,
      field: "currentCustomerName",
      enabled: true,
      unmovable: false,
      width: 170,
      type: null,
    }),
  },
  {
    ...new ColumnHeader({
      index: 12,
      label: headerColumns.CUSTOMER_PO,
      field: "currentCustomerPO",
      enabled: true,
      unmovable: false,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 13,
      label: headerColumns.REFERENCE_NUMBER,
      field: "currentReferenceNumber",
      enabled: true,
      unmovable: false,
      width: 150,
      type: propertyType.TEXT,
    }),
  },
  {
    ...new ColumnHeader({
      index: 14,
      label: headerColumns.CREATION_DATE,
      field: "creationDate",
      enabled: true,
      unmovable: false,
      width: 150,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 15,
      label: headerColumns.EXPORTED_DATE,
      field: "exportedDate",
      enabled: true,
      unmovable: false,
      width: 150,
      type: propertyType.DATE,
    }),
  },
  {
    ...new ColumnHeader({
      index: 16,
      label: headerColumns.ED_TOOL,
      field: headerColumns.ED_TOOL,
      enabled: true,
      unmovable: true,
      width: 150,
      type: null,
    }),
  },
];

export const columnHeaderSetup = {
  PO_HEADER_SETUP: "POHeaderSetup",
  SO_HEADER_SETUP: "SOHeaderSetup",
  INBOX_HEADER_SETUP: "InboxHeaderSetup",
  TASK_HEADER_SETUP: "taskHeaderSetup",
  ORDER_BY_PHASE_SO_SETUP: "orderByPhaseSOSetup",
  ORDER_BY_PHASE_PO_GROUP_BY_SO_SETUP: "orderByPhasePOGroupBySOSetup",
  SHIPMENT_HEADER_SETUP: "shipmentHeaderSetup",
  EXPENSES_HEADER_SETUP: "expensesHeaderSetup",
};
