import React from "react";
import IntlMessages from "../../util/IntlMessages";

function CustomButton({
  children,
  onClick,
  type,
  className,
  disabled = false,
  style = {},
  noSubmit,
  containerColor = "white",
  priorityView = 0,
  styleContainer = {},
  id = "",
}) {
  return (
    <div
      id={id}
      style={{
        ...styleContainer,
        position: "sticky",
        background: containerColor,
        zIndex: priorityView,
        display: "flex",
        alignItems: "center",
      }}
    >
      <button
        style={{ ...style, minWidth: 80 }}
        onClick={onClick}
        className={className ? className : "button-" + type}
        type={!noSubmit ? "submit" : "button"}
        disabled={disabled}
      >
        {children && <IntlMessages id={children} />}
      </button>
    </div>
  );
}

export default CustomButton;
