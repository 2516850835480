import numeral from "numeral";
import {
  generalPermissions,
  headerColumns,
  notAllowedToSee,
  propertyType,
} from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import { dbTables } from "../../api/types/dbTables";

export function footerList({
  headers = [],
  list = [],
  isAllowed,
  scope = "",
  amountPermission = "",
}) {
  let avoidRendering = [
    headerColumns.DIAMOND,
    headerColumns.MENTIONS,
    headerColumns.PROGRESS,
  ];
  if (scope === dbTables.SALES_ORDERS) {
    avoidRendering.push(headerColumns.SO);
  } else if (scope === dbTables.PURCHASE_ORDERS) {
    avoidRendering.push(headerColumns.PO);
  } else if (scope === dbTables.SHIPMENTS) {
    avoidRendering.push(headerColumns.SHIPMENT);
  }
  const TOTALS_FOOTER = {
    [dbTables.SALES_ORDERS]: `Totals of all ${list.length} Sales Orders:`,
    [dbTables.PURCHASE_ORDERS]: `Totals of all ${list.length} POs:`,
    [dbTables.SHIPMENTS]: `Totals of all ${list.length} Shipments:`,
  };

  return headers
    .filter(
      (header) => header.enabled && !avoidRendering.includes(header.label)
    )
    .map((header) => {
      if (header.label === headerColumns.FAVORITE) {
        return {
          id: header.id,
          value: TOTALS_FOOTER[scope],
          colSpan: 5,
          styles: {
            padding: "9px 16px",
            height: 45,
          },
        };
      } else if (header.type === propertyType.CURRENCY) {
        let totals = 0;
        list.forEach((order) => {
          totals += +numeral(order[header.field]).value() || 0;
        });
        totals = numeral(totals).format("$ 0,0.00");
        const hasPermissionToSeeDollarAmount = isAllowed(amountPermission);

        return {
          id: header.id,
          value: hasPermissionToSeeDollarAmount ? totals : notAllowedToSee,
          divider: true,
          styles: {
            padding: "9px 16px 9px 16px",
            height: 45,
            display: hasPermissionToSeeDollarAmount ? "" : "none",
          },
        };
      } else if (
        header.type === propertyType.INTEGER ||
        header.type === propertyType.FLOAT
      ) {
        let format = "0,0";
        if (header.type === propertyType.FLOAT) {
          format = "0,0.00";
        }
        let totals = 0;
        list.forEach((order) => {
          totals += +numeral(order[header.field]).value() || 0;
        });
        totals = numeral(totals).format(format);
        return {
          id: header.id,
          value: totals,
          divider: true,
          styles: {
            padding: "9px 16px 9px 16px",
            height: 45,
          },
        };
      } else {
        return {
          id: header.id,
          value: " ",
          styles: {
            padding: "9px 16px",
            height: 45,
          },
        };
      }
    });
}

export function footerOrdersByPhasePOsWithinSO({
  headers = [],
  list = [],
  isAllowed,
}) {
  const avoidRendering = [
    headerColumns.DIAMOND,
    headerColumns.FAVORITE,
    headerColumns.PO,
  ];
  const headersCpy = [...headers];
  headersCpy.push({ label: "empty", id: "empty_1", enabled: true });
  return headersCpy
    .filter(
      (header) => header.enabled && !avoidRendering.includes(header.label)
    )
    .map((header) => {
      if (header.label === headerColumns.EXPAND) {
        return {
          id: header.id,
          value: `Totals of all ${list.length} POs:`,
          colSpan: 4,
          styles: {
            padding: "9px 16px",
            height: 45,
            borderTop: `2px solid ${colors.dividerColor}`,
          },
        };
      } else if (header.type === propertyType.CURRENCY) {
        let totals = 0;
        list.forEach((order) => {
          totals += +numeral(order[header.field]).value() || 0;
        });
        totals = numeral(totals).format("$ 0,0.00");
        const hasPermissionToSeeDollarAmount = isAllowed(
          generalPermissions.PURCHASE_ORDER_AMOUNT
        );

        return {
          id: header.id,
          value: hasPermissionToSeeDollarAmount ? totals : notAllowedToSee,
          divider: true,
          styles: {
            padding: "9px 16px 9px 16px",
            height: 45,
            display: hasPermissionToSeeDollarAmount ? "" : "none",
          },
        };
      } else {
        return {
          id: header.id,
          value: " ",
          styles: {
            padding: "9px 16px",
            height: 45,
            borderTop: `2px solid ${colors.dividerColor}`,
          },
        };
      }
    });
}

export function footerOrdersByPhaseShipmentsWithinSO({
  headers = [],
  list = [],
  isAllowed,
}) {
  const avoidRendering = [
    headerColumns.DIAMOND,
    headerColumns.FAVORITE,
    headerColumns.SHIPMENT,
  ];

  const headersCpy = [...headers];
  headersCpy.push({ label: "empty", id: "empty_1", enabled: true });
  return headersCpy
    .filter(
      (header) => header.enabled && !avoidRendering.includes(header.label)
    )
    .map((header) => {
      if (header.label === headerColumns.EXPAND) {
        return {
          id: header.id,
          value: `Totals of all ${list.length} Shipmentss:`,
          colSpan: 4,
          styles: {
            padding: "9px 16px",
            height: 45,
            borderTop: `2px solid ${colors.dividerColor}`,
          },
        };
      } else if (header.type === propertyType.CURRENCY) {
        let totals = 0;
        list.forEach((order) => {
          totals += +numeral(order[header.field]).value() || 0;
        });
        totals = numeral(totals).format("$ 0,0.00");
        const hasPermissionToSeeDollarAmount = isAllowed(
          generalPermissions.SHIPMENT_AMOUNT
        );

        return {
          id: header.id,
          value: hasPermissionToSeeDollarAmount ? totals : notAllowedToSee,
          divider: true,
          styles: {
            padding: "9px 16px 9px 16px",
            height: 45,
            display: hasPermissionToSeeDollarAmount ? "" : "none",
          },
        };
      } else {
        return {
          id: header.id,
          value: " ",
          styles: {
            padding: "9px 16px",
            height: 45,
            borderTop: `2px solid ${colors.dividerColor}`,
          },
        };
      }
    });
}

export function footerOrdersByPhaseSOs({ headers = [], list = [], isAllowed }) {
  const avoidRendering = [
    headerColumns.DIAMOND,
    headerColumns.FAVORITE,
    headerColumns.SALES_ORDER,
  ];

  return headers
    .filter(
      (header) => header.enabled && !avoidRendering.includes(header.label)
    )
    .map((header) => {
      if (header.label === headerColumns.EXPAND) {
        return {
          id: header.id,
          value: `Totals of all ${list.length} SOs:`,
          colSpan: 4,
          styles: {
            padding: "9px 16px",
            height: 45,
          },
        };
      } else if (header.type === propertyType.CURRENCY) {
        let totals = 0;
        list.forEach((order) => {
          totals += +numeral(order[header.field]).value() || 0;
        });
        totals = numeral(totals).format("$ 0,0.00");
        const hasPermissionToSeeDollarAmount = isAllowed(
          generalPermissions.SALES_ORDER_AMOUNT
        );

        return {
          id: header.id,
          value: hasPermissionToSeeDollarAmount ? totals : notAllowedToSee,
          divider: true,
          styles: {
            padding: "9px 16px 9px 16px",
            height: 45,
            display: hasPermissionToSeeDollarAmount ? "" : "none",
          },
        };
      } else {
        return {
          id: header.id,
          value: " ",
          styles: {
            padding: "9px 16px",
            height: 45,
          },
        };
      }
    });
}
