import React from "react";
import { PREFIX_ITEM_TABLE } from "../../helpers/constants";
import { TitleExpandModalStyled } from "./styles";
import {
  getTotalCBMFromItems,
  getTotalWeightFromItems,
} from "../../helpers/orderDashboardRefactored";
import numeral from "numeral";
import { globalScopes } from "../../api/types/dbTables";
import { getFormattedItems } from "./dashboardHelpers";

function TitleInformation({
  type = "",
  purchaseOrder = {},
  shipment = {},
  items = [],
  salesOrder = {},
}) {
  const poShipments = purchaseOrder.shipmentIds || [];
  const getShipmentCBM = () => {
    const formattedItems = getFormattedItems({
      items: items,
      scope: globalScopes.SHIPMENT,
      orderId: shipment.id,
    });

    return numeral(getTotalCBMFromItems({ items: formattedItems })).format(
      "0,0.00"
    );
  };
  const getTotalWeight = () => {
    const formattedItems = getFormattedItems({
      items: items,
      scope: globalScopes.SHIPMENT,
      orderId: shipment.id,
    });
    return numeral(getTotalWeightFromItems({ items: formattedItems })).format(
      "0,0.00"
    );
  };

  const getUniqueItemsSize = () => {
    if (PREFIX_ITEM_TABLE.SALES_ORDER === type) {
      const soItems = salesOrder.items || [];
      return soItems.length;
    } else {
      return items.length;
    }
  };
  return (
    <TitleExpandModalStyled
      id={"sales-order-title"}
      className={"titleExpandModalContainerStyled"}
    >
      <span className="principalTitle">Unique Items:</span>
      <span className="subtitle">{getUniqueItemsSize()}</span>
      {type === PREFIX_ITEM_TABLE.PURCHASE_ORDER && (
        <React.Fragment>
          <span className="principalTitle">Total Shipments:</span>
          <span className="subtitle">{poShipments.length}</span>
        </React.Fragment>
      )}
      {type === PREFIX_ITEM_TABLE.SHIPMENT && (
        <React.Fragment>
          <span className="principalTitle">Total CBM:</span>
          <span className="subtitle">{getShipmentCBM()}</span>
          <span className="principalTitle">Total Weight:</span>
          <span className="subtitle">{getTotalWeight()}</span>
        </React.Fragment>
      )}
    </TitleExpandModalStyled>
  );
}

export default TitleInformation;
