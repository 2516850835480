import React, { useState, useEffect } from "react";
import moment from "moment";
import { TYPE_OF_FILE } from "../../../helpers/constants";
import { useCompanyUsers } from "../../../hooks/user";
import { DocumentInfoStyled } from "../../Documentsv2/styles";
import { ListItemIcon } from "@mui/material";
import {
  PermissionIcon,
  PlusSquare,
  YellowFolderIcon,
} from "../../../helpers/iconSvgPath";
import FileSelector from "../../Documentsv2/FileSelector";
import EditableLabelv2 from "../../Documentsv2/EditableLabelv2";
import TooltipTD from "../../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import { getDocumentNameByType } from "../../Documents/documentHelpers";

function DocumentNoteInfo({
  currentDocument,
  isVoided,
  onDragEnd,
  onDragStart,
  onRichDocument,
  onEditPermission,
}) {
  const [date, setDate] = useState(
    moment(currentDocument.created).format("h:mma M/D/YY")
  );
  const [editing, setEditing] = useState(false);

  const users = useCompanyUsers({
    showInactiveUsers: true,
    showBotUser: true,
  });

  useEffect(() => {
    setDate(moment(currentDocument.created).format("h:mma M/D/YY"));
  }, [currentDocument.created, currentDocument.lastModifiedDate]);

  function getDocumentUser({ users = [], currentDocument = {} }) {
    if (currentDocument.createdBy && users.length > 0) {
      const user = users.find((user) => user.id === currentDocument.createdBy);
      if (user) {
        return "by @" + user.displayName;
      }
      return "@defaultUser";
    }
    return "@defaultUser";
  }

  const isPlaceholder = currentDocument.placeholder;

  return (
    <DocumentInfoStyled
      className="document-info-container"
      style={{ padding: 0 }}
    >
      <div
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        style={{
          display: "flex",
          width: "100%",
          padding: "14px 12px 10px 19px",
          borderRadius: 8,
          cursor: "grab",
        }}
      >
        <div className="document-icon-container">
          {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
            <ListItemIcon className="listItemIcon">
              {YellowFolderIcon({
                svgClass: "imgListItemIcon",
                width: 38,
                height: 38,
              })}
            </ListItemIcon>
          ) : (
            <ListItemIcon className={"listItemIcon"}>
              <FileSelector
                path={currentDocument.path}
                version={currentDocument.version}
                type={currentDocument.type}
                currentDocument={currentDocument}
              />
            </ListItemIcon>
          )}
        </div>
        <div className="document-detail-container">
          <EditableLabelv2
            className="document-name-content"
            text={getDocumentNameByType({
              currentDocument,
            })}
            editing={editing}
            onCancel={() => {
              console.log("ON CANCEL");
              setEditing(false);
            }}
          />
          <div className="document-creation-date-content">
            <span>Added {date}</span>
          </div>
          <div className="document-created-by-content">
            <div className="created-by">
              {getDocumentUser({ users, currentDocument })}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 14,
          marginLef: "auto",
        }}
      >
        {!isVoided && currentDocument.type === TYPE_OF_FILE.FOLDER && (
          <TooltipTD
            tooltipClick={(ev) => {
              if (!isPlaceholder) {
                onEditPermission(ev);
              }
              ev.stopPropagation();
            }}
            label={
              !isPlaceholder
                ? "Change folder view permissions"
                : "Access Groups cannot be changed in placeholder folders"
            }
          >
            {currentDocument.child !== undefined && (
              <PermissionIcon
                width={30}
                height={30}
                svgClass={cx("permissionIcon", {
                  placeholderStyle: isPlaceholder,
                })}
                color="#25486D"
              />
            )}
          </TooltipTD>
        )}
        <TooltipTD
          label={
            currentDocument.type === TYPE_OF_FILE.FOLDER
              ? "Add folder"
              : "Add file"
          }
          style={{ marginRight: 15, minWidth: 25 }}
          tooltipClick={(ev) => {
            onRichDocument({ ...currentDocument, withButton: true });
            setTimeout(() => {
              onDragEnd();
            }, 300);
            ev.stopPropagation();
          }}
        >
          <PlusSquare
            svgClass="squareButton"
            childClass="line"
            width={25}
            height={25}
          />
        </TooltipTD>
      </div>
    </DocumentInfoStyled>
  );
}

export default DocumentNoteInfo;
