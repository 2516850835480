export const categoriesTableheader = [
  {
    id: 1,
    label: "generic.text.category",
    key: "name",
  },
  {
    id: 2,
    label: "generic.text.description",
    key: "description",
  },
  {
    id: 3,
    label: "generic.text.createdBy",
    key: "createdBy",
  },
  {
    id: 4,
    label: "generic.text.dateCreated",
    key: "createdDate",
    styles: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  {
    id: 5,
    label: "generic.text.updatedBy",
    key: "updatedBy",
  },
  {
    id: 6,
    label: "generic.text.dateUpdated",
    key: "updatedDate",
    styles: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  {
    id: 7,
    label: "generic.text.blank",
    key: "blank",
  },
];

export const TagsHeadCells = [
  {
    label: "Label",
    key: "name",
    id: 0,
    canBeSort: true,
  },
  {
    label: "Description",
    key: "description",
    id: 1,
    canBeSort: false,
  },
  {
    label: "generic.text.createdBy",
    key: "createdBy",
    id: 2,
    canBeSort: true,
  },
  {
    id: 3,
    label: "generic.text.dateCreated",
    key: "createdDate",
    canBeSort: true,
  },
  {
    id: 4,
    label: "generic.text.updatedBy",
    key: "updatedBy",
    canBeSort: true,
  },
  {
    id: 5,
    label: "generic.text.dateUpdated",
    key: "updatedDate",
    canBeSort: true,
  },
  {
    id: 6,
    label: "generic.text.blank",
    key: "blank",
    canBeSort: false,
  },
];

export const ProjectHeadCells = [
  {
    label: "salesorders.title",
    key: "number",
    id: 0,
    canBeSort: true,
  },
  {
    label: "customers.title",
    key: "customerName",
    id: 1,
    canBeSort: true,
  },
  {
    label: "orderDashboard.label.createdDate",
    key: "creationDate",
    id: 2,
    canBeSort: true,
  },
  {
    id: 3,
    label: "orderDashboard.label.date",
    key: "date",
    canBeSort: true,
  },
  {
    id: 4,
    label: "components.saleOrders.ActionsModal.voidPO",
    key: "updatedDate",
    canBeSort: false,
  },
  {
    id: 5,
    label: "components.saleOrders.ActionsModal.completeAllTasks",
    key: "blank",
    canBeSort: false,
  },
];

export const AccessGroupHeadCells = [
  {
    label: "generic.text.name",
    key: "name",
    id: 0,
    canBeSort: true,
  },
  {
    label: "generic.text.description",
    key: "description",
    id: 1,
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.text.blank",
  },
  {
    id: 3,
    label: "generic.text.blank",
  },
];

export const UserHeaderCell = [
  {
    id: 0,
    label: "generic.textForm.firstName",
    key: "firstName",
    canBeSort: true,
  },
  {
    id: 1,
    label: "generic.textForm.lastName",
    key: "lastName",
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.textForm.displayName",
    key: "displayName",
    canBeSort: true,
  },
  {
    id: 3,
    label: "generic.textForm.cellPhone",
    key: "cellPhone",
    canBeSort: true,
  },
  {
    id: 4,
    label: "generic.textForm.email",
    key: "email",
    canBeSort: true,
  },
  {
    id: 5,
    label: "generic.textForm.role",
    key: "role",
    canBeSort: true,
  },
  {
    id: 6,
    label: "generic.textForm.permissionGroup",
    key: "permissionGroup",
    canBeSort: false,
  },
  {
    id: 7,
    label: "userscompany.tableheader.analyticsPerson",
    key: "typeOfUser",
    canBeSort: true,
    onlyTradedashEmployee: true,
  },
  {
    id: 8,
    label: "userscompany.tableheader.automaticSystemUser",
    key: "automaticSystemUser",
    canBeSort: false,
    onlyTradedashEmployee: true,
  },
  {
    id: 9,
    label: "generic.textForm.avatar",
    key: "avatar",
    canBeSort: false,
    style: { padding: "10px 5px" },
  },
  {
    id: 10,
    label: "generic.text.blank",
    key: "blank",
    canBeSort: false,
  },
];


export const theadFontendHeader = [
  { label: "Date and Time", style: { width: 200, minWidth: 200 } },
  { label: "Error ID", style: { width: 160, minWidth: 160 } },
  { label: "Pathname", style: { width: 215, minWidth: 215 } },
  { label: "Description", style: { width: "100%" } },
];

export const tableReportHeader = [
  {
    id: 1,
    label: "Name",
    styles: {
      width: 545,
      minWidth: 545,
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 2,
    label: "Description",
    styles: {
      width: "100%",
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 3,
    label: "Action",
    styles: {
      width: 120,
      minWidth: 120,
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
];

export const suppliersTableheader = [
  {
    id: 1,
    label: "title.text.Supplier",
    key: "name",
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.text.blank",
    key: "",
  },
  {
    id: 3,
    label: "generic.text.blank",
    key: "",
  },
];

export const tableGLAccountHeader = [
  {
    id: 1,
    label: "title.text.GLAccount",
    key: "name",
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.text.description",
    key: "description",
    canBeSort: true,
  },

  {
    id: 3,
    label: "generic.text.blank",
    key: "",
  },
];

export const theadErroLog = [
  {
    label: "Date and Time",
    styles: {
      width: "210px",
    },
  },
  {
    label: "Error ID",
    styles: {
      width: "90px",
    },
  },
  {
    label: "Description",
    styles: {
      width: "auto",
    },
  },
];