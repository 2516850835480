import React from "react";
import { dbTables, globalScopes, typeOfTask } from "../../api/types/dbTables";
import moment from "moment";
import {
  deliveryWindowHeaderCells,
  FIELD_NAME,
  shipmensHeaderCells,
  shipmentWindowHeaderCells,
} from "../../helpers/orderDashboard";
import {
  getTotalCBMFromItems,
  getTotalWeightFromItems,
} from "../../helpers/orderDashboardRefactored";
import numeral from "numeral";
import {
  FIELD_SOURCE,
  generalPermissions,
  notAllowedToSee,
  propertyType,
  SALES_ORDER_STATUS,
  shippingTerms,
} from "../../helpers/constants";
import TextFieldNumericMetadata from "../TextFields/TextFieldNumericMetadata";
import { InputAdornment } from "@mui/material";
import DateFieldMetadata from "../TextFields/DateFieldMetadata";
import TextFieldMetadata from "../TextFields/TextFieldMetadata";
import isURL from "validator/lib/isURL";
import { CopyLinkIcon } from "../../helpers/iconSvgPath";
import AutocompleteMetadata from "../TextFields/AutocompleteMetadata";
import { deleteField, updateDoc } from "firebase/firestore";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { colors } from "../../assets/jss/variables";
import ShipmentDateWindow from "./ShipmentDateWindow";
import AppConfig from "../../constants/AppConfig";
import { isEqual, union } from "lodash";
import { getTheDate } from "../../helpers/helpers";

export const getStringDate = (date = "") => {
  const parseDate = moment(date);
  return parseDate.isValid() ? parseDate.format("M/D/YY") : "";
};

export const mismatchLabel = {
  SO_MISMATCH: {
    key: "SO_MISMATCH",
    content: (
      <span style={{ lineHeight: 1.5 }}>
        Red numbers in the Order Quantity column indicate
        <br />
        that the Sales Order quantity does not match the PO quantity
      </span>
    ),
  },
  SO_WHOLE_NUMBER: {
    key: "SO_WHOLE_NUMBER",
    content: (
      <span style={{ lineHeight: 1.5 }}>
        Red numbers in the Cartons column indicate
        <br />
        that it is not a whole number of cartons
      </span>
    ),
  },
  SO_MISMATCH_AND_NUMBER: {
    key: "SO_MISMATCH_AND_NUMBER",
    content: (
      <span style={{ lineHeight: 1.5 }}>
        Red numbers in the Order Quantity column indicate that the Sales Order
        quantity does not match the PO quantity
        <br />
        and
        <br />
        Red numbers in the Cartons column indicate that it is not a whole number
        of cartons.
      </span>
    ),
    tooltip: (
      <span>
        Red numbers in the Order Quantity column indicate that the Sales Order
        quantity does not match the PO quantity and <br />
        Red numbers in the Cartons column indicate that it is not a whole number
        of cartons.
      </span>
    ),
  },

  [globalScopes.PURCHASE_ORDER]: {
    key: globalScopes.PURCHASE_ORDER,
    content: (
      <span>
        The quantity of one or more items is not 100% <br /> allocated to
        shipments.
      </span>
    ),
  },
  [globalScopes.SHIPMENT]: {
    key: globalScopes.SHIPMENT,
    content: (
      <span>
        There are one or more items in this shipment <br /> that are not a whole
        number of cartons.
      </span>
    ),
  },
};

export function getMiscellaneoList({ miscellaneous = [], miscellaneoId = "" }) {
  const miscellaneo = miscellaneous.find((item) => item.id === miscellaneoId);
  if (miscellaneo) {
    return miscellaneo.list;
  }
  return [];
}

export function getFormattedItems({ items = [], scope, orderId }) {
  if (scope === dbTables.SALES_ORDERS) {
    return items;
  } else if (scope === dbTables.PURCHASE_ORDERS) {
    return items;
  } else if (scope === dbTables.SHIPMENTS) {
    return items.map((item) => ({
      ...item,
      orderQuantity: item.shipmentQuantity[orderId],
      price: item.unitCost,
    }));
  } else {
    return [];
  }
}

export function getItemsAmount({ items = [] }) {
  return items.reduce((prevValue, currentValue) => {
    return (prevValue +=
      parseFloat(currentValue.orderQuantity) * parseFloat(currentValue.price));
  }, 0);
}

export function getEntityTextValue({
  field,
  entity,
  scope = dbTables.SALES_ORDERS,
}) {
  switch (field) {
    case FIELD_NAME.TOTAL_POS: {
      return entity.purchaseOrderIds?.length || 0;
    }

    case FIELD_NAME.UNIQUE_ITEMS: {
      return entity.items && entity.items.length;
    }

    case FIELD_NAME.DISCOUNT_PERCENTAGE: {
      return entity[field] + "%";
    }

    case FIELD_NAME.TOTAL_DISCOUNT: {
      const discount = parseFloat(entity.discount || 0);
      const formattedItems = getFormattedItems({
        items: entity.items,
        scope,
        orderId: entity.id,
      });
      const total = getItemsAmount({ items: formattedItems });
      const totalDiscount = (total * discount) / 100;
      return !discount ? "" : totalDiscount;
    }

    case FIELD_NAME.BALANCE: {
      const discount = parseFloat(entity.discount || 0);
      const formattedItems = getFormattedItems({
        items: entity.items,
        scope,
        orderId: entity.id,
      });
      const total = getItemsAmount({ items: formattedItems });
      const deposit = entity.deposit || 0;
      const totalDiscount = (total * discount) / 100;
      return total - totalDiscount - deposit;
    }

    case FIELD_NAME.TOTAL: {
      const discount = parseFloat(entity.discount || 0);
      const formattedItems = getFormattedItems({
        items: entity.items,
        scope,
        orderId: entity.id,
      });
      const total = getItemsAmount({ items: formattedItems });
      const totalDiscount = (total * discount) / 100;
      if (!discount) {
        return total;
      }
      return total - totalDiscount;
    }

    case FIELD_NAME.AMOUNT: {
      const discount = parseFloat(entity.discount || 0);
      const formattedItems = getFormattedItems({
        items: entity.items,
        scope,
        orderId: entity.id,
      });
      const total = getItemsAmount({ items: formattedItems });
      if (!discount) {
        return total;
      }
      return total;
    }

    case FIELD_NAME.CBM: {
      const formattedItems = getFormattedItems({
        items: entity.items,
        scope,
        orderId: entity.id,
      });

      return numeral(getTotalCBMFromItems({ items: formattedItems })).format(
        "0,0.00"
      );
    }
    case FIELD_NAME.WEIGHT: {
      const formattedItems = getFormattedItems({
        items: entity.items,
        scope,
        orderId: entity.id,
      });
      return numeral(getTotalWeightFromItems({ items: formattedItems })).format(
        "0,0.00"
      );
    }

    default: {
      return entity[field] || "";
    }
  }
}

export function isDisabledToEdit({
  item = {},
  isAllowedCompany = () => {},
  isAllowed = () => {},
  isReadOnly,
  entity = {},
  scope = dbTables.SALES_ORDERS,
}) {
  let currentCompanyPermission = true;
  let rolePermission = true;
  if (entity.status === SALES_ORDER_STATUS.VOIDED || isReadOnly) {
    return true;
  }
  if (item.companyPermission && scope !== dbTables.SHIPMENTS) {
    currentCompanyPermission = isAllowedCompany(item.companyPermission);
  }
  if (item.permission) {
    rolePermission = isAllowed(item.permission);
  }

  if (
    currentCompanyPermission &&
    rolePermission &&
    item.source === FIELD_SOURCE.USER
  ) {
    return false;
  }
  return true;
}

export const WINDOW_VIEW = [
  FIELD_NAME.SHIPMENT_WINDOW,
  FIELD_NAME.DELIVERY_WINDOW,
  FIELD_NAME.TOTAL_SHIPMENTS,
];

export const WINDOW_FIELD = {
  [FIELD_NAME.SHIPMENT_WINDOW]: "shipDate",
  [FIELD_NAME.DELIVERY_WINDOW]: "deliveryDate",
  [FIELD_NAME.TOTAL_SHIPMENTS]: "shipDate",
};

export const getWindowsTextValue = (itemField) => {
  const WINDOW_TITLE = {
    [FIELD_NAME.SHIPMENT_WINDOW]: "Shipment Window",
    [FIELD_NAME.DELIVERY_WINDOW]: "Delivery Window",
    [FIELD_NAME.TOTAL_SHIPMENTS]: "Shipments",
  };
  const WINDOW_HEADER_CELLS = {
    [FIELD_NAME.SHIPMENT_WINDOW]: shipmentWindowHeaderCells,
    [FIELD_NAME.DELIVERY_WINDOW]: deliveryWindowHeaderCells,
    [FIELD_NAME.TOTAL_SHIPMENTS]: shipmensHeaderCells,
  };

  const title = WINDOW_TITLE[itemField];
  const field = WINDOW_FIELD[itemField];
  const windowHeaderCells = WINDOW_HEADER_CELLS[itemField];
  return { title, field, windowHeaderCells };
};

export const renderMetadataTextField = ({
  item,
  isReadOnly,
  entity,
  isAllowed = () => {},
  isAllowedCompany = () => {},
  onChangeAmountProperty = () => {},
  onChangeEntityProperty = () => {},
  currentCustomer = {},
  scope = dbTables.SALES_ORDERS,
  windowValue,
  shipments,
  setSignShipment = () => {},
}) => {
  const permissionScope = {
    [dbTables.SALES_ORDERS]: generalPermissions.SALES_ORDER_AMOUNT,
    [dbTables.PURCHASE_ORDERS]: generalPermissions.PURCHASE_ORDER_AMOUNT,
    [dbTables.SHIPMENTS]: generalPermissions.SHIPMENT_AMOUNT,
  };

  const isDisabled = isDisabledToEdit({
    item,
    isAllowedCompany,
    isReadOnly,
    entity,
    isAllowed,
    scope,
  });

  if (WINDOW_VIEW.includes(item.field)) {
    const { title, field, windowHeaderCells } = getWindowsTextValue(item.field);
    return (
      <TextFieldMetadata
        item={item}
        value={windowValue}
        isDisabled
        scope={scope}
        endAdornment={
          <InputAdornment position="start">
            <ShipmentDateWindow
              shipments={shipments}
              title={title}
              field={field}
              headCells={windowHeaderCells}
              propertyScope={scope}
              setSignShipment={setSignShipment}
            />
          </InputAdornment>
        }
      />
    );
  }
  if (
    item.type === propertyType.CURRENCY &&
    !isAllowed(permissionScope[scope])
  ) {
    return (
      <TextFieldMetadata item={item} value={notAllowedToSee} scope={scope} />
    );
  }
  switch (item.type) {
    case propertyType.CURRENCY: {
      const value = getEntityTextValue({
        field: item.field,
        entity,
        scope,
      });

      return (
        <TextFieldNumericMetadata
          item={item}
          value={value}
          isDisabled={isDisabled}
          onChange={(e) =>
            onChangeAmountProperty({
              value: e.target.value,
              field: item.field,
              label: item.label,
              type: item.type,
            })
          }
          scope={scope}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          decimalScale={2}
        />
      );
    }
    case propertyType.PERCENTAGE: {
      return (
        <TextFieldNumericMetadata
          item={item}
          value={entity[item.field]}
          isDisabled={isDisabled}
          onChange={(e) =>
            onChangeAmountProperty({
              value: e.target.value,
              field: item.field,
              label: item.label,
              type: item.type,
            })
          }
          scope={scope}
          endAdornment={
            <InputAdornment position="end" className="percentageAdorment">
              %
            </InputAdornment>
          }
          decimalScale={2}
        />
      );
    }
    case propertyType.INTEGER:
    case propertyType.FLOAT: {
      const isInteger = propertyType.INTEGER === item.type;
      const value = getEntityTextValue({
        field: item.field,
        entity,
        scope,
      });
      return (
        <TextFieldNumericMetadata
          item={item}
          value={value}
          isDisabled={isDisabled}
          onChange={(e) =>
            onChangeAmountProperty({
              value: e.target.value,
              field: item.field,
              label: item.label,
              type: item.type,
            })
          }
          scope={scope}
          decimalScale={isInteger ? 0 : 2}
        />
      );
    }
    case propertyType.DATE_TIME:
    case propertyType.DATE: {
      const isDateTime = item.type === propertyType.DATE_TIME;

      return (
        <DateFieldMetadata
          item={item}
          isDateTime={isDateTime}
          value={entity[item.field]}
          isDisabled={isDisabled}
          scope={scope}
          onSave={(newDate) => {
            onChangeEntityProperty({
              value: newDate,
              field: item.field,
              label: item.label,
              type: item.type,
            });
          }}
        />
      );
    }
    case propertyType.CALCULATED:
    case propertyType.LINK:
    case propertyType.TEXT: {
      const value = getEntityTextValue({
        field: item.field,
        entity,
        scope,
      });
      const isLink = item.type === propertyType.LINK;
      const normalizedLink =
        isLink && value?.startsWith("http") ? value : `https://${value}`;

      return (
        <TextFieldMetadata
          item={item}
          value={value}
          isDisabled={isDisabled}
          scope={scope}
          onChange={(e) =>
            onChangeEntityProperty({
              value: e.target.value,
              field: item.field,
              label: item.label,
              type: item.type,
            })
          }
          isLink={isLink}
          endAdornment={
            isLink &&
            isURL(value) && (
              <CopyLinkIcon
                svgClass={"downloadIcon"}
                color={colors.primaryDark}
                height={16}
                width={16}
                onClick={() => window.open(normalizedLink, "_blank")}
              />
            )
          }
        />
      );
    }

    case propertyType.AUTOCOMPLETE: {
      const id = item.listName || item.id;
      const options = currentCustomer[id] || {};
      const optionsList = Object.keys(options).map((key) => ({
        id: key,
        label: options[key].name,
      }));
      return (
        <AutocompleteMetadata
          item={item}
          value={entity[item.field]}
          isDisabled={isDisabled}
          scope={scope}
          onChange={(value) =>
            onChangeEntityProperty({
              value,
              field: item.field,
              label: item.label,
              type: item.type,
            })
          }
          onSaveNewOption={async (newItem) => {
            onChangeEntityProperty(newItem.name, item.field);
            updateDoc(currentCustomer.ref, {
              [`${id}.${newItem.id}`]: {
                ...newItem,
              },
            });
            onChangeEntityProperty({
              value: newItem.name,
              field: item.field,
              label: item.label,
              type: item.type,
            });
          }}
          options={optionsList.sort(sortObjectsBy("label", false))}
          onRemove={async (newItem) => {
            updateDoc(currentCustomer.ref, {
              [`${id}.${newItem.id}`]: deleteField(),
            });
          }}
        />
      );
    }

    case propertyType.SELECTOR: {
      return (
        <TextFieldMetadata
          item={item}
          value={entity[item.field]}
          isDisabled={isDisabled}
          scope={scope}
          onChange={(e) =>
            onChangeEntityProperty({
              value: e.target.value,
              field: item.field,
              label: item.label,
              type: item.type,
            })
          }
          select
          options={Object.keys(shippingTerms).map((key) => ({
            id: key,
            label: key + " - " + shippingTerms[key],
          }))}
        />
      );
    }

    case propertyType.SPACER:
      return <div />;
    default:
      return <div />;
  }
};
export function getShipmentValue({ shipments = [], shipmentId, field }) {
  const shipment = shipments.find((shipment) => shipment.id === shipmentId);
  return shipment?.[field] || "";
}

export function getBackgroundColorByType({ type }) {
  const colorType = {
    [typeOfTask.SALES_ORDER]: AppConfig.themeColors.salesOrderBackgroundColor,
    [typeOfTask.PURCHASE_ORDER]:
      AppConfig.themeColors.purchaseOrderBackgroundColor,
    [typeOfTask.SHIPMENT]: AppConfig.themeColors.shipmentColor,
  };
  return colorType[type] || "#FFF";
}

export function getEntityByField({ itemList = [], itemId, field = "name" }) {
  const currentItem = itemList.find((item) => item.id === itemId);
  if (currentItem) {
    return currentItem[field] || "NO_DATA";
  }
  return "";
}

export const getParseAmount = (amount = "", oldValue = "") => {
  const formattedValue = amount.replace(/,/g, "");
  if (isNaN(formattedValue) || isEqual(oldValue, formattedValue)) {
    return;
  }
  return formattedValue;
};

export function getDateRangeLabel({
  shipmentsToRender = [],
  totalShipment = 0,
  field,
}) {
  if (shipmentsToRender.length === 0 && totalShipment === 0) {
    return "  ";
  } else if (shipmentsToRender.length === 0 && totalShipment !== 0) {
    return "Dates pending";
  } else if (shipmentsToRender.length === 1) {
    return `${getTheDate(shipmentsToRender[0][field])} -`;
  } else if (shipmentsToRender.length > 1) {
    return `${getTheDate(shipmentsToRender[0][field])} - ${getTheDate(
      shipmentsToRender[shipmentsToRender.length - 1][field]
    )}`;
  }
  return "";
}

export function getSOWindowValue({ window, shipments = [], purchaseOrders }) {
  const field = WINDOW_FIELD[window];
  if (window === FIELD_NAME.TOTAL_SHIPMENTS) {
    let totalShipments = [];
    purchaseOrders.forEach((purchaseOrder) => {
      if (purchaseOrder.shipmentIds) {
        totalShipments = union(totalShipments, purchaseOrder.shipmentIds);
      }
    });
    return totalShipments.length;
  } else {
    let shipmentsToRender = [...shipments];
    const allShipments = [];
    purchaseOrders.forEach((purchaseOrder) => {
      if (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds.length > 0) {
        purchaseOrder.shipmentIds.forEach((shipmentId) => {
          if (!allShipments.includes(shipmentId)) {
            allShipments.push(shipmentId);
          }
        });
      }
    });
    shipmentsToRender = shipmentsToRender
      .filter((shipment) => allShipments.includes(shipment.id))
      .sort(sortObjectsBy(field, false));
    return getDateRangeLabel({
      shipmentsToRender: shipmentsToRender.filter(
        (shipment) => shipment[field]
      ),
      totalShipment: shipmentsToRender.length,
      field,
    });
  }
}

export function getPOWindowValue({
  window,
  shipments = [],
  purchaseOrder = {},
}) {
  const field = WINDOW_FIELD[window];
  if (!field) {
    return "";
  }
  if (window === FIELD_NAME.TOTAL_SHIPMENTS) {
    return purchaseOrder?.shipmentIds?.length || 0;
  } else {
    let shipmentsToRender = [...shipments];

    return getDateRangeLabel({
      shipmentsToRender: shipmentsToRender.filter(
        (shipment) => shipment[field]
      ),
      totalShipment: shipmentsToRender.length,
      field,
    });
  }
}
