import React, { useCallback, useEffect } from "react";
import {
  isSuperAdmin,
  isTradeDashEmployee,
  listenToData,
} from "../../helpers/helpers";
import { useCompanyId, useUser } from "../../hooks/user";
import { useRolesPermissionList } from "../../hooks/permissions";
import { TabRoutes } from "./Tabs";
import { updateDoc } from "firebase/firestore";
import { dbTables } from "../../api/types/dbTables";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getTabPath } from "../CompanyTabs/companyTabHelper";

function AdminLayOut() {
  const user = useUser();
  const tradedashEmployee = isTradeDashEmployee(user);
  const superAdminUser = isSuperAdmin(user);
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const params = useParams();
  const rolePermissions = useRolesPermissionList();
  const allPermission = tradedashEmployee || superAdminUser;
  const dispatch = useDispatch();

  const listenToSalesOrderTemplates = useCallback(() =>
    listenToData({
      path: [dbTables.COMPANIES, companyId, dbTables.SALES_ORDER_TEMPLATES],
    })(dispatch)
  );
  const listenToPOTemplates = useCallback(() =>
    listenToData({
      path: [dbTables.COMPANIES, companyId, dbTables.PURCHASE_ORDER_TEMPLATES],
    })(dispatch)
  );

  useEffect(() => {
    if (companyId) {
      listenToSalesOrderTemplates();
      listenToPOTemplates();
    }
  }, [companyId]);

  useEffect(() => {
    if (
      params.companyId &&
      tradedashEmployee &&
      companyId !== params.companyId &&
      params.companyId !== "settings"
    ) {
      updateDoc(user.ref, { observedCompany: params.companyId });
    }
  }, [params.companyId]);

  useEffect(() => {
    if (!params.companyId && (rolePermissions.size > 0 || allPermission)) {
      const tabPath = getTabPath({
        allPermission,
        rolePermissions,
        TabRoutes,
      });
      navigate(`/app/admin/${companyId || "settings"}/${tabPath}`, {
        replace: true,
      });
    }
  }, [rolePermissions]);

  return <Outlet />;
}

export default AdminLayOut;
