import React, { useContext } from "react";
import CalendarDisplayOptions from "../../Timeline/CalendarDispalyOptions";
import { TableContentSetupStyled } from "../../Timeline/styles";
import TaskTableHeader from "../../Timeline/TaskTableHeader";
import { TaskTableSize } from "../../Timeline/GanttChartContext";
import { GANTT_CHART_SETTINGS } from "../../../helpers/ganttChart";
import { cx } from "@emotion/css";
import {
  GANTT_CHART_ACTION,
  GANTTCHART_TABLE_SIZE,
  soTaskTableSize,
} from "../../../helpers/constants";
import { GanttChartTemplateContext } from "../../CompanyTabs/tabsContext";

function TableContentSetup({ soTemplate = false, poTemplate = false }) {
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const { tableSize, calendarSetup } = ganttChartTemplateState;
  function handleChangeCalendarSetup(prevCalendarSetup, calendarSetup) {
    const ganttWrapperEl = document.getElementById("table-content-graph");
    const scrollLeft = ganttWrapperEl.scrollLeft;
    const scrollTop = ganttWrapperEl.scrollTop;
    const prevState = GANTT_CHART_SETTINGS[prevCalendarSetup];
    const nextState = GANTT_CHART_SETTINGS[calendarSetup];
    const scrollLeftToGo = (scrollLeft / prevState) * nextState;
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: { calendarSetup: calendarSetup, reRenderArrow: false },
    });
    setTimeout(() => {
      ganttWrapperEl.scrollTo({
        top: scrollTop,
        left: scrollLeftToGo,
        behavior: "smooth",
      });
    }, 5);
  }

  const timeframeButton = () => (
    <CalendarDisplayOptions
      calendarSetup={calendarSetup}
      onChangeCalendarSetup={handleChangeCalendarSetup}
      tableSize={tableSize}
      handleChangeTableSize={(size) =>
        dispatchGanttChartTemplate({
          type: GANTT_CHART_ACTION.COMMON,
          payload: {
            tableSize: size,
          },
        })
      }
      soTemplate={soTemplate}
      collapseListTask={tableSize === GANTTCHART_TABLE_SIZE.SMALL}
    />
  );

  return (
    <TableContentSetupStyled
      className="tableContentSetup"
      style={{
        width: soTemplate
          ? soTaskTableSize[tableSize]
          : TaskTableSize[tableSize],
        minWidth: soTemplate
          ? soTaskTableSize[tableSize]
          : TaskTableSize[tableSize],
      }}
    >
      <div
        className={cx("setup-container", {
          "setup-template-big": tableSize !== GANTTCHART_TABLE_SIZE.SMALL,
          "setup-template-small": tableSize === GANTTCHART_TABLE_SIZE.SMALL,
        })}
      >
        {timeframeButton()}
      </div>
      {tableSize !== GANTTCHART_TABLE_SIZE.SMALL && (
        <TaskTableHeader
          showHeader={tableSize === GANTTCHART_TABLE_SIZE.LARGE}
          soTemplate={soTemplate}
          poTemplate={poTemplate}
          tableSize={tableSize}
        />
      )}
    </TableContentSetupStyled>
  );
}

export default TableContentSetup;
