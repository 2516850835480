import React, { useState, useEffect } from "react";
import { FactoryInfoStyled } from "./styles";
import IntlMessages from "../../util/IntlMessages";
import AttentionModal from "../Modal/AttentionModal";
import {
  GENERAL_PERMISSION_VALUE,
} from "../../helpers/constants";
import FieldByProperty from "../Inputs/FieldByProperty";
import { updateDoc } from "firebase/firestore";
import { useCategories, useCategoryItems } from "../../hooks";
import AutocompleteCategory from "../Categories/AutocompleteCategory";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { cx } from "@emotion/css";
import { validateEmail } from "../../helpers/helpers";

const FACTORY_FIELD = {
  NUMBER: "number",
  INACTIVE: "inactive",
  NAME: "name",
  MAIN_CONTACT: "mainContact",
  JOB_TITLE: "jobTitle",
  EMAIL: "email",
  PHONE: "phone",
  TERMS: "terms",
  CATEGORIES: "categories",
  NOTES: "notes",
};

const factoryFields = [
  FACTORY_FIELD.NUMBER,
  FACTORY_FIELD.INACTIVE,
  FACTORY_FIELD.NAME,
  FACTORY_FIELD.MAIN_CONTACT,
  FACTORY_FIELD.JOB_TITLE,
  FACTORY_FIELD.EMAIL,
  FACTORY_FIELD.PHONE,
  FACTORY_FIELD.TERMS,
  FACTORY_FIELD.CATEGORIES,
  FACTORY_FIELD.NOTES,
];


function FactoryInfo({
  factory = {},
  factories = [],
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateSummary = () => {},
  isAllowed = {},
}) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentFactory, setCurrentFactory] = useState({});
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const categoryItems = useCategoryItems({});
  const categories = useCategories();
  const [selectedCategories, setSelectedCategories] = useState(
    categoryItems.filter((category) =>
      factory.categories?.includes(category.id)
    ) || []
  );

  useEffect(() => {
    setCurrentFactory(factory);
  }, [factory]);

  function handleInactiveFactory(checked, property) {
    setCurrentFactory({ ...currentFactory, [property]: checked });
    handleUpdateSummary({ ...factory, [property]: checked });
    updateDoc(factory.ref, { [property]: checked, triggerType: property });
  }

  function handleSaveCustomer(property) {
    if (currentFactory[property] === factory[property]) {
      return;
    }
    if (
      property === "email" &&
      currentFactory[property] !== "" &&
      !validateEmail(currentFactory[property])
    ) {
      setOpenAttentionModal(true);
      setDescriptionModal("Invalid email format, please choose a valid email");
      setCurrentFactory({
        ...currentFactory,
        [property]: factory[property] || "",
      });
    } else if (property === "notes") {
      updateDoc(factory.ref, {
        [property]: currentFactory[property],
        triggerType: property,
      });
    } else {
      currentFactory[property] = currentFactory[property]
        .replace(/\s\s+/g, " ")
        .trim();
      updateDoc(factory.ref, {
        [property]: currentFactory[property],
        triggerType: property,
      });
    }
  }

  function handleChange(event, property) {
    let value = event.target.value;
    if (property === "terms") {
      value = Math.abs(value);
    }
    setCurrentFactory({ ...currentFactory, [property]: `${value}` });
  }

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          title="Attention"
          description={descriptionModal}
          isOpen={openAttentionModal}
          onClick={() => setOpenAttentionModal(false)}
          onClose={() => setOpenAttentionModal(false)}
        />
      )}

      <FactoryInfoStyled className="container">
        {factories.length > 0 && (
          <React.Fragment>
            <div className="documents-header">
              {<IntlMessages id={"general.basicinfo"} />}
            </div>
            <ul>
              {factoryFields.map((property, index) => {
                if (property === FACTORY_FIELD.CATEGORIES) {
                  if (
                    !isAllowed(GENERAL_PERMISSION_VALUE.VISIBLE_IN_VENDOR_PAGE)
                  ) {
                    return null;
                  }
                  return (
                    <li
                      key={currentFactory.id + property}
                      className={"infoField"}
                    >
                      <span className="fieldName">
                        {<IntlMessages id={`factory.${property}`} />}:
                      </span>

                      <AutocompleteCategory
                        options={categoryItems}
                        value={selectedCategories.sort(
                          sortObjectsBy("name", false)
                        )}
                        onChange={(event, newValue) => {
                          setSelectedCategories(newValue);
                        }}
                        onBlur={() => {
                          updateDoc(factory.ref, {
                            categories: selectedCategories.map(
                              (category) => category.id
                            ),
                          });
                        }}
                        selectedOptions={selectedCategories}
                        categories={categories}
                        styles={{
                          display: "block",
                          width: "70%",
                        }}
                        onCreateCategory={({ categoryItem }) => {
                          const selectedCategoriesCpy = [
                            ...selectedCategories,
                            categoryItem,
                          ];
                          setSelectedCategories(selectedCategoriesCpy);
                          updateDoc(factory.ref, {
                            categories: selectedCategoriesCpy.map(
                              (category) => category.id
                            ),
                          });
                        }}
                        canAssign={isAllowed(
                          GENERAL_PERMISSION_VALUE.ASIGN_CATEGORIES
                        )}
                        canUnassign={isAllowed(
                          GENERAL_PERMISSION_VALUE.UN_ASSIGN_CATEGORIES
                        )}
                        canCreateCategory={isAllowed(
                          GENERAL_PERMISSION_VALUE.CREATE_CATEGORIES
                        )}
                      />
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={currentFactory.id + property}
                      className={cx("infoField", {
                        initStyle: index === 0,
                      })}
                    >
                      <span className="fieldName">
                        {<IntlMessages id={`factory.${property}`} />}:
                      </span>
                      <FieldByProperty
                        property={property}
                        currentItem={currentFactory}
                        isReadOnly={
                          isReadOnly ||
                          (property === FACTORY_FIELD.TERMS &&
                            !isAllowed(
                              GENERAL_PERMISSION_VALUE.CAN_CHANGE_VENDOR_PAYMENT_TERMS
                            ))
                        }
                        handleSave={handleSaveCustomer}
                        handleChange={handleChange}
                        handleInactive={handleInactiveFactory}
                        handleReadOnlyModal={handleReadOnlyModal}
                        type={
                          property === FACTORY_FIELD.TERMS ? "number" : "text"
                        }
                      />
                    </li>
                  );
                }
              })}
            </ul>
          </React.Fragment>
        )}
      </FactoryInfoStyled>
    </React.Fragment>
  );
}

export default FactoryInfo;
