import { now } from "moment";
import { dbTables } from "../api/types/dbTables";
import { getCompanyUserDisplayName, getDashboardSearchPath } from "./helpers";
import {
  getAssignedTaskDetail,
  getDetailFromMoveDependantTask,
  getDetailFromMovedTask,
} from "./tasks";
import {
  actionType,
  activityType,
  SALES_ORDER_STATUS,
} from "./constants";
import { updateCompanyUsersUnreadActivity } from "./activitiesStream";
import { firestore } from "../firebase";
import { SALES_ORDER_FIELDS } from "./salesOrder";
import { colors } from "../assets/jss/variables";
import {
  doc,
  getDocs,
  query,
  setDoc,
  collection as collectionFirestore,
  where,
  collection,
  getDoc,
} from "firebase/firestore";
import Activity from "../api/model/activity";
import { sortObjectsBy } from "./sortingHelper";

function getActivityFromCompleted({ task, companyUsers = [] }) {
  const isTheSameUser = task.assignedTo === task.completedBy;
  const diffDays = task.dayOffset;

  const assignedUserName = companyUsers.find(
    (cUser) => cUser.id === task.assignedTo
  ).displayName;

  return `<p><input class='checkbox-input-done' type='checkbox' checked={true} disabled={true} /> <strong>${
    task.description
  }</strong> ${
    !isTheSameUser
      ? `that was assigned to <strong>@${assignedUserName}</strong>`
      : ""
  } ${
    diffDays > 0
      ? `<span class='styles-activity-stream-from-backend-red'> ${Math.abs(
          diffDays
        )} ${Math.abs(diffDays) === 1 ? "day" : "days"} late </span>`
      : diffDays < 0
      ? `<span class='styles-activity-stream-from-backend-green'> ${Math.abs(
          diffDays
        )} ${Math.abs(diffDays) === 1 ? "day" : "days"} early </span>`
      : ""
  }  </p> `;
}

export function createActivityEntryFromMovedTask({
  task,
  creationDate = now(),
  user = {},
  typeChange,
  isDependantTaskMoved,
  isMovingForward,
  diffDaysTask,
  diffDaysRemainingTasks,
  companyUsers = [],
  mainEntity,
}) {
  const { companyId } = user;
  const activity = {
    ...new Activity({
      scope: mainEntity.type || mainEntity.taskScope,
      companyId: companyId,
      creationDate: creationDate,
      type: "UPDATED_TASK",
      user: user.id || "",
    }),
  };
  if (task[actionType.COMPLETED_TASK]) {
    activity.detail = getActivityFromCompleted({
      task,
      companyUsers,
    });
    activity.type = activityType.TASK_COMPLETED;
  } else if (task[actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED]) {
    activity.detail = getDetailFromMoveDependantTask({
      task,
      companyUsers,
      type: actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED,
      user,
      dayOffsetAbs: Math.abs(task.dayOffset),
      remainingOffset: task.offsetRemainingTask,
    });
  } else if (task[actionType.DENIED_REMAINING_DAY_OFFSET_MOVED]) {
    activity.detail = getDetailFromMoveDependantTask({
      task,
      companyUsers,
      type: actionType.DENIED_REMAINING_DAY_OFFSET_MOVED,
      user,
      dayOffsetAbs: Math.abs(task.dayOffset),
      remainingOffset: task.offsetRemainingTask,
    });
  } else if (task[actionType.CHANGED_ASSIGNED_TO]) {
    activity.detail = getAssignedTaskDetail({
      description: task.description,
      currentUser: getCompanyUserDisplayName(companyUsers, task.assignedTo),
      lastUser: getCompanyUserDisplayName(companyUsers, task.lastAssignedTo),
    });
    activity.type = activityType.REASSIGNED_TASK;
  } else {
    activity.detail = getDetailFromMovedTask({
      task: task,
      typeChange,
      isDependantTaskMoved,
      isMovingForward,
      diffDaysTask,
      diffDaysRemainingTasks,
    });
  }
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${mainEntity.parentCollection}/${mainEntity.id}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );

  updateCompanyUsersUnreadActivity({
    activity,
    companyId,
    users: companyUsers,
    order: mainEntity,
  });
}

export const FIELD_NAME = {
  UNIQUE_ITEMS: "uniqueItems",
  CBM: "CBM",
  AMOUNT: "amount",
  DATE: "date",
  INVOICE: "invoice",
  SHIP_DATE: "shipDate",
  TOTAL: "total",
  WEIGHT: "weight",
  DEPOSIT: "deposit",
  TOTAL_SHIPMENTS: "totalShipments",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  BALANCE: "balance",
  SHIPPING_TERMS: "shippingTerms",
  SHIPMENT_WINDOW: "shipmentWindow",
  DELIVERY_WINDOW: "deliveryWindow",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  DISCOUNT: "discount",
  CREATION_DATE: "creationDate",
  SUB_TOTAL: "subtotal",
  TOTAL_POS: "totalPOs",
  TOTAL_DISCOUNT: "totalDiscount",
  NUMBER: "number",
};



export const shipmentWindowHeaderCells = [
  {
    id: "shipment",
    label: "Shipment",
    align: "left",
    reference: "number",
    canBeSort: true,
    style: {
      width: 120,
      padding: "8px 0px 8px 29px",
      color: colors.darkGray,
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "date",
    label: "Ship Date",
    reference: "shipDate",
    canBeSort: true,
    style: {
      width: 105,
      padding: "8px 15px 8px 0px",
      color: colors.darkGray,
      textAlign: "right",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "finalDestination",
    label: "Final Destination",
    reference: "finalDestination",
    canBeSort: true,
    style: {
      width: 250,
      padding: "8px 6px",
      color: colors.darkGray,
      textAlign: "left",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
];

export const shipmensHeaderCells = [
  {
    id: "shipment",
    label: "Shipment",
    align: "left",
    reference: "number",
    canBeSort: true,
    style: {
      width: 120,
      padding: "8px 0px 8px 29px",
      color: colors.darkGray,
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "date",
    label: "Ship Date",
    reference: "shipDate",
    canBeSort: true,
    style: {
      width: 105,
      padding: "8px 15px 8px 0px",
      color: colors.darkGray,
      textAlign: "right",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "deliveryDate",
    label: "Delivery Date",
    reference: "deliveryDate",
    canBeSort: true,
    style: {
      width: 125,
      padding: "8px 15px 8px 0px",
      color: colors.darkGray,
      textAlign: "right",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "finalDestination",
    label: "Final Destination",
    reference: "finalDestination",
    canBeSort: true,
    style: {
      width: 250,
      padding: "8px 6px",
      color: colors.darkGray,
      textAlign: "left",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
];

export const deliveryWindowHeaderCells = [
  {
    id: "shipment",
    label: "Shipment",
    align: "left",
    reference: "number",
    canBeSort: true,
    style: {
      width: 120,
      padding: "8px 0px 8px 29px",
      color: colors.darkGray,
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "date",
    label: "Delivery Date",
    reference: "deliveryDate",
    canBeSort: true,
    style: {
      width: 125,
      padding: "8px 15px 8px 0px",
      color: colors.darkGray,
      textAlign: "right",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "finalDestination",
    label: "Final Destination",
    reference: "finalDestination",
    canBeSort: true,
    style: {
      width: 250,
      padding: "8px 6px",
      color: colors.darkGray,
      textAlign: "left",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
];

export const itemTableRowHeight = {
  NORMAL: 41,
  WITH_IMAGE: 65,
};

export const salesOrderAmountFieldsToVerify = [
  SALES_ORDER_FIELDS.DEPOSIT,
  SALES_ORDER_FIELDS.DISCOUNT,
  SALES_ORDER_FIELDS.POTENTIAL_LATE_FEE,
  "price",
];

export const poAmountFieldsToVerify = [
  SALES_ORDER_FIELDS.DEPOSIT,
  SALES_ORDER_FIELDS.DISCOUNT,
  SALES_ORDER_FIELDS.POTENTIAL_LATE_FEE,
  "unitCost",
];

export const shipmentAmountFieldsToVerify = [
  SALES_ORDER_FIELDS.POTENTIAL_LATE_FEE,
  "unitCost",
];


export const getRemainingPromises = function ({
  list = [],
  field = "salesOrderIds",
  actualList = [],
  incomingList = [],
  incomingListField = "salesOrderIds",
  type = "array",
}) {
  let arrIds = [];
  if (type === "singleField") {
    list.forEach((el) => {
      if (el[field]) {
        if (!arrIds.includes(el[field])) {
          arrIds.push(el[field]);
        }
      }
    });
    incomingList.forEach((el) => {
      if (el[incomingListField] && el[incomingListField].length > 0) {
        el[incomingListField].forEach((id) => {
          if (!arrIds.includes(id)) {
            arrIds.push(id);
          }
        });
      }
    });
  } else if (type === "array") {
    list.forEach((el) => {
      const arrayList = el[field] || [];
      arrIds = [...arrIds, ...arrayList];
    });
    incomingList.forEach((el) => {
      if (el[field] && el[field].length > 0) {
        const arrayList = el[field] || [];
        arrIds = [...arrIds, ...arrayList];
      }
    });
    arrIds = [...new Set(arrIds)];
  }
  arrIds = arrIds.filter((id) => !actualList.find((el) => el.id === id));
  const vectorIds = [];
  do {
    vectorIds.push(arrIds.splice(0, 10));
  } while (arrIds.length > 0);
  return vectorIds;
};

export const arrPromisesResolved = async function ({
  arrPromises = [],
  withRef = false,
}) {
  const result = [];
  await Promise.all(arrPromises)
    .then((data) => {
      data.forEach((snapshot) => {
        const dataResolve = snapshot.docs.map((doc) => {
          if (withRef) {
            return { ...doc.data(), ref: doc.ref };
          }
          return doc.data();
        });
        result.push(...dataResolve);
      });
      return result;
    })
    .catch((error) => console.log("PROMISSE TO GET POs DB...", error));
  return result;
};

export const getOrderByIds = async ({ list, collection, companyId }) => {
  const vectorIds = [];
  do {
    vectorIds.push(list.splice(0, 10));
  } while (list.length > 0);

  let arrPromises = [];
  vectorIds.forEach((idsList) => {
    if (idsList.length > 0) {
      const promiseOrder = getDocs(
        query(
          collectionFirestore(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${collection}`
          ),
          where("id", "in", idsList),
          where("status", "!=", SALES_ORDER_STATUS.VOIDED)
        )
      );
      arrPromises.push(promiseOrder);
    }
  });
  const arrResolved = await arrPromisesResolved({
    arrPromises,
  });
  return arrResolved;
};

export const QUERY_PARAMS_SCOPE = {
  SHIPMENT: "SHIPMENT",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SALES_ORDER: "SALES_ORDER",
};

export async function onChangeQueryParamsv2({
  scope = "",
  shipmentId = "",
  purchaseOrderId = "",
  salesOrderId = "",
  queryParams = {},
  companyId = "",
  navigate = () => {},
  poRedirect,
}) {
  if (scope === QUERY_PARAMS_SCOPE.SHIPMENT) {
    navigate(
      getDashboardSearchPath(
        {
          ...queryParams,
          shipmentId,
        },
        { replace: true }
      )
    );
  } else if (scope === QUERY_PARAMS_SCOPE.PURCHASE_ORDER) {
    if (poRedirect) {
      const shipmentsIds = poRedirect.shipmentIds || [];
      const currentShipmentId = queryParams.shipmentId || "";
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          purchaseOrderId: poRedirect.id,
          shipmentId: shipmentsIds.includes(currentShipmentId)
            ? currentShipmentId
            : shipmentsIds[0],
        }),
        { replace: true }
      );
    } else {
      const shipmentsByPOSnap = await getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
          ),
          where("purchaseOrderIds", "array-contains", purchaseOrderId)
        )
      );
      const shipmentsByPO = shipmentsByPOSnap.docs
        .map((doc) => doc.data())
        .sort(sortObjectsBy("number", false));
      const shipmentId = shipmentsByPO[0] ? shipmentsByPO[0].id : "";
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          purchaseOrderId,
          shipmentId,
        }),
        { replace: true }
      );
    }
  } else if (scope === QUERY_PARAMS_SCOPE.SALES_ORDER) {
    const salesOrderSnap = await getDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${salesOrderId}`
      )
    );
    const salesOrder = salesOrderSnap.data();
    const poIds = salesOrder.purchaseOrderIds || [];
    const newPOid = !poIds.includes(queryParams.purchaseOrderId)
      ? [poIds[0] || ""]
      : queryParams.purchaseOrderId;

    const shipmentsByPOSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
        ),
        where("purchaseOrderIds", "array-contains", newPOid)
      )
    );
    console.log("SHIPMENTS BY PO ", shipmentsByPOSnap.size);
    const shipmentsByPO = shipmentsByPOSnap.docs
      .map((doc) => doc.data())
      .sort(sortObjectsBy("number", false));
    const shipmentId = shipmentsByPO[0] ? shipmentsByPO[0].id : "";
    console.log("ON CHANGE QUERY PARAMS V2 ", shipmentId, shipmentsByPO);
    //

    navigate(
      getDashboardSearchPath({
        ...queryParams,
        salesOrderId: salesOrder.id,
        purchaseOrderId: newPOid,
        shipmentId,
      }),
      { replace: true }
    );
  }
}

export const MENTION_POPOVER_SCOPES = {
  LIST_VIEW: "LIST_VIEW",
  ORDER_DASHBOARD: "ORDER_DASHBOARD",
};

export const SEARCH_BOX_TYPES = {
  SKU_TABLE: "SKU_TABLE",
  NOTES: "NOTES",
  DOCUMENTS: "DOCUMENTS",
  NOTE_DOCUMENTS: "NOTE_DOCUMENTS",
  BASIC: "BASIC",
};
