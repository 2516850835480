import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import Company from "../../api/model/company";
import UploadAvatar from "../General/UploadAvatar";
import CustomModal from "../Modal/Modal";
import CustomButton from "../Buttons/CustomButton";
import IntlMessages from "../../util/IntlMessages";
import User from "../../api/model/user";
import { getRandomId } from "../../helpers/helpers";
import FormTextField from "../TextFields/FormTextField";
import userTypes from "../../api/types/userTypes";
import SpreadSheetOptions from "./SpreadSheetOptions";
import Loader from "../General/Loader";

import { FormGroup, Switch, TextField } from "@mui/material";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";

function CompanyModal({ open, onClose, company }) {
  const [selectedCompany, setSelectedCompany] = useState(
    new Company({
      id: getRandomId(),
    })
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [user, setUser] = useState(
    new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN })
  );
  const [enableSpreadSheet, setEnableSpreadSheet] = useState(false);
  const [spreadSheetMessage, setSpreadSheetMessage] = useState("");
  const [isSuccessSpreadSheetMessage, setIsSuccessSpreadSheetMessage] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [sheetID, setSheetID] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    if (!company) {
      setSelectedCompany(
        new Company({
          id: getRandomId(),
        })
      );
      setErrorMessage("");
    } else {
      setSelectedCompany(company);
      setErrorMessage("");
      setUser(new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN }));
    }
    if (!open) {
      setUser(new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN }));
    }
  }, [open]);

  function onChangeField(field, event) {
    setCompanyName(event.target.value);
    const selectedCompanyName = event.target.value
      .replace(/\s\s+/g, " ")
      .trim();
    setSelectedCompany({ ...selectedCompany, [field]: selectedCompanyName });
    const companyNameValid = event.target.value.replace(/\s\s+/g, " ").trim();
    if (!selectedCompanyName) {
      return setErrorMessage("errormessage.nocompanyname");
    }
    if (selectedCompanyName.length > 55) {
      return setErrorMessage("errormessage.companynametoolong");
    }
    if (companyNameValid.match(/[<>!@#$%^&*]+/i)) {
      return setErrorMessage("errormessage.specialcharacters");
    }
    return setErrorMessage("");
  }
  const onChangeFieldValue = (field) => (value) => {
    setSelectedCompany({ ...selectedCompany, [field]: value });
  };

  function onChange(field) {
    return function (ev) {
      if (ev.target.value[0] !== "@") {
        if (field === "displayName") {
          setUser({ ...user, [field]: "@" });
        } else {
          setUser({ ...user, [field]: ev.target.value });
        }
      } else {
        setUser({ ...user, [field]: ev.target.value });
      }
    };
  }

  async function handleSubmit() {
    if (accessToken && sheetID) {
      setLoading(true);
      console.log("CREATING BASED ON A SPREASHEET");
      const functions = getFunctions();
      const callable = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: `democompany`,
          env: globalEnvironment,
        })
      );
      callable({
        ACCESS_TOKEN: accessToken,
        SHEET_ID: sheetID,
        company: selectedCompany,
      }).then((result) => {
        console.log("-----handleValidate", result);
        const { data } = result;
        const { status, message } = data;
        const superAdmin = data?.spreadSheetData?.superAdmin;
        console.log("SUPER ADMIN", superAdmin);
        if (superAdmin) {
          setUser({
            ...superAdmin,
            companyId: selectedCompany.id,
            displayName: "@" + superAdmin.displayName,
          });
        }
        if (status !== 200) {
          setIsSuccessSpreadSheetMessage(false);
          setSpreadSheetMessage(message);
        } else {
          setIsSuccessSpreadSheetMessage(true);
          setSpreadSheetMessage(message);
        }
        setLoading(false);
      });
    } else {
      console.log("CREATING BASED ON A FORM ONLY");
    }
  }

  function handleKeyPress(keyEvent) {
    if (errorMessage || !selectedCompany.name) {
      return;
    }
    if (keyEvent.key === "Enter") {
      handleSubmit();
    }
  }

  function handleSuccessGoogleOAuthLogged() {
    console.log("handleSuccessGoogleOAuthLogged");
    setIsSuccessSpreadSheetMessage(true);
    setSpreadSheetMessage("Successfully Logged");
  }

  return (
    <CustomModal
      isOpen={open}
      onClose={loading ? () => {} : onClose}
      header={<IntlMessages id={"companymodal.custommodal.createcompany"} />}
      className="new-company-modal"
    >
      <form
        onSubmit={(ev) => {
          handleSubmit();
          ev.preventDefault();
        }}
        style={{
          position: "relative",
        }}
      >
        {loading && (
          <Loader
            style={{
              justifyContent: "center",
              display: "flex",
              zIndex: 1000,
              alignItems: "center",
            }}
          />
        )}

        <div className="companyInfo">
          <div className="avatar">
            Company Avatar
            <UploadAvatar
              onUpload={onChangeFieldValue("url_logo")}
              obj={selectedCompany}
              storageLocation={`${dbTables.COMPANIES}/${selectedCompany.id}`}
            />
          </div>
          <div className="name">
            Company Name:
            <TextField
              onKeyPress={handleKeyPress}
              helperText={
                errorMessage ? <IntlMessages id={errorMessage} /> : ""
              }
              error={errorMessage}
              label={"Name"}
              variant="outlined"
              fullWidth
              autoFocus
              value={companyName}
              onChange={(event) => {
                onChangeField("name", event);
              }}
            />
          </div>
        </div>
        <div className="bodyNewCompany">
          <div className="metadata">
            <FormGroup className="userAvatar">
              Super Admin User
              <UploadAvatar
                obj={{
                  url_logo: user.avatar,
                  name: user.firstName,
                }}
                storageLocation={`${dbTables.USERS}/${user.id}`}
                onUpload={(url) => setUser({ ...user, avatar: url })}
                className="user-avatar"
                style={{
                  zIndex: 10,
                  opacity: 0,
                  width: 80,
                  height: 80,
                  borderRadius: "50%",
                  position: "absolute",
                  cursor: "pointer",
                }}
                classNameHover="avatar-hover-userform"
              />
            </FormGroup>
            <FormGroup>
              <FormTextField
                width={250}
                label="generic.textForm.displayName"
                value={user.displayName}
                onChange={onChange("displayName")}
                placeholder="userscompany.createuserform.displayname.required"
                required={true}
                inputProps={{ maxLength: 20, minLength: 2 }}
                name="displayname"
                disabled={enableSpreadSheet}
              />
              <FormTextField
                label="generic.textForm.email"
                width={250}
                value={user.email}
                onChange={onChange("email")}
                placeholder="userscompany.createuserform.email.required"
                required={true}
                type="email"
                fullWidth={true}
                autoFocus={true}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                width={250}
                label="generic.textForm.firstName"
                value={user.firstName}
                onChange={onChange("firstName")}
                placeholder="userscompany.createuserform.firstname.required"
                required={true}
                inputProps={{ maxLength: 20, minLength: 2 }}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                width={250}
                label="generic.textForm.lastName"
                value={user.lastName}
                onChange={onChange("lastName")}
                placeholder="userscompany.createuserform.lastname.required"
                required={true}
                inputProps={{ maxLength: 20, minLength: 2 }}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                label="generic.textForm.cellPhone"
                value={user.cellPhone}
                onChange={(event) => setUser({ ...user, cellPhone: event })}
                required={false}
                width={250}
                type="phone"
                autoFocus={true}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                width={250}
                label="generic.textForm.role"
                value={user.role}
                onChange={onChange("role")}
                placeholder="userscompany.createuserform.role.required"
                required={true}
                disabled={enableSpreadSheet}
              />
            </FormGroup>
          </div>
        </div>

        <div className="buttomAction">
          <CustomButton
            noSubmit
            margin="0px 8px"
            type="cancel"
            onClick={() => {
              setCompanyName("");
              setEnableSpreadSheet(false);
              setErrorMessage("");
              setSpreadSheetMessage("");
              onClose();
            }}
          >
            {"generic.text.cancel"}
          </CustomButton>
          <CustomButton
            disabled={!selectedCompany.name}
            margin="0 0 0 8px"
            type={"success"}
          >
            {company ? "generic.text.update" : "generic.text.create"}
          </CustomButton>
        </div>
      </form>
      <div>
        <Switch
          checked={enableSpreadSheet}
          onChange={() => {
            setEnableSpreadSheet(!enableSpreadSheet);
            setIsSuccessSpreadSheetMessage(false);
            setSpreadSheetMessage("");
          }}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {spreadSheetMessage && (
          <div
            style={
              isSuccessSpreadSheetMessage
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "green",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "red",
                  }
            }
          >
            {spreadSheetMessage}
          </div>
        )}
        {enableSpreadSheet && (
          <SpreadSheetOptions
            handleShowMessage={(message) => {
              setIsSuccessSpreadSheetMessage(false);
              setSpreadSheetMessage(message);
              setLoading(false);
            }}
            handleSuccessGoogleOAuthLogged={handleSuccessGoogleOAuthLogged}
            setAccessToken={setAccessToken}
            setSheetID={setSheetID}
          />
        )}
      </div>
    </CustomModal>
  );
}

export default CompanyModal;
