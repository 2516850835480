import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavPickerPopoverStyled } from "./styles";
import Mark from "mark.js";
import SearchBox from "../SearchBox/SearchBox";
import NavPickerItem from "./NavPickerItem";
import { getNavPickerFilter } from "../../container/screenHelpers";

function NavPickerv2({
  anchorEl,
  open,
  onClose,
  list,
  scope,
  selectedId,
  handleDateActivities = () => {},
  user,
  sizeNumber,
  sizeSubtitle,
  searchBox = false,
  searchBoxText = "Search for Sales Order, Customer, Ref # or Customer PO #",
  searchBoxSort = {},
}) {
  const [search, setSearch] = useState("");

  useEffect(() => {
    performMark(search);
  }, [search]);

  const markInstance = new Mark(document.getElementById("orderList"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: [".shipmentIconPopover > *"],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  return (
    <NavPickerPopoverStyled
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: "paper" }}
      className="navPickerPopoverContainer"
      getContentAnchorEl={null}
      onClose={() => onClose()}
    >
      {searchBox && list.length > 0 && (
        <div className="textFieldContainer">
          <SearchBox
            placeholder={searchBoxText}
            onDebounceValue={(filter, value) => {
              setSearch(value);
            }}
            value={search}
            hasBeenReseted={() => {
              setSearch("");
            }}
            style={{ width: "100%", minWidth: 300 }}
            autoFocus
          />
        </div>
      )}
      <div className="scrollList" id="orderList">
        {getNavPickerFilter({
          list,
          search,
          searchBox,
          searchBoxSort,
        }).map((item) => {
          const selectedStyle = item.id === selectedId ? "selectedStyle" : "";
          return (
            <NavPickerItem
              item={item}
              selectedStyle={selectedStyle}
              search={search}
              list={list}
              onClose={onClose}
              open={open}
              scope={scope}
              sizeNumber={sizeNumber}
              sizeSubtitle={sizeSubtitle}
              user={user}
              handleDateActivities={handleDateActivities}
            />
          );
        })}
      </div>
    </NavPickerPopoverStyled>
  );
}

NavPickerv2.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NavPickerv2;
