import React, { useRef, useState } from "react";
import {
  getTagsLabel,
  isTradeDashEmployee,
  getProgressValue,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import { NavLink } from "react-router-dom";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
  PO_DIAMOND_STATUS,
  taskStatus,
  screenPath,
  ORDER_DASHBOARD_ACTUAL_VIEWS,
} from "../../helpers/constants";
import TagsModal from "../../components/Modal/TagsModal";
import userTypes from "../../api/types/userTypes";
import { dbTables } from "../../api/types/dbTables";
import {
  checkSpecificPermissions,
  usePermissions,
  verifyPermission,
} from "../../hooks/permissions";
import {
  getTagStatus,
  orderType,
  SALES_ORDER_VIEW_FIELDS,
} from "../../helpers/salesOrder";
import { useFactories } from "../../hooks/factories";
import MentionPopover from "./MentionPopover";
import { MENTION_POPOVER_SCOPES } from "../../helpers/orderDashboard";
import POsItem from "./POsItem";
import ShipmentsItem from "./ShipmentsItem";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { TableCell } from "@mui/material";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import {
  getDiamondStatus,
  getDisclouserArrow,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";
import TaskListPopover from "../../components/DatePicker/TaskListPopover";
import CustomTableRowSelector from "./CustomTableRowSelector";

function SalesOrdersTableRow({
  item,
  favorites,
  user,
  isAllowed = () => {},
  customers,
  nonLookUpfilters = {},
  headers = [],
  tags,
  onUpdateItem,
  filters = { factory: [] },
  salesOrderSetup = {},
  onChangeSalesOrderSetup,
  favoritesDB = [],
}) {
  const customer = customers.find((cus) => cus.id === item.customerId);
  const factories = useFactories();
  const currentTags = filters.tag || [];
  const [taskPopoverData, setTaskPopoverData] = useState({
    open: false,
    diamondStatus: "",
  });
  const diamondRef = useRef(null);
  const permissions = usePermissions();

  function handleChangeSalesOrderSetup() {
    const setup = salesOrderSetup[item.id] || {};
    if (setup.POs || setup.shipments) {
      return onChangeSalesOrderSetup({
        ...salesOrderSetup,
        [item.id]: {
          POs: false,
          shipments: false,
        },
      });
    } else {
      return onChangeSalesOrderSetup({
        ...salesOrderSetup,
        [item.id]: {
          POs: true,
          shipments: true,
        },
      });
    }
  }

  function SOTableRowSelector({ header = {}, index }) {
    const { field, enabled } = header;
    let paddingLeft;
    const purchaseOrders = item.purchaseOrders || [];
    const filterPurchaseOrders = purchaseOrders.filter((po) =>
      verifyPermission({
        user: user,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    );

    switch (field) {
      case SALES_ORDER_VIEW_FIELDS.FAVORITE:
        return (
          <TableCell key={index} className="favorite">
            {getDisclouserArrow({
              handleChangeSetup: handleChangeSalesOrderSetup,
              currentSetup: salesOrderSetup[item.id],
              shipments: item.shipments,
              purchaseOrders: item.purchaseOrders,
              type: orderType.SALES_ORDER,
            })}
          </TableCell>
        );

      case SALES_ORDER_VIEW_FIELDS.DIAMOND:
        return (
          <TableCell key={index} className="diamond">
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
              }}
              ref={diamondRef}
            >
              {getStarFavorite({
                favorites: favorites,
                itemId: item.id,
                removeFavorite: () =>
                  removeFavorite({
                    favoritesDB,
                    id: item.id,
                  }),
                addFavorite: () =>
                  addFavorite({
                    id: item.id,
                    user,
                    favoritesDB,
                    dbTable: dbTables.SALES_ORDER_FAVORITES,
                  }),
              })}
              {getDiamondStatus({
                label: (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {item.diamondStatus === PO_DIAMOND_STATUS.LATE
                      ? "There are one or more late task"
                      : "There are one or more tasks due today or tomorrow"}
                    <br />
                    <span style={{ textAlign: "center" }}>
                      click to see more
                    </span>
                  </div>
                ),
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
                style: { cursor: "pointer" },
                onClick: () => {
                  setTaskPopoverData({
                    open: true,
                    diamondStatus:
                      item.diamondStatus === PO_DIAMOND_STATUS.LATE
                        ? taskStatus.LATE
                        : taskStatus.NEAR_DUE,
                  });
                },
              })}
            </div>
          </TableCell>
        );

      case SALES_ORDER_VIEW_FIELDS.PROGRESS:
        return item.status === SALES_ORDER_STATUS.VOIDED ? (
          <TableCell key={index} />
        ) : (
          <TableCell key={index} className="circularProgress">
            <CircularStaticProgress
              label={`${item.completedTasks}/${item.totalTasks} Tasks`}
              value={+getProgressValue(item)}
              thickness={16}
              style={{
                transform: "rotate(270deg)",
                color: "#39F",
              }}
              size={18}
            />
          </TableCell>
        );

      case SALES_ORDER_VIEW_FIELDS.MENTIONS:
        return (
          <TableCell key={index} className="mention">
            <MentionPopover
              currentSalesOrder={item}
              user={user}
              shipmentsDB={item.shipments}
              scope={MENTION_POPOVER_SCOPES.LIST_VIEW}
              purchaseOrders={purchaseOrders}
            />
          </TableCell>
        );

      case SALES_ORDER_VIEW_FIELDS.NUMBER:
        const [firstPO = {}] = purchaseOrders;
        const shipmentIds = firstPO.shipmentIds || [];
        const soTags = item.tags || [];
        const tagStatus = getTagStatus({
          tags: item.tags,
          currentTags: currentTags,
        });
        return (
          enabled && (
            <TableCell key={index} className="number" id="soContainerList">
              <div className={"number-table-container"}>
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={item.id}
                    label={getTagsLabel(tags, soTags).tagsString}
                    tags={getTagsLabel(tags, soTags).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.SALES_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={tagStatus}
                  />
                )}
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: item.id,
                    purchaseOrderId: firstPO.id,
                    shipmentId: firstPO.shipmentIds
                      ? firstPO?.shipmentIds[0]
                      : "",
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                  })}
                  className="navLink"
                  id="list-view-content-salesorder"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <SalesOrderBadge number={item.number} />
                </NavLink>
                {filterPurchaseOrders.length > 0 && (
                  <ThreePanelButton
                    soId={item.id}
                    poId={firstPO.id}
                    shipmentId={shipmentIds[0]}
                  />
                )}
              </div>
            </TableCell>
          )
        );

      case SALES_ORDER_VIEW_FIELDS.POS:
        paddingLeft = 36;
        if (nonLookUpfilters.poDiamonds) {
          paddingLeft = paddingLeft + 20;
        }
        if (nonLookUpfilters.poProgress) {
          paddingLeft = paddingLeft + 26;
        }
        return (
          enabled && (
            <TableCell key={index} className="POs">
              <POsItem
                item={item}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                poDiamonds={nonLookUpfilters.poDiamonds}
                poProgress={nonLookUpfilters.poProgress}
                tags={tags}
                salesOrderSetup={salesOrderSetup}
                factories={factories}
                onChangeSalesOrderSetup={onChangeSalesOrderSetup}
                iconStyle={{ paddingLeft }}
                query={nonLookUpfilters.query}
              />
            </TableCell>
          )
        );

      case SALES_ORDER_VIEW_FIELDS.SHIPMENTS:
        paddingLeft = 33;
        if (nonLookUpfilters.shipmentDiamonds) {
          paddingLeft = paddingLeft + 21;
        }
        if (nonLookUpfilters.shipmentProgress) {
          paddingLeft = paddingLeft + 25;
        }
        return (
          enabled && (
            <TableCell key={index} className="shipmentNumber">
              <ShipmentsItem
                item={item}
                filters={filters}
                user={user}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={salesOrderSetup}
                onChangeSalesOrderSetup={onChangeSalesOrderSetup}
                salesOrderId={item.id}
                type={"SO"}
                permissions={permissions}
                shipmentDiamonds={nonLookUpfilters.shipmentDiamonds}
                shipmentProgress={nonLookUpfilters.shipmentProgress}
                iconStyle={{ paddingLeft }}
                query={nonLookUpfilters.query}
              />
            </TableCell>
          )
        );

      case SALES_ORDER_VIEW_FIELDS.CUSTOMER:
        return (
          enabled &&
          customer && (
            <TableCell key={index} className="customerName">
              <TooltipTD label={customer.name} className="customized-tooltip">
                <NavLink
                  to={
                    !(
                      user.role === userTypes.SUPER_ADMIN ||
                      user.role === userTypes.TRADEDASH_EMPLOYEE ||
                      checkSpecificPermissions({
                        userPermissions: user.permissions,
                        permissionToCheck: [
                          item.customerId,
                          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                        ],
                      })
                    )
                      ? "#"
                      : `/app/${screenPath.CUSTOMER}/${item.customerId}`
                  }
                  className="navLink"
                  id="list-view-content-customer"
                >
                  <span className="span-description">{customer.name}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      default:
        return (
          <CustomTableRowSelector
            index={item.id + header.field}
            header={header}
            item={item}
          />
        );
    }
  }
  return (
    item && (
      <MainRowStyled
        key={item.id + "MainRowStyled"}
        id="salesOrderTableRow"
        className={cx(mainRowClasses.SOTableRow, mainRowClasses.hoverEffect)}
      >
        {taskPopoverData.open && (
          <TaskListPopover
            anchorEl={diamondRef}
            diamondStatus={taskPopoverData.diamondStatus}
            entityDB={dbTables.SALES_ORDERS}
            entityId={item.id}
            onClose={() => {
              setTaskPopoverData({
                open: false,
                diamondStatus: "",
              });
            }}
            item={item}
          />
        )}
        {headers
          .filter(
            (header) =>
              header.enabled &&
              !(
                header[GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT] &&
                !isAllowed(GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT)
              )
          )
          .map((header) => {
            if (!!header.type) {
              return (
                <CustomTableRowSelector
                  index={item.id + header.field}
                  header={header}
                  item={item}
                />
              );
            } else {
              return SOTableRowSelector({
                header,
                index: item.id + header.field,
              });
            }
          })}
        <TableCell className="LAST_BLANK_COLUMN" />
        <TableCell className="LAST_100_WIDTH_COLUMN" />
      </MainRowStyled>
    )
  );
}

export default SalesOrdersTableRow;
